import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../../crowdfunding_basics/crowdfunding_basics.less'
import DateInputBox from 'components/common/date_input_box'
import CurrencyInputBox from 'components/common/currency_input'
import { CROWDFUNDING_CAMPAIGN_MAX_DAYS } from 'shared/shared_consts'
import {
  CaptionText,
  TextInput,
  DropdownMenu,
  HeadingText,
} from '@paypalcorp/pp-react'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import { useIntl, getDefaultCurrencyCode } from 'intl'
import { Message } from '@paypalcorp/worldready-react'

const PersonalBasicsForm = props => {
  const {
    categoryList,
    currency,
    disclaimer,
    fields: { poolName, goalAmount, endDate, category },
    formattedMaxGoalAmount,
    shouldAutoFocus,
    timeZone,
    isTitleInfoAndMinDescriptionEnabled,
  } = props

  const { formatMessage } = useIntl()
  const helpTextId = isTitleInfoAndMinDescriptionEnabled
    ? 'components.pages.crowdfunding.pool_basics.personal_tip_description'
    : 'components.pages.crowdfunding.pool_basics.tip_description'
  return (
    <>
      <HeadingText size="sm" styleName="title">
        <Message id="components.pages.crowdfunding.pool_basics.title" />
      </HeadingText>

      <div styleName="textbox-wrapper">
        <TextInput
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.personal_pool_name_title'
          )}
          {...domOnlyProps(poolName)}
          errorText={getFormError(poolName)}
          autoFocus={shouldAutoFocus}
          helperText={formatMessage(helpTextId)}
          data-testid="basics-name"
        />
      </div>

      <div styleName="category">
        <DropdownMenu
          label={formatMessage(
            'components.pages.crowdfunding.pool_story.category_title'
          )}
          dataNemo="pool-category"
          data-testid="basics-category"
          options={categoryList}
          value={category.value}
          onChange={event => category.onChange(event.target.value)}
        />
      </div>

      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.goal_title'
          )}
          dataNemo="goal-amount"
          dataTestId="basics-amount"
          disableCents={true}
          errorText={getFormError(goalAmount)}
          currencyCode={currency || getDefaultCurrencyCode()}
          helperText={formatMessage(
            'components.pages.crowdfunding.pool_basics.goal_tip_description',
            {
              maxGoalAmount: formattedMaxGoalAmount,
            }
          )}
          {...goalAmount}
        />
      </div>

      <div styleName="textbox-wrapper">
        <DateInputBox
          id="end_date"
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.end_date_title',
            {
              maxDays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
            }
          )}
          dataNemo="date-pool-ends"
          dataTestId="basics-end-date"
          errorText={getFormError(endDate)}
          {...endDate}
          timeZone={timeZone}
          useEndOfDay={false}
        />
      </div>

      {disclaimer && (
        <CaptionText styleName="gn-disclaimer">{disclaimer}</CaptionText>
      )}
    </>
  )
}

PersonalBasicsForm.defaultProps = {
  isTitleInfoAndMinDescriptionEnabled: false,
}

PersonalBasicsForm.propTypes = {
  categoryList: PropTypes.array,
  currency: PropTypes.string,
  disclaimer: PropTypes.string,
  fields: PropTypes.object.isRequired,
  formattedMaxGoalAmount: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  timeZone: PropTypes.string,
  isTitleInfoAndMinDescriptionEnabled: PropTypes.bool,
}

export default cssModules(PersonalBasicsForm, styles)
