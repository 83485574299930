import CampaignHeader from './crowdfunding_campaign_header'
import { connect } from 'react-redux'
import { formatName } from 'intl'
import {
  getCampaignSelector,
  getCampaignCategorySelector,
} from 'redux/selectors/campaign'
import { getInitials } from 'shared/helpers/name_helpers'
import { generateCategoryName } from 'client/lib/category'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  const ownerName =
    campaign.owner.business_name ||
    formatName(ownProps.worldReady, {
      givenName: campaign.owner.first_name,
      surname: campaign.owner.last_name,
    })
  const category = getCampaignCategorySelector(state, ownProps)
  const categoryName = generateCategoryName(state, category)

  return {
    campaignId: campaign.id,
    canChipIn: campaign.can_chip_in,
    categoryName,
    charity: campaign.charity,
    creatorName: ownerName,
    end_date: campaign.end_date,
    initials: getInitials(ownerName),
    paypalDomain: state.geo.paypalDomain,
    poolName: campaign.title,
    profileImage: campaign.owner.profile_photo,
    discoverable: campaign.discoverable,
    ...ownProps,
  }
}

export default injectWorldReady(connect(mapStateToProps)(CampaignHeader))
