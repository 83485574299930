import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_select_cover.less'
import { DEAFULT_COVER_IMAGES } from 'shared/shared_consts'
import PrivateCover from './partials/private_cover'
import PersonalAndCharityCover from './partials/personal_charity_cover'
import DefaultImages from './partials/default_images'

const defaultImgGen = () =>
  Math.floor(Math.random() * DEAFULT_COVER_IMAGES.length) // NOSONAR - Not used in a security context
const defaultImage1 = defaultImgGen()
let defaultImage2 = defaultImgGen()

//Generate 2nd default image
const getDefaultImage2 = () => {
  if (defaultImage2 === defaultImage1) {
    defaultImage2 = defaultImgGen()
    getDefaultImage2()
  }
}
getDefaultImage2()

let defaultImage3 = defaultImgGen()

const getDefaultImgGen3 = () => {
  if (defaultImage2 === defaultImage3 || defaultImage1 === defaultImage3) {
    defaultImage3 = defaultImgGen()
    getDefaultImgGen3()
  }
}

// Generate third default image for private fundraiser
getDefaultImgGen3()

function CrowdFundingSelectCover(props) {
  const coverForSelectedCategory = props.defaultCategoryCover
  const checkIfUploadedImg = Boolean(
    props.fields.backgroundImage.value &&
      !DEAFULT_COVER_IMAGES.includes(props.fields.backgroundImage.value)
  )

  const [selectedPhoto, setSelectedPhoto] = useState(
    checkIfUploadedImg
      ? props.fields.backgroundImage.value
      : coverForSelectedCategory
  )
  useEffect(
    () => {
      if (props.fields.backgroundImage.value !== selectedPhoto) {
        props.fields.backgroundImage.onChange(selectedPhoto)
      }
    },
    [selectedPhoto]
  )

  useEffect(() => {
    // Select default photo for private fundraiser
    if (props.isPrivateCampaign && !selectedPhoto) {
      setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage3])
    }
  }, [])

  const commonProps = {
    defaultImage1,
    defaultImage2,
    defaultImage3,
    setSelectedPhoto,
    selectedPhoto,
  }

  const CoverComponent = props.isPrivateCampaign
    ? PrivateCover
    : PersonalAndCharityCover

  return (
    <>
      <CoverComponent {...props} {...commonProps} />
      <DefaultImages {...commonProps} />
    </>
  )
}

CrowdFundingSelectCover.propTypes = {
  cdnHost: PropTypes.string,
  handleSelectCover: PropTypes.func,
  defaultCategoryCover: PropTypes.string,
  fields: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
  }),
  charity: PropTypes.object,
  isPrivateCampaign: PropTypes.boolean,
  campaignType: PropTypes.string.isRequired,
}

export default cssModules(CrowdFundingSelectCover, styles)
