import EditGlimpse from 'components/glimpses/edit_glimpse'
import PostUpdate from 'components/glimpses/edit_glimpse/post_update_cf'
import PostUpdateShare from 'components/pages/crowdfunding/postupdate_share'
import PostUpdateError from 'components/glimpses/edit_glimpse/post_update_error'
import ViewUpdatesActivity from 'components/glimpses/edit_glimpse/viewupdates'
import { trackEnter, trackChange, firstInGlimpse } from 'lib/glimpse'
import {
  getStepName,
  glimpselessRoute,
  assertNoDeepLink,
} from 'lib/route_utils'
import { isCampaignOwner } from 'lib/is_owner'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { fromVeniceSelector } from 'redux/selectors/venice'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import OrganizerUpdateDetail from 'components/glimpses/edit_glimpse/organizer_update_detail'

const INITIAL_STEP = 'manage'
const INITIAL_STEP_IN_PREVIEW = undefined
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export default (store, history) => ({
  path: 'postupdate',
  component: { glimpse: EditGlimpse },
  indexRoute: {
    getComponent(nextState, callback) {
      const campaign = getCampaignSelector(store.getState(), {
        campaignId: nextState.params.campaignId,
      })

      if (campaign.campaign_type === CROWD_FUNDING) {
        return callback(null, PostUpdate)
      }
    },
  },
  onEnter(nextState, replace) {
    const campaignId = nextState.params.campaignId
    const inPreview = campaignId === NEW_CAMPAIGN_ID
    if (!store.getState()?.flags?.isOrganizerUpdatesEnabled) {
      replace('/pools')
      return
    }
    if (isCampaignOwner(store, campaignId) || inPreview) {
      trackEnter('postupdate', nextState, replace)
      nextState.params.hideBack = true
      const stepName = getStepName('postupdate', nextState.routes)
      if (stepName !== 'transfer') {
        // Only support deep linking into edit flow is the user is coming from Venice
        const isVeniceUser = true || fromVeniceSelector()(store.getState())
        if (!isVeniceUser) {
          assertNoDeepLink(
            'postupdate',
            inPreview ? INITIAL_STEP_IN_PREVIEW : INITIAL_STEP,
            nextState,
            replace
          )
        }
      }
    } else {
      replace(glimpselessRoute('postupdate'))
    }
  },
  onChange(prevState, nextState, replace) {
    trackChange('postupdate', prevState, nextState, replace)
    nextState.params.hideBack = firstInGlimpse('postupdate')
  },
  childRoutes: [
    {
      path: 'viewupdates',
      component: ViewUpdatesActivity,
      onEnter(nextState, replace) {
        nextState.params.isOwnerView = true
      },
    },
    {
      path: ':fouId/updatedetails',
      component: OrganizerUpdateDetail,
      onEnter(nextState, replace) {
        nextState.params.enableEditButton = true
        nextState.params.hideLogo = true
        nextState.params.hideButton = true
      },
    },
    {
      path: 'error',
      component: PostUpdateError,
    },
    {
      path: ':fouId',
      component: PostUpdate,
      onEnter(nextState, replace) {
        nextState.params.hideLogo = true
        nextState.params.hideButton = true
      },
    },
    {
      path: ':fouId/share',
      component: PostUpdateShare,
      onEnter(nextState, replace) {
        nextState.params.hideLogo = true
        nextState.params.hideButton = true
      },
    },
  ],
})
