import React from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import cssModules from 'react-css-modules'
import styles from './cross_fade.less'

const DURATION = 500

class CrossFadeTransition extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  }

  render() {
    const transitionNames = {
      enter: styles['crossfade-enter'],
      enterActive: styles['crossfade-enter-active'],
      leave: styles['crossfade-leave'],
      leaveActive: styles['crossfade-leave-active'],
    }

    return (
      <ReactCSSTransitionGroup
        transitionName={transitionNames}
        transitionEnterTimeout={DURATION}
        transitionLeaveTimeout={DURATION}
        component="div"
        styleName="crossfade-container"
      >
        {this.props.children}
      </ReactCSSTransitionGroup>
    )
  }
}

export default cssModules(CrossFadeTransition, styles)
