import CreateTypeSmallGroup from './create_type_small_group'
import { connect } from 'react-redux'
import { formatCurrency } from 'intl'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { getMaxLimitReachedSelector } from 'redux/selectors/cip'
import { getMinMaxAmount } from 'shared/helpers/campaign'
import { injectWorldReady } from '@paypalcorp/worldready-react'

export const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const minMaxAmount = getMinMaxAmount(campaign)
  const isGNCEnabled = state.flags.is_gnc_enabled
  const countryCode = state.country.country_code

  const formattedMaxGoalAmount = minMaxAmount.MAX_AMOUNT
    ? formatCurrency({
        value: minMaxAmount.MAX_AMOUNT,
        currencyCode: campaign.currency,
        additionalConfig: {},
        worldReady: props.worldReady,
      })
    : ''

  const isCampaignLimitReached = getMaxLimitReachedSelector(state)
  return {
    countryCode,
    formattedMaxGoalAmount,
    isGNCEnabled,
    isCampaignLimitReached,
  }
}

export default injectWorldReady(connect(mapStateToProps)(CreateTypeSmallGroup))
