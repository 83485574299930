import CampaignNotFound from './campaign_not_found'
import { connect } from 'react-redux'

const mapStateToProps = (state, props) => {
  return {
    cdnHost: state.geo.cdnHost,
  }
}

export default connect(mapStateToProps)(CampaignNotFound)
