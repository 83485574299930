import {
  INTEGRATION_IDENTIFIER,
  INTEGRATION_IDENTIFIER_BY_COUNTRY,
  NON_DISCOVERABLE,
} from '../../shared/shared_consts'
import { safeNumber } from 'shared/helpers/math_helpers'

/**
 * Get total value of campaign including any creator pledge. This can be
 * different the pool balance due to pledges, pool withdrawls, creator having
 * a negative balance when a contributor chips-in, etc.
 * @param {number|string} currentValue - total value of contributions to pool
 * @param {number|string} pledge - total pledge made by pool creator
 * @returns {float} total value of campaign contributions including pledge
 *
 * Keep in mind: If we ever handle cents, this needs to be checked because
 * 95.15 + 10.15 = 105.30000000000001 because of parseFloat issues but
 * probably WorldReadyJS will take care of this, is good to be aware though
 */
export function getCampaignTotalValue({ currentValue, pledge }) {
  return safeNumber(currentValue) + safeNumber(pledge)
}

export function getCampaignType(store, campaignId) {
  const state = store.getState()
  return (
    state.campaign &&
    state.campaign[campaignId] &&
    state.campaign[campaignId].campaign_type
  )
}

export function getNonDiscoverable(discoverable) {
  if (discoverable === NON_DISCOVERABLE) {
    return true
  }
  // if discoverable is not set the default is discoverable
  // which makes non_discoverable false
  return false
}

export function hasDirectIntegrationIdentifier(countryCode) {
  return Boolean(
    INTEGRATION_IDENTIFIER_BY_COUNTRY[countryCode]?.INTEGRATION_IDENTIFIER ===
      INTEGRATION_IDENTIFIER.DIRECT
  )
}
