import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import form from './form'
import campaign from './campaign'
import user_txns from './user_txns'
import send_iframe from './send_iframe'
import nop, { defaultStateReducer } from './nop'
import funding_sources from './funding_sources'
import current_user from './current_user'
import server from './server'
import url from './url'
import dnw_transaction from './dnw_transaction'
import toast_notification from './toast_notification'

export const reducer = combineReducers({
  routing: routerReducer,
  form,
  campaign,
  user_txns,
  send_iframe,
  funding_sources,
  current_user,
  server,
  queryParams: url,
  dnw_transaction,
  // This is just data passed from the server. We use the nop reducer
  // (which does nothing) to prevent redux's unrecognized keys warning
  charity: nop,
  locality: nop,
  login_info: nop,
  flags: nop,
  device_info: nop,
  links: nop,
  user_config: nop,
  features: nop,
  group_features: nop,
  country: nop,
  social: nop,
  mobile_app: nop,
  categoryData: nop,
  personalizationStudio: defaultStateReducer,
  // TODO we should consider putting all of these in an 'experiments' reducer
  can_pledge: nop,
  rampdown: nop,
  isContributionEligible: nop,
  deployEnv: nop,
  preloadSendValues: nop,
  geo: nop,
  toast_notification,
})
