import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { Avatar as AvatarIcon } from '@paypalcorp/pp-react'
import styles from './avatar.less'

export const avatarColors = ['blue', 'purple', 'green']

class Avatar extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    initials: PropTypes.string,
    backgroundColor: PropTypes.oneOf(avatarColors),
    blank: PropTypes.bool,
  }

  render() {
    let noAvatarElement = ''
    if (!this.props.blank) {
      if (this.props.imageUrl) {
        noAvatarElement = (
          <AvatarIcon
            src={this.props.imageUrl}
            size="sm"
            data-testid="avatar-image"
          />
        )
      }

      if (!this.props.imageUrl) {
        if (this.props.initials) {
          noAvatarElement = (
            <AvatarIcon
              initials={this.props.initials}
              color={this.props.backgroundColor || 'blue'}
              size="sm"
              data-testid="avatar-initials"
            />
          )
        } else {
          noAvatarElement = (
            <AvatarIcon
              generic="profile"
              size="sm"
              styleName="anonymous-avatar"
              data-testid="anonymous-avatar"
            />
          )
        }
      }
    }

    return <div data-testid="avatar__wrapper">{noAvatarElement}</div>
  }
}

export default cssModules(Avatar, styles, { allowMultiple: true })
