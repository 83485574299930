import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { FUNDING_SOURCES } from 'shared/shared_consts'
import { LOCATION_CHANGE } from 'react-router-redux'

const START_FUNDING_SOURCES = 'pools/funding_sources/START_FUNDING_SOURCES'
const FINISH_FUNDING_SOURCES = 'pools/funding_sources/FINISH_FUNDING_SOURCES'
const FAILED_FUNDING_SOURCES = 'pools/funding_sources/FAILED_FUNDING_SOURCES'

function setFundingSources(state, obj) {
  return { ...state, ...obj }
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case START_FUNDING_SOURCES:
      return setFundingSources(state, { operatingRemotely: true })
    case FINISH_FUNDING_SOURCES:
      return setFundingSources(state, {
        operatingRemotely: false,
        err: null,
        ...action.payload.data,
      })
    case FAILED_FUNDING_SOURCES:
      return setFundingSources(state, {
        operatingRemotely: false,
        err: action.payload.err,
      })
    case LOCATION_CHANGE:
      return { ...state, operatingRemotely: false }
    default:
      return state
  }
}

export function startRemoteOperation() {
  return { type: START_FUNDING_SOURCES, payload: {} }
}

export function finishRemoteOperation(data) {
  return { type: FINISH_FUNDING_SOURCES, payload: { data } }
}

export function failedRemoteOperation(err) {
  return { type: FAILED_FUNDING_SOURCES, payload: { err } }
}

export function getFundingSources(campaignId) {
  return (dispatch, getState) => {
    dispatch(startRemoteOperation())
    return fetchClient()
      .request(routes.funding_sources.api.index(), {
        method: 'GET',
      })
      .then(response => {
        const campaignCurrency = getCampaignSelector(getState(), { campaignId })
          .currency
        const fundingSources = response.fundingSources.reduce(
          (reducedSources, source) => {
            if (
              source.type !== FUNDING_SOURCES.HOLDING ||
              (source.currency === campaignCurrency && source.balance.value > 0)
            ) {
              reducedSources[source.id] = source
            }
            return reducedSources
          },
          {}
        )
        dispatch(finishRemoteOperation({ map: fundingSources }))
      })
      .catch(err => {
        dispatch(failedRemoteOperation(err))
        return Promise.reject(err)
      })
  }
}
