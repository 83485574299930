import { createSelector } from 'reselect'

const mobileAppSelector = state => state.mobile_app

export function fromVeniceSelector() {
  return createSelector(mobileAppSelector, mobileApp => mobileApp.from_app)
}

export const canManageVeniceSelector = createSelector(
  mobileAppSelector,
  mobileApp => mobileApp.can_manage
)

export const canShareVeniceSelector = createSelector(
  mobileAppSelector,
  mobileApp => mobileApp.can_share
)
