import { getJSONFromScript } from 'lib/server_params'
import { parseDate } from 'intl'

export function dateFormatValidator(field, message) {
  return values => {
    if (!(values[field] instanceof Date)) {
      if (!values[field]) {
        return {}
      }

      const dateObj = parseDate(values[field], {
        style: 'yMMdd',
      })

      if (dateObj.errors) {
        return { [field]: [message] }
      }
      return {}
    }

    if (isNaN(values[field].valueOf())) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function maxDaysValidator(
  fieldName,
  start = new Date(),
  maxDays,
  message
) {
  return values => {
    const START_DATE_BUFFER = 0
    const dateInQuestion = values[fieldName]
    const maxTime = (START_DATE_BUFFER + maxDays) * 86400000
    // if it's not a date, user is probably still typing, so ignore that
    if (!(dateInQuestion instanceof Date)) {
      return {}
    }

    if (dateInQuestion.getTime() - start.getTime() > maxTime) {
      return { [fieldName]: [message] }
    }
    return {}
  }
}

export function endDateValidators(fieldName, message) {
  const validators = []

  const deviceInfo = getJSONFromScript('store').device_info
  if (!deviceInfo.isMobile) {
    validators.push(dateFormatValidator(fieldName, message))
  }

  return validators
}
