import PayPalPage from './paypal_page'
import { connect } from 'react-redux'
import { fromVeniceSelector } from 'redux/selectors/venice'
import { getAllowEntryBannerSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, { campaignId, showHeader, showFooter }) => {
  const fromVenice = fromVeniceSelector()(state)
  if (fromVenice) {
    showHeader = false
    showFooter = false
  }

  return {
    showHeader,
    showFooter,
    isLoggedIn: state.login_info && state.login_info.logged_in,
    allowEntryBanner: getAllowEntryBannerSelector(state, { campaignId }),
    paypalDomain: state.geo.paypalDomain,
    banner: state.personalizationStudio.banner,
  }
}
export default connect(mapStateToProps)(PayPalPage)
