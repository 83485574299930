import React from 'react'
import PropTypes from 'prop-types'
import {
  PhysicalCard,
  Row,
  Col,
  HeadingText,
  BodyText,
} from '@paypalcorp/pp-react'
import { Checkbox } from '@paypalcorp/pp-react-checkbox'
import { DEAFULT_COVER_IMAGES } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import CustomImageSelection from './custom_image'

import styles from '../crowdfunding_select_cover.less'
import cssModules from 'react-css-modules'

const PrivateCover = props => {
  const { defaultImage3, setSelectedPhoto, selectedPhoto } = props
  return (
    <div>
      <HeadingText
        data-testid="private_cover_header"
        size="sm"
        styleName="title"
      >
        <Message id="flow.private.select_cover.header" />
      </HeadingText>
      <BodyText styleName="sub-title">
        <Message id="flow.private.select_cover.description" />
      </BodyText>

      <Row styleName="row-margin">
        <CustomImageSelection {...props} />
        <Col>
          <PhysicalCard
            style={{
              background: `no-repeat url(${
                DEAFULT_COVER_IMAGES[defaultImage3]
              })`,
              backgroundSize: 'cover',
              cursor: 'pointer',
              borderRadius: '1rem',
            }}
            onClick={() =>
              setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage3])
            }
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage3])
              }
            }}
            data-testid="default_image_3"
          />
          <div styleName="image-checkbox-container">
            <Checkbox
              checked={selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage3]}
              onClick={() =>
                setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage3])
              }
              tabIndex={-1}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

//Few props are using by its children component
PrivateCover.propTypes = {
  defaultImage3: PropTypes.string,
  setSelectedPhoto: PropTypes.func,
  selectedPhoto: PropTypes.string,
  defaultCategoryCover: PropTypes.string,
  defaultImage1: PropTypes.string,
  defaultImage2: PropTypes.string,
  handleSelectCover: PropTypes.func,
  fields: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
  }),
  charity: PropTypes.object,
}

export default cssModules(PrivateCover, styles)
