import React from 'react'

class FirstChild extends React.Component {
  render() {
    const children = React.Children.toArray(this.props.children) // eslint-disable-line react/prop-types
    return children[0] || null
  }
}

export default FirstChild
