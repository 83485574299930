import {
  PPGF_ENROLLED,
  PPGF_UNENROLLED,
  DIRECT_MODEL_ENROLLED,
  PPGF_ENROLLED_LINKS,
} from 'shared/shared_consts'
import { getI18n } from 'intl'

const i18n = getI18n('components/enrich_charity_card')

export function charityNumberFormat(ein, country) {
  let formattedEin
  if (ein.length > 2 && country === 'US') {
    formattedEin = `${ein.substring(0, 2)}-${ein.substring(2)}`
  } else {
    formattedEin = ein
  }
  const charityNumber = i18n(`charity_number_${country}`, {
    ein_number: formattedEin,
  })
  return charityNumber
}

export function charityCityState(city, state) {
  const address = []
  if (city) {
    address.push(city)
  }
  if (state) {
    address.push(state)
  }
  return address.join(', ')
}

export function enrichCharityData(charityType, countryCode) {
  switch (charityType) {
    case PPGF_ENROLLED:
      return { sourceContent: i18n(`source_enrolled`) }
    case PPGF_UNENROLLED:
      return {
        sourceContent: i18n(`source_unenrolled_${countryCode}`),
        disclaimerContent: i18n(`disclaimer_unenrolled_${countryCode}`),
        unEnrolledLink: PPGF_ENROLLED_LINKS[countryCode],
        learnMoreCharityLink: i18n(`learn_more_charity_link`),
      }
    case DIRECT_MODEL_ENROLLED:
      return { sourceContent: i18n(`source_directModel`) }
    default:
      return {}
  }
}
