import CreateFundraiserError from './create_fundraiser_error'
import { connect } from 'react-redux'
import { loginUrlSelector, logoutUrlSelector } from 'redux/selectors/login'

const mapStateToProps = (state, props) => {
  const countryCode = state.country.country_code || 'US'
  const loginUrl = loginUrlSelector({ returnUri: window.location.href })(state)

  return {
    countryCode,
    email: state.current_user.email,
    countryName: state.country.country_name,
    logoutUrl: logoutUrlSelector({ returnUri: loginUrl })(state),
  }
}

export default connect(mapStateToProps)(CreateFundraiserError)
