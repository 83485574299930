import React from 'react'
import PropTypes from 'prop-types'
import { Message } from '@paypalcorp/worldready-react'
import { HeadingText, BodyText } from '@paypalcorp/pp-react'

import cssModules from 'react-css-modules'
import styles from '../introduction.less'

const PersonalIntroductionContent = ({ formattedMaxGoalAmount }) => {
  return (
    <>
      <HeadingText size="sm" styleName="body__title">
        <Message id="components.pages.halfFullSheet.half_full_sheet.gn_title" />
      </HeadingText>
      <BodyText as="p" styleName="body__subtitle">
        <Message id="components.pages.halfFullSheet.half_full_sheet.gn_subtitle" />
      </BodyText>

      <ul>
        {[
          { id: 'components.pages.halfFullSheet.half_full_sheet.gn_list_one' },
          {
            id: 'components.pages.halfFullSheet.half_full_sheet.gn_list_two',
            amount: formattedMaxGoalAmount,
          },
        ].map(instance => (
          <li key={instance.id}>
            <BodyText>
              <Message {...instance} />
            </BodyText>
          </li>
        ))}
      </ul>
    </>
  )
}

PersonalIntroductionContent.propTypes = {
  formattedMaxGoalAmount: PropTypes.string.isRequired,
}

export default cssModules(PersonalIntroductionContent, styles)
