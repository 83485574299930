import { createStore, applyMiddleware, compose } from 'redux'
import { browserHistory } from 'react-router'
import thunkMiddleware from 'redux-thunk'

import { reducer } from './modules'
import { middleware as analyticsMiddleware } from './analytics'
import { routerMiddleware } from 'react-router-redux'
import { isStage, isDev } from 'client/lib/deploy_env'
import { syncScriptAndStore } from '../lib/store'

const getComposeEnhancers = () => {
  const isUsingDevTools =
    (isDev() || isStage()) &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (isUsingDevTools) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  }
  return compose
}
const composeEnhancers = getComposeEnhancers()

const middleware = [
  routerMiddleware(browserHistory),
  thunkMiddleware,
  analyticsMiddleware,
]

export function configureStore(initialState, callback) {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  store.subscribe(() => {
    // The callback function will be called on every store update
    const state = store.getState()
    // Sync the client redux store with the injected script
    syncScriptAndStore(state)
  })

  callback(store)
}
