import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import StandardDialog from 'components/common/standard_dialog'
import {
  DESCRIPTION_ROWS,
  CROWDFUNDING_DESCRIPTION_LENGTH,
} from 'shared/lengths'
import styles from './post_update_cf.less'
import { domOnlyProps } from 'lib/form_utils'
import {
  TextInput,
  HeadingText,
  Button,
  TextArea,
  BodyText,
  CaptionText,
} from '@paypalcorp/pp-react'
import { getFormError } from 'lib/validators'
import { TOAST_NOTIFICATIONS } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import DeleteUpdateActivity from '../delete_organizer_update/delete_update_activity_container'
import { appendPath } from 'lib/route_utils'
import { trackFPTI } from 'lib/fpti_utils'
import { get } from 'lodash'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { useIntl } from 'intl'

const PostUpdate = props => {
  const { formatMessage } = useIntl()
  const [showBackDialog, setShowBackDialog] = useState(false)

  useEffect(() => {
    if (props.organizerUpdateId) {
      trackFPTI(
        props.campaign,
        'impression',
        'dw_giving_edit_update_screen_shown'
      )
    } else {
      trackFPTI(
        props.campaign,
        'impression',
        'dw_giving_create_update_screen_shown'
      )
    }
  }, [])

  const handleUpdateSubmit = () => {
    trackFPTI(
      props.campaign,
      'click',
      'dw_giving_post_update_quit_finish_update_pressed'
    )
    setShowBackDialog(false)
    const request = {
      subject: props.fields.subject.value,
      text: props.fields.text.value,
    }
    return props
      .onSave(request, props.organizerUpdateId)
      .then(response => {
        if (props.organizerUpdateId) {
          props.onToggleToast(TOAST_NOTIFICATIONS.EDIT_SUCCESS)
          editBack()
          props.onToggleToast(
            TOAST_NOTIFICATIONS.EDIT_SUCCESS,
            formatMessage(
              'components.edit_glimpse.post_update.edit_notification'
            )
          )
        } else {
          goToSharePage(response)
        }
        trackFPTI(
          props.campaign,
          'ac',
          'dw_giving_update_post_success_occurred'
        )
      })
      .catch(err => {
        props.router.push({
          pathname: appendPath(location.pathname, 'error'),
          state: { error: err },
        })
      })
  }

  const goToSharePage = updateData => {
    const pathname = appendPath(location.pathname, `${updateData.fou_id}/share`)
    props.router.push({
      pathname,
      state: { updateData },
    })
  }

  const editBack = params => {
    const { params: { hideBack = false } = {} } = props.router
    if (hideBack || params?.hideBack) {
      props.router.push({
        pathname: '/pools',
        state: { campaignId: props.campaignId },
      })
    } else {
      props.router.goBack()
    }
  }

  const handleDialogClose = () => {
    setShowBackDialog(false)
    trackFPTI(
      props.campaign,
      'click',
      'dw_giving_post_update_quit_dismiss_pressed',
      'close'
    )
  }

  const handleQuit = () => {
    trackFPTI(
      props.campaign,
      'click',
      'dw_giving_post_update_quit_cancel_pressed',
      'close'
    )
    editBack()
  }
  const {
    fields: { subject, text },
    handleSubmit,
    showDelete,
    organizerUpdateId,
    campaign,
  } = props
  const description = get(props, 'values.text', '')
  if (description.length > CROWDFUNDING_DESCRIPTION_LENGTH) {
    const error = {
      error_desc: `exceed_max_${CROWDFUNDING_DESCRIPTION_LENGTH}_characters`,
      error_code: 'MATCH',
    }
    trackFPTI(
      campaign,
      'ac',
      'dw_giving_post_update_form_error_occurred',
      '',
      error
    )
  }

  return (
    <form onSubmit={handleSubmit(handleUpdateSubmit)} autoComplete="off">
      {showBackDialog && (
        <StandardDialog
          size="medium"
          title={
            showDelete
              ? formatMessage(
                  'components.edit_glimpse.post_update.dirty_dialog_title_cf'
                )
              : formatMessage(
                  'components.edit_glimpse.post_update.dirty_dialog_create_title_cf'
                )
          }
          subtitle={
            showDelete
              ? formatMessage(
                  'components.edit_glimpse.post_update.dirty_dialog_subtitle_cf'
                )
              : formatMessage(
                  'components.edit_glimpse.post_update.dirty_dialog_create_subtitle_cf'
                )
          }
          closeButtonTitle={
            showDelete
              ? formatMessage(
                  'components.edit_glimpse.post_update.quit_editing'
                )
              : formatMessage('components.edit_glimpse.post_update.cancel')
          }
          okButtonTitle={
            showDelete
              ? formatMessage(
                  'components.edit_glimpse.post_update.save_changes'
                )
              : formatMessage(
                  'components.edit_glimpse.post_update.finish_creating_update'
                )
          }
          onClose={handleDialogClose}
          onOk={() => {
            const { text: errorText, subject: errorSubject } =
              props.errors || {}
            if (errorText || errorSubject) {
              setShowBackDialog(false)
            } else {
              handleUpdateSubmit()
            }
          }}
          onQuit={handleQuit}
          data-testid="edit-update"
          iconName="logout"
        />
      )}
      <HeadingText size="sm" styleName="title">
        {showDelete ? (
          <Message id="components.edit_glimpse.post_update.edit_title" />
        ) : (
          <Message id="components.edit_glimpse.post_update.create_title" />
        )}
      </HeadingText>
      <BodyText size="sm" styleName="subtitle">
        <Message id="components.edit_glimpse.post_update.subtitle" />
      </BodyText>
      <div styleName="textbox-wrapper" data-testid="post-update__subject">
        <TextInput
          id={subject.name}
          label={formatMessage('components.edit_glimpse.post_update.subject')}
          {...domOnlyProps(subject)}
          errorText={getFormError(subject)}
          data-testid="subject-textInput"
          auocomplete={false}
        />
      </div>
      <div styleName={'description-container'}>
        <div styleName="textbox-wrapper" data-testid="post-update__text">
          <TextArea
            {...domOnlyProps(text)}
            id={text.name}
            minRows={DESCRIPTION_ROWS}
            label={formatMessage(
              'components.edit_glimpse.post_update.description'
            )}
            showCharacterCounter={true}
            maxCharacters={CROWDFUNDING_DESCRIPTION_LENGTH}
            errorText={getFormError(text)}
            data-testid="text-textArea"
          />
        </div>
      </div>
      {showDelete ? (
        <DeleteUpdateActivity
          campaignId={props?.campaignId}
          id={props.organizerUpdateId}
          onClose={() => editBack({ hideBack: true })}
          campaign={props.campaign}
          data-testid="delete_update_activity"
        />
      ) : (
        <CaptionText data-testid="post-update__max_update_fundraiser">
          <Message id="components.edit_glimpse.post_update.max_update_fundraiser" />
        </CaptionText>
      )}
      <Button
        size="lg"
        styleName="save-button"
        data-testid="post-update__save-button"
        onClick={() => {
          if (organizerUpdateId) {
            trackEventNameClick({
              event_name: 'dw_giving_edit_update_save_pressed',
            })
          } else {
            trackEventNameClick({
              event_name: 'dw_giving_create_update_save_pressed',
            })
          }
        }}
      >
        <Message id="components.edit_glimpse.post_update.save_cf" />
      </Button>
    </form>
  )
}

PostUpdate.propTypes = {
  fields: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  router: PropTypes.object,
  campaign: PropTypes.object,
  dirty: PropTypes.bool,
  onToggleToast: PropTypes.func,
  organizerUpdateId: PropTypes.string,
  resetError: PropTypes.func,
  campaignId: PropTypes.string,
  campaignError: PropTypes.object,
  errors: PropTypes.object,
}

export default cssModules(PostUpdate, styles)
