/*
 * USAGE EXAMPLE:
 *
 * import routes from 'server_routes'
 *
 * let url = paths.campaign.activity({id: 12345})
 * console.log(url)
 * > /pools/c/12345/activity
 */

function generatePathFunction(route) {
  return (ids = {}) => {
    let clonedRoute = `${GLOBAL_CONSTS.REQUEST_URI}${route}`
    Object.keys(ids).forEach(key => {
      clonedRoute = clonedRoute.replace(`:${key}`, encodeURIComponent(ids[key]))
    })
    return clonedRoute
  }
}

export default {
  error: generatePathFunction('/error'),
  campaign: {
    index: generatePathFunction('/c'),
    id: generatePathFunction('/c/:id'),
    api: {
      index: generatePathFunction('/api/campaign'),
      id: generatePathFunction('/api/campaign/:id'),
      end: generatePathFunction('/api/campaign/:id/end'),
      withdraw: generatePathFunction('/api/campaign/:id/withdraw'),
      chip_in: generatePathFunction('/api/campaign/:id/chip_in'),
      create_payments_token: generatePathFunction(
        '/api/campaign/payments_token'
      ),
      get_payments_token_transaction: generatePathFunction(
        '/api/campaign/payments_token/:id'
      ),
    },
  },
  organizer_update: {
    api: {
      index: generatePathFunction('/api/organizer_update'),
    },
  },
  contributors: {
    api: {
      transactions: generatePathFunction('/api/contributors/transactions'),
    },
  },
  funding_sources: {
    api: {
      index: generatePathFunction('/api/funding_sources'),
      balances: generatePathFunction('/api/funding_sources/balances'),
    },
  },
  charity: {
    api: {
      getCharities: generatePathFunction('/api/charity'),
    },
  },
}
