import React from 'react'
import { throttle } from 'lodash'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './session_timeout.less'
import { Sheet, BodyText, Button, HeadingText } from '@paypalcorp/pp-react'
import { EXTEND_SESSION } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'

const {
  URL,
  SHOW_TIMEOUT_SCREEN_AT,
  SHOW_EU_TIMEOUT_SCREEN_AT,
  RESET_TIMER_TIMEOUT,
  SESSION_TIMEOUT,
} = EXTEND_SESSION

class SessionTimeout extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    loginUrl: PropTypes.string,
    isEuCountry: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    this.state = { isSheetOpen: false }
    this.sheetTimer = null
    this.sessionTimer = null
    this.displayTimeoutPageAt = this.isEuCountry
      ? SHOW_EU_TIMEOUT_SCREEN_AT
      : SHOW_TIMEOUT_SCREEN_AT
  }

  resetTimer = throttle(() => {
    this.setState({ isSheetOpen: false })
    clearTimeout(this.sheetTimer)
    clearTimeout(this.sessionTimer)
    return fetch(URL).then(() => this.startSheetTimer())
  }, RESET_TIMER_TIMEOUT)

  startSheetTimer() {
    this.sheetTimer = setTimeout(() => {
      this.setState({ isSheetOpen: true })
    }, this.displayTimeoutPageAt)
  }

  startSessionTimer() {
    this.sessionTimer = setTimeout(() => {
      location.href = this.props.loginUrl
    }, SESSION_TIMEOUT)
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.startSheetTimer()
      window.addEventListener('click', this.resetTimer)
    }
  }

  render() {
    if (this.state.isSheetOpen) {
      this.startSessionTimer()
    }
    return (
      <Sheet
        center
        isOpen={this.state.isSheetOpen}
        onClose={() => this.resetTimer}
      >
        <img
          width="160"
          alt=""
          role="presentation"
          src="https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-warning.svg"
        />
        <HeadingText size="sm">
          <Message id="components.session_timeout.all_done" />
        </HeadingText>
        <BodyText styleName="sheet__description">
          <Message id="components.session_timeout.log_you_out" />
        </BodyText>
        <Button onClick={() => this.setState({ isSheetOpen: false })}>
          <Message id="components.session_timeout.stay_logged_in" />
        </Button>
      </Sheet>
    )
  }
}

export default cssModules(SessionTimeout, styles)
