import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_image_preview.less'
import Hammer from 'react-hammerjs'
import Strip from 'components/strip'
import { CaptionText, Button } from '@paypalcorp/pp-react'
import ImageFileField from 'components/common/image_file_field'
import { inverseLerp } from 'shared/helpers/math_helpers'
import { COVER_IMAGE_MAX_TOTAL_SIZE } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'

export const DEFAULT_RELATIVE_PAN = 50

class CrowdFundingImagePreview extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    categoryImage: PropTypes.string,
    pan: PropTypes.number,
    onPanChange: PropTypes.func,
    onImageChange: PropTypes.func,
    onImageLoad: PropTypes.func,
    onImageRemove: PropTypes.func,
  }

  state = {
    pan: this.props.pan,
    imageUrl: this.props.imageUrl,
    categoryImage: this.props.categoryImage,
  }

  componentDidMount() {
    this.refreshImageSize()
  }

  refreshImageSize = () => {
    let image = new Image()
    image.addEventListener('load', () => {
      this.imageWidth = image.width
      this.imageHeight = image.height
      this.previewWidth = this.strip.offsetWidth
      this.previewHeight = this.strip.offsetHeight
      this.previewZoom = this.previewWidth / this.imageWidth
    })
    image.src = this.state.imageUrl
  }

  handleImageChangeIntent = e => {
    e.preventDefault()
    this.imageInput.handleOpenFileDialog()
  }

  handleImageChange = val => {
    this.setState(
      {
        pan: DEFAULT_RELATIVE_PAN,
        imageUrl: val.url,
      },
      () => {
        this.refreshImageSize()
      }
    )
    this.props.onImageChange(val)
  }

  handlePanStart = () => {
    this.panStart = this.lastAbsolutePan || 0
  }

  handlePan = evt => {
    const remainingHeight =
      (this.previewZoom * this.imageHeight - this.previewHeight) / 2
    let absolutePan = this.panStart + evt.deltaY
    absolutePan = Math.min(
      Math.max(-remainingHeight, absolutePan),
      remainingHeight
    )
    const relativePan = Math.round(
      inverseLerp(remainingHeight, -remainingHeight, absolutePan) * 100
    )
    this.lastAbsolutePan = absolutePan
    this.setState({ pan: relativePan })
  }

  handlePanEnd = () => {
    this.props.onPanChange(this.state.pan)
  }

  render() {
    // TODO Figure out a way to do this in scalable way.
    const customStyle = {
      height: '201px',
    }

    return (
      <Hammer
        onPanStart={this.handlePanStart}
        onPan={this.handlePan}
        onPanEnd={this.handlePanEnd}
        options={{ recognizers: { pan: { threshold: 0 } } }}
      >
        <div styleName="preview-container">
          <CaptionText styleName="drag">
            <span>
              <Message id="components.crowdfunding_image_preview.drag_image" />
            </span>
          </CaptionText>
          <div>
            <Strip
              isResponsive={true}
              backgroundImage={this.state.imageUrl}
              backgroundPan={this.state.pan}
              customStyle={customStyle}
              stripRef={strip => {
                this.strip = strip
              }}
              categoryImage={this.state.categoryImage}
            />
          </div>
          <div styleName="actions">
            <Button
              tertiary
              data-pa-click="changeImage"
              data-testid="change-image"
              styleName="action"
              onClick={this.handleImageChangeIntent}
            >
              <Message id="components.crowdfunding_image_preview.change_image" />
            </Button>
            <Button
              tertiary
              data-pa-click="removeImage"
              data-testid="remove-image"
              styleName="action"
              onClick={this.props.onImageRemove}
            >
              <Message id="components.crowdfunding_image_preview.remove_image" />
            </Button>
          </div>
          <ImageFileField
            ref={imageInput => {
              this.imageInput = imageInput
            }}
            onImageLoad={this.props.onImageLoad}
            restrictedTotalSize={COVER_IMAGE_MAX_TOTAL_SIZE}
            onChange={this.handleImageChange}
          />
        </div>
      </Hammer>
    )
  }
}

export default cssModules(CrowdFundingImagePreview, styles)
