export const addPledgeToContributionList = props => {
  const contributionArray = props.contributions ? [...props.contributions] : []

  contributionArray.unshift({
    amount: props.pledge,
    anonymous: false,
    campaign_id: props.campaignId,
    contributor_id: props.ownerId,
    contributor_name: props.ownerName,
    contributor_photo: undefined,
    currency: props.currency,
    date: '',
    isPledge: true,
  })

  return contributionArray
}
