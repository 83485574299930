import ZeroFundraiser from './zero_fundraiser'
import { connect } from 'react-redux'
import { formatCurrency } from 'intl'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import { getBlockType } from 'lib/create_util'
import { getMinMaxAmount } from 'shared/helpers/campaign'
import { destroy } from 'redux-form'
import { injectWorldReady } from '@paypalcorp/worldready-react'

export const mapStateToProps = (state, props) => {
  const campaign = state.campaign[NEW_CAMPAIGN_ID]
  const isGNCEnabled = state.flags.is_gnc_enabled
  const countryCode = state.country.country_code || 'US'
  const gnHref = `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/basics`
  const gncHref = `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/nonprofit`
  const userHasCampaigns = false
  const isFundRaiserEnabled = state.flags.isFundRaiserEnabled

  const minMaxAmount = getMinMaxAmount(campaign)
  const formattedMaxGoalAmount = minMaxAmount.MAX_AMOUNT
    ? formatCurrency({
        value: minMaxAmount.MAX_AMOUNT,
        currencyCode: campaign.currency,
        additionalConfig: {},
        worldReady: props.worldReady,
      })
    : ''

  /**
   * Being an almost static page, this won't even be validated
   * but is required for create_type (CreateType) component
   * to work properly
   */
  const isCampaignLimitReached = false

  return {
    blockType: getBlockType(state, props, campaign),
    gnHref,
    gncHref,
    countryCode,
    isGNCEnabled,
    userHasCampaigns,
    formattedMaxGoalAmount,
    isCampaignLimitReached,
    isFundRaiserEnabled,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onComponentMount() {
      dispatch(destroy('crowdfunding_basics'))
    },
  }
}

export default injectWorldReady(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ZeroFundraiser)
)
