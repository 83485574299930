import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './left_side_hub_campaign.less'
import Strip from 'components/strip'
import { getCampaignTotalValue } from 'lib/campaign'
import FlatProgress from 'components/common/flat_progress'
import {
  Badge,
  BodyText,
  Card,
  CaptionText,
  CausesIcon,
  ClockIcon,
} from '@paypalcorp/pp-react'
import { useIntl } from 'intl'
import { Message } from '@paypalcorp/worldready-react'
import { POOL_TYPE } from 'shared/shared_consts'
import clsx from 'clsx'

const LeftSideHubCampaign = props => {
  const {
    campaign: {
      background_pan,
      background_thumbnail_image,
      can_chip_in,
      category_image: categoryImage,
      charity,
      currency,
      current_value,
      end_date,
      goal,
      gradient_color,
      moneybox_amount,
      pledge,
      title,
      type,
    },
  } = props

  const hasGoalAmount = goal > 0
  const isCharity = !!charity && charity.name
  const { formatCurrency, formatDate, formatMessage } = useIntl()

  const moneyBoxAmountFormatted = formatCurrency(moneybox_amount || 0, currency)

  const trackFPTI = () => {
    props.leftSideHubCallback(props.index)
  }

  const currentValue = getCampaignTotalValue({
    currentValue: current_value,
    pledge,
  })

  const contentId = can_chip_in
    ? 'components.pages.hub.hub_campaign.ends_on'
    : 'components.pages.hub.hub_campaign.ended_on'

  const endDateFormatted = formatMessage(contentId, {
    date: formatDate(end_date, { style: 'date-short' }),
  })

  return (
    <Card
      onClick={trackFPTI}
      styleName={clsx('card', {
        'card--selected': props.index === props.selectedCard,
      })}
    >
      <Card.Content styleName="card__content">
        <div styleName="content__column-left">
          <div styleName="column-left__top">
            <Badge
              type={can_chip_in ? 'warning' : 'neutral'}
              data-test="end-date"
              leftIcon={ClockIcon}
            >
              {endDateFormatted}
            </Badge>
          </div>

          <div styleName="column-left__middle">
            {type !== POOL_TYPE.CHARITY && (
              <>
                <CaptionText data-test="campaign-value" size="sm">
                  <Message id="components.pages.hub.hub_campaign.available" />
                </CaptionText>
                <BodyText
                  strong
                  data-test="campaign-goal"
                  styleName="middle__formatted-amount"
                >
                  {moneyBoxAmountFormatted}
                </BodyText>
              </>
            )}

            {type === POOL_TYPE.PRIVATE &&
              !hasGoalAmount && (
                <>
                  <CaptionText data-test="campaign-value" size="sm">
                    <Message id="components.common.flat_progress.collected" />
                  </CaptionText>
                  <BodyText strong data-test="campaign-goal">
                    {formatCurrency(currentValue, currency)}
                  </BodyText>
                </>
              )}
          </div>

          <div styleName="column-left__bottom">
            {type === POOL_TYPE.PRIVATE && !hasGoalAmount ? (
              <CaptionText clampAtLine={1}>{title}</CaptionText>
            ) : (
              <FlatProgress
                goalAmount={goal}
                totalAmount={currentValue}
                currencyCode={currency}
                title={title}
                titleIcon={
                  isCharity && (
                    <CausesIcon size="sm" styleName="campaign__icon" />
                  )
                }
                displayTotalAmount={true}
                size="sm"
              />
            )}
          </div>
        </div>
        <div styleName="content__column-right">
          <Strip
            backgroundImage={background_thumbnail_image}
            backgroundPan={background_pan}
            gradientColor={gradient_color}
            customStyleInput={true}
            categoryImage={categoryImage}
          />
        </div>
      </Card.Content>
    </Card>
  )
}

LeftSideHubCampaign.propTypes = {
  campaign: PropTypes.object,
  index: PropTypes.number,
  selectedCard: PropTypes.number,
  leftSideHubCallback: PropTypes.func,
}

export default cssModules(LeftSideHubCampaign, styles, {
  allowMultiple: true,
})
