import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n, formatCurrency } from 'intl'
import styles from './activity.less'
import {
  CompletedActivityList,
  PendingContributionsList,
} from 'components/contributions_list/owner_activity_list'
import ActivityRow from 'components/contributions_list/rows/activity_row'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/edit_glimpse/activity')

class Activity extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    moneyBoxAmount: PropTypes.number,
    moneyBoxCurrency: PropTypes.string,
    worldReady: PropTypes.Object,
  }

  render() {
    const balance = formatCurrency({
      value: this.props.moneyBoxAmount,
      currencyCode: this.props.moneyBoxCurrency,
      additionalConfig: {},
      worldReady: this.props.worldReady,
    })

    return (
      <div>
        <div styleName="title">{i18n('activity.title')}</div>
        <div styleName="balance">{i18n('activity.balance', { balance })}</div>
        <div
          styleName="subtitle"
          dangerouslySetInnerHTML={{
            __html: i18n('activity.subtitle', {
              url: '/myaccount/activity',
            }),
          }}
        />
        <div styleName="list-container">
          <PendingContributionsList
            title={i18n('activity.pending')}
            hideEmpty={true}
            showDaysRows={true}
            txnsPerPage={5}
            campaignId={this.props.campaignId}
            rowElement={(activityItem, key) => (
              <ActivityRow {...activityItem} key={key} />
            )}
          />
        </div>
        <div styleName="list-container">
          <CompletedActivityList
            title={i18n('activity.completed')}
            hideEmpty={true}
            showDaysRows={true}
            txnsPerPage={5}
            campaignId={this.props.campaignId}
            rowElement={(activityItem, key) => (
              <ActivityRow {...activityItem} key={key} />
            )}
          />
        </div>
      </div>
    )
  }
}

export default injectWorldReady(
  connect((state, ownProps) => {
    const campaign = getCampaignSelector(state, ownProps)
    return {
      moneyBoxAmount: campaign.moneybox_amount,
      moneyBoxCurrency: campaign.currency,
    }
  })(withRouter(cssModules(Activity, styles)))
)
