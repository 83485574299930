import React from 'react'
import PropTypes from 'prop-types'
import StandardError from 'components/common/standard_error'
import { useIntl } from 'intl'

const DeleteError = ({ onDelete }) => {
  const { formatMessage } = useIntl()

  return (
    <StandardError
      iconType="error"
      title={formatMessage('components.edit_glimpse.edit_delete.delete_error')}
      onTryAgain={onDelete}
      showButton={true}
      data-testid="delete-error__modal"
    />
  )
}

DeleteError.propTypes = {
  onDelete: PropTypes.func.isRequired,
}

export default DeleteError
