import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './type_ahead_search.less'
import { debounce } from 'lodash'

import { SearchIcon, TextInput, LoadingSpinner } from '@paypalcorp/pp-react'

class TypeAheadSearch extends Component {
  static propTypes = {
    children: PropTypes.func,
    data: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    getOptions: PropTypes.func,
    getSelectedItem: PropTypes.func,
    delay: PropTypes.number,
    setItemDecoration: PropTypes.func,
    loadingElement: PropTypes.func,
    datasource: PropTypes.string,
  }
  static defaultProps = {
    datasource: 'label',
  }

  constructor(props) {
    super(props)

    this.state = {
      highlightedSuggestionIndex: -1,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
      isCampaignLoading: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onDocumentMouseDown)
    this.debounceSearch = debounce(
      (items, userInput) => this.search(items, userInput),
      this.props.delay || 0
    )
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onDocumentMouseDown)
  }

  onDocumentMouseDown = event => {
    const suggestionsNode = document.querySelector('#suggestions')
    if (
      !(
        event.target.id === this.props.id ||
        (suggestionsNode && suggestionsNode.contains(event.target))
      )
    ) {
      this.setState({
        showSuggestions: false,
      })
    }
  }

  closeSuggestions(userInput) {
    const userText = userInput || this.state.userInput
    this.setState({
      highlightedSuggestionIndex: -1,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: userText,
      isCampaignLoading: false,
    })
    document.querySelector(`#${this.props.id || 'typeAheadSearchBox'}`).focus()
  }

  getOptions(items, userInput) {
    const { datasource } = this.props
    return items.filter(
      suggestion =>
        suggestion[datasource] &&
        suggestion[datasource].toLowerCase().includes(userInput.toLowerCase())
    )
  }

  onChange = e => {
    const userInput = e.currentTarget.value
    const items = this.props.data
    this.setState({ isCampaignLoading: true, userInput, showSuggestions: true })
    this.debounceSearch(items, userInput)
  }

  async search(items, userInput) {
    const getOptions = this.props.getOptions
      ? this.props.getOptions
      : this.getOptions
    const filteredSuggestions = await getOptions(items, userInput)
    this.setState({
      highlightedSuggestionIndex: -1,
      filteredSuggestions,
      showSuggestions: true,
      isCampaignLoading: false,
    })
  }

  onItemClick = index => {
    const {
      state: { filteredSuggestions },
    } = this
    const item = filteredSuggestions[index]
    this.props.getSelectedItem(item)
    this.closeSuggestions(item[this.props.datasource])
  }

  onKeyDown = event => {
    const keyCode = event.key || event.keyCode
    const { highlightedSuggestionIndex, filteredSuggestions } = this.state
    switch (keyCode) {
      case 13: //On Enter
      case 'Enter':
      case 'ENTER':
        this.closeSuggestions(
          filteredSuggestions[highlightedSuggestionIndex][this.props.datasource]
        )
        break
      case 38: //up arrow, decrement the index
      case 'Up':
      case 'ArrowUp':
        if (highlightedSuggestionIndex === 0) {
          this.setState({
            highlightedSuggestionIndex: filteredSuggestions.length - 1,
          })
          return
        }
        this.setState({
          highlightedSuggestionIndex: highlightedSuggestionIndex - 1,
        })
        break
      case 40: //down arrow, increment the index
      case 'Down':
      case 'ArrowDown':
        if (highlightedSuggestionIndex - 1 === filteredSuggestions.length) {
          this.setState({
            highlightedSuggestionIndex: 0,
          })
          return
        }
        this.setState({
          highlightedSuggestionIndex: highlightedSuggestionIndex + 1,
        })
        break
      //Tab
      case 9:
      case 'Tab':
        this.closeSuggestions()
        break
      //ESC
      case 27:
      case 'Escape':
      case 'Esc':
        this.closeSuggestions()
        break
      default:
        break
    }
  }

  getLoadingElement = () => {
    return (
      this.props.loadingElement ||
      (this.state.isCampaignLoading && (
        <div styleName="loadingSpinner">
          <LoadingSpinner screenReaderText="loading" />
        </div>
      ))
    )
  }

  getTemplate = () => {
    const {
      onItemClick,
      getItemProps,
      getListProps,
      getInputProps,
      getLoadingElement,
      state: {
        highlightedSuggestionIndex,
        filteredSuggestions,
        userInput,
        showSuggestions,
        isCampaignLoading,
      },
    } = this
    const suggestionList =
      (showSuggestions && (
        <ul
          styleName={
            filteredSuggestions.length > 0 || isCampaignLoading
              ? 'suggestions'
              : 'suggestions suggestionborder'
          }
          {...getListProps({})}
          aria-hidden={!showSuggestions}
        >
          {getLoadingElement()}
          {filteredSuggestions.map((suggestion, index) => {
            let className,
              area_selected = false

            // Flag the active suggestion with a class
            if (index === highlightedSuggestionIndex) {
              className = 'highlightedSuggestion'
              area_selected = true
            }
            return (
              <li
                id={suggestion.id}
                styleName={className}
                key={suggestion.id}
                {...getItemProps({ onClick: () => onItemClick(index) })}
                aria-selected={area_selected}
              >
                {this.props.setItemDecoration
                  ? this.props.setItemDecoration(suggestion)
                  : suggestion[this.props.datasource]}
              </li>
            )
          })}
        </ul>
      )) ||
      null
    return (
      <Fragment>
        <TextInput
          value={userInput}
          leftIcon={<SearchIcon size="sm" />}
          className={`${styles['ppvx_text-input']} ${
            styles['search-input-border-radius']
          }`}
          {...getInputProps({})}
        />
        {suggestionList}
      </Fragment>
    )
  }

  callAllEvents = (...fns) => (event, ...args) =>
    fns.forEach(fn => fn && fn(event, ...args))

  getInputProps = ({ onKeyDown, onChange, onBlur, ...rest }) => {
    const eventHandlers = {
      onKeyDown: this.callAllEvents(onKeyDown, this.onKeyDown),
      onChange: this.callAllEvents(onChange, this.onChange),
      onBlur: this.callAllEvents(onBlur),
    }
    return {
      name: this.props.name || 'search',
      id: this.props.id || 'typeAheadSearchBox',
      label: this.props.label || 'search',
      autoComplete: 'off',
      'aria-owns': 'suggestions',
      'aria-expanded': 'false',
      'aria-autocomplete': 'both',
      'aria-activedescendant': '',
      ...eventHandlers,
      ...rest,
    }
  }

  getListProps = ({ id, ...rest }) => {
    return {
      className: 'suggestions',
      id: id || 'suggestions',
      role: 'listbox',
      ...rest,
    }
  }

  getItemProps = ({ onClick, className, ...rest }) => {
    return {
      className: className,
      onClick: this.callAllEvents(onClick, this.onClick),
      role: 'option',
      ...rest,
    }
  }

  render() {
    const {
      onItemClick,
      getItemProps,
      getListProps,
      getInputProps,
      state: {
        highlightedSuggestionIndex,
        filteredSuggestions,
        userInput,
        showSuggestions,
      },
    } = this
    return this.props.children
      ? this.props.children({
          onItemClick,
          getItemProps,
          getListProps,
          getInputProps,
          highlightedSuggestionIndex,
          filteredSuggestions,
          userInput,
          showSuggestions,
        })
      : this.getTemplate()
  }
}

export default cssModules(TypeAheadSearch, styles, { allowMultiple: true })
