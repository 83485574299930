import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import clsx from 'clsx'
import {
  Alert,
  BodyText,
  Button,
  CaptionText,
  HeadingText,
  PictureIcon,
} from '@paypalcorp/pp-react'
import styles from './crowdfunding_cover_image.less'
import CrossFadeTransition from 'components/common/transitions/cross_fade'
import ImageUploadField from 'components/common/image_upload_field'
import { trackClick } from 'lib/fpti_analytics'
import CrowdFundingImageAdjust from '../crowdfunding_image_adjust'
import CrowdFundingImagePreview from 'components/crowdfunding_image_preview'
import {
  COVER_IMAGE_MAX_TOTAL_SIZE,
  COVER_IMAGE_UPLOAD_ERRORS,
} from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl } from 'intl'

const { FILE_INVALID_TYPE } = COVER_IMAGE_UPLOAD_ERRORS

const DEFAULT_PAN = 50

function CrowdFundingCoverImage(props) {
  const { formatMessage } = useIntl()
  const child = React.createRef()
  const [isLoading, setLoading] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const updateImage = (eventName, url, fileName) => {
    const {
      fields: { backgroundImage, backgroundImageFilename, backgroundPan },
    } = props
    trackClick({
      clickName: eventName,
      data: {
        event_name: eventName,
      },
    })
    setUploadError(null)
    backgroundPan.onChange(DEFAULT_PAN)
    backgroundImage.onChange(url)
    backgroundImageFilename.onChange(fileName)
  }

  const handleImageRemove = e => {
    e.preventDefault()
    updateImage('dw_giving_edit_fundraiser_remove_photo_pressed')
  }

  const handleImageChange = val => {
    const {
      fields: { backgroundImageFilename },
    } = props

    updateImage(
      'dw_giving_edit_fundraiser_change_photo_pressed',
      val.url,
      val.name || backgroundImageFilename.value
    )
  }

  const handleImageUploadError = imageLoadError => {
    setUploadError(imageLoadError)
  }

  const getErrorMessage = () => {
    const key = uploadError.code || FILE_INVALID_TYPE
    return formatMessage(
      `components.pages.crowdfunding.crowdfunding_cover_image.${key}`
    )
  }

  const {
    fields: { backgroundImage = {}, backgroundPan },
    showDoneButton,
    showTitle,
    showPreviewComponent,
    categoryImage,
  } = props

  return (
    <div>
      {showTitle && (
        <HeadingText size="md" styleName="title_cf">
          <Message id="components.pages.crowdfunding.crowdfunding_cover_image.title_cf" />
        </HeadingText>
      )}
      <CrossFadeTransition>
        {backgroundImage.value ? (
          <div key="with-value">
            <div
              styleName="image-container"
              className={clsx({ 'vx_has-spinner-medium': isLoading })}
            >
              {/* To manage older version from cf edit */}
              {showPreviewComponent ? (
                <CrowdFundingImagePreview
                  imageUrl={backgroundImage.value}
                  pan={parseInt(backgroundPan.value)}
                  onPanChange={backgroundPan.onChange}
                  onImageChange={handleImageChange}
                  onImageLoad={setLoading}
                  onImageRemove={handleImageRemove}
                  categoryImage={categoryImage}
                />
              ) : (
                <CrowdFundingImageAdjust
                  onImageRemove={handleImageRemove}
                  imageUrl={backgroundImage.value}
                  onImageChange={handleImageChange}
                  data-testid="crowdfunding-cover-image__image-adjust"
                />
              )}
            </div>
          </div>
        ) : (
          <div key="without-value">
            <div className={clsx({ 'vx_has-spinner-medium': isLoading })}>
              <ImageUploadField
                onImageLoad={setLoading}
                onError={handleImageUploadError}
                restrictedTotalSize={COVER_IMAGE_MAX_TOTAL_SIZE}
                onChange={handleImageChange}
                data-testid="crowdfunding-cover-image__image-upload"
                ref={child}
              >
                <div styleName="upload-info">
                  <PictureIcon styleName="upload-info-icon" />
                  <BodyText strong styleName="upload-info-text">
                    <Message id="components.pages.crowdfunding.crowdfunding_cover_image.upload" />
                  </BodyText>
                </div>
              </ImageUploadField>
              <CaptionText styleName="tip">
                <Message id="components.pages.crowdfunding.crowdfunding_cover_image.upload_tip" />
              </CaptionText>
              {uploadError && (
                <Alert
                  data-testid="crowdfunding-cover-image__error"
                  type="error"
                >
                  {getErrorMessage()}
                </Alert>
              )}
            </div>
          </div>
        )}
        {showDoneButton && (
          <Button
            data-pa-click="done"
            data-nemo="done"
            styleName="done-btn"
            className="vx_btn vx_btn-block"
          >
            <Message id="components.pages.crowdfunding.crowdfunding_cover_image.done" />
          </Button>
        )}
      </CrossFadeTransition>
    </div>
  )
}

CrowdFundingCoverImage.propTypes = {
  fields: PropTypes.object.isRequired,
  showDoneButton: PropTypes.bool,
  showTitle: PropTypes.bool,
  showPreviewComponent: PropTypes.bool,
  categoryImage: PropTypes.string,
}

export default cssModules(CrowdFundingCoverImage, styles)
