import React from 'react'
import PropTypes from 'prop-types'
import { Message } from '@paypalcorp/worldready-react'
import { HeadingText, BodyText } from '@paypalcorp/pp-react'

import cssModules from 'react-css-modules'
import styles from '../introduction.less'

const PrivateIntroductionContent = ({ formattedMaxGoalAmount }) => {
  return (
    <>
      <HeadingText size="sm" styleName="body__title">
        <Message id="flow.private.intro.header" />
      </HeadingText>
      <BodyText as="p" styleName="body__subtitle">
        <Message id="flow.private.intro.description" />
      </BodyText>

      <ul>
        <li>
          <BodyText>
            <Message id="flow.private.intro.bullet_money_to_account" />
          </BodyText>
        </li>
        <li>
          <BodyText>
            <Message
              id="flow.private.intro.bullet_collect_up"
              amount={formattedMaxGoalAmount}
            />
          </BodyText>
        </li>
      </ul>
    </>
  )
}

PrivateIntroductionContent.propTypes = {
  formattedMaxGoalAmount: PropTypes.string.isRequired,
}

export default cssModules(PrivateIntroductionContent, styles)
