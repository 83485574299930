import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './standard_error.less'
import { withRouter } from 'react-router'
import { routerShape } from 'react-router/lib/PropTypes'
import {
  Button,
  HeadingText,
  BodyText,
  InternationalIcon,
  PersonalInfoIcon,
  UndoIcon,
  PasskeyIcon,
  FundraiserIcon,
  CashIcon,
} from '@paypalcorp/pp-react'
import { getCdnHostName } from '@paypalcorp/instance-location'
import { Message } from '@paypalcorp/worldready-react'

const ERROR = 'error'
const WARNING = 'warning'
class StandardError extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    iconType: PropTypes.oneOf([WARNING, ERROR]),
    showButton: PropTypes.bool,
    onTryAgain: PropTypes.func,
    backButton: PropTypes.bool,
    extraContent: PropTypes.element,
    router: routerShape,
    icon: PropTypes.element,
    iconName: PropTypes.string,
  }

  static defaultProps = { iconType: WARNING }

  handleButtonClick = () => {
    if (this.props.backButton) {
      this.props.router.goBack()
    } else if (this.props.onTryAgain) {
      this.props.onTryAgain()
    }
  }

  renderIcon = () => {
    const { icon, iconType } = this.props
    if (icon) {
      return <div styleName="custom-icon">{icon}</div>
    }

    const ICON_NAME = iconType === WARNING ? 'empty-warning' : 'empty-critical'
    const ICON_URL = `https://${getCdnHostName()}/paypal-ui/illustrations/focused/svg/${ICON_NAME}.svg`
    const isShowingImage = iconType === WARNING || iconType === ERROR

    const iconClass = (() => {
      switch (iconType) {
        case WARNING:
          return ICON_URL
        case ERROR:
          return ICON_URL
        default:
          return ''
      }
    })()

    return isShowingImage ? (
      <img styleName="error-icon" src={ICON_URL} alt={iconType} />
    ) : (
      <span className={`icon icon-large ${iconClass}`} styleName="large-icon" />
    )
  }

  icon = iconName => {
    let iconComponent
    if (iconName === 'passkey') {
      iconComponent = <PasskeyIcon color="sysColorAccent1Main" size="xl" />
    } else if (iconName === 'international') {
      iconComponent = (
        <InternationalIcon color="sysColorAccent1Main" size="xl" />
      )
    } else if (iconName === 'personal') {
      iconComponent = <PersonalInfoIcon color="sysColorAccent1Main" size="xl" />
    } else if (iconName === 'undo') {
      iconComponent = <UndoIcon color="sysColorAccent1Main" size="xl" />
    } else if (iconName === 'fundraiser') {
      iconComponent = <FundraiserIcon color="sysColorAccent1Main" size="xl" />
    } else if (iconName === 'cash') {
      iconComponent = <CashIcon color="sysColorAccent1Main" size="xl" />
    }
    return <div styleName="iconAlign">{iconComponent}</div>
  }

  render() {
    const { iconName } = this.props
    return (
      <div data-testid="standard-error" styleName="standardError">
        {iconName ? this.icon(iconName) : this.renderIcon()}
        <HeadingText data-testid="standard-error-title" size="sm">
          {this.props.title}
        </HeadingText>
        <BodyText data-testid="standard-error-subtitle" styleName="subtitle">
          {this.props.subtitle}
        </BodyText>
        {this.props.showButton && (
          <Button
            data-pa-click="tryAgain"
            secondary
            onClick={this.handleButtonClick}
            data-testid="standard-error__try-again"
          >
            {this.props.backButton ? (
              <Message id="components.common.standard_error.back" />
            ) : (
              <Message id="components.common.standard_error.try_again" />
            )}
          </Button>
        )}
        {this.props.extraContent}
      </div>
    )
  }
}

export default withRouter(cssModules(StandardError, styles))
