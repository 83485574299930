import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'window-location-origin'
import 'whatwg-fetch'
import './stylesheets/app.less'
import './init_localization_resources'
import React from 'react'
import { initialize as fptiInitialize } from 'lib/fpti_analytics'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore } from './redux/store'
import { getJSONFromScript, getDataAttr } from './lib/server_params'
import poolsRouter from './router'
import SessionTimeout from 'components/session_timeout'
import { startErrorTracking } from 'lib/errors'
import routes from 'server_routes'
import { PAYPAL_THEME } from '@paypalcorp/pp-react'
import { ContextProvider } from '@paypalcorp/worldready-react'
import { WorldReadyProvider } from '@paypalcorp/pp-react-worldready'
import { ThemeProvider } from '@emotion/react'

function renderMainView() {
  configureStore(getJSONFromScript('store'), store => {
    const PoolsRouter = poolsRouter(store)
    const locale =
      store.getState() &&
      store.getState().locality &&
      store.getState().locality.worldReadyLocale
    render(
      <Provider store={store}>
        <ContextProvider locale={locale}>
          <WorldReadyProvider>
            <ThemeProvider theme={PAYPAL_THEME}>
              <div>
                <SessionTimeout />
                <PoolsRouter />
              </div>
            </ThemeProvider>
          </WorldReadyProvider>
        </ContextProvider>
      </Provider>,
      document.getElementById('main')
    )
  })
}

function main(/* int argc, char *argv[] */) {
  startErrorTracking(routes.error())

  fptiInitialize()

  // since errors are rendered in the server-side, don't activate react rendering when there is one.
  if (!getDataAttr('error')) {
    renderMainView()
  }
}

main()
