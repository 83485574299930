import { getI18n } from 'intl'
import {
  campaignUrlForDisplay,
  campaignUrlWithMeDomain,
} from 'lib/campaign_url'
import { canShareSelector } from 'redux/selectors/share'
import { getCampaignSelector } from 'redux/selectors/campaign'

const i18nBase = getI18n('components/pages/crowdfunding/update_share')

export function getUpdateActivityShareDetails(state, ownProps) {
  const canShare = canShareSelector(state)
  const campaign = getCampaignSelector(state, ownProps)
  const shareUrl = campaignUrlWithMeDomain(campaign.id, campaign.campaign_type)
  const campaignTitle = campaign.title

  const emailSubject = i18nBase('email_subject', {
    campaignTitle,
    organizerName: campaign.owner.full_name,
  })

  const body = i18nBase('body', {
    campaignTitle,
    shareUrl,
  })

  let props = {
    shareable: true,
    displayUrl: campaignUrlForDisplay(campaign.id, campaign.campaign_type),
    appId: state.social.app_id,
    emailBody: body,
    emailSubject,
    isMobile: state.device_info.isMobile,
    shareMsgFB: body,
    shareMsgTwitter: `${body}\n#PeopleHelpingPeople`,
    shareMsgWhatsapp: body,
  }

  if (!canShare) {
    props.showFacebook = false
    props.showMessenger = false
    props.showTwitter = false
    props.showWhatsApp = false
    props.showEmail = false
    props.showSms = false
  }

  return props
}
