import _ from 'lodash'
import { getCdnHostName } from '@paypalcorp/instance-location'

const CATEGORY_IMAGES_PATH = 'cause-gn-images/categories'

const defaultCover = `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/catergory-default-cover.svg`

export const poolCategory = [
  {
    key: 'A_BUSINESS',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/business-employment.png`,
  },
  {
    key: 'ANIMALS_AND_PETS',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/animal-wildlife.png`,
  },
  {
    key: 'ARTS_AND_ENTERTAINMENT',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/arts-culture.png`,
  },
  {
    key: 'BILLS',
    cover: defaultCover,
  },
  {
    key: 'CHARITY',
    cover: defaultCover,
  },
  {
    key: 'EDUCATION',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/education.png`,
  },
  {
    key: 'EMPLOYEES',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/business-employment.png`,
  },
  {
    key: 'FAMILY_CARE',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/family-support.png`,
  },
  {
    key: 'FOOD',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/housing-hunger.png`,
  },
  {
    key: 'GENERAL_SUPPORT',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/general-support.png`,
  },
  {
    key: 'MEDICAL_HELP',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/health-medicine.png`,
  },
  {
    key: 'MEMORIAL_EXPENSES',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/memorial-expenses.png`,
  },
  {
    key: 'MY_COMMUNITY',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/community.png`,
  },
  {
    key: 'DISASTER_RELIEF',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/disaster-relief.png`,
  },
  {
    key: 'THE_ENVIRONMENT',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/enviroment.png`,
  },
  {
    key: 'FAITH_AND_SPIRITUALITY',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/faith-spirituality.png`,
  },
  { key: 'GRANTS_AND_SCHOLARSHIPS', cover: defaultCover },
  {
    key: 'HOUSING_AND_RENT',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/housing-hunger.png`,
  },
  {
    key: 'SOCIAL_ACTIVISM',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/social-justice.png`,
  },
  {
    key: 'TECHNOLOGY',
    cover: defaultCover,
  },
  {
    key: 'TRAVEL_AND_TRANSPORTATION',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/international.png`,
  },
  {
    key: 'VETERANS',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/military-veterans.png`,
  },
  {
    key: 'VOLUNTEERING',
    cover: defaultCover,
  },
  {
    key: '1001',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/arts-culture.png`,
  },
  {
    key: '1002',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/education.png`,
  },
  {
    key: '1003',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/enviroment.png`,
  },
  {
    key: '1004',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/animal-wildlife.png`,
  },
  {
    key: '1005',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/health-medicine.png`,
  },
  {
    key: '1006',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/social-justice.png`,
  },
  {
    key: '1007',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/business-employment.png`,
  },
  {
    key: '1008',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/housing-hunger.png`,
  },
  {
    key: '1009',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/disaster-relief.png`,
  },
  {
    key: '1010',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/sports-recreation.png`,
  },
  {
    key: '1011',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/children-youth.png`,
  },
  {
    key: '1012',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/human-services.png`,
  },
  {
    key: '1013',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/international.png`,
  },
  {
    key: '1014',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/community.png`,
  },
  {
    key: '1015',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/philanthropy-grants.png`,
  },
  {
    key: '1016',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/science-research.png`,
  },
  {
    key: '1017',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/military-veterans.png`,
  },
  {
    key: '1018',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/faith-spirituality.png`,
  },
  {
    key: '1019',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/general-support.png`,
  },
  {
    key: '1020',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/family-support.png`,
  },
  {
    key: '1021',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/memorial-expenses.png`,
  },
  {
    key: '1022',
    cover: `https://${getCdnHostName()}/${CATEGORY_IMAGES_PATH}/crime-justice.png`,
  },
]

export function getDefaultCover(value) {
  let mainCategory = []
  if (Array.isArray(value)) {
    mainCategory = value[0]
  } else {
    mainCategory = value
  }
  const selectedCategory = _.find(
    poolCategory,
    category => category.key === mainCategory
  )

  return selectedCategory && selectedCategory.cover
}

export default { poolCategory, getDefaultCover }
