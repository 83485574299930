import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import {
  Row,
  Col,
  Link,
  Button,
  Container,
  CaptionText,
  HeadingText,
} from '@paypalcorp/pp-react'
import styles from './empty_hub.less'
import { Message } from '@paypalcorp/worldready-react'
import Pictograms from 'components/common/pictograms'

class EmptyHub extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  }

  isOpenTab = this.props.title === 'open'

  render() {
    return (
      <Container styleName="zero-fundraiser">
        <Row>
          <Col>
            <HeadingText
              size="sm"
              styleName="zero-fundraiser__title state-pictograms"
            >
              {this.isOpenTab ? (
                <>
                  <Pictograms
                    svg="grayscale-3"
                    data-testid="open-state-img"
                    classname="state-image"
                    type="grayscale"
                  />
                  <Message
                    id={
                      'components.pages.hub.empty_hub.zero_fundraiser_open_title'
                    }
                  />
                </>
              ) : (
                <>
                  <Pictograms
                    svg="grayscale-1"
                    data-testid="close-state-img"
                    classname="state-image"
                    type="grayscale"
                  />
                  <Message
                    id={
                      'components.pages.hub.empty_hub.zero_fundraiser_closed_title'
                    }
                  />
                </>
              )}
            </HeadingText>

            {this.isOpenTab ? (
              <>
                <CaptionText styleName="zero-fundraiser__subtitle">
                  <Message id="components.pages.hub.empty_hub.zero_fundraiser_open_subtitle" />
                </CaptionText>
                <Button
                  as="a"
                  className="visible-xs"
                  href={`${GLOBAL_CONSTS.REQUEST_URI}/c/create`}
                >
                  <Message id="components.pages.hub.manage_all_page.start_fundraiser_button" />
                </Button>
              </>
            ) : (
              <Link
                className="visible-xs"
                href={`${GLOBAL_CONSTS.REQUEST_URI}/c/create`}
              >
                <Message id="components.pages.hub.manage_all_page.start_fundraiser_button" />
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default cssModules(EmptyHub, styles, { allowMultiple: true })
