import PostUpdateError from './post_update_error'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    error: campaign.err,
    campaign,
  }
}

export default withRouter(connect(mapStateToProps)(PostUpdateError))
