import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import {
  detailDaysFromToday,
  formatTime,
} from 'shared/helpers/datetime_helpers'
import { HeadingText } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

import styles from './count_down.less'

class CountDown extends React.Component {
  static propTypes = {
    datetime: PropTypes.string,
    showSeconds: PropTypes.boolean,
    format: PropTypes.Array,
  }

  intervalId = null
  defaultFormat = ['days']
  updateSeconds = false
  separator = ':'

  constructor(props) {
    super(props)
    const detailDaysFromTodayState = detailDaysFromToday(props.datetime)

    this.state = {
      ...detailDaysFromTodayState,
      isShowingDetail: detailDaysFromTodayState.days < 2,
    }
    // only update seconds if we have default format or in custom format
    this.updateSeconds =
      !this.props.format || this.props.format.indexOf('seconds') > -1
  }

  componentDidUpdate(prevProps) {
    if (prevProps.datetime !== this.props.datetime) {
      this.updateCountDown()
    }
  }

  updateCountDown() {
    const detailDaysFromTodayState = detailDaysFromToday(this.props.datetime)
    this.setState(() => {
      return {
        ...detailDaysFromTodayState,
        isShowingDetail: detailDaysFromTodayState.days < 2,
      }
    })
  }

  // function to start the countdown and update the state every minute
  startCountDown(interval = 1) {
    this.updateCountDown()
    this.intervalId = setInterval(() => this.updateCountDown(), interval * 1000)
  }

  getTimeFormat(cdItem) {
    let time = this.state[cdItem] || 0
    if (cdItem === 'hours') {
      time += 24 * this.state.days
    }
    const timeFormat = `${time}${formatTime[cdItem]}`
    return time < 10 ? `0${timeFormat}` : `${timeFormat}`
  }

  showRemainingInDays() {
    return this.defaultFormat.map((cdItem, index) => {
      return (
        <div styleName="unit_container" key={index}>
          <div>
            <HeadingText size="sm" styleName="unit_value">
              {`${this.state[cdItem]} `}
              <Message id="components.common.count_down.days" />
            </HeadingText>
          </div>
        </div>
      )
    })
  }

  showRemainingInDetail() {
    return (this.props.format || this.defaultFormat).map((cdItem, index) => {
      return (
        <div styleName="unit_container" key={index}>
          {index > 0 ? (
            <HeadingText size="sm" styleName="unit_separator">
              {this.separator}
            </HeadingText>
          ) : null}

          <div>
            <HeadingText size="sm" styleName="unit_value">
              {`${this.getTimeFormat(cdItem)}`}
            </HeadingText>
          </div>
        </div>
      )
    })
  }

  renderComponents() {
    return this.state.isShowingDetail
      ? this.showRemainingInDetail()
      : this.showRemainingInDays()
  }

  clearCountDowns() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  componentWillMount() {
    // initiate the countdown to run every min after roll over seconds.
    if (this.updateSeconds) {
      return this.startCountDown()
    }

    setTimeout(() => this.startCountDown(60), this.state.seconds * 1000)
  }

  componentWillUnmount() {
    this.clearCountDowns()
  }

  render() {
    return (
      <HeadingText styleName="count_down_container" size="sm">
        {this.renderComponents()}
      </HeadingText>
    )
  }
}

export default cssModules(CountDown, styles, {
  allowMultiple: true,
})
