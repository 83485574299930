import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../../crowdfunding_basics/crowdfunding_basics.less'
import DateInputBox from 'components/common/date_input_box'
import CurrencyInputBox from 'components/common/currency_input'
import { CROWDFUNDING_CAMPAIGN_MAX_DAYS } from 'shared/shared_consts'
import { CaptionText, TextInput, HeadingText } from '@paypalcorp/pp-react'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import { useIntl, getDefaultCurrencyCode } from 'intl'
import { Message } from '@paypalcorp/worldready-react'

const PrivateBasicsForm = props => {
  const {
    currency,
    countryCode,
    disclaimer,
    fields: { poolName, goalAmount, endDate, pledgeAmount },
    formattedMaxGoalAmount,
    timeZone,
  } = props

  const { formatMessage } = useIntl()
  const pledgeContentKey =
    // eslint-disable-next-line i18n-scan/no-country-dependency
    countryCode === 'DE'
      ? 'flow.private.basics.input_pledge_label_DE'
      : 'flow.private.basics.input_pledge_label'
  return (
    <>
      <HeadingText size="sm" styleName="title">
        <Message id="flow.private.basics.header" />
      </HeadingText>

      <div styleName="textbox-wrapper">
        <TextInput
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.personal_pool_name_title'
          )}
          {...domOnlyProps(poolName)}
          errorText={getFormError(poolName)}
          autoFocus={props.shouldAutoFocus}
          helperText={formatMessage('flow.private.basics.input_name_help_text')}
          data-testid="basics-name"
        />
      </div>

      <div styleName="textbox-wrapper">
        <DateInputBox
          id="end_date"
          label={formatMessage('flow.private.basics.input_date_label', {
            maxDays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
          })}
          dataNemo="date-pool-ends"
          dataTestId="basics-end-date"
          errorText={getFormError(endDate)}
          {...endDate}
          timeZone={timeZone}
          useEndOfDay={false}
        />
      </div>

      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          label={formatMessage('flow.private.basics.input_goal_amount_label')}
          dataTestId="basics-amount"
          disableCents={true}
          errorText={getFormError(goalAmount)}
          currencyCode={currency || getDefaultCurrencyCode()}
          {...goalAmount}
          helperText={formatMessage(
            'flow.private.basics.input_goal_amount_help_text',
            { amount: formattedMaxGoalAmount }
          )}
        />
      </div>

      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          label={formatMessage(pledgeContentKey)}
          dataTestId="basics-pledge"
          disableCents={true}
          currencyCode={currency || getDefaultCurrencyCode()}
          {...pledgeAmount}
          helperText={formatMessage(
            'flow.private.basics.disclaimer_no_deduction'
          )}
        />
      </div>

      {disclaimer && (
        <CaptionText styleName="gn-disclaimer">{disclaimer}</CaptionText>
      )}
    </>
  )
}

PrivateBasicsForm.propTypes = {
  currency: PropTypes.string,
  countryCode: PropTypes.string,
  disclaimer: PropTypes.string,
  fields: PropTypes.object.isRequired,
  formattedMaxGoalAmount: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  timeZone: PropTypes.string,
}

export default cssModules(PrivateBasicsForm, styles)
