import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal from 'components/common/modal'

import cssModules from 'react-css-modules'
import styles from './campaign_not_found.less'
import { getI18n } from 'intl'
import { baseUrl } from 'shared/campaign_url'
import { trackClick, trackImpression } from 'lib/fpti_analytics'

const i18n = getI18n('components/pages/campaign_not_found')

const GENEROSITY_NETWORK_URL = baseUrl(
  window.location.hostname,
  '/generosity-network'
)

class CampaignNotFound extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    cdnHost: PropTypes.string,
  }

  componentDidMount() {
    trackImpression({
      event_name: 'dw_giving_detail_fundraiser_unavailable_screen_shown',
    })
  }

  render() {
    const WARNING_SVG_URL = `https://${
      this.props.cdnHost
    }/paypal-ui/illustrations/svg/warning.svg`
    return (
      <Modal showClose={false}>
        <div styleName="campaign-not-found">
          <div styleName="icon-container">
            <img src={`${WARNING_SVG_URL}`} />
          </div>
          <div styleName="title">{i18n('title_cf')}</div>
          <div styleName="description">{i18n('description_cf')}</div>
          <a
            href={`${GENEROSITY_NETWORK_URL}`}
            className="vx_btn vx_btn-block vx_btn-secondary"
            styleName="cta-button"
            onClick={() => {
              trackClick({
                clickName: 'dw_giving_detail_fundraiser_unavailable_gn_pressed',
                data: {
                  event_name:
                    'dw_giving_detail_fundraiser_unavailable_gn_pressed',
                },
              })
            }}
          >
            {i18n('cta_cf')}
          </a>
        </div>
      </Modal>
    )
  }
}

export default connect()(cssModules(CampaignNotFound, styles))
