import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import {
  CaptionText,
  BodyText,
  Button,
  Dialog,
  HeadingText,
  PictureIcon,
  Sheet,
} from '@paypalcorp/pp-react'
import styles from './crowdfunding_cover.less'
import CrowdFundingPreview from 'components/pages/crowdfunding/crowdfunding_preview'
import CrowdFundingSelectCover from 'components/pages/crowdfunding/crowdfunding_select_cover'
import {
  trackClick,
  trackEventNameClick,
  trackImpression,
} from 'lib/fpti_analytics'
import TipBox from 'components/common/tip_box'
import { getI18n } from 'intl'
import clsx from 'clsx'
import { getMajorMinorAgreementVersion } from 'lib/user_consent'
import { Message } from '@paypalcorp/worldready-react'
import PositionPhoto from './partials/position_photo'
import AddPhoto from './partials/add_photo'
import { POOL_TYPE } from 'shared/shared_consts'

export const I18N_CONTEXT = 'components/pages/crowdfunding/crowdfunding_cover'
const i18n = getI18n(I18N_CONTEXT)

class CrowdFundingCover extends React.Component {
  static propTypes = {
    anyInactiveFieldsTouchedWithError: PropTypes.bool.isRequired,
    backgroundImage: PropTypes.string,
    backgroundPan: PropTypes.number,
    charity: PropTypes.object,
    countryCode: PropTypes.string,
    fields: PropTypes.object.isRequired,
    gradientColor: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    isAndroid: PropTypes.bool,
    onChangeCover: PropTypes.func,
    setData: PropTypes.func,
    shouldAutoFocus: PropTypes.bool,
    submit: PropTypes.func,
    handleCoverSelect: PropTypes.func,
    termsAndConditionLink: PropTypes.string,
    cdnHost: PropTypes.string,
    route: PropTypes.object,
    router: PropTypes.object,
    defaultCategoryCover: PropTypes.string,
    isPrivateCampaign: PropTypes.bool,
    userConsentDisclaimer: PropTypes.string,
    userConsentBtnText: PropTypes.string,
    userLegalAgreementVersion: PropTypes.string,
    categoryImage: PropTypes.string,
    isMobile: PropTypes.bool,
    campaignType: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.coverImage = React.createRef()
    this.path = 'select-cover'
  }

  state = {
    isOpen: false,
    isPhotoModal: false,
  }
  setData() {
    this.props.setData({
      background_image: this.props.fields.backgroundImage.value,
      background_image_filename:
        this.props.fields.backgroundImageFilename.value ||
        this.props.fields.backgroundImage.value,
      background_pan: this.props.fields.backgroundPan.value,
      user_legal_agreement_version: this.props.userLegalAgreementVersion,
    })
  }
  handleModalClick = e => {
    e.preventDefault()
    this.setData()
    this.setState({ isOpen: true })
    const eventName =
      this.props.route && this.props.route.path === this.path
        ? 'dw_giving_fundraiser_select_photo_preview_pressed'
        : 'dw_giving_fundraiser_add_photo_preview_pressed'
    trackClick({
      clickName: eventName,
      data: {
        event_name: eventName,
        photo_uploaded_flag: this.props.fields.backgroundImage.value ? 1 : 0,
      },
    })
    trackImpression({
      pageName: 'Preview',
      event_name: 'dw_giving_fundraiser_preview_screen_shown',
    })
  }

  handleModalClose = () => {
    this.setState({ isOpen: false })
    trackClick({
      clickName: 'dw_giving_fundraiser_preview_dismiss_pressed',
      data: {
        event_name: 'dw_giving_fundraiser_preview_dismiss_pressed',
        dismiss_method: 'close',
      },
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { userConsentBtnText, userLegalAgreementVersion } = this.props

    const eventName =
      this.props.route && this.props.route.path === this.path
        ? 'dw_giving_fundraiser_select_photo_publish_pressed'
        : 'dw_giving_fundraiser_add_photo_publish_pressed'
    trackClick({
      clickName: eventName,
      data: {
        event_name: eventName,
        photo_uploaded_flag: this.props.fields.backgroundImage.value ? 1 : 0,
      },
    })

    if (userConsentBtnText) {
      trackClick({
        clickName: 'active_consent_disclaimer_agree_pressed',
        data: {
          event_name: 'active_consent_disclaimer_agree_pressed',
          ...getMajorMinorAgreementVersion(userLegalAgreementVersion),
        },
      })
    }
    if (this.props.fields.backgroundImage.value) {
      this.submitCoverPage()
    } else {
      trackImpression({
        pageName: 'no-photo-screen-shown',
        event_name: 'dw_giving_fundraiser_no_photo_screen_shown',
      })
      this.setState({ isPhotoModal: true })
    }
  }
  submitCoverPage = () => {
    if (this.state.isPhotoModal) {
      trackEventNameClick({
        event_name: 'dw_giving_fundraiser_no_photo_proceed_pressed',
      })
    }

    this.setData()
    return this.props.submit(
      `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/publish`
    )
  }

  handlePhotoModalClose = e => {
    if (e) {
      trackEventNameClick({
        event_name: 'dw_giving_fundraiser_no_photo_dismiss_pressed',
        dismiss_method: 'close',
      })
    }
    this.setState({ isPhotoModal: false })
  }

  handleAddPhoto = () => {
    trackEventNameClick({
      event_name: 'dw_giving_fundraiser_no_photo_add_pressed',
    })
    this.handlePhotoModalClose()
    this.props.router.push({
      pathname: `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/cover`,
    })
  }

  handleSelectCover = () => {
    return this.props.handleCoverSelect(
      `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/cover`
    )
  }

  handleTcClick = () => {
    const { userConsentDisclaimer, userLegalAgreementVersion } = this.props
    const event_name =
      this.props.route && this.props.route.path === this.path
        ? 'dw_giving_fundraiser_select_photo_terms_pressed'
        : 'dw_giving_fundraiser_add_photo_terms_pressed'
    trackClick({
      clickName: event_name,
      data: {
        event_name,
      },
    })

    // User consent event
    if (userConsentDisclaimer) {
      trackClick({
        clickName: 'active_consent_disclaimer_terms_pressed',
        data: {
          event_name: 'active_consent_disclaimer_terms_pressed',
          ...getMajorMinorAgreementVersion(userLegalAgreementVersion),
        },
      })
    }
  }

  render() {
    const {
      anyInactiveFieldsTouchedWithError,
      termsAndConditionLink,
      route,
      fields,
      defaultCategoryCover,
      userConsentDisclaimer,
      userConsentBtnText,
      userLegalAgreementVersion,
      isMobile,
    } = this.props
    const disclaimer =
      userConsentDisclaimer ||
      i18n('disclaimer', { tc_link: termsAndConditionLink })

    if (userConsentDisclaimer) {
      trackImpression({
        pageName: 'crowdfunding-cover',
        event_name: 'active_consent_disclaimer_shown',
        ...getMajorMinorAgreementVersion(userLegalAgreementVersion),
      })
    }
    return (
      <form>
        {route && route.path === this.path ? (
          <CrowdFundingSelectCover
            handleSelectCover={this.handleSelectCover}
            cdnHost={this.props.cdnHost}
            fields={this.props.fields}
            charity={this.props.charity}
            isPrivateCampaign={this.props.isPrivateCampaign}
            defaultCategoryCover={defaultCategoryCover}
            campaignType={this.props.campaignType}
          />
        ) : (
          <>
            {fields.backgroundImage?.value ? (
              <PositionPhoto {...this.props} coverImage={this.coverImage} />
            ) : (
              <AddPhoto {...this.props} coverImage={this.coverImage} />
            )}
          </>
        )}
        {route?.path === this.path && (
          <>
            {this.props.campaignType === POOL_TYPE.CHARITY && (
              <TipBox
                text={
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.tip_description" />
                }
                type="cover"
              />
            )}
            <CaptionText
              styleName="disclaimer"
              onClick={this.handleTcClick}
              dangerouslySetInnerHTML={{
                __html: disclaimer,
              }}
            />
            <div styleName="buttons">
              <Button
                onClick={this.handleSubmit}
                className={clsx({
                  'vx_btn-disabled': anyInactiveFieldsTouchedWithError,
                })}
                disabled={anyInactiveFieldsTouchedWithError}
                data-nemo="agree-publish"
                type="button"
                data-testid="s-cover-create-publish"
              >
                {userConsentBtnText || (
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.publish" />
                )}
              </Button>
              <Button
                secondary
                onClick={this.handleModalClick}
                className={clsx({
                  'vx_btn-disabled': anyInactiveFieldsTouchedWithError,
                })}
                disabled={anyInactiveFieldsTouchedWithError}
                data-nemo="preview-next"
              >
                <Message id="components.pages.crowdfunding.crowdfunding_cover.preview" />
              </Button>
            </div>
            {isMobile ? (
              <Dialog
                isOpen={this.state.isOpen}
                onClose={this.handleModalClose}
                closeButtonProps={{ 'data-nemo': 'preview-close' }}
                styleName="modal"
                action={
                  <HeadingText size="md" styleName="modal_title">
                    <Message id="components.pages.crowdfunding.crowdfunding_cover.preview" />
                  </HeadingText>
                }
              >
                <CrowdFundingPreview />
              </Dialog>
            ) : (
              <Sheet
                isOpen={this.state.isOpen}
                onClose={this.handleModalClose}
                title={
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.preview" />
                }
                divider
                styleName="modal"
              >
                <CrowdFundingPreview />
              </Sheet>
            )}
            <Dialog
              center
              isOpen={this.state.isPhotoModal}
              containerClassName={styles.container_class}
              onClose={this.handlePhotoModalClose}
              title={
                <>
                  <div styleName="icon-alignment">
                    <PictureIcon color="sysColorAccent1Main" size="xl" />
                  </div>
                  <HeadingText size="sm">
                    <Message id="components.pages.crowdfunding.crowdfunding_cover.no_photo_title" />
                  </HeadingText>
                </>
              }
            >
              <div styleName="no_photo_row">
                <BodyText>
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.no_photo_subtitle" />
                </BodyText>
              </div>
              <div styleName="button_offset">
                <Button
                  styleName="no_photo-button"
                  onClick={this.handleAddPhoto}
                  data-testid="cover-btn-add-a-photo"
                >
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.add_photo" />
                </Button>
              </div>
              <div styleName="no_photo_row">
                <Button
                  styleName="no_photo-button"
                  onClick={this.submitCoverPage}
                  secondary
                  data-nemo="no-photo"
                  data-testid="cover-btn-no-photo"
                >
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.continue" />
                </Button>
              </div>
            </Dialog>
          </>
        )}
      </form>
    )
  }
}

export default cssModules(CrowdFundingCover, styles)
