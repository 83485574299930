import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n, formatCurrency } from 'intl'
import styles from './transfer_review_box.less'
import { toCFPBStringId } from 'client/lib/cfpb'
import { BodyText, CaptionText } from '@paypalcorp/pp-react'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/edit_glimpse/transfer_review')

class TransferReviewBox extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    amount: PropTypes.number,
    currency: PropTypes.string.isRequired,
    showAmount: PropTypes.bool,
    showFrom: PropTypes.bool,
    worldReady: PropTypes.Object,
  }

  static defaultProps = { showAmount: true }

  render() {
    return (
      <div data-testid="transfer_review_box__panel">
        {this.props.showAmount && (
          <div>
            <CaptionText strong styleName="detail-name">
              {i18n('transfer_review.amount')}
            </CaptionText>
            <BodyText>
              {formatCurrency({
                value: this.props.amount,
                currencyCode: this.props.currency,
                additionalConfig: {},
                worldReady: this.props.worldReady,
              })}
            </BodyText>
          </div>
        )}
        <CaptionText strong styleName="detail-name">
          {i18n('transfer_review.to')}
        </CaptionText>
        <BodyText>{i18n(toCFPBStringId('transfer_review.balance'))}</BodyText>
        <CaptionText strong styleName="detail-name">
          {i18n('transfer_review.from')}
        </CaptionText>
        <BodyText>{this.props.title}</BodyText>
      </div>
    )
  }
}

export default injectWorldReady(cssModules(TransferReviewBox, styles))
