import { CurrencyInput } from '@paypalcorp/pp-react'
import React from 'react'
import PropTypes from 'prop-types'

CurrencyInputBox.propTypes = {
  onBlur: PropTypes.func,
  disabled: PropTypes.boolean,
  currencyCode: PropTypes.string,
  errorText: PropTypes.any,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  disableCents: PropTypes.string,
  value: PropTypes.string,
  dataNemo: PropTypes.string,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  isControlledComponent: PropTypes.boolean,
  helperText: PropTypes.string,
}

function CurrencyInputBox(props) {
  const { isControlledComponent = true } = props
  const onAccept = (value, unmaskedValue, typedValue) => {
    props.onBlur(typedValue)
  }

  const commonProps = isControlledComponent
    ? { value: props.value.toString() }
    : {}

  const maskOptions = {
    scale: props.disableCents ? 0 : 2,
  }

  return (
    <CurrencyInput
      id={props.id}
      label={props.label}
      data-nemo={props.dataNemo}
      data-testid={props.dataTestId}
      currencyType={props.currencyCode}
      errorText={props.errorText}
      onAccept={onAccept}
      disabled={props.disabled}
      maskOptions={maskOptions}
      autoComplete={'off'}
      {...commonProps}
      helperText={props.helperText}
    />
  )
}

export default CurrencyInputBox
