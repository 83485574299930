import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import cssModules from 'react-css-modules'
import { Link } from 'react-router'
import { glimpseRoute } from 'lib/route_utils'
import CrowdFundingCampaignHeader from 'components/crowdfunding_campaign_header'
import CrowdFundingTabLayout from 'components/crowdfunding_tab_layout'
import styles from './crowdfunding_detail_view.less'
import {
  HeadingText,
  Button,
  BodyText,
  Container,
  LoadingSpinner,
  ArrowLeftIcon,
  Link as PPLink,
  CaptionText,
  Elevation,
  CalendarIcon,
  WarningIcon,
  Badge,
} from '@paypalcorp/pp-react'
import clsx from 'clsx'
import Strip from 'components/strip'
import FlatProgress from 'components/common/flat_progress'
import CountDown from 'components/common/count_down'
import ShareButton from 'components/common/share_button'
import DonateButton from 'components/pages/crowdfunding/donate_button'
import ShareCampaign from 'components/common/share_campaign_compact'
import ReportLink from 'components/pages/campaign/report_link'
import ModalAnimations from 'components/common/transitions/modal_animations'
import PpgfInset from 'components/common/ppgf_inset'
import CharityDetailsModal from 'components/common/charity_details_modal'
import { trackClick, trackImpression } from 'lib/fpti_analytics'
import PaymentsTokenSheet from '../common/payments_token_sheet/payments_token_sheet'
import { getDisclaimerVariables } from 'shared/helpers/helperDisclaimer'
import { hasPPGFIntegrationIdentifier } from 'shared/helpers/campaign'
import PublicContributionsList from 'components/contributions_list/public_contributions_list'
import EnrichCharityCard from 'components/pages/crowdfunding/enrich_charity_card'
import { Message } from '@paypalcorp/worldready-react'
import { getI18n, legacyFormatDate as formatDate } from 'intl'
import PaypalPage from '../common/paypal_page/index'
import { getCampaignTotalValue } from 'lib/campaign'
import ProfileCover from '../profile_cover'
import AvatarCollection from '../avatar_collection/avatar_collection'

const i18n = getI18n('components/crowdfunding_detail_view')
const i18n2 = getI18n('components/donate_button')
const i18nCharity = getI18n('components/crowdfunding_charity_detail_view')

const PageSpinner = () => {
  return (
    <div className={styles.pageLoading}>
      <LoadingSpinner screenReaderText="loading" size="xl" />
    </div>
  )
}
const CanDonateOrChipInProptypes = {
  endDate: PropTypes.string,
  canDonate: PropTypes.bool,
  canChipIn: PropTypes.bool,
}

const CanDonateOrChipIn = ({ endDate, canDonate, canChipIn }) => {
  const formattedEndDate = formatDate(endDate)

  if (!canDonate) {
    return (
      <div className={styles.donateDisabledWrapper}>
        <Badge type="neutral" leftIcon={WarningIcon}>
          <Message id="components.donate_button.limit-reached" />
        </Badge>
      </div>
    )
  } else if (!canChipIn) {
    return (
      <div className={styles.donateDisabledWrapper}>
        <CalendarIcon size="md" />
        <BodyText className={styles.donateDisabledText}>
          {i18n2('expired', { date: formattedEndDate })}
        </BodyText>
      </div>
    )
  }
  if (canChipIn) {
    return (
      <div>
        <BodyText>{i18n('expires')}</BodyText>
        <CountDown datetime={endDate} format={['hours', 'minutes']} />
      </div>
    )
  }
  return null
}
CanDonateOrChipIn.propTypes = CanDonateOrChipInProptypes

class CrowdfundingDetailView extends React.Component {
  static propTypes = {
    paymentsTokenError: PropTypes.string,
    campaign: PropTypes.Object,
    campaignId: PropTypes.string,
    clearDNWTransactionError: PropTypes.func,
    setIsLoading: PropTypes.func,
    countryCode: PropTypes.string,
    defaultCategoryCover: PropTypes.string,
    geo: PropTypes.Object,
    glimpse: PropTypes.element,
    isEdit: PropTypes.bool,
    isLoadingDNWTransaction: PropTypes.bool,
    isMobile: PropTypes.bool,
    preview: PropTypes.bool,
    reversed: PropTypes.string,
    secondary: PropTypes.string,
    small: PropTypes.string,
    hostName: PropTypes.string,
    hasMarketplaceTreatment: PropTypes.bool,
    contributions: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = { showGivingFundTermsModal: false }
    this.modalRoot = document.getElementById('modal-root')
  }

  invokeGivingFundTermsModal = e => {
    e.preventDefault()
    this.setState({
      showGivingFundTermsModal: true,
    })
    this.trackFPTI('click', 'dw_giving_detail_giving_fund_info_pressed')
    this.trackFPTI('impression', 'dw_giving_paypal_fund_info_screen_shown')
  }

  onGivingFundTermsModalClose = () => {
    this.setState({
      showGivingFundTermsModal: false,
    })
    this.trackFPTI('click', 'dw_giving_paypal_fund_info_close_pressed')
  }

  trackFPTI = (eventType, event) => {
    if (eventType === 'impression') {
      trackImpression({ event_name: event })
    } else {
      trackClick({
        clickName: event,
        data: {
          event_name: event,
        },
      })
    }
  }

  renderReportingLink = (isPreview, is_owner, id, isGnc = false) => (
    <div styleName={clsx('campaignReporting', { gnc: isGnc })}>
      {!isPreview && !is_owner && <ReportLink campaignId={id} />}
    </div>
  )

  renderEmptyDiv = () => <div styleName="emptyDiv" />

  handleClickFindMore = event => {
    const CLICK_NAME = 'dw_giving_detail_find_more_fundraisers_pressed'
    trackClick({
      clickName: CLICK_NAME,
      data: {
        event_name: CLICK_NAME,
      },
    })
  }

  render() {
    let {
      background_image,
      background_pan,
      currency,
      current_value = 0,
      end_date,
      goal,
      id,
      is_owner,
      pledge = 0,
      charity,
      can_chip_in: canChipIn,
      can_donate: canDonate,
      transactionSummary,
      category_image: categoryImage,
    } = this.props.campaign
    const {
      isLoadingDNWTransaction,
      isEdit = false,
      paymentsTokenError,
      clearDNWTransactionError,
      setIsLoading,
      hostName,
      countryCode,
      hasMarketplaceTreatment,
      contributions,
    } = this.props
    let isCharity = !!charity && charity.name
    const isPPGFCharity = hasPPGFIntegrationIdentifier(countryCode) && isCharity
    const customStyle = {
      height: '100%',
    }
    const isPreview = id === '__new_campaign__' || isEdit
    const showHeader = !isPreview
    const { isMobile } = this.props
    const showFindMoreLink = hasMarketplaceTreatment && showHeader
    let contributionCount = _get(
      transactionSummary,
      'total_number_of_contributions',
      0
    )
    if (pledge > 0) {
      contributionCount = contributionCount + 1
    }

    return (
      <div>
        {isLoadingDNWTransaction && <PageSpinner />}
        <PaymentsTokenSheet
          isOpen={Boolean(paymentsTokenError)}
          onClose={() => {
            setIsLoading(true)
            clearDNWTransactionError()
          }}
          paymentsTokenError={paymentsTokenError}
          hostName={hostName}
        />
        {ReactDOM.createPortal(
          <ModalAnimations>{this.props.glimpse}</ModalAnimations>,
          this.modalRoot
        )}
        <PaypalPage
          isPreview={isPreview}
          showHeader={showHeader}
          showFooter={!isPreview}
          useWhiteBg={true}
          campaignId={id}
          alwaysShowBanner={true}
          alwaysShowHeaderNavigation={false}
        >
          <Container styleName="campaign_container">
            <div styleName="campaignHeader">
              {showFindMoreLink && (
                <PPLink
                  href={`https://${hostName}/fundraiser/hub`}
                  leftIcon={<ArrowLeftIcon size="sm" />}
                  styleName="findMoreLink"
                  onClick={this.handleClickFindMore}
                  data-test="find-more-link"
                >
                  {i18n('find_more')}
                </PPLink>
              )}
              <div styleName="fundraiser_header">
                <CrowdFundingCampaignHeader
                  campaignId={id}
                  isCharityLink={true}
                />
              </div>
            </div>
            <div styleName="campaignCover">
              <Elevation level={2}>
                <Strip
                  isResponsive={true}
                  customStyle={customStyle}
                  backgroundImage={
                    background_image || this.props.defaultCategoryCover
                  }
                  backgroundPan={background_pan}
                  categoryImage={categoryImage}
                />
              </Elevation>
              <ProfileCover campaignId={id} />
            </div>
            <div styleName="campaignProgress">
              {!!contributions?.length && (
                <div>
                  <AvatarCollection
                    contributions={contributions}
                    contributionCount={contributionCount}
                  />
                </div>
              )}
              <div styleName="progress-bar">
                <FlatProgress
                  totalAmount={getCampaignTotalValue({
                    currentValue: current_value,
                    pledge,
                  })}
                  goalAmount={goal}
                  showProgress={true}
                  currencyCode={currency}
                  dataTestId="detail-view-amount"
                  campaignType={this.props.campaign.type}
                  detailsPageSpace={true}
                />
              </div>
              <CanDonateOrChipIn
                canChipIn={canChipIn}
                canDonate={canDonate}
                endDate={end_date}
              />
              {is_owner && !isPreview ? (
                <Button
                  as={Link}
                  to={{
                    pathname: glimpseRoute('pools'),
                    state: { campaignId: id, reloadTransactions: true },
                  }}
                  secondary={this.props.secondary}
                  inverse={this.props.reversed}
                  small={this.props.small}
                  onClick={() =>
                    this.trackFPTI(
                      'click',
                      'dw_giving_view_fundraiser_manage_pressed'
                    )
                  }
                  styleName="manage-button"
                  data-nemo="manage"
                >
                  {i18n('manage')}
                </Button>
              ) : (
                <div styleName="donate-button">
                  <DonateButton campaignId={id} isPreview={isPreview} />
                </div>
              )}
              {!isPreview &&
                isMobile && (
                  <div styleName="share-button">
                    <ShareButton
                      campaignId={id}
                      i18n={i18n}
                      text={i18n('share')}
                    />
                  </div>
                )}
            </div>
            <div styleName="p-15">
              <div styleName="campaignTabs">
                <CrowdFundingTabLayout campaignId={id} />
              </div>
              <div styleName="donations">
                <HeadingText size="sm" data-testid="detail-view-count">
                  <Message
                    id="components.crowdfunding_tab_layout.contribution_title"
                    count={contributionCount}
                  />
                </HeadingText>
                <PublicContributionsList campaignId={id} hideChipIn={true} />
              </div>
              {isCharity && (
                <div styleName="charity">
                  <EnrichCharityCard campaignId={id} />
                </div>
              )}
              {this.renderEmptyDiv()}
              <div styleName="campaignSharing">
                {!isPreview &&
                  !isMobile && (
                    <div styleName="text-center social-share-container">
                      <BodyText strong>{i18n('social_share_heading')}</BodyText>
                      <ShareCampaign
                        eventName="dw_giving_view_fundraiser_share_channel_pressed"
                        campaignId={id}
                      />
                    </div>
                  )}
                {(!isMobile || (isMobile && !charity)) &&
                  this.renderReportingLink(isPreview, is_owner, id)}
              </div>
            </div>
          </Container>
          {isPPGFCharity && (
            <React.Fragment>
              <PpgfInset
                cdnHost={this.props.geo.cdnHost}
                countryCode={countryCode}
                invokeGivingFundTermsModal={this.invokeGivingFundTermsModal}
                isPreview={isPreview}
              />
              <section styleName="gncDisclaimerContainer">
                <CaptionText
                  dangerouslySetInnerHTML={{
                    __html: i18nCharity(
                      `disclaimer_gnc_${countryCode}`,
                      getDisclaimerVariables({
                        countryCode: countryCode,
                        isGnc: isCharity,
                      })
                    ),
                  }}
                />
              </section>

              {this.state.showGivingFundTermsModal && (
                <CharityDetailsModal
                  cdnHost={this.props.geo.cdnHost}
                  countryCode={this.props.countryCode}
                  onGivingFundTermsModalClose={this.onGivingFundTermsModalClose}
                  showGivingFundTermsModal={this.state.showGivingFundTermsModal}
                />
              )}
            </React.Fragment>
          )}

          {charity &&
            isMobile &&
            this.renderReportingLink(isPreview, is_owner, id, true)}
        </PaypalPage>
      </div>
    )
  }
}

export default cssModules(CrowdfundingDetailView, styles, {
  allowMultiple: true,
})
