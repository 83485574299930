import { useContext } from 'react'
import { getStore } from 'lib/store'
import {
  CurrencyFormat,
  DateTimeFormat,
  MessageFormat,
  NumberFormat,
  WorldReady,
  NameFormat,
} from '@paypalcorp/worldready'
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react'
import { getJSONFromScript } from '../lib/server_params'

function getWorldReadyRules() {
  return getJSONFromScript('worldReady')
}

function getWorldReadyMarketData() {
  return getJSONFromScript('worldReadyMarketData')
}

const worldReadyMarketData = getWorldReadyMarketData()

function getDefaultCurrencyCode() {
  return worldReadyMarketData.defaultCurrencyCode
}

/**
 * Get user's timeZone from Store
 *
 * @returns {string}
 */
function getTimeZone() {
  return getStore().locality.timezone
}

/**
 * Get user's country from Store
 *
 * @returns {string}
 */
function getCountry() {
  return getStore().locality.country
}

/**
 * Get WorldReady context based on store locality
 *
 * When hooks are used then the worldReady object can be get from the context
 * when the function is called directly hooks can't be used, therefore, we need
 * to create the context based on the locale
 *
 * @returns {Object}
 */
function getPlainWorldReadyContext() {
  const worldReadyLocale = getStore().locality.worldReadyLocale
  return new WorldReady(worldReadyLocale)
}

/**
 * Return the amount of decimals a number should have
 * according to product rules: decimal positions are only displayed
 * when they are > 0 i.e. 10 or 10.50, but not 10.00 or when value
 * is exactly 0 i.e. 0.00
 *
 * @param {Number} value
 * @returns {Number}
 */
function getMaximumFractionDigits(value) {
  let maximumFractionDigits = 2
  if (Number.isInteger(value) && value > 0) {
    maximumFractionDigits = 0
  }

  return maximumFractionDigits
}

/**
 * Receives the localization context and a currency value and code,
 * formats the currency value as per currency code and locale
 * and adds the proper currency symbol based on the localization
 *
 * @param {string} value
 * @param {string} currencyCode
 * @param {Object} additionalConfig
 * @param {Object} worldReady
 * @returns {string}
 *
 * @see go/worldreadyjs => Currency => Displaying Currencies
 */
function formatCurrency({
  value,
  currencyCode,
  additionalConfig,
  worldReady = getPlainWorldReadyContext(),
}) {
  const number = isNaN(value) ? 0 : value
  const floatValue = parseFloat(number)
  const maximumFractionDigits = getMaximumFractionDigits(floatValue)

  return new CurrencyFormat(worldReady, {
    currency: currencyCode,
    maximumFractionDigits,
    ...additionalConfig,
  }).format(floatValue)
}

/**
 * Receives the localization context,
 * the a date string or instance and extra options if required
 * and returns a formated date based on the default style or
 * provided options
 *
 * @param {Object} worldReady
 * @param {date|string} value
 * @param {Object} options
 * @returns {string}
 */
function formatDate(worldReady, value, options) {
  const date = value instanceof Date ? value : new Date(value)
  return new DateTimeFormat(worldReady, {
    style: 'date-medium',
    timeZone: getTimeZone(),
    ...options,
  }).format(date)
}

/**
 * This function mimics the current Griffin's formatDate function
 * TODO: This function can be removed once everything is migrated
 * @param date
 * @param style
 * @returns {string}
 */
export function legacyFormatDate(date, style = 'date-short') {
  const worldReady = getPlainWorldReadyContext()
  return formatDate(worldReady, date, { style })
}

/*
* Parses a string into a Date
* @param {string} text
* @param {Object} options
* @param {Object} worldReady
* @returns {Date}
*/
export function parseDate(
  text,
  options,
  worldReady = getPlainWorldReadyContext()
) {
  if (typeof text !== 'string') {
    return null
  }
  return new DateTimeFormat(worldReady, {
    style: 'date-medium',
    timeZone: getTimeZone(),
    ...options,
  }).parse(text)
}

/**
 * Receives the worldReady localization context contentId, formatOptions
 * and returns a formatted/localized string
 *
 * @param {Object} worldReady
 * @param {string} contentId
 * @param {Object} formatOptions
 * @returns {string}
 */
function formatMessage(worldReady, contentId, formatOptions) {
  return new MessageFormat(worldReady, { id: contentId }).format(formatOptions)
}

/**
 * Receives the worldReady localization context contentId, formatOptions
 * and returns a formatted/localized string
 * name schema -> https://github.paypal.com/ApiSpecifications-R/CommonComponentsSpecification/blob/v3/schema/name.json
 * @param {Object} worldReady
 * @param {Object} name
 * @param {Object} formatOptions
 * @returns {string}
 */
function formatName(worldready, name, options) {
  return new NameFormat(worldready, {
    nameRegion: getCountry(),
    ...options,
  }).format(name)
}

/**
 * Returns a decimal number formatted as percent
 * i.e. 0.125 returns as 12.5%
 *
 * @param {string|number} value
 * @param {Object} formatOptions
 * @param {Object} worldReady
 * @returns {string}
 */
function formatPercent(
  value,
  formatOptions = {},
  worldReady = getPlainWorldReadyContext()
) {
  const number = isNaN(value) ? 0 : value
  const floatValue = parseFloat(number)
  const maximumFractionDigits = getMaximumFractionDigits(floatValue)

  const numberFormatter = new NumberFormat(worldReady, {
    style: 'percent',
    maximumFractionDigits,
    ...formatOptions,
  })
  return numberFormatter.format(floatValue)
}

/**
 * Custom hook to avoid the use of the HOC injectWorldReady in every component
 *
 * @returns {Object} Depends on the called function
 */
function useIntl() {
  const worldReady = useContext(WorldReadyContext)

  return {
    formatCurrency: (value, currencyCode, additionalConfig) => {
      return formatCurrency({
        value,
        worldReady,
        currencyCode,
        additionalConfig,
      })
    },
    formatDate: (value, options) => {
      return formatDate(worldReady, value, options)
    },
    formatMessage: (contentId, formatOptions) => {
      return formatMessage(worldReady, contentId, formatOptions)
    },
    formatName: (name, formatOptions) => {
      return formatName(worldReady, name, formatOptions)
    },
    formatPercent: (number, formatOptions) => {
      return formatPercent(number, formatOptions, worldReady)
    },
  }
}

export function getI18n(filePath) {
  const path = filePath.replaceAll('/', '.')

  return (key, values) => {
    const worldReady = getPlainWorldReadyContext()
    const contentId = `${path}.${key}`
    return formatMessage(worldReady, contentId, values)
  }
}

export function getMergedI18n(filePaths) {
  const i18ns = filePaths.map(getI18n)
  return (key, values) => {
    const texts = i18ns.map(i18n => i18n(key, values))
    return texts.find(text => !text.includes(key)) || texts[0]
  }
}

export {
  useIntl,
  formatCurrency,
  formatDate,
  formatMessage,
  formatName,
  formatPercent,
  getDefaultCurrencyCode,
  getTimeZone,
  getWorldReadyRules,
}
