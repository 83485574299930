import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { DeleteIcon, Button } from '@paypalcorp/pp-react'
import styles from './delete_update_activity.less'
import StandardDialog from '../../../common/standard_dialog/standard_dialog'
import { trackFPTI } from 'lib/fpti_utils'
import { TOAST_NOTIFICATIONS } from 'shared/shared_consts'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { useIntl } from 'intl'
import { Message } from '@paypalcorp/worldready-react'

export const DeleteUpdateActivity = ({
  campaignId,
  id,
  deleteOrganizerActivity,
  onClose,
  campaign,
  onToggleToast,
}) => {
  const { formatMessage } = useIntl()
  const [showDeleteDialog, setDeleteDailog] = useState(false)

  const handledeleteDialogClose = () => {
    trackFPTI(
      campaign,
      'click',
      'dw_giving_delete_update_dismiss_pressed',
      'close'
    )
    setDeleteDailog(false)
  }

  const handleQuit = () => {
    trackFPTI(
      campaign,
      'click',
      'dw_giving_delete_update_keep_pressed',
      'close'
    )
    setDeleteDailog(false)
  }

  const handleDeleteOk = e => {
    e.preventDefault()
    setDeleteDailog(false)
    trackFPTI(campaign, 'click', 'dw_giving_delete_update_confirm_pressed')
    deleteOrganizerActivity(campaignId, id)
      .then(() => {
        trackFPTI(campaign, 'ac', 'dw_giving_delete_update_success_occurred')
        onToggleToast(
          TOAST_NOTIFICATIONS.DELETE_SUCCESS,
          formatMessage(
            'components.edit_glimpse.post_update.delete_notification'
          )
        )
        onClose()
      })
      .catch(() => null)
  }

  return (
    <div styleName="delete-update">
      <Button
        tertiary
        iconComponent={DeleteIcon}
        onClick={e => {
          e.preventDefault()
          trackFPTI(
            campaign,
            'impression',
            'dw_giving_delete_update_screen_shown'
          )
          trackEventNameClick({
            event_name: 'dw_giving_edit_update_delete_pressed',
          })
          setDeleteDailog(true)
        }}
        data-nemo="delete-btn"
      >
        <Message id="components.edit_glimpse.post_update.delete_cf_title" />
      </Button>
      {showDeleteDialog && (
        <StandardDialog
          size="medium"
          title={formatMessage(
            'components.edit_glimpse.post_update.delete_title_cf'
          )}
          subtitle={formatMessage(
            'components.edit_glimpse.post_update.delete_subtitle_cf'
          )}
          closeButtonTitle={formatMessage(
            'components.edit_glimpse.post_update.keep_cf'
          )}
          okButtonTitle={formatMessage(
            'components.edit_glimpse.post_update.delete_cf'
          )}
          onClose={handledeleteDialogClose}
          onQuit={handleQuit}
          onOk={handleDeleteOk}
          iconName="delete"
        />
      )}
    </div>
  )
}

DeleteUpdateActivity.propTypes = {
  campaignId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  deleteOrganizerActivity: PropTypes.func,
  onClose: PropTypes.func,
  campaign: PropTypes.object,
  onToggleToast: PropTypes.func,
}

export default cssModules(DeleteUpdateActivity, styles)
