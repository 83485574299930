import React from 'react'
import cssModules from 'react-css-modules'
import { PropTypes } from 'prop-types'
import styles from './charity_details_modal.less'
import { Sheet, HeadingText, BodyText, Row } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

const US = 'US'

class CharityDetailsModal extends React.Component {
  static propTypes = {
    onGivingFundTermsModalClose: PropTypes.function,
    showGivingFundTermsModal: PropTypes.bool,
    countryCode: PropTypes.string,
    cdnHost: PropTypes.string,
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { countryCode, cdnHost } = this.props
    const IconUrl = `https://${cdnHost}/images/Cause/hub/ic_blueheart.svg`
    const isCountryCodeUS = countryCode === US

    return (
      <Sheet
        isOpen={this.props.showGivingFundTermsModal}
        onClose={this.props.onGivingFundTermsModalClose}
        noPadding="true"
      >
        <article styleName="charity-modal-content">
          <Row noGutters="true" align="center" styleName="charity-heart-image">
            <img
              src={IconUrl}
              alt=""
              role="presentation"
              data-testid="charity_paypal_heart"
            />
          </Row>

          <Row noGutters="true" align="center" styleName="margin-bottom-24">
            <HeadingText>
              <Message id="components.crowdfunding_charity_detail_view.powered_by" />
            </HeadingText>
          </Row>

          <Row noGutters="true" styleName="margin-bottom-24">
            <BodyText>
              <Message id="components.crowdfunding_charity_detail_view.secure_funds" />
            </BodyText>
          </Row>

          <Row noGutters="true" styleName="charity-modal-content-header">
            <BodyText strong="true">
              <Message id="components.crowdfunding_charity_detail_view.how_it_works" />
            </BodyText>
          </Row>

          <Row noGutters="true">
            <BodyText styleName="charity-details-modal-body">
              <ol>
                <li>
                  <Message id="components.crowdfunding_charity_detail_view.charity_donation_process_step1" />
                </li>

                <li>
                  <Message
                    id={`components.crowdfunding_charity_detail_view.charity_donation_process_step2_${countryCode}`}
                  />
                </li>

                <li>
                  <Message id="components.crowdfunding_charity_detail_view.charity_donation_process_step3" />
                </li>

                <li>
                  <Message id="components.crowdfunding_charity_detail_view.charity_donation_process_step4" />
                </li>

                <li>
                  <Message id="components.crowdfunding_charity_detail_view.charity_donation_process_step5" />
                </li>

                {isCountryCodeUS && (
                  <li>
                    <Message id="components.crowdfunding_charity_detail_view.charity_donation_process_step6" />
                  </li>
                )}
              </ol>
            </BodyText>
          </Row>
        </article>
      </Sheet>
    )
  }
}

export default cssModules(CharityDetailsModal, styles, { allowMultiple: true })
