import { connect } from 'react-redux'
import UnboundContributionsList from './contributions_list'
import {
  pendingContributionsListSelector,
  completedContributionsListSelector,
  fullActivitiesListSelector,
} from 'redux/selectors/contributions'

export const PendingContributionsList = connect((state, props) => {
  return {
    contributions: pendingContributionsListSelector(state, props),
    transactionType: 'ALL',
  }
})(UnboundContributionsList)

export const CompletedActivityList = connect((state, props) => {
  return {
    contributions: completedContributionsListSelector(state, props),
    transactionType: 'ALL',
  }
})(UnboundContributionsList)

export const FullActivityList = connect((state, props) => {
  return {
    contributions: fullActivitiesListSelector(state, props),
    transactionType: 'ALL',
  }
})(UnboundContributionsList)
