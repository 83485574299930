import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'

export const CREATE_DNW_TRANSACTION_REQUEST = 'CREATE_DNW_TRANSACTION_REQUEST'
export const CREATE_DNW_TRANSACTION_SUCCESS = 'CREATE_DNW_TRANSACTION_SUCCESS'
export const CREATE_DNW_TRANSACTION_FAILURE = 'CREATE_DNW_TRANSACTION_FAILURE'
export const CLEAR_DNW_TRANSACTION_ERROR = 'CLEAR_DNW_TRANSACTION_ERROR'
export const SET_IS_LOADING = 'SET_IS_LOADING'

const initialState = {
  isLoading: false,
  error: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      }
    }
    case CREATE_DNW_TRANSACTION_FAILURE: {
      const { payload } = action
      const error =
        payload.error.name || payload.error.error || payload.error.message
      return {
        ...state,
        error,
        isLoading: false,
      }
    }
    case CREATE_DNW_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CLEAR_DNW_TRANSACTION_ERROR: {
      return {
        ...state,
        error: '',
      }
    }
    default:
      return state
  }
}

export const buildTransaction = config => {
  return {
    amount: parseFloat(Number(config.transaction_details.amount.value)),
    anonymous: config.anonymous,
    contributor_name: config.contributor_name,
    campaign_id: config.campaign_id,
    ...(config.anonymous
      ? { contributor_id: false }
      : { contributor_id: config.transaction_details.payer_id }),
    currency: config.transaction_details.amount.currency,
    date: config.date,
    id: config.transaction_details.id,
    note: config.transaction_details.note_to_payee,
  }
}

export function createDNWTransaction({
  campaignId,
  hasFullPageDonationRedirect,
}) {
  return (dispatch, getState) => {
    dispatch(setIsLoading(true))

    return fetchClient()
      .request(routes.campaign.api.create_payments_token(), {
        method: 'POST',
        body: { campaignId },
      })
      .then(({ baseUrl, token }) => {
        dispatch(createDNWTransactionRequest())
        if (hasFullPageDonationRedirect) {
          window.location.href = `${baseUrl}/donate?token=${token}`
        } else {
          dispatch(setIsLoading(false))

          // eslint-disable-next-line new-cap
          const instance = window.Donation.Checkout({
            baseUrl,
            token,
            type: 'remote',
            onComplete(onCompleteResponse) {
              dispatch(setIsLoading(true))
              return fetchClient()
                .request(
                  routes.campaign.api.get_payments_token_transaction({
                    id: token,
                  }),
                  {
                    method: 'GET',
                  }
                )
                .then(({ anonymous, sender, transaction_details }) => {
                  dispatch(
                    createDNWTransactionSuccess(
                      buildTransaction({
                        anonymous: anonymous,
                        contributor_name: sender.full_name,
                        campaign_id: campaignId,
                        date: new Date().toISOString(),
                        transaction_details: {
                          ...transaction_details,
                        },
                      }),
                      sender
                    )
                  )
                })
                .catch(error => {
                  dispatch(createDNWTransactionFailure(error))
                  return error
                })
            },
          })
          instance.renderTo(window)
        }

        return token
      })
      .catch(error => {
        dispatch(createDNWTransactionFailure(error))
        return error
      })
  }
}

export function setIsLoading(isLoading) {
  return {
    payload: {
      isLoading: isLoading,
    },
    type: SET_IS_LOADING,
  }
}

export function createDNWTransactionRequest() {
  return {
    type: CREATE_DNW_TRANSACTION_REQUEST,
  }
}
export function createDNWTransactionSuccess(txn, sender, prevTxnCount) {
  return {
    type: CREATE_DNW_TRANSACTION_SUCCESS,
    payload: { txn, sender, prevTxnCount },
  }
}
export function createDNWTransactionFailure(error) {
  return {
    payload: { error },
    type: CREATE_DNW_TRANSACTION_FAILURE,
  }
}

export function clearDNWTransactionError() {
  return {
    payload: {},
    type: CLEAR_DNW_TRANSACTION_ERROR,
  }
}
