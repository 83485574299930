import ClipboardSharing from './clipboard_sharing'
import { connect } from 'react-redux'
import { show } from 'redux/modules/toast_notification'
import { TOAST_NOTIFICATIONS } from 'shared/shared_consts'

const mapStateToProps = (state, ownProps) => {
  return { ...ownProps }
}

const mapDispatchToProps = dispatch => {
  return {
    onShowToast() {
      dispatch(show(TOAST_NOTIFICATIONS.COPY_SUCCESS))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClipboardSharing)
