import EnrichCharityCard from './enrich_charity_card'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import _get from 'lodash/get'
import {
  charityNumberFormat,
  charityCityState,
  enrichCharityData,
} from 'lib/enrich_charity_utils'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  const { charity, country_code: countryCode } = campaign

  const charityName = _get(charity, 'name', '')
  const charityUrl = _get(charity, 'logo_url', '')
  const charityType = _get(charity, 'charity_type', '')
  const charityCity = _get(charity, 'address.city', '')
  const charityState = _get(charity, 'address.state', '')
  const ein = _get(charity, 'ein', '')
  const charityDescription = _get(charity, 'description', '')
  const websiteUrl = _get(charity, 'website_url', '')
  const cityState = charityCityState(charityCity, charityState)
  const {
    sourceContent,
    disclaimerContent,
    unEnrolledLink,
    learnMoreCharityLink,
  } = enrichCharityData(charityType, countryCode)
  const einContent = ein ? charityNumberFormat(ein, countryCode) : ''

  return {
    charityName,
    charityUrl,
    sourceContent,
    disclaimerContent,
    unEnrolledLink,
    learnMoreCharityLink,
    cityState,
    einContent,
    charityDescription,
    websiteUrl,
    campaign,
  }
}
export default connect(mapStateToProps)(EnrichCharityCard)
