import React from 'react'
import PropTypes from 'prop-types'

// This is a React HoC which adds all kinds of utils (as props) that are relevant for a redux-form component
// Should be wrapped by the redux-form HoC, e.g.:
// reduxForm(...)(formUtils(MyForm))
const formUtils = Component => {
  return class extends React.Component {
    static propTypes = {
      errors: PropTypes.any.isRequired,
      fields: PropTypes.object.isRequired,
    }

    anyInactiveFieldsTouchedWithError = () => {
      const { fields, errors } = this.props
      for (const field in fields) {
        if (
          fields[field].touched &&
          errors[field] &&
          errors[field].length &&
          !fields[field].active
        ) {
          return true
        }
      }
      return false
    }

    static displayName = `formUtils(${Component.displayName ||
      Component.name ||
      '???'})`

    render() {
      const newProps = {
        ...this.props,
        anyInactiveFieldsTouchedWithError: this.anyInactiveFieldsTouchedWithError(),
      }
      return <Component {...newProps} />
    }
  }
}

export default formUtils

export const domOnlyProps = ({
  initialValue,
  autofill,
  onUpdate,
  valid,
  invalid,
  dirty,
  pristine,
  active,
  touched,
  visited,
  autofilled,
  error,
  ...domProps
}) => domProps
