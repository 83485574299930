import CrowdFundingCover, { I18N_CONTEXT } from './crowdfunding_cover'
import { getI18n } from 'intl'
import { reduxForm } from 'redux-form'
import { DESCRIPTION_LENGTH } from 'shared/lengths'
import { DISCOVERABLE, GN_DISCLAIMERS, POOL_TYPE } from 'shared/shared_consts'
import formUtils from 'lib/form_utils'
import { length } from 'lib/validators'
import { push } from 'react-router-redux'

import { NEW_CAMPAIGN_ID, setData } from 'redux/modules/campaign'
import {
  getActiveUserConsent,
  getCampaignSelector,
} from 'redux/selectors/campaign'
import { getCategoryDefaultCover, getMainCategory } from 'client/lib/category'
import { get } from 'lodash'

const i18n = getI18n(I18N_CONTEXT)

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  // only set the prop if no category is defined.
  const mainCategory = getMainCategory(campaign.category)
  const defaultCategoryCover = getCategoryDefaultCover(state, mainCategory)

  const activeUserConsent = getActiveUserConsent(state)

  const isPrivateCampaign = campaign.type === POOL_TYPE.PRIVATE
  return {
    isAndroid:
      state.device_info.isMobile && state.device_info.deviceOS === 'Android',
    defaultCategoryCover,
    initialValues: {
      backgroundImage: campaign.background_image,
      backgroundPan: campaign.background_pan,
      backgroundImageFilename: campaign.background_image_filename,
    },
    charity: campaign.charity,
    termsAndConditionLink:
      GN_DISCLAIMERS[state.country.country_code].termsAndConditions,
    shouldDisplayTipBox: Boolean(campaign.discoverable === DISCOVERABLE),
    cdnHost: state.geo.cdnHost,
    isPrivateCampaign,
    userConsentDisclaimer: activeUserConsent.disclaimer_message,
    userConsentBtnText: activeUserConsent.cta_text,
    userLegalAgreementVersion: activeUserConsent.userLegalAgreementVersion,
    categoryImage: get(campaign, 'category_image', ''),
    campaignType: campaign.type,
    isMobile: state.device_info.isMobile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setData: ({
      background_image,
      background_image_filename,
      background_pan,
      user_legal_agreement_version,
    }) => {
      dispatch(
        setData(NEW_CAMPAIGN_ID, {
          background_image,
          background_image_filename,
          background_pan,
          user_legal_agreement_version,
        })
      )
    },
    submit: pathname => {
      return dispatch(push({ pathname }))
    },
    handleCoverSelect: pathname => {
      return dispatch(push({ pathname }))
    },
  }
}

export default reduxForm(
  {
    form: 'create_description',
    destroyOnUnmount: false,
    fields: [
      'description',
      'backgroundImage',
      'backgroundImageFilename',
      'backgroundPan',
    ],
    touchOnBlur: false,
    touchOnChange: true,
    validate: length(
      'description',
      DESCRIPTION_LENGTH,
      i18n('description_too_long', {
        maxLength: DESCRIPTION_LENGTH,
      })
    ),
  },
  mapStateToProps,
  mapDispatchToProps
)(formUtils(CrowdFundingCover))
