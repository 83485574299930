export const UPDATE_QUERY_PARAMS = 'pools/campaign/RESET_REMOTE_OPERATION'

const onUpdateQueryParams = (state, urlData) => {
  const { query = {} } = urlData
  return { ...state, ...query }
}

export const updateQueryParams = payload => {
  return { type: UPDATE_QUERY_PARAMS, payload }
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_QUERY_PARAMS:
      return onUpdateQueryParams(state, action.payload)
    default:
      return state
  }
}
