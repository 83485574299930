import NonProfitPage from './crowdfunding_nonprofit'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { NEW_CAMPAIGN_ID, setData } from 'redux/modules/campaign'
import { getFeaturedCharities } from 'redux/selectors/charity'
import { getCharities } from 'redux/modules/charity'

const mapStateToProps = (state, props) => {
  const featuredCharities = getFeaturedCharities(state, props)

  return {
    isMobile: state.device_info.isMobile,
    featuredCharities: featuredCharities,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    selectCharity(charity) {
      dispatch(
        setData(NEW_CAMPAIGN_ID, {
          charity: charity,
          npid: charity.nonprofit_id,
        })
      )
      dispatch(
        push({
          pathname: `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/basics`,
        })
      )
    },
    getCharities(searchParams) {
      return dispatch(getCharities(searchParams))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NonProfitPage)
