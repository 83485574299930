import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './share_campaign_compact.less'
import CopyBox from 'components/common/copy_box'
import 'node_modules/@paypalcorp/pp-vx/dist/components/icons/icons.css'
import {
  EmailSharing,
  TwitterSharing,
  FacebookSharing,
  MessengerSharing,
  WhatsAppSharing,
  SmsSharing,
} from 'components/common/share_buttons'
import { useIntl } from 'intl'

ShareCampaignCompact.propTypes = {
  shareUrl: PropTypes.string.isRequired,
  displayUrl: PropTypes.string.isRequired,
  shareable: PropTypes.bool.isRequired,
  appId: PropTypes.number.isRequired,
  onClickOverride: PropTypes.func,
  showFacebook: PropTypes.bool,
  showMessenger: PropTypes.bool,
  showTwitter: PropTypes.bool,
  showWhatsApp: PropTypes.bool,
  showEmail: PropTypes.bool,
  showSms: PropTypes.bool,
  showSubtext: PropTypes.bool,
  emailBody: PropTypes.string,
  emailSubject: PropTypes.string,
  isMobile: PropTypes.bool,
  charity: PropTypes.object,
  eventName: PropTypes.string,
}
ShareCampaignCompact.defaultProps = {
  shareable: true,
  showFacebook: true,
  showMessenger: true,
  showTwitter: true,
  showWhatsApp: true,
  showEmail: true,
  showSms: true,
  showSubtext: false,
  isMobile: false,
}

// using a common const due to sonarqube warning
const shareIconStyle = styles['share-icon']

function ShareCampaignCompact(props) {
  const { formatMessage } = useIntl()
  const shareText = props.shareable
    ? props.displayUrl
    : formatMessage('components.share_campaign_compact.link_placeholder')
  return (
    <div>
      <div styleName="share-icons">
        <div styleName="row">
          <EmailSharing {...props} className={shareIconStyle} />

          <MessengerSharing {...props} className={shareIconStyle} />

          {props.isMobile && (
            <SmsSharing {...props} className={shareIconStyle} />
          )}

          <FacebookSharing {...props} className={shareIconStyle} />

          <TwitterSharing {...props} className={shareIconStyle} />

          <WhatsAppSharing {...props} className={shareIconStyle} />
        </div>
      </div>
      <CopyBox
        text={shareText}
        copyText={props.shareUrl}
        enabled={props.shareable}
        charity={props.charity}
        eventName={props.eventName}
      />
    </div>
  )
}

export default cssModules(ShareCampaignCompact, styles)
