import { formatCurrency } from 'intl'
import {
  INSTRUMENT_EVENTS,
  INSTRUMENT_TAGS,
  CHARITY_INSTRUMENTATION,
} from 'shared/shared_consts'
import { getAudience } from 'shared/helpers/campaign'
import { getStore } from './store'
import { addGlobalProperties } from './fpti_global'

const store = getStore()

export const ANALYTICS_TYPES = {
  TRACK_CLICK: 'analytics/track_click',
  TRACK_IMPRESSION: 'analytics/track_impression',
}

const noop = () => {}
const PAYPAL = window.PAYPAL
const emptyAnalytics = { recordClick: noop, recordImpression: noop }
const analytics =
  PAYPAL && PAYPAL.analytics ? new PAYPAL.analytics.Analytics() : emptyAnalytics

export function initialize() {
  if (window.PAYPAL && window.PAYPAL.analytics) {
    window.PAYPAL.analytics.setupComplete = paypalAnalyticsSetupCompleted
    // call it anyway,
    // in case there's a weird race condition where `setupComplete` is already called from the analytics lib
    // before this was loaded.
    paypalAnalyticsSetupCompleted()
  }
}

function getEventSource() {
  return store.device_info.isMobile ? 'mobile' : 'web'
}

export function paypalAnalyticsSetupCompleted() {
  if (_initialized) {
    return // don't init twice
  }
  if (!window.PAYPAL.analytics.instance) {
    return // don't init if the analytic lib doesn't have a an instance yet
  }

  _initialized = true
  _flushQueue()
}

export function trackImpression(payload) {
  track(ANALYTICS_TYPES.TRACK_IMPRESSION, payload)
}

export function trackEventNameClick(payload) {
  const { event_name, dismiss_method } = payload
  trackClick({
    clickName: event_name,
    data: {
      event_name: event_name,
      product: INSTRUMENT_EVENTS.DW_GIVING,
      dismiss_method: dismiss_method,
    },
  })
}

export function trackClick(payload) {
  if (!payload.clickName) {
    return
  }

  track(ANALYTICS_TYPES.TRACK_CLICK, payload)
}

export function track(type, payload) {
  const enhancedPayload = addGlobalProperties(payload)

  if (_initialized) {
    switch (type) {
      case ANALYTICS_TYPES.TRACK_CLICK:
        _trackClick(enhancedPayload)
        break
      case ANALYTICS_TYPES.TRACK_IMPRESSION:
        _trackImpression(enhancedPayload)
        break
      default:
        break
    }
  } else {
    _enqueueEvent(type, enhancedPayload)
  }
}

let _initialized = false

// This queue is here to prevent a race condition.
// Since an analytics event can fire before the analytics client-side script
// has been initialized, we're queuing the events that fired while it's initializing,
// and fire all of them sequentially once initialization has completed.
const _q = []

function _flushQueue() {
  while (_q.length) {
    const ev = _q.shift()
    track(ev.type, ev.payload)
  }
}

function _enqueueEvent(type, payload) {
  _q.push({ type, payload })
}

function _trackClick({ clickName, data = {} }) {
  if (clickName) {
    const fpti = {
      data: {
        ...window.fpti,
        ...data,
      },
    }

    fpti.data.pgln = `${window.fpti.page}|${clickName}`
    fpti.data.pglk = `${window.fpti.pgrp}|${clickName}`
    fpti.data.link = clickName

    // actually record the click
    analytics.recordClick(fpti)

    // remove those additional keys for subsequent calls
    Object.keys(data).forEach(key => {
      delete window.fpti[key]
    })
  }
}

export function _trackImpression({
  pageName,
  campaignId = '',
  flow = '',
  poolOwner = '',
  erpg = '',
  eccd = '',
  contingency = '',
  amount = '',
  currency = '',
  event_name = '',
  charityname = '',
  charity_name = '',
  npid = '',
  crid = '',
  error_code = '',
  screen = '',
  error_desc = '',
  event_type = '',
  status = '',
  state = '',
  count = '',
  rank = '',
  update_state = '',
  fundraiser_expired = '',
  context_id = '',
  discoverable,
  ...others
}) {
  pageName = `main:pools:${pageName}`

  window.fpti = {
    page: pageName + ':::',
    pgrp: pageName,
    link: undefined, //NOSONAR
    cmpn_id: campaignId,
    encr_rcvr_id: poolOwner,
    erpg,
    eccd,
    flow: flow || window.fpti.flow,
    contingency,
    event_name,
    charityname: charityname || charity_name || window.fpti.charityname,
    crid: crid || npid || window.fpti.crid,
    product: INSTRUMENT_EVENTS.DW_GIVING,
    platform: INSTRUMENT_TAGS.WEB,
    pool_id: campaignId,
    campaign_id: campaignId,
    error_code,
    screen,
    error_desc,
    event_type,
    status,
    state,
    count,
    rank,
    update_state,
    fundraiser_expired,
    context_id: context_id || window.fpti.csci,
    event_source: getEventSource(),
  }

  if (CHARITY_INSTRUMENTATION.FUNDRAISER_SCREEN_SHOWN === event_name) {
    window.fpti.audience = getAudience(discoverable)
  }

  Object.keys(others || {}).forEach(tagKey => {
    window.fpti[tagKey] = others[tagKey]
  })

  if (amount && currency) {
    const txnAmt = formatCurrency({ value: amount, currencyCode: currency })
    window.fpti.txn_amt = `${txnAmt} ${currency}`
  }

  window.PAYPAL.analytics.instance.recordImpression()
}
