export function values(obj) {
  return Object.keys(obj).map(key => obj[key])
}

// makes sure that all of the keys are in the obj, or none of them are
export function colocate(obj, ...keys) {
  let returnValue = { ...obj }
  const existingKeys = keys.filter(key => !!obj[key])

  if (existingKeys.length !== 0 && existingKeys.length !== keys.length) {
    existingKeys.forEach(key => {
      delete returnValue[key]
    })
  }

  return returnValue
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}
