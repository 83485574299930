import EditGlimpse from './edit_glimpse'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const progressValue = campaign.goal
    ? Math.min((campaign.current_value + campaign.pledge) / campaign.goal, 1)
    : 0
  return {
    operatingRemotely: campaign.operatingRemotely,
    progressValue: progressValue,
    goalReached: progressValue >= 1,
    charity: campaign.charity,
    campaign: campaign,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGlimpse)
