export function sendEnsightenEvent(eventName, attempts = 3) {
  if (
    window.Bootstrapper &&
    window.Bootstrapper.ensEvent &&
    typeof window.Bootstrapper.ensEvent.trigger === 'function'
  ) {
    window.Bootstrapper.ensEvent.trigger(eventName)
  } else if (attempts > 0) {
    // wait for it to load maybe? it's a disgusting heuristic, but it works
    setTimeout(() => {
      sendEnsightenEvent(eventName, attempts - 1)
    }, 1250)
  }
}
