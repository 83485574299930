import React from 'react'
import PropTypes from 'prop-types'
import styles from './create_card.less'
import cssModules from 'react-css-modules'
import { Link } from 'react-router'
import { BodyText, CaptionText, Card } from '@paypalcorp/pp-react'
import { trackClick } from 'lib/fpti_analytics'

class CreateCard extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    cartIcon: PropTypes.elementType.isRequired,
    event_name: PropTypes.string,
    data_nemo: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    dataTestId: PropTypes.string,
    isSmallGroup: PropTypes.bool,
  }

  render() {
    return (
      <Link
        to={!this.props.href ? this.props.to : undefined}
        href={this.props.href}
        styleName="card-link"
        data-nemo={this.props.data_nemo}
        data-testid={this.props.dataTestId}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick()
          }
          trackClick({
            clickName: this.props.event_name,
            data: {
              event_name: this.props.event_name,
            },
          })
        }}
        onKeyUp={e => {
          if (e.key === 'Enter' && this.props.onClick) {
            this.props.onClick()
          }
        }}
      >
        <Card styleName="content-wrapper" size="xs" tabIndex={0}>
          {this.props.cartIcon}
          <Card.Content styleName="content-tile">
            <BodyText>{this.props.title}</BodyText>
            <CaptionText
              styleName={
                this.props.isSmallGroup
                  ? 'caption-text-small-group'
                  : 'caption-text'
              }
            >
              {this.props.subtitle}
            </CaptionText>
          </Card.Content>
        </Card>
      </Link>
    )
  }
}

export default cssModules(CreateCard, styles)
