import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_share.less'
import { getI18n } from 'intl'
import StandardError from 'components/common/standard_error'
import CrossFadeTransition from 'components/common/transitions/cross_fade'
import {
  CIP_ELIGIBILITY_CODES,
  COUNTRY_NOT_SUPPORTED,
  SPRIG_SURVEY,
  UNDER_REVIEW_ERROR,
} from 'shared/shared_consts'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import { sendEnsightenEvent } from 'lib/ensighten_analytics'
import { getDexterReturnUrl } from 'lib/create_campaign_eligibility'
import ShareCampaignTextarea from 'components/common/share_campaign_textarea'
import { Button } from '@paypalcorp/pp-react'
import CampaignEligibility from 'components/common/campaign_eligibility'
import { dexterURL } from 'shared/campaign_url'
import { trackImpression, trackEventNameClick } from 'lib/fpti_analytics'
import Pictograms from 'components/common/pictograms'
import HalfSheet from '../../../common/half_sheet/half_sheet'
import { Message } from '@paypalcorp/worldready-react'
import FundraiserShareInfo from './partials/fundraiser_share_info'

export const I18N_CONTEXT = 'components/pages/crowdfunding/pool_share'
const i18n = getI18n(I18N_CONTEXT)
class CrowdfundingShare extends React.Component {
  static propTypes = {
    onPublish: PropTypes.func.isRequired,
    operatingRemotely: PropTypes.bool,
    campaignId: PropTypes.string,
    poolUrl: PropTypes.string.isRequired,
    creationError: PropTypes.object,
    shareDescription: PropTypes.string,
    params: PropTypes.object,
    paypalDomain: PropTypes.string,
    route: PropTypes.object,
    cdnHost: PropTypes.string,
    campaignType: PropTypes.string,
    hasSocialShare5xEnabled: PropTypes.bool,
    hasSocialShare7xEnabled: PropTypes.bool,
  }

  state = {
    showMobileSharePage: false,
  }

  campaignCreated = ({ campaignId }) => {
    return campaignId !== NEW_CAMPAIGN_ID
  }

  componentDidMount() {
    const { operatingRemotely, creationError } = this.props

    // Checking if the campaign has already been created so that we don't create it twice.
    // This can happen when going back from the share page to here.
    if (
      !operatingRemotely &&
      !creationError &&
      !this.campaignCreated(this.props)
    ) {
      this.props.onPublish()
    }
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    if (this.campaignCreated(newProps) && !this.campaignCreated(this.props)) {
      sendEnsightenEvent('MoneyPoolFin')
    }
  }

  renderShareComponent = () => (
    <div styleName="textarea-wrapper">
      <ShareCampaignTextarea
        campaignId={this.props.campaignId}
        description={this.props.shareDescription}
        data-pa-click="share"
        eventName="dw_giving_fundraiser_publish_share_channel_pressed"
      />
    </div>
  )

  renderReviewError() {
    return (
      <StandardError
        key="review_error"
        iconType="error"
        title={i18n('review_error_title')}
        subtitle={i18n('review_error_subtitle')}
        backButton={true}
      />
    )
  }

  renderNeedMoreData() {
    const returnUrl = getDexterReturnUrl('/pools/c/create-crowdfunding/basics')
    location.href = dexterURL(returnUrl)
  }

  renderCampaignEligibility(creationError) {
    return (
      <CampaignEligibility
        CIPStatusCode={CIP_ELIGIBILITY_CODES[creationError.error]}
      />
    )
  }

  renderCountryNotSupported(paypalDomain) {
    return (
      <StandardError
        key="error"
        iconType="warning"
        title={i18n('error_country_eligible_title')}
        subtitle={i18n('error_country_eligible_sub_title')}
        extraContent={
          <Button
            as="a"
            href={`https://${paypalDomain}/myaccount/summary`}
            role="button"
            secondary
            styleName="go-to-pool-button"
          >
            <Message id="components.pages.crowdfunding.pool_share.error_country_eligible_button" />
          </Button>
        }
      />
    )
  }

  renderStandardError() {
    return (
      <StandardError
        key="error"
        iconType="warning"
        title={i18n('error_title')}
        subtitle={i18n('error_subtitle')}
        onTryAgain={this.props.onPublish}
      />
    )
  }

  renderCreateIndicatorContainer() {
    return (
      <div styleName="create-indicator-container" key="spinner">
        <div className="vx_spinner-large" styleName="create-indicator" />
        <div styleName="spinner-title">
          <Message id="components.pages.crowdfunding.pool_share.creating" />
        </div>
      </div>
    )
  }

  handleSurvey = () => {
    const survey = SPRIG_SURVEY[this.props.campaignType]
    window.latmconf.trackSprigEvent(survey)
  }

  renderPublishContainer(props, state) {
    const {
      campaignType,
      hasSocialShare5xEnabled,
      hasSocialShare7xEnabled,
      route,
    } = props
    const hideLogo = get(props, 'params.hideLogo', false)
    const hideButton = get(props, 'params.hideButton', false)
    const isPublishPath = route && route.path === 'publish'
    const isSprigEnabled =
      window.Sprig?.loaded && window.latmconf?.trackSprigEvent
    const { showMobileSharePage } = state

    const handleShareButtonClick = () => {
      this.setState({ showMobileSharePage: true })
      trackEventNameClick({
        event_name: 'dw_giving_fundraiser_publish_share_pressed',
      })
    }

    const handleGoToPoolButtonClick = () => {
      trackEventNameClick({
        event_name: 'dw_giving_fundraiser_publish_view_pressed',
      })
    }

    const renderMobileSharePage = () => (
      <HalfSheet
        show={true}
        title={i18n('share_text')}
        mobileFixedHeight="dynamic"
        onClose={() => this.setState({ showMobileSharePage: false })}
        containerClassName={styles['mshare-container']}
      >
        {this.renderShareComponent()}
      </HalfSheet>
    )

    const Feedback = () => (
      <div className={styles.feedback}>
        <Button
          tertiary
          className={styles['feedback-button']}
          onClick={this.handleSurvey}
        >
          <Message id="components.pages.crowdfunding.pool_share.feedback_link" />
        </Button>
      </div>
    )

    return (
      <div styleName="publish-container">
        {!hideLogo && (
          <div styleName="img-container-header">
            <Pictograms
              classname="creation-share-image"
              svg="heart-grow"
              data-testid="crowdfunding-share-image-url"
            />
          </div>
        )}

        {showMobileSharePage && renderMobileSharePage()}

        <FundraiserShareInfo
          campaignType={campaignType}
          hasSocialShare5xEnabled={hasSocialShare5xEnabled}
          hasSocialShare7xEnabled={hasSocialShare7xEnabled}
        />

        {isPublishPath && isSprigEnabled && <Feedback />}

        <div className="hidden-xs">{this.renderShareComponent()}</div>

        <Button
          className="visible-xs"
          styleName="mb-16"
          onClick={handleShareButtonClick}
        >
          <Message id="components.pages.crowdfunding.pool_share.share_text" />
        </Button>

        {!hideButton && (
          <Button
            as="a"
            secondary
            data-pa-click="goToPool"
            data-nemo="go-to-pool"
            data-testid="share-go-to-pool"
            href={props.poolUrl}
            styleName="go-to-pool-button"
            onClick={handleGoToPoolButtonClick}
          >
            <Message id="components.pages.crowdfunding.pool_share.see_my_story" />
          </Button>
        )}
      </div>
    )
  }

  render() {
    let Component
    const { creationError, paypalDomain, route } = this.props
    const isPublishPath = route && route.path === 'publish'

    if (creationError) {
      if (!CIP_ELIGIBILITY_CODES.hasOwnProperty(creationError.error)) {
        trackImpression({
          error_desc: creationError.error || 'CREATE_ERROR',
          error_code: 500,
          event_type: 'ac',
          event_name: 'dw_giving_failed_customer_interaction_occurred',
          screen: 'publish',
        })
      }

      if (creationError.error === UNDER_REVIEW_ERROR) {
        Component = this.renderReviewError()
      } else if (CIP_ELIGIBILITY_CODES.hasOwnProperty(creationError.error)) {
        if (CIP_ELIGIBILITY_CODES[creationError.error] === 'NEED_MORE_DATA') {
          this.renderNeedMoreData()
          return
        } else {
          Component = this.renderCampaignEligibility(creationError)
        }
      } else if (creationError.error === COUNTRY_NOT_SUPPORTED) {
        Component = this.renderCountryNotSupported(paypalDomain)
      } else {
        Component = this.renderStandardError()
      }
    } else if (this.campaignCreated(this.props)) {
      if (isPublishPath) {
        trackImpression({
          event_name: 'dw_giving_fundraiser_publish_screen_shown',
        })
      }

      Component = this.renderPublishContainer(this.props, this.state)
    } else {
      // assume operatingRemotely
      trackImpression({
        pageName: 'create:spinner',
      })
      Component = this.renderCreateIndicatorContainer()
    }

    return <CrossFadeTransition>{Component}</CrossFadeTransition>
  }
}

export default cssModules(CrowdfundingShare, styles, { allowMultiple: true })
