import { connect } from 'react-redux'

import ReportLink from './report_link'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const campaignType = campaign ? campaign.campaign_type : ''

  return {
    currentUserCountryCode: state.country.country_code,
    campaignType,
    paypalDomain: state.geo.paypalDomain,
    charity: campaign && campaign.charity,
  }
}

export default connect(mapStateToProps)(ReportLink)
