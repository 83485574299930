import {
  BodyText,
  Button,
  PAYPAL_THEME,
  Row,
  Col,
  HorizontalScrollDesktop,
  HeadingText,
} from '@paypalcorp/pp-react'
import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import { getI18n } from 'intl'
import PropTypes from 'prop-types'
import { GNC_DISCLAIMERS } from 'shared/shared_consts'
import styles from './ppgf_inset.less'

const i18n = getI18n('components/crowdfunding_charity_detail_view')

PpgfInset.propTypes = {
  invokeGivingFundTermsModal: PropTypes.func,
  isPreview: PropTypes.boolean,
  cdnHost: PropTypes.string,
  countryCode: PropTypes.string,
}

export default function PpgfInset(props) {
  const { sysColorBackgroundMain } = PAYPAL_THEME
  const { isPreview, cdnHost, countryCode } = props
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = index => {
    setCurrentPage(index)
  }
  const donationDeliveryPolicyUrl = `${
    GNC_DISCLAIMERS[countryCode].donationDeliveryPolicy
  }`

  const renderBig = steps => {
    const [step1, step2, step3] = steps.steps
    return (
      <React.Fragment>
        <HeadingText className={styles['ppgf-container-title']} size="sm">
          {i18n('charity_donating_to_paypal_title')}
        </HeadingText>
        <div className={styles['ppgf-container']}>
          <Row>
            <Col
              className={styles['link-override']}
              style={{ textAlign: 'center' }}
            >
              <img
                className={styles['ppgf-carousel-image']}
                src={step1.image}
                alt=""
                role="presentation"
                style={{ maxHeight: '136px' }}
              />
              <BodyText className={styles['ppgf-step-text']}>
                {step1.text}
              </BodyText>
            </Col>
            <Col
              className={styles['link-override']}
              style={{ textAlign: 'center' }}
            >
              <img
                className={styles['ppgf-carousel-image']}
                src={step2.image}
                alt=""
                role="presentation"
                style={{ maxHeight: '136px' }}
              />
              <BodyText
                className={styles['ppgf-step-text']}
                dangerouslySetInnerHTML={{ __html: step2.text }}
              />
            </Col>
            <Col
              className={styles['link-override']}
              style={{ textAlign: 'center' }}
            >
              <img
                className={styles['ppgf-carousel-image']}
                src={step3.image}
                alt=""
                role="presentation"
                style={{ maxHeight: '136px' }}
              />
              <BodyText className={styles['ppgf-step-text']}>
                {step3.text}
              </BodyText>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    )
  }

  const renderSmall = steps => {
    const renderCard = item => {
      const { image, text, index } = item
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              key={`donation-step-${index}`}
              style={{
                display: 'grid',
                width: '75%',
              }}
            >
              <img
                className={styles['ppgf-carousel-image']}
                src={image}
                alt=""
              />
              {index === 2 ? (
                <div className={styles['ppgf-carousel-image']}>
                  <BodyText
                    className={styles['ppgf-step-text']}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              ) : (
                <BodyText className={styles['ppgf-step-text']}>{text}</BodyText>
              )}
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <div className={styles['ppgf-carousel-container']}>
        <HeadingText className={styles['ppgf-container-title-small']} size="sm">
          {i18n('charity_donating_to_paypal_title')}
        </HeadingText>
        <HorizontalScrollDesktop
          itemClassName={styles['ppgf-horizontal-scroll']}
          items={steps.steps}
          renderItem={renderCard}
          numOfVisibleItems={1}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    )
  }

  const renderButton = () => {
    return (
      <Button
        as="a"
        data-nemo="moreAboutCharity"
        href={donationDeliveryPolicyUrl}
        onClick={event => (isPreview ? event.preventDefault() : null)}
      >
        {i18n('giving_fund_more_details_link_2')}
      </Button>
    )
  }

  const steps = {
    title: i18n('label.donationStep-title'),
    steps: [
      {
        index: 1,
        step: i18n('charity_donating_to_paypal_text1'),
        text: i18n('charity_donating_to_paypal_text1'),
        image: `https://${cdnHost}/paypal-ui/pictograms/blue/svg/giving-fund-blue.svg`,
      },
      {
        index: 2,
        step: i18n('charity_donating_to_paypal_text2', {
          donationDeliveryPolicyUrl,
        }),
        text: i18n('charity_donating_to_paypal_text2', {
          donationDeliveryPolicyUrl,
        }),
        image: `https://${cdnHost}/paypal-ui/pictograms/blue/svg/transfer-money-blue.svg`,
      },
      {
        index: 3,
        step: i18n('charity_donating_to_paypal_text3'),
        text: i18n('charity_donating_to_paypal_text3'),
        image: `https://${cdnHost}/paypal-ui/pictograms/blue/svg/invoices-blue.svg`,
      },
    ],
  }

  return (
    <div style={{ backgroundColor: sysColorBackgroundMain }}>
      <MediaQuery minWidth={768}>{renderBig(steps)}</MediaQuery>
      <MediaQuery maxWidth={767}>{renderSmall(steps)}</MediaQuery>
      <Row>
        <Col>
          <div className={styles['ppgf-button-container']}>
            {renderButton()}
          </div>
        </Col>
      </Row>
    </div>
  )
}
