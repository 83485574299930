import React from 'react'
import PropTypes from 'prop-types'
import { getI18n } from 'intl'
import styles from './crowdfunding_banner.less'
import cssModules from 'react-css-modules'
import { baseUrl } from 'shared/campaign_url'
import { CROWD_FUNDING_HOME_URI } from 'shared/shared_consts'
import {
  PAYPAL_THEME,
  Button,
  Col,
  Container,
  HeadingText,
  Row,
} from '@paypalcorp/pp-react'
import Icon from '../../../common/icon/icon'
import clsx from 'clsx'

const i18n = getI18n('components/crowdfunding_banner')

class CrowdfundingBanner extends React.Component {
  static propTypes = {
    bannerButtonText: PropTypes.string,
    bannerIcon: PropTypes.string,
    bannerLink: PropTypes.string,
    bannerTitleText: PropTypes.string,
    cta: PropTypes.string,
    isLoggedIn: PropTypes.any,
    analytics: PropTypes.object,
  }

  handleHelpNow = () => {
    const { bannerLink } = this.props
    const uri = bannerLink || CROWD_FUNDING_HOME_URI
    open(baseUrl(location.hostname, uri))
  }

  componentDidMount() {
    if (this.props.analytics?.impressionUrl) {
      fetch(this.props.analytics.impressionUrl)
    }
  }

  render() {
    const { bannerIcon, bannerButtonText, bannerTitleText } = this.props
    const { sysColorPrimaryContrastHover } = PAYPAL_THEME

    return (
      <Container
        fluid={true}
        styleName={clsx({ entryBannerContainer: this.props.isLoggedIn })}
        style={{ background: sysColorPrimaryContrastHover }}
      >
        <Row styleName="crowdfunding-banner">
          <Col md="auto" styleName={'banner-text'}>
            <Icon
              name={bannerIcon || 'donate'}
              styleName="crowdfunding-banner-icon"
            />
            <HeadingText size="md">
              {bannerTitleText || i18n('crowdfunding_banner_info')}
            </HeadingText>
          </Col>
          <Col md="auto">
            <Button
              onClick={this.handleHelpNow}
              secondary
              size="sm"
              styleName="crowdfunding-banner-button"
              to={this.props.cta}
            >
              {bannerButtonText || i18n('crowdfunding_banner_cta')}
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default cssModules(CrowdfundingBanner, styles)
