import edit from './edit'
import send from './send'
import guest from './guest'
import CreatePage from 'components/pages/create'
import { sendEnsightenEvent } from 'lib/ensighten_analytics'
import { createCampaignEligibilityCheck } from 'lib/create_campaign_eligibility'
import CrowdfundingBasicsSmallGroup from 'components/pages/crowdfunding/crowdfunding_basics_small_group'
import CrowdfundingNonprofit from 'components/pages/crowdfunding/crowdfunding_nonprofit'
import CrowdfundingStory from 'components/pages/crowdfunding/crowdfunding_story'
import CrowdfundingCover from 'components/pages/crowdfunding/crowdfunding_cover'
import CrowdfundingShare from 'components/pages/crowdfunding/crowdfunding_share'
import PoolPublish from 'components/pages/crowdfunding/crowdfunding_publish'
import { POOLS_CAMPAIGN_TYPES, POOL_TYPE } from 'shared/shared_consts'
import CountryBlockedError from 'components/common/country_blocked'

import { updateQueryParams } from 'redux/modules/url'

import {
  NEW_CAMPAIGN_ID,
  create,
  resetRemoteOperation,
} from 'redux/modules/campaign'

import {
  getStepName,
  assertInitialStep,
  assignActionToRouterParams,
  glimpseRoute,
} from 'lib/route_utils'

const INITIAL_STEP = 'basics'
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export default (store, history) => ({
  path: 'create-crowdfunding',
  campaignType: CROWD_FUNDING,
  getComponent(nextState, callback) {
    const state = store.getState()
    if (!state.current_user.isFundRaiserEnabled) {
      return callback(null, CountryBlockedError)
    } else
      return createCampaignEligibilityCheck(
        store.getState(),
        nextState,
        CROWD_FUNDING,
        CreatePage,
        callback
      )
  },
  onEnter(nextState, replace) {
    nextState.params.nextStep = getStepName(
      'create-crowdfunding',
      nextState.routes
    )

    let selectedPoolType
    store.dispatch((d, getState) => {
      // This updates the selected type if previously selected
      selectedPoolType =
        getState().campaign?.__new_campaign__?.type ||
        (nextState.location?.query?.npid && POOL_TYPE.CHARITY) //When user is creating from hub page

      //When user refresh the charity select page
      if (!selectedPoolType && nextState.params.nextStep === 'nonprofit') {
        selectedPoolType = POOL_TYPE.CHARITY
      } else if (nextState.location?.query?.type === POOL_TYPE.PRIVATE) {
        selectedPoolType = POOL_TYPE.PRIVATE
      } else if (nextState.location?.query?.type === POOL_TYPE.PERSONAL) {
        selectedPoolType = POOL_TYPE.PERSONAL
      }

      d(create(CROWD_FUNDING, selectedPoolType)) // creates the new campaign object.
      d(updateQueryParams(nextState.location)) // updates the state with query params
    })

    if (nextState.params.nextStep !== 'share/:campaignId') {
      nextState.params.campaignId = NEW_CAMPAIGN_ID
      if (
        !(
          store.getState().flags &&
          store.getState().flags.is_gnc_enabled &&
          nextState.params.nextStep === 'nonprofit'
        )
      ) {
        assertInitialStep(
          'create-crowdfunding',
          INITIAL_STEP,
          nextState,
          replace
        )
      }
    }

    /**
     * While refreshing the any step of create flow, poolType is undefined
     * Then we need to redirect to the select pool type page
     */

    if (!selectedPoolType) {
      replace({ pathname: glimpseRoute('pools', `c/create/types`) })
    }
  },
  onChange(prevState, nextState) {
    assignActionToRouterParams(nextState)
    nextState.params.campaignId = NEW_CAMPAIGN_ID
    nextState.params.prevStep = getStepName(
      'create-crowdfunding',
      prevState.routes
    )
    nextState.params.nextStep = getStepName(
      'create-crowdfunding',
      nextState.routes
    )
  },
  childRoutes: [
    {
      path: INITIAL_STEP,
      component: CrowdfundingBasicsSmallGroup,
      onEnter(nextState) {
        if (
          nextState.location.action !== 'POP' ||
          !nextState.params.prevSteps
        ) {
          sendEnsightenEvent('MoneyPoolStart')
        }
      },
    },
    { path: 'story', component: CrowdfundingStory },
    { path: 'nonprofit', component: CrowdfundingNonprofit },
    { path: 'select-cover', component: CrowdfundingCover },
    { path: 'cover', component: CrowdfundingCover },
    {
      path: 'publish',
      component: PoolPublish,
      onEnter(nextState) {
        if (nextState.location.action !== 'POP') {
          store.dispatch(resetRemoteOperation(NEW_CAMPAIGN_ID))
        }
      },
    },
    { path: 'share/:campaignId', component: CrowdfundingShare },
    edit(store, history),
    send(store, history),
    guest(store, history),
  ],
})
