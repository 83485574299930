function veniceBridge() {
  return window.venice
}

function callBridgeFunction(funcName, ...args) {
  const bridge = veniceBridge()
  if (bridge) {
    if (typeof bridge[funcName] === 'function') {
      bridge[funcName].apply(bridge, args)
    }
  }
}

export function campaignCreated() {
  callBridgeFunction('created')
}

export function reAuth() {
  callBridgeFunction('reAuth')
}
