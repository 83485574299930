import { getNonDiscoverable } from './campaign'

export function serializeCampaignToServer(
  campaignData,
  { alwaysIncludePan = false } = {}
) {
  const { user_legal_agreement_version } = campaignData
  const serializedData = {
    campaign_type: campaignData.campaign_type,
    amount_type: campaignData.amount_type,
    amount: campaignData.amount,
    currency: campaignData.currency,
    background_image: campaignData.background_image,
    background_image_filename: campaignData.background_image
      ? campaignData.background_image_filename
      : undefined,
    background_pan:
      campaignData.background_image || alwaysIncludePan
        ? campaignData.background_pan
        : undefined,
    description: campaignData.description,
    event_name: campaignData.event_name,
    postal_code: campaignData.postal_code,
    end_date: campaignData.end_date
      ? new Date(campaignData.end_date).toISOString()
      : undefined,
    goal: campaignData.goal,
    gradient_color: campaignData.gradient_color,
    title: campaignData.title,
    share_progress: campaignData.share_progress,
    share_contributors: campaignData.share_contributors,
    //If we don't share progress we don't share contributions as well. This change has to be done after the redux form.
    share_contributions:
      campaignData.share_progress && campaignData.share_contributions,
    pledge: campaignData.pledge,
    npid: campaignData.npid,
    non_discoverable: getNonDiscoverable(campaignData.discoverable),
  }
  if (campaignData.npid) {
    serializedData.categories = campaignData.category
  } else {
    serializedData.category = campaignData.category
  }
  if (user_legal_agreement_version) {
    serializedData.user_legal_agreement_version = user_legal_agreement_version
  }
  return serializedData
}
