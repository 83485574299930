import React from 'react'
import PropTypes from 'prop-types'
import { UnboundAvatar, avatarColors } from 'components/common/avatar'
import MockText from 'components/common/mock_text'
import cssModules from 'react-css-modules'
import styles from './public_contribution_row.less'
import { getMergedI18n, formatDate, formatCurrency } from 'intl'
import { getHash } from 'shared/helpers/string_helpers'
import { getInitials } from 'shared/helpers/name_helpers'
import { daysUntilToday } from 'shared/helpers/datetime_helpers'
import {
  DAYS_THRESHOLD,
  MIN_CONTRIBUTOR_ROW_HEIGHT,
} from 'shared/shared_consts'
import { BodyText, CaptionText } from '@paypalcorp/pp-react'
import clsx from 'clsx'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getMergedI18n(['components/txn_row', 'components/txns_list'])

export class PublicContributionRow extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    date: PropTypes.string,
    note: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    contributor_id: PropTypes.string,
    contributor_name: PropTypes.string,
    contributor_photo: PropTypes.string,
    preview: PropTypes.bool,
    isPledge: PropTypes.bool,
    removeExistingCommentFlag: PropTypes.bool,
    worldReady: PropTypes.Object,
  }

  static defaultProps = {
    isPledge: false,
  }

  getAvatarBackgroundColor = () => {
    if (!this.props.contributor_id) return 'anonymous-grey'
    const uniqueNumericId = getHash(this.props.contributor_id.toString())
    return avatarColors[uniqueNumericId % avatarColors.length]
  }

  formattedDate = () => {
    let date = this.props.date
    // this checks if a string is a valid date, and if so, translates it to a 'days ago' syntax
    if (!Number.isNaN(Date.parse(date))) {
      const days = daysUntilToday(new Date(date))
      if (days > DAYS_THRESHOLD) {
        date = i18n('on_date', {
          date: formatDate(this.props.worldReady, date, {
            style: 'date-short',
          }),
        })
      } else {
        const key =
          days === 0 || days === 1 ? `days_ago_${days}` : 'days_ago_other'
        date = i18n(key, { days })
      }
    }
    return date
  }

  render() {
    const amountStyleName = this.props.preview ? 'amount-preview' : 'amount'
    const bodyTextStyleNames = clsx({
      'contributor-name': !this.props.preview,
      'contributor-name-preview': this.props.preview,
      'pledge-contribution-row': this.props.isPledge,
    })

    const contributor = this.props.isPledge
      ? i18n('organizer_name', { name: this.props.contributor_name })
      : this.props.contributor_name
    let contributorName = this.props.contributor_name
      ? contributor
      : i18n('anonymous')

    return (
      <div styleName="row">
        <div styleName="avatar-wrapper">
          <UnboundAvatar
            initials={
              this.props.contributor_name &&
              getInitials(this.props.contributor_name)
            }
            imageUrl={this.props.contributor_photo}
            backgroundColor={
              this.props.preview ? 'grey' : this.getAvatarBackgroundColor()
            }
            blank={this.props.preview}
          />
        </div>
        {this.props.preview ? (
          <div styleName="details-wrapper-preview">
            <p>
              <MockText width={170} />
            </p>
            <p>
              <MockText width={60} />
            </p>
          </div>
        ) : (
          <div styleName="details-wrapper">
            <BodyText
              strong
              styleName={bodyTextStyleNames}
              style={{ minHeight: MIN_CONTRIBUTOR_ROW_HEIGHT }}
              data-testid="pc-row-name"
            >
              {contributorName}
            </BodyText>
            <CaptionText styleName="days-ago">
              {this.formattedDate()}
            </CaptionText>
          </div>
        )}
        {this.props.amount != null && (
          <BodyText styleName={amountStyleName} data-testid="pc-row-amount">
            {formatCurrency({
              value: this.props.amount,
              currencyCode: this.props.currency,
              additionalConfig: {},
              worldReady: this.props.worldReady,
            })}
          </BodyText>
        )}
        {this.props.note &&
          !this.props.removeExistingCommentFlag && (
            <div styleName="details-note" data-testid="pc-row-note">
              <BodyText styleName="note">{this.props.note}</BodyText>
            </div>
          )}
      </div>
    )
  }
}

export default injectWorldReady(
  cssModules(PublicContributionRow, styles, {
    allowMultiple: true,
  })
)
