import { disableTabsForNodes, reEnableTabs } from './tab_disabler'
import Tweenable from 'shifty'

const LIMIT_SCROLLABILITY_CLASS = 'limit-scrollability'
const SCROLL_ANIMATION_DURATION = 400

// rerturns all of a node's siblings
function getSiblings(node) {
  const siblings = []
  let sibling = node
  while ((sibling = sibling.nextSibling) !== null) {
    siblings.push(sibling)
  }
  return siblings
}

function isWebkit() {
  // We could theoretically query device_info for the engine, but this info is available
  // only in production.
  return 'WebkitAppearance' in document.documentElement.style
}

// -------------------------------------------------------------------------

// These two functions work around the bug(?) in webkit where
// setting overflow-y: hidden on an element zeroes out its scroll position.
// The solution here is on modal open, save the scroll position in a variable,
// and translateY the content that is not part of the modal, so that it won't 'jump'.
// When the modal closes, remove the translateY and restore the body's scroll position.

let bodyScrollY = 0

export function fixWebKitScrollPosition(nodes) {
  if (!isWebkit()) {
    return
  }
  bodyScrollY = window.scrollY
  Array.prototype.forEach.call(nodes, node => {
    if (node.getAttribute('data-fixed') !== 'true') {
      node.style.transform = `translateY(-${bodyScrollY}px)`

      // Traverse all decendants which have position: fixed and
      // counteract the translation
      const fixedNodes = node.querySelectorAll("*[data-fixed='true']")
      for (let i = 0; i < fixedNodes.length; i++) {
        let fixedNode = fixedNodes[i]
        let xform = fixedNode.style.transform
        fixedNode.style.transform = `translateY(${bodyScrollY}px)`
        fixedNode.originalTransform = xform
      }
    }
  })
}

export function restoreWebKitScrollPosition(nodes) {
  if (!isWebkit()) {
    return
  }
  window.scrollTo(0, bodyScrollY)
  bodyScrollY = 0
  Array.prototype.forEach.call(nodes, node => {
    if (node.getAttribute('data-fixed') !== 'true') {
      node.style.transform = null

      // Traverse all decendants that used to have have position: fixed and
      // revert the translation counteraction
      const fixedNodes = node.querySelectorAll("*[data-fixed='true']")
      for (let i = 0; i < fixedNodes.length; i++) {
        let fixedNode = fixedNodes[i]
        fixedNode.style.transform = fixedNode.originalTransform
        delete fixedNode.originalTransform
      }
    }
  })
}

// -------------------------------------------------------------------------

// Gateway functions to the module. Call the first when showing the modal,
// and call the second when hiding it.

export function disableBodyScrollability(modalNode) {
  const siblingNodes = getSiblings(modalNode)
  fixWebKitScrollPosition(siblingNodes)
  document.body.classList.add(LIMIT_SCROLLABILITY_CLASS)
  disableTabsForNodes(siblingNodes)
}

export function enableBodyScrollability(modalNode) {
  const siblingNodes = getSiblings(modalNode)
  document.body.classList.remove(LIMIT_SCROLLABILITY_CLASS)
  restoreWebKitScrollPosition(siblingNodes)
  reEnableTabs()
}

export function scrollToTop(element) {
  const tweenable = new Tweenable()

  tweenable.tween({
    from: { x: element.scrollLeft, y: element.scrollTop },
    to: { x: 0, y: 0 },
    duration: SCROLL_ANIMATION_DURATION,
    easing: 'easeInOutQuad',
    step(currentState) {
      element.scrollLeft = currentState.x
      element.scrollTop = currentState.y
    },
    finish(currentState) {
      element.scrollLeft = currentState.x
      element.scrollTop = currentState.y
    },
  })
}
