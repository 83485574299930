import React from 'react'
import PropTypes from 'prop-types'
import { legacyFormatDate as formatDate } from 'intl'
import RevealTransition from 'components/common/transitions/reveal'
import { connect } from 'react-redux'
import cssModules from 'react-css-modules'
import styles from './contributions_list.less'
import { showMoreTxns } from 'redux/modules/txns'
import { CaptionText, Link } from '@paypalcorp/pp-react'
import { CONTRIBUTION_TRANSACTIONS_PER_PAGE } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'

const IDLE_STATE = 'idle'
const LOADING_STATE = 'loading'

class ContributionList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    hideEmpty: PropTypes.bool,
    onShowMore: PropTypes.func.isRequired,
    contributions: PropTypes.array,
    showDaysRows: PropTypes.bool,
    preview: PropTypes.bool,
    txnsPerPage: PropTypes.number.isRequired,
    topElement: PropTypes.element,
    bottomElement: PropTypes.element,
    rowElement: PropTypes.func.isRequired,
    transactionType: PropTypes.string,
    totalTxnsCount: PropTypes.number.isRequired,
  }

  static defaultProps = {
    topElement: '',
  }

  state = {
    showMoreState: IDLE_STATE,
    pagesShown: 1,
  }

  totalShown = () => {
    return this.props.txnsPerPage * this.state.pagesShown
  }

  shownContributions = () => {
    return this.props.contributions
  }

  handleShowMore = page => {
    this.setState({ showMoreState: LOADING_STATE })
    this.props
      .onShowMore(
        this.state.pagesShown + 1,
        this.props.txnsPerPage,
        this.props.transactionType
      )
      .then(resp => {
        this.setState({
          showMoreState: IDLE_STATE,
          pagesShown: this.state.pagesShown + 1,
        })
      })
      .catch(() => {
        this.setState({
          showMoreState: IDLE_STATE,
          pagesShown: this.state.pagesShown + 1,
        })
      })
  }

  shouldSkipRenderingList = () => {
    return (
      !this.props.contributions ||
      (this.props.contributions.length === 0 && this.props.hideEmpty)
    )
  }

  addDatesBetweenContributions = contributions => {
    return contributions.reduce((elements, contribution, index) => {
      if (
        this.shouldShowDateRowBetweenContributions(
          contribution,
          contributions[index - 1]
        )
      ) {
        elements.push({ isDate: true, date: contribution.date })
      }
      elements.push(contribution)
      return elements
    }, [])
  }

  shouldShowDateRowBetweenContributions = (contribution, prevContribution) => {
    if (!prevContribution) {
      return true
    }
    const thisDate = new Date(contribution.date)
    const prevDate = new Date(prevContribution.date)
    return thisDate.toDateString() !== prevDate.toDateString()
  }

  render() {
    if (this.shouldSkipRenderingList()) {
      return null
    }

    let shownElements = this.shownContributions()

    if (this.props.showDaysRows) {
      shownElements = this.addDatesBetweenContributions(shownElements)
    }

    return (
      <div
        className={
          this.state.showMoreState === 'loading'
            ? 'vx_has-spinner-medium vx_has-spinner-bottom'
            : ''
        }
      >
        {this.props.topElement}

        {this.props.title && (
          <div styleName="title">
            {this.props.title} {`(${this.props.contributions.length})`}
          </div>
        )}
        <RevealTransition>
          {shownElements.length > 0 &&
            shownElements.map((element, index) => {
              if (element.isDate) {
                return (
                  <div key={element.date} styleName="date-row">
                    <CaptionText>
                      {formatDate(element.date, 'MMMd')}
                    </CaptionText>
                  </div>
                )
              } else {
                return this.props.rowElement(element, element.id)
              }
            })}
          {this.props.bottomElement}
          {this.props.contributions.length < this.props.totalTxnsCount && (
            <>
              <div styleName="show-more">
                <Link
                  data-pa-click="showMoreActivity"
                  href="javascript:;"
                  onClick={this.handleShowMore}
                >
                  <Message id="components.txns_list.show_more_action" />
                </Link>
              </div>
            </>
          )}
        </RevealTransition>
      </div>
    )
  }
}

export default connect(
  (state, ownProps) => {
    return {
      txnsPerPage: ownProps.txnsPerPage || CONTRIBUTION_TRANSACTIONS_PER_PAGE,
    }
  },
  (dispatch, { campaignId }) => {
    return {
      onShowMore(page, pageSize, transactionType) {
        return dispatch(
          showMoreTxns(campaignId, page, pageSize, transactionType)
        )
      },
    }
  }
)(cssModules(ContributionList, styles))
