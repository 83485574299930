import { connect } from 'react-redux'

import CrowdfundingDetailView from 'components/crowdfunding_detail_view'
import { getNewCampaignSelector } from 'redux/selectors/campaign'
import { getCategoryDefaultCover, getMainCategory } from 'client/lib/category'
import { getStagingHostName } from '@paypalcorp/instance-location'

const mapStateToProps = (state, ownProps) => {
  const campaign = ownProps.isEdit
    ? ownProps.editedCampaign
    : getNewCampaignSelector(state, ownProps)
  const mainCategory = getMainCategory(campaign.category)
  const defaultCategoryCover = getCategoryDefaultCover(state, mainCategory)
  const countryCode = campaign.countryCode || state.country.country_code // create flow doesnt have countryCode set
  const hostName = [state.deployEnv.isDev, state.deployEnv.isStage].includes(
    true
  )
    ? getStagingHostName()
    : state.geo.paypalDomain
  return {
    campaign,
    countryCode,
    defaultCategoryCover,
    edit: ownProps.isEdit,
    geo: state.geo,
    hostName,
  }
}

export default connect(mapStateToProps)(CrowdfundingDetailView)
