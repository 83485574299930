import React from 'react'
import { dexterURL, baseUrl } from 'shared/campaign_url'
import CountryBlockedError from 'components/common/country_blocked'
import CampaignEligibility from 'components/common/campaign_eligibility'
import { ELIGIBILITY_STATUS, POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { isStage, isDev } from './deploy_env'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export function createCampaignEligibilityCheck(
  state,
  nextState,
  campaignType,
  CreatePage,
  callback
) {
  const cipStatus =
    state.current_user.CIPEligibility &&
    state.current_user.CIPEligibility[campaignType]
  if (state.current_user.moneypools_blocked) {
    return callback(null, CountryBlockedError)
  } else if (
    campaignType === CROWD_FUNDING &&
    !state.current_user.crowdfunding_enabled
  ) {
    //when elmo is not enabled for GN pools based on country
    window.location.href = baseUrl(location.hostname, '')
    return
  } else if (
    cipStatus &&
    getCIPStatus(campaignType, cipStatus, nextState.params, state) ===
      ELIGIBILITY_STATUS.INELIGIBLE
  ) {
    if (cipStatus.CIPStatusCode === 'NEED_MORE_DATA') {
      const returnUrl = getDexterReturnUrl(nextState.location.pathname)
      location.href = dexterURL(returnUrl, cipStatus.redirectUrl)
      return
    }
    return callback(null, props => {
      return <CampaignEligibility {...props} dialog={false} />
    })
  } else if (
    cipStatus &&
    getCIPStatus(campaignType, cipStatus, nextState.params, state) ===
      ELIGIBILITY_STATUS.ELIGIBLE &&
    state.queryParams &&
    state.queryParams.next_compliance_Url
  ) {
    //When user visited the first url which returns success from dexter but user has a second url to verify
    const returnUrl = getDexterReturnUrl(nextState.location.pathname)
    location.href = dexterURL(returnUrl, [
      state.queryParams.next_compliance_Url,
    ])
    return
  }
  return callback(null, CreatePage)
}

export function getCIPStatus(campaignType, cipStatusObj, params, state) {
  const campaign = getCampaignSelector(state, params)
  if (
    (params && params.nextStep === 'nonprofit') ||
    (campaign && campaign.charity)
  ) {
    return cipStatusObj.CIPStatus.GENEROSITY_NETWORK_FUNDRAISER_CHARITY
  } else {
    return cipStatusObj.CIPStatus.GENEROSITY_NETWORK_FUNDRAISER
  }
}

export function getDexterReturnUrl(relativeUrl) {
  if (isDev() || isStage()) {
    return baseUrl(location.hostname, relativeUrl)
  }
  return relativeUrl
}
