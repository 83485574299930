import CrowdfundingBasics from './crowdfunding_basics'
import { reduxForm } from 'redux-form'
import formUtils from 'lib/form_utils'
import _get from 'lodash/get'
import {
  combine,
  required,
  futureCreationEndDate,
  atMost,
  atLeast,
  length,
} from 'lib/validators'
import {
  getTranslatedCategoryList,
  isValidCategory,
  filterDepreciatedCategory,
  sortCategory,
} from 'lib/category'
import { endDateValidators, maxDaysValidator } from 'lib/end_date_validators'
import { TITLE_LENGTH } from 'shared/lengths'
import { push } from 'react-router-redux'
import { NEW_CAMPAIGN_ID, setData } from 'redux/modules/campaign'
import { getCampaignSelector, isGNSelector } from 'redux/selectors/campaign'
import { getI18n, formatCurrency, getTimeZone } from 'intl'
import {
  POOLS_CAMPAIGN_TYPES,
  CROWDFUNDING_CAMPAIGN_MAX_DAYS,
  DISCOVERABLE,
  NON_DISCOVERABLE,
  POOL_TYPE,
  PPGF_UNENROLLED,
} from 'shared/shared_consts'
import { trackClick } from 'lib/fpti_analytics'
import { endOfDay } from 'shared/helpers/datetime_helpers'
import { getMinMaxAmount } from 'shared/helpers/campaign'
import { getDisclaimerVariables } from '../../../../../shared/helpers/helperDisclaimer'
import { injectWorldReady } from '@paypalcorp/worldready-react'
import {
  hasDisclaimer,
  hasSmallLegalTexGnc,
} from '../../../../lib/disclaimer_utils'

const i18n = getI18n('components/pages/crowdfunding/pool_basics')
const i18nCharity = getI18n(
  'components/pages/crowdfunding/crowdfunding_nonprofit'
)

const i18nHalfFullSheet = getI18n(
  'components/pages/halfFullSheet/half_full_sheet'
)

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

const addDays = (date, days) => {
  const copy = new Date(Number(date.valueOf()))
  copy.setDate(date.getDate() + days)
  return endOfDay(copy)
}

const initialDate = new Date()
const defaultDate = addDays(initialDate, CROWDFUNDING_CAMPAIGN_MAX_DAYS)
const getCharityDisclaimer = (countryCode, charityType, npoCharity) => {
  const disclaimer1 = i18nHalfFullSheet(`disclaimer_gnc_${countryCode}`)
  const disclaimer2 = hasSmallLegalTexGnc(countryCode)
    ? i18nCharity(
        `small_legal_text_gnc_${countryCode}`,
        getDisclaimerVariables({ countryCode, isGnc: true })
      )
    : ''
  const combinedDisclaimer = npoCharity
    ? `${disclaimer1} ${disclaimer2}`
    : disclaimer2

  return charityType === PPGF_UNENROLLED
    ? i18nCharity(
        `legal_text_unenrolled_gnc_${countryCode}`,
        getDisclaimerVariables({ countryCode, isGnc: true })
      )
    : combinedDisclaimer
}

const getCategoryList = (state, countryCode) => {
  let categoryList = getTranslatedCategoryList(state)
  const isNewCategoryEnabled = state.flags.enable_new_category
  categoryList = filterDepreciatedCategory(categoryList, isNewCategoryEnabled)

  if (countryCode === 'DE') {
    categoryList = categoryList.filter(category => category.key !== '1017')
  }

  return sortCategory(categoryList)
}

const mapStateToProps = (state, props) => {
  const countryCode = state.country.country_code
  const campaign = getCampaignSelector(state, props)
  const { type, currency } = campaign
  const categoryList = getCategoryList(state, countryCode)
  const categoryQueryParam = _get(state, 'queryParams.category')
  // category initial/default value
  let category =
    campaign.category ||
    (isValidCategory(state, categoryQueryParam)
      ? categoryQueryParam
      : categoryList[0] && categoryList[0].value)

  if (campaign.npid) {
    const categories = _get(campaign, 'charity.cause_area', [])
    category = categories.map(element => element.code)
  }

  if (type === POOL_TYPE.PRIVATE) {
    category = null
  }

  let MIN_AMOUNT
  let MAX_AMOUNT
  let formattedMaxGoalAmount
  let formattedMinGoalAmount = formatCurrency({
    value: state.flags.min_amount_donation,
    currencyCode: campaign.currency,
    additionalConfig: {},
    worldReady: props.worldReady,
  })

  const validators = [
    required('endDate', i18n('end_date_required')),
    futureCreationEndDate('endDate', i18n('date_must_be_future_2')),
    length(
      'poolName',
      TITLE_LENGTH,
      i18n('pool_name_too_long', { maxCharLength: TITLE_LENGTH })
    ),
    required(
      'poolName',
      i18n('pool_name_is_required', { maxCharLength: TITLE_LENGTH })
    ),
    ...endDateValidators('endDate', i18n('invalid_date')),
  ]

  if (isGNSelector(state, props)) {
    const minMaxAmount = getMinMaxAmount(campaign)
    MIN_AMOUNT = minMaxAmount.MIN_AMOUNT
    MAX_AMOUNT = minMaxAmount.MAX_AMOUNT

    formattedMaxGoalAmount = formatCurrency({
      value: MAX_AMOUNT,
      currencyCode: campaign.currency,
      additionalConfig: {},
      worldReady: props.worldReady,
    })
    formattedMinGoalAmount = formatCurrency({
      value: MIN_AMOUNT,
      currencyCode: campaign.currency,
      additionalConfig: {},
      worldReady: props.worldReady,
    })

    validators.push(
      atMost(
        'goalAmount',
        MAX_AMOUNT,
        i18n('goal_amount_invalid', {
          amount: formattedMaxGoalAmount,
        })
      )
    )
  }

  // For PRIVATE fundraisers goal is expected to be optional
  // and with no minimum
  if (type !== POOL_TYPE.PRIVATE) {
    validators.push(
      required(
        'goalAmount',
        i18n('goal_amount_required', {
          amount: formattedMinGoalAmount,
        })
      ),
      atLeast(
        'goalAmount',
        MIN_AMOUNT || state.flags.min_amount_donation,
        i18n('goal_amount_invalid_min', {
          amount: formattedMinGoalAmount,
        })
      )
    )
  }

  return {
    validate: combine(
      ...validators,
      maxDaysValidator(
        'endDate',
        new Date(),
        CROWDFUNDING_CAMPAIGN_MAX_DAYS,
        i18n('invalid_date_limit', {
          maxdays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
        })
      )
    ),
    initialValues: {
      category,
      poolName: campaign.title,
      goalAmount: campaign.goal && campaign.goal.toString(),
      pledgeAmount:
        campaign.pledge > 0 ? campaign.pledge.toString() : undefined,
      endDate: campaign.end_date ? new Date(campaign.end_date) : defaultDate,
      charity: campaign.charity,
      discoverable:
        type === POOL_TYPE.PRIVATE ? NON_DISCOVERABLE : DISCOVERABLE,
    },
    formattedMaxGoalAmount,
    currency,
    charity: campaign.charity,
    // if user is coming from NPO page. he will be having charity as query parameter
    npoCharity: state.charity?.npoCharity,
    // use timezone of user session for ppui dateInput & calendar to display
    // correct date after selection
    timeZone: getTimeZone(),
    categoryList,
    category,
    npid: campaign.npid,
    disclaimer:
      hasDisclaimer(countryCode) && i18n(`disclaimer_new_flow_${countryCode}`),
    disclaimerCharity: getCharityDisclaimer(
      countryCode,
      campaign.charity?.charity_type,
      state.charity?.npoCharity
    ),
    type,
    isTitleInfoAndMinDescriptionEnabled:
      state.flags.isTitleInfoAndMinDescriptionEnabled &&
      type === POOL_TYPE.PERSONAL,
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit(formValues) {
    trackClick({
      clickName: 'next',
    })
    dispatch(
      setData(NEW_CAMPAIGN_ID, {
        title: formValues.poolName,
        goal: formValues.goalAmount,
        pledge: formValues.pledgeAmount,
        category: formValues.category,
        end_date: formValues.endDate,
        campaign_type: CROWD_FUNDING,
        discoverable: formValues.discoverable,
      })
    )
    dispatch(
      push({
        pathname: `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/story`,
      })
    )
  },
})

export default injectWorldReady(
  reduxForm(
    {
      form: 'crowdfunding_basics',
      destroyOnUnmount: false,
      touchOnBlur: true,
      touchOnChange: false,
      fields: [
        'poolName',
        'goalAmount',
        'pledgeAmount',
        'endDate',
        'charity',
        'category',
        'discoverable',
      ],
    },
    mapStateToProps,
    mapDispatchToProps
  )(formUtils(CrowdfundingBasics))
)
