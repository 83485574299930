import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './profile_cover.less'
import { Avatar, CaptionText } from '@paypalcorp/pp-react'

const ProfileCover = props => {
  const { charity } = props

  const renderAvatar = link => {
    return (
      <Avatar
        alt=""
        role="presentation"
        src={link}
        initials={props.initials}
        size="lg"
        styleName="profile-avatar"
        data-testid="profile_cover__avatar"
      />
    )
  }

  const renderCharityLogo = () => {
    return charity.logo_url ? (
      renderAvatar(charity.logo_url)
    ) : (
      <div
        data-testid="profile_cover__charity-cause-logo"
        styleName="profile-avatar"
      >
        <Avatar generic="causes" size="lg" />
      </div>
    )
  }

  return (
    <div styleName="profile-cover">
      <div>
        {charity ? (
          renderCharityLogo()
        ) : (
          <>
            <div>{renderAvatar(props.profileImage)}</div>
            <div>
              <CaptionText
                data-testid="profile_cover__creator-name"
                styleName="profile-cover__creator-name"
                size="sm"
              >
                {props.creatorName}
              </CaptionText>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

ProfileCover.propTypes = {
  charity: PropTypes.object.isRequired,
  creatorName: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  profileImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default cssModules(ProfileCover, styles, {
  allowMultiple: true,
})
