import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './no_currency_alert.less'
import { getI18n } from 'intl'
import Alert from 'components/common/alert'

const i18n = getI18n('components/no_currency_alert')

class NoCurrencyAlert extends React.Component {
  static propTypes = {
    currency: PropTypes.string,
    paypalDomain: PropTypes.string,
  }

  render() {
    const currency = this.props.currency
    const currencyUrl = `https://${
      this.props.paypalDomain
    }/il/cgi-bin/webscr?cmd=_profile-currencies`

    return (
      <Alert type="critical">
        {i18n('need_to_add_currency', { currency })}
        &nbsp;
        <a href={currencyUrl} target="_blank" styleName="add-currency">
          {i18n('add_currency')}
        </a>
      </Alert>
    )
  }
}

export default cssModules(NoCurrencyAlert, styles)
