import DonateButton from './donate_button'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { createDNWTransaction } from 'client/redux/modules/dnw_transaction'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    campaign,
    canChipIn: campaign.can_chip_in,
    canDonate: campaign.can_donate,
    endDate: campaign.end_date,
    preview: campaign.preview || props.isPreview,
  }
}

const mapDispatchToProps = {
  createDNWTransaction,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonateButton)
