const SHOW = 'pools/toast_notification/SHOW'
const HIDE = 'pools/toast_notification/HIDE'

export function show(toastId, message) {
  return { type: SHOW, payload: { id: toastId, message } }
}

export function hide(toastId) {
  return { type: HIDE, payload: { id: toastId } }
}

export default function reducer(state = {}, action) {
  if (action.type === HIDE) {
    return { [action.payload.id]: false }
  }
  if (action.type === SHOW) {
    return {
      [action.payload.id]: { message: action.payload.message, status: true },
    }
  }
  return state
}
