import Transfer from './transfer'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { reduxForm, change } from 'redux-form'
import formUtils from 'lib/form_utils'
import { glimpseRoute } from 'lib/route_utils'
import { push } from 'react-router-redux'
import { required, atMost, combine } from 'lib/validators'
import { getI18n } from 'intl'
import { withdrawRemotely } from 'redux/modules/campaign'
import { CROWD_FUNDING } from 'shared/shared_consts'

const i18n = getI18n('components/edit_glimpse/transfer')

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const isCrowdfunding = campaign.campaign_type === CROWD_FUNDING
  return {
    shouldAutoFocus: props.router.params.action !== 'POP',
    campaignName: campaign.title,
    currency: campaign.currency,
    moneyboxAmount: campaign.moneybox_amount,
    countryCode: state.country.country_code,
    isCrowdfunding: isCrowdfunding,
    validate: combine(
      required('transferAmount', i18n('amount_required')),
      atMost(
        'transferAmount',
        campaign.moneybox_amount,
        i18n('amount_too_much')
      )
    ),
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onTransferAll(amount) {
      dispatch(
        change('transfer', 'transferAmount', amount && amount.toString())
      )
    },
    onSubmit(formValues) {
      dispatch(push({ pathname: glimpseRoute('edit', 'transfer/review') }))
    },
    onTransfer(amount) {
      dispatch(withdrawRemotely(campaignId, amount))
        .then(() => {
          dispatch(
            push({ pathname: glimpseRoute('edit', 'transfer/complete') })
          )
        })
        .catch(() => {
          dispatch(
            push({ pathname: glimpseRoute('edit', 'transfer/complete') })
          )
        })
    },
  }
}

export default reduxForm(
  {
    form: 'transfer',
    destroyOnUnmount: false,
    fields: ['transferAmount'],
  },
  mapStateToProps,
  mapDispatchToProps
)(formUtils(Transfer))
