import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './enrich_charity_card.less'
import {
  HeadingText,
  BodyText,
  CaptionText,
  WebsiteIcon,
  Alert,
  Avatar,
  Link,
} from '@paypalcorp/pp-react'
import CollapsibleText from 'components/common/collapsible_text'
import { getI18n } from 'intl'
import {
  KEY_PRESS_EVENTS,
  CHARITY_INSTRUMENTATION,
  COLLAPSIBLE_STATE,
} from 'shared/shared_consts'
import { trackEventNameClick } from 'lib/fpti_analytics'

const i18n = getI18n('components/expandable_paragraph')

const { ENTER_KEY } = KEY_PRESS_EVENTS
class EnrichCharityCard extends React.Component {
  static propTypes = {
    charityName: PropTypes.string,
    charityUrl: PropTypes.string,
    sourceContent: PropTypes.string,
    disclaimerContent: PropTypes.string,
    unEnrolledLink: PropTypes.string,
    learnMoreCharityLink: PropTypes.string,
    cityState: PropTypes.string,
    einContent: PropTypes.string,
    charityDescription: PropTypes.string,
    websiteUrl: PropTypes.string,
    campaign: PropTypes.object,
  }

  openWebsite = url => {
    window.open(url, '_blank')
    this.handleFPTI(CHARITY_INSTRUMENTATION.CHARITY_LINK_PRESSED)
  }

  handleKeyUp = (url, event) => {
    if (event.key === ENTER_KEY) {
      this.openWebsite(url)
    }
  }

  handleFPTI = eventName => {
    const { campaign } = this.props
    const { charity } = campaign
    if (charity) {
      trackEventNameClick({
        event_name: eventName,
        discoverable: campaign?.discoverable,
      })
    }
  }

  source = () =>
    this.props.sourceContent && (
      <CaptionText>{this.props.sourceContent}</CaptionText>
    )

  header = () => {
    const {
      charityName,
      charityUrl,
      websiteUrl,
      einContent,
      cityState,
    } = this.props
    return (
      <>
        <div styleName="charity-row-one">
          <div>
            <Avatar src={charityUrl} alt="Charity Logo" size="md" />
          </div>
          <div styleName="charity-name-section">
            <HeadingText size="sm">{charityName}</HeadingText>
          </div>
        </div>
        <div styleName="charity-row-two">
          <div>
            {cityState && <BodyText size="sm">{cityState}</BodyText>}
            {einContent && <BodyText size="sm">{einContent}</BodyText>}
          </div>
          {websiteUrl && (
            <WebsiteIcon
              size="sm"
              styleName="web-icon"
              color="sysColorAccent1Main"
              onKeyUp={e => this.handleKeyUp(websiteUrl, e)}
              onClick={() => this.openWebsite(websiteUrl)}
              tabIndex={0}
            />
          )}
        </div>
      </>
    )
  }

  render() {
    const {
      disclaimerContent,
      unEnrolledLink,
      learnMoreCharityLink,
    } = this.props

    return (
      <>
        <CollapsibleText
          header={this.header()}
          footer={this.source()}
          dataTestId="charity-details"
          toggleContent={{
            open: i18n('show_less'),
            closed: i18n('read_more'),
          }}
          fpti={{
            onClick: status => {
              this.handleFPTI(
                status === COLLAPSIBLE_STATE.COLLAPSED
                  ? CHARITY_INSTRUMENTATION.SHOWLESS_LINK_PRESSED
                  : CHARITY_INSTRUMENTATION.READMORE_LINK_PRESSED
              )
            },
          }}
        >
          <BodyText>{this.props.charityDescription}</BodyText>
        </CollapsibleText>
        {disclaimerContent && (
          <>
            <Alert type="info" styleName="alert">
              {disclaimerContent}
            </Alert>
            <div styleName="un-enrolled-link">
              <Link
                href={unEnrolledLink}
                target="_blank"
                onClick={() =>
                  this.handleFPTI(CHARITY_INSTRUMENTATION.PPGF_TNC_LINK_PRESSED)
                }
              >
                {learnMoreCharityLink}
              </Link>
            </div>
          </>
        )}
      </>
    )
  }
}

export default cssModules(EnrichCharityCard, styles, { allowMultiple: true })
