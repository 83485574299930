import React from 'react'
import { useIntl, getDefaultCurrencyCode } from 'intl'
import PropTypes from 'prop-types'
import styles from '../edit_main_cf.less'
import cssModules from 'react-css-modules'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import { TextInput } from '@paypalcorp/pp-react'
import DateInputBox from 'components/common/date_input_box'
import CurrencyInputBox from 'components/common/currency_input'
import { POOL_TYPE } from 'shared/shared_consts'

const PersonalOrCharityEdit = props => {
  const { formatMessage } = useIntl()
  const {
    poolName,
    goalAmount,
    endDate,
    campaign,
    currency,
    timeZone,
    formattedMaxGoalAmount,
  } = props

  return (
    <>
      <div styleName="textbox-wrapper">
        <TextInput
          id={poolName.name}
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.personal_pool_name_title'
          )}
          dataNemo="your-pool-name"
          {...domOnlyProps(poolName)}
          disabled={!campaign.can_chip_in}
          errorText={getFormError(poolName)}
          data-testid="personal-or-charity-edit__edit-name"
        />
      </div>
      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          id={goalAmount.name}
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.goal_title'
          )}
          dataNemo="goal-amount"
          disableCents={true}
          disabled={!campaign.can_chip_in}
          errorText={getFormError(goalAmount)}
          currencyCode={currency || getDefaultCurrencyCode()}
          {...goalAmount}
          dataTestId="personal-or-charity-edit__edit-amount"
          helperText={
            campaign.type === POOL_TYPE.PERSONAL
              ? formatMessage(
                  'components.pages.crowdfunding.pool_basics.goal_tip_description',
                  {
                    maxGoalAmount: formattedMaxGoalAmount,
                  }
                )
              : ''
          }
        />
      </div>
      <div styleName="textbox-wrapper">
        <DateInputBox
          id={endDate.name}
          label={formatMessage('components.edit_glimpse.edit_main.end_date_cf')}
          dataNemo="date-pool-ends"
          errorText={getFormError(endDate)}
          disabled={!campaign.can_chip_in}
          {...endDate}
          timeZone={timeZone}
          useEndOfDay={false}
          dataTestId="personal-or-charity-edit__edit-end-date"
        />
      </div>
    </>
  )
}

PersonalOrCharityEdit.propTypes = {
  campaign: PropTypes.object.isRequired,
  currency: PropTypes.string,
  formattedMaxGoalAmount: PropTypes.string.isRequired,
  timeZone: PropTypes.string,
  endDate: PropTypes.object.isRequired,
  goalAmount: PropTypes.string.isRequired,
  poolName: PropTypes.string.isRequired,
}

export default cssModules(PersonalOrCharityEdit, styles)
