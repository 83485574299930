import { get } from 'lodash'
import { connect } from 'react-redux'
import { formatName } from 'intl'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { getInitials } from 'shared/helpers/name_helpers'
import ProfileCover from './profile_cover'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  const ownerName =
    get(campaign, 'owner.business_name', '') ||
    formatName(ownProps.worldReady, {
      givenName: get(campaign, 'owner.first_name', ''),
      surname: get(campaign, 'owner.last_name', ''),
    })

  return {
    charity: campaign?.charity,
    creatorName: ownerName,
    initials: getInitials(ownerName),
    profileImage: get(campaign, 'owner.profile_photo'),
  }
}

export default injectWorldReady(connect(mapStateToProps)(ProfileCover))
