import React from 'react'
import PropTypes from 'prop-types'
import Linkify from 'react-linkify'
import cssModules from 'react-css-modules'
import styles from './campaign_details.less'
import {
  Col,
  Row,
  Avatar,
  BodyText,
  CaptionText,
  HeadingText,
} from '@paypalcorp/pp-react'
import CollapsibleText from 'components/common/collapsible_text/collapsible_text'
import { useIntl } from 'intl'

const CampaignDetails = props => {
  const { formatMessage } = useIntl()
  const {
    charity,
    details,
    initials,
    isCharity,
    ownerName,
    profileImage,
    tab_layout,
  } = props

  if (!details) {
    return null
  }
  let title
  if (isCharity) {
    title = formatMessage(
      'components.crowdfunding_charity_detail_view.about_fundraiser'
    )
  } else {
    title = tab_layout ? '' : formatMessage('components.campaign_details.title')
  }

  return (
    <React.Fragment>
      {isCharity && (
        <Row>
          <Col>
            <div styleName="charityOrganiserDetailsContainer">
              <Avatar
                src={profileImage}
                initials={initials}
                size="xs"
                alt=""
                role="presentation"
              />
              <CaptionText
                styleName="charityOrganiserDetailsCaption"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    'components.crowdfunding_charity_detail_view.raising_charity_fund_for_2',
                    {
                      creatorFullName: ownerName,
                      supportingCharityName: charity.name,
                      creatorFullNameStyle: styles.strong,
                      supportingCharityNameStyle: styles.strong,
                    }
                  ),
                }}
              />
            </div>
          </Col>
        </Row>
      )}
      <div styleName="details-margin" data-testid="collapsible-text">
        <CollapsibleText
          maxLines={17}
          dataTestId="campaing-details"
          header={<HeadingText size="sm">{title}</HeadingText>}
          toggleContent={{
            open: formatMessage('components.expandable_paragraph.show_less'),
            closed: formatMessage('components.expandable_paragraph.read_more'),
          }}
        >
          <Linkify properties={{ target: '_blank' }}>
            <BodyText>{details}</BodyText>
          </Linkify>
        </CollapsibleText>
      </div>
    </React.Fragment>
  )
}

CampaignDetails.propTypes = {
  details: PropTypes.string,
  tab_layout: PropTypes.bool,
  profileImage: PropTypes.string,
  initials: PropTypes.string,
  isCharity: PropTypes.bool,
  charity: PropTypes.any,
  isPreview: PropTypes.bool,
  ownerName: PropTypes.string,
}

CampaignDetails.defaultProps = {
  tab_layout: false,
}

export default cssModules(CampaignDetails, styles)
