import React from 'react'
import { useIntl, getDefaultCurrencyCode } from 'intl'
import PropTypes from 'prop-types'
import styles from '../edit_main_cf.less'
import cssModules from 'react-css-modules'

import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'

import { TextInput } from '@paypalcorp/pp-react'
import DateInputBox from 'components/common/date_input_box'
import CurrencyInputBox from 'components/common/currency_input'

const PrivateEditForm = ({
  campaign,
  currency,
  endDate,
  formattedMaxGoalAmount,
  goalAmount,
  poolName,
}) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <div styleName="textbox-wrapper">
        <TextInput
          id={poolName.name}
          label={formatMessage('flow.private.basics.input_name_label')}
          {...domOnlyProps(poolName)}
          disabled={!campaign.can_chip_in}
          errorText={getFormError(poolName)}
          data-testid="edit-name"
        />
      </div>
      <div styleName="textbox-wrapper">
        <DateInputBox
          id={endDate.name}
          label={formatMessage('flow.private.basics.input_date_label')}
          errorText={getFormError(endDate)}
          disabled={!campaign.can_chip_in}
          {...endDate}
          useEndOfDay={false}
          dataTestId="edit-end-date"
        />
      </div>
      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          id={goalAmount.name}
          label={formatMessage('flow.private.basics.input_goal_amount_label')}
          disableCents={true}
          disabled={!campaign.can_chip_in}
          errorText={getFormError(goalAmount)}
          currencyCode={currency}
          {...goalAmount}
          dataTestId="edit-amount"
          helperText={formatMessage(
            'flow.private.basics.input_goal_amount_help_text',
            {
              amount: formattedMaxGoalAmount,
            }
          )}
        />
      </div>
    </>
  )
}

PrivateEditForm.propTypes = {
  campaign: PropTypes.object.isRequired,
  currency: PropTypes.string,
  endDate: PropTypes.string.isRequired,
  formattedMaxGoalAmount: PropTypes.string.isRequired,
  goalAmount: PropTypes.string.isRequired,
  poolName: PropTypes.string.isRequired,
}

PrivateEditForm.defaultProps = {
  currency: getDefaultCurrencyCode(),
}

export default cssModules(PrivateEditForm, styles)
