import React from 'react'
import CountryBlockedError from 'components/common/country_blocked'
import ManageAllPage from 'components/pages/hub/manage_all_page'
import { get } from 'lodash'
import { getCampaignCount } from 'redux/selectors/campaign'
import ZeroFundraiser from 'components/pages/hub/zero_fundraiser'
import { create } from 'redux/modules/campaign'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import { useIntl } from 'intl'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export default function(store, history) {
  const { formatMessage } = useIntl()
  const blockedTitle = formatMessage(
    'components.pages.create.create.kill_switched_title'
  )
  const errorEligibility = formatMessage(
    'components.pages.create.create.error_eligible_sub_title'
  )
  const deprecatedMessage = {
    fundraiserDeprecatedBlockedTitle: blockedTitle,
    fundraiserDeprecatedEligibility: errorEligibility,
  }
  return {
    getComponent(nextState, callback) {
      const state = store.getState()
      if (state.current_user.moneypools_blocked) {
        return callback(null, CountryBlockedError)
      } else {
        return callback(null, props => {
          const campaignCount = getCampaignCount(state)

          return campaignCount > 0 ? (
            <ManageAllPage
              {...props}
              campaignId={get(nextState, 'location.state.campaignId')}
              reloadTransactions={
                get(nextState, 'location.state.reloadTransactions') === false
                  ? false
                  : true
              }
            />
          ) : (
            <ZeroFundraiser deprecatedMessage={deprecatedMessage} />
          )
        })
      }
    },
    onEnter() {
      store.dispatch(d => {
        d(create(CROWD_FUNDING)) // creates the new campaign object.
      })
    },
  }
}
