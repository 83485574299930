import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_nonprofit.less'
import { BodyText, HeadingText, Avatar } from '@paypalcorp/pp-react'
import { trackEventNameClick } from 'lib/fpti_analytics'
import Typeahead from 'components/common/type_ahead_search'
import HorizontalScroller from 'components/horizontal_scroller'
import { Message } from '@paypalcorp/worldready-react'
import { validateImagePath } from 'shared/campaign_url'

function NonProfitPage(props) {
  const { featuredCharities, isMobile } = props

  const getOptions = async (options, userText) => {
    if (!userText) {
      return []
    }
    const response =
      (await props.getCharities({
        search_all: userText,
        page: 1,
        page_size: 30,
      })) || []

    return response
  }

  const getSelectedItem = item => {
    trackEventNameClick({
      event_name: 'dw_giving_browse_fundraiser_charity_selection_pressed',
      charity_name: item.name,
      npid: item.nonprofit_id,
    })
    props.selectCharity(item)
  }

  const handleCardOnClick = item => {
    trackEventNameClick({
      event_name: 'dw_giving_find_fundraiser_charity_recommendation_pressed',
      charity_name: item.name,
      npid: item.nonprofit_id,
    })

    props.selectCharity(item)
  }

  const setItemDecoration = item => {
    const logoURL = validateImagePath(item.logo_url)
    return (
      <React.Fragment>
        {logoURL ? (
          <div
            styleName="thumbnail"
            style={{
              background: `url(${
                item.logo_url
              }) center center / contain no-repeat`,
            }}
          />
        ) : (
          <Avatar styleName="thumbnail" generic="causes" size="sm" />
        )}
        <p styleName="item-text">
          {item.name}
          <div>{`${item.address.city}, ${item.address.country_code}`}</div>
        </p>
      </React.Fragment>
    )
  }

  return (
    <div>
      <HeadingText size="sm" styleName="title">
        <Message id="components.pages.crowdfunding.pool_nonprofit.title" />
      </HeadingText>
      <div
        styleName="textbox-wrapper"
        onClick={() =>
          trackEventNameClick({
            event_name: 'dw_giving_find_fundraiser_charity_search_pressed',
          })
        }
      >
        <Typeahead
          name="charitySearch"
          getOptions={getOptions}
          getSelectedItem={getSelectedItem}
          setItemDecoration={cssModules(setItemDecoration, styles)}
          delay={1000}
          datasource="name"
          data-nemo="charity_search"
          label={
            <Message id="components.pages.crowdfunding.crowdfunding_nonprofit.search_placeholder" />
          }
        />
      </div>
      {featuredCharities.length && (
        <>
          <BodyText size="sm" styleName="legend">
            <Message id="components.pages.crowdfunding.crowdfunding_nonprofit.charity_header" />
          </BodyText>
          <HorizontalScroller
            isMobile={isMobile}
            items={featuredCharities}
            dataTestId="np-featured-charities"
            onClick={handleCardOnClick}
          />
        </>
      )}
    </div>
  )
}

NonProfitPage.propTypes = {
  featuredCharities: PropTypes.arrays,
  selectCharity: PropTypes.func,
  getCharities: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default cssModules(NonProfitPage, styles)
