import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './organizer_update_detail.less'
import { glimpseRoute } from 'lib/route_utils'
import { Link as RouterLink } from 'react-router'
import {
  BodyText,
  HeadingText,
  CaptionText,
  Button,
  Row,
} from '@paypalcorp/pp-react'
import { Message, injectWorldReady } from '@paypalcorp/worldready-react'
import clsx from 'clsx'
import { daysUntilToday } from 'shared/helpers/datetime_helpers'
import { trackFPTI } from 'lib/fpti_utils'
import { formatDate } from 'intl'

export class OrganizerUpdateDetail extends React.Component {
  static propTypes = {
    organizerUpdateRow: PropTypes.object.isRequired,
    postUpdateUrl: PropTypes.string.isRequired,
    enableEditButton: PropTypes.bool,
    truncateSubject: PropTypes.bool,
    truncateDescription: PropTypes.bool,
    isDonerView: PropTypes.bool,
    campaign: PropTypes.object,
    eventName: PropTypes.string,
    updateState: PropTypes.string,
    shown: PropTypes.string,
    worldReady: PropTypes.Object,
  }
  render() {
    const {
      organizerUpdateRow,
      postUpdateUrl,
      enableEditButton,
      truncateDescription,
      truncateSubject,
      isDonerView,
      campaign,
      eventName,
      updateState,
      worldReady,
    } = this.props

    const days = Math.abs(
      daysUntilToday(new Date(organizerUpdateRow.time_created))
    )
    const key = days === 0 || days === 1 ? `days_ago_${days}` : 'days_ago_other'
    if (enableEditButton || isDonerView) {
      const isDonerEventName = isDonerView
        ? 'dw_giving_donor_update_detail_screen_shown'
        : eventName
      trackFPTI(
        campaign,
        'impression',
        isDonerEventName,
        '',
        '',
        '',
        updateState
      )
    }
    return (
      <div
        styleName={clsx('details-content', {
          'details-content-mweb': enableEditButton,
        })}
      >
        <div styleName="organizer-update">
          <HeadingText
            size="sm"
            styleName={clsx('subject-name', {
              'truncate-subject': truncateSubject,
            })}
            data-testid="subject-name"
          >
            {organizerUpdateRow.subject}
          </HeadingText>
          <CaptionText
            styleName="subject-timestamp"
            data-testid="subject-timestamp"
          >
            {days <= 28 ? (
              <Message id={`components.txns_list.${key}`} days={days} />
            ) : (
              formatDate(worldReady, organizerUpdateRow.time_created)
            )}
          </CaptionText>
        </div>
        <BodyText
          styleName="subject-description "
          data-testid="subject-description"
        >
          <span
            styleName={clsx({ 'truncate-description': truncateDescription })}
          >
            {organizerUpdateRow.text}
            {organizerUpdateRow.edited && (
              <CaptionText
                data-testid="edit-btn"
                as="span"
                styleName="subject-timestamp parentheses"
              >
                <Message id="components.edit_glimpse.updates_activity.edited_label" />
              </CaptionText>
            )}
          </span>
        </BodyText>
        {enableEditButton && (
          <Row styleName="update-button">
            <Button
              as={RouterLink}
              to={glimpseRoute(
                'pools',
                `${postUpdateUrl}/${organizerUpdateRow.fou_id}`
              )}
              size="lg"
              dataTestId="edit_organizer_update"
              onClick={() => {
                trackFPTI(
                  campaign,
                  'click',
                  'dw_giving_update_detail_edit_pressed'
                )
              }}
            >
              <Message id="components.edit_glimpse.updates_activity.edit_update" />
            </Button>
          </Row>
        )}
      </div>
    )
  }
}

export default injectWorldReady(
  cssModules(OrganizerUpdateDetail, styles, {
    allowMultiple: true,
  })
)
