import React from 'react'
import PropTypes from 'prop-types'
import { UnboundAvatar, avatarColors } from 'components/common/avatar'
import cssModules from 'react-css-modules'
import styles from './activity_row.less'
import { getHash } from 'shared/helpers/string_helpers'
import { getInitials } from 'shared/helpers/name_helpers'
import { BodyText, Card, PAYPAL_THEME } from '@paypalcorp/pp-react'
import { useIntl } from 'intl'
import { Message } from '@paypalcorp/worldready-react'
import contributionStyles from './public_contribution_row.less'

const ActivityRow = props => {
  const { sysColorAccent3Main, sysColorBackgroundHighContrast } = PAYPAL_THEME

  const { formatMessage, formatCurrency } = useIntl()
  const getAvatarBackgroundColor = () => {
    if (props.withdraw) {
      return 'blue'
    }
    if (!props.contributor_id) {
      return 'white'
    }
    const uniqueNumericId = getHash(props.contributor_id.toString())
    return avatarColors[uniqueNumericId % avatarColors.length]
  }

  const amountSign = props.withdraw ? '-' : '+'
  const subjectName = props.withdraw
    ? formatMessage('components.txn_row.txn_row.paypal_balance')
    : props.contributor_name
  const activityType = props.withdraw
    ? formatMessage('components.txn_row.txn_row.transfer')
    : formatMessage('components.txn_row.txn_row.donation')
  const imageUrl = props.withdraw
    ? 'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg'
    : props.contributor_photo

  return (
    <Card styleName="activity-row" size="xs">
      <div styleName="activity-owner-details">
        <div styleName="avatar-wrapper">
          <UnboundAvatar
            initials={getInitials(props.contributor_name)}
            imageUrl={imageUrl}
            backgroundColor={getAvatarBackgroundColor()}
          />
        </div>
        <div styleName="details-wrapper">
          <div styleName="details-content">
            <BodyText strong styleName="subject-name">
              {subjectName}
            </BodyText>
            <BodyText styleName="activity-status">
              {activityType}
              {props.pending && (
                <span>
                  {' \u2022 '}
                  <Message id="components.edit_glimpse.activity.activity.pending" />
                </span>
              )}
            </BodyText>
          </div>
          <BodyText
            style={{
              color: props.withdraw
                ? sysColorBackgroundHighContrast
                : sysColorAccent3Main,
            }}
          >
            {amountSign}
            {formatCurrency(props.net_amount, props.currency)}
          </BodyText>
        </div>
      </div>
      {props.note && (
        <div styleName="details-note">
          <BodyText className={contributionStyles.note}>{props.note}</BodyText>
        </div>
      )}
    </Card>
  )
}

ActivityRow.defaultProps = {
  note: '',
  amount: null,
  currency: '',
  contributor_id: '',
  contributor_name: '',
  contributor_photo: '',
  pending: false,
  withdraw: false,
  net_amount: null,
}

ActivityRow.propTypes = {
  id: PropTypes.string,
  note: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  contributor_id: PropTypes.string,
  contributor_name: PropTypes.string,
  contributor_photo: PropTypes.string,
  pending: PropTypes.bool,
  withdraw: PropTypes.bool,
  net_amount: PropTypes.number,
}

export default cssModules(ActivityRow, styles)
