import Avatar from './avatar'
import { connect } from 'react-redux'

import { getInitials } from 'shared/helpers/name_helpers'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const campaignOwner = campaign.owner
  const initials = props.showInitials
    ? getInitials(campaignOwner.full_name)
    : null

  return {
    imageUrl: campaignOwner.profile_photo,
    initials,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Avatar)
