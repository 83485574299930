import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './pledge.less'
import CurrencyInputBox from 'components/common/currency_input'
import { routerShape } from 'react-router/lib/PropTypes'
import { sendEnsightenEvent } from 'lib/ensighten_analytics'
import { trackClick } from 'lib/fpti_analytics'
import { HeadingText, Button } from '@paypalcorp/pp-react'
import { getFormError } from 'lib/validators'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl, getDefaultCurrencyCode } from 'intl'

const Pledge = props => {
  const {
    router,
    currency,
    onPledge,
    minAmount,
    handleSubmit,
    campaignTitle,
    fields: { pledge },
  } = props

  const { formatMessage } = useIntl()

  const postPledge = formValues => {
    const { pathname } = router.location
    const nextPathname = `${pathname}/complete`
    router.push({
      pathname: `${nextPathname}`,
      state: {
        params: {
          currency,
          campaignTitle,
          pledge: formValues.pledge,
        },
      },
    })
  }

  const handleOnSubmit = formValues => {
    sendEnsightenEvent('MoneyPoolPledgeFin')
    trackClick({
      clickName: 'dw_giving_fundraiser_pledge_confirm_pressed',
      data: {
        event_name: 'dw_giving_fundraiser_pledge_confirm_pressed',
        amount: formValues && formValues.pledge,
        currency,
      },
    })
    return onPledge(formValues).then(() => postPledge(formValues))
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <HeadingText size="sm" styleName="form__title">
        <Message id="components.chip_in_glimpse.pledge.subtitle_cf" />
      </HeadingText>

      <CurrencyInputBox
        label={formatMessage('components.chip_in_glimpse.pledge.amount')}
        disableCents={true}
        errorText={getFormError(pledge)}
        minAmount={minAmount}
        autoFocus={true}
        currencyCode={currency || getDefaultCurrencyCode()}
        {...pledge}
        helperText={
          <Message id={'components.chip_in_glimpse.pledge.text_cf'} />
        }
        dataTestId="pledge__currency-input"
      />

      <div styleName="cta">
        <Button data-testid="pledge-button">
          <Message id={'components.chip_in_glimpse.pledge.pledge_cf'} />
        </Button>
      </div>
    </form>
  )
}

Pledge.propTypes = {
  fields: PropTypes.object.isRequired,
  onPledge: PropTypes.func,
  currency: PropTypes.string,
  handleSubmit: PropTypes.func,
  router: routerShape.isRequired,
  minAmount: PropTypes.number,
  campaignTitle: PropTypes.string,
}

export default cssModules(Pledge, styles)
