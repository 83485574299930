import React from 'react'
import PropTypes from 'prop-types'
import { getI18n } from 'intl'
import StandardError from 'components/common/standard_error'
import {
  UNDER_REVIEW_ERROR,
  INVALID_IMAGE,
  POOLS_CAMPAIGN_TYPES,
  GN_DISCLAIMERS,
} from 'shared/shared_consts'
import { trackImpression } from 'lib/fpti_analytics'
import { Button } from '@paypalcorp/pp-react'

const i18n = getI18n('components/edit_glimpse/edit_error')
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

class EditError extends React.Component {
  static propTypes = {
    fieldNames: PropTypes.arrayOf(PropTypes.string),
    onTryAgain: PropTypes.func,
    error: PropTypes.object,
    campaignType: PropTypes.string,
    countryCode: PropTypes.string,
  }

  render() {
    const error = this.props.error && this.props.error.error
    const errorCodeMappping = {
      UNDER_REVIEW_ERROR: 400,
      INVALID_IMAGE: 403,
    }

    trackImpression({
      event_name: 'dw_giving_failed_customer_interaction_occurred',
      event_type: 'ac',
      error_desc: error,
      error_code:
        error && errorCodeMappping[error] ? errorCodeMappping[error] : 500,
      screen: 'edit_fundraiser',
    })
    const { countryCode } = this.props

    if (error === UNDER_REVIEW_ERROR) {
      return (
        <StandardError
          iconType="error"
          title={i18n('gn_account_resrticted_title')}
          subtitle={i18n('gn_account_resrticted_subtitle')}
          backButton={true}
        />
      )
    } else if (error === INVALID_IMAGE) {
      const isCrowdfunding = this.props.campaignType === CROWD_FUNDING
      const title = isCrowdfunding
        ? 'error_account_restricted_title'
        : 'account_resrticted_title'
      const subTitle = isCrowdfunding
        ? 'error_account_restricted_sub_title'
        : 'account_resrticted_subtitle'
      return (
        <StandardError
          iconType="error"
          title={i18n(`${title}`)}
          subtitle={i18n(`${subTitle}`)}
          backButton={true}
          extraContent={
            isCrowdfunding ? (
              <Button
                as="a"
                href={`${GN_DISCLAIMERS[countryCode].userAgreement}`}
                role="button"
                secondary
              >
                {i18n('error_account_restricted_button')}
              </Button>
            ) : null
          }
          iconName="passkey"
        />
      )
    }
    return (
      <StandardError
        iconType="warning"
        title={i18n('title')}
        subtitle={i18n('subtitle')}
        onTryAgain={this.props.onTryAgain}
        showButton={true}
      />
    )
  }
}

export default EditError
