import { GNC_DISCLAIMERS } from 'shared/shared_consts'

const COUNTRY_CODES = {
  US: 'US',
  GB: 'GB',
  AU: 'AU',
  CA: 'CA',
}

export const getDisclaimerVariables = ({ countryCode, isGnc }) => {
  if (isGnc) {
    switch (countryCode) {
      case COUNTRY_CODES.AU:
        return GNC_DISCLAIMERS.AU
      case COUNTRY_CODES.CA:
        return GNC_DISCLAIMERS.CA
      case COUNTRY_CODES.GB:
        return GNC_DISCLAIMERS.GB
      case COUNTRY_CODES.US:
        return GNC_DISCLAIMERS.US
      default:
        return {}
    }
  }
}
