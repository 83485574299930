import { createClient } from 'fetch-plus'
import { getDataAttr, getJSONFromScript } from './server_params'
import fetchPlusJson from 'fetch-plus-json'
import fetchPlusCsrf from 'fetch-plus-csrf'
import { TEST_OPTIONS_HEADER } from 'shared/shared_consts'
import { loginUrlSelector } from 'redux/selectors/login'
import { reAuth } from 'lib/venice_bridge'
import { fromVeniceSelector } from 'redux/selectors/venice'

let _fetchClient = null

const cookieMiddleware = request => {
  request.options.credentials = 'same-origin'
  return response => response
}

const XHRMiddleware = request => {
  request.options.headers['X-Requested-With'] = 'XMLHttpRequest'
  return response => response
}

const testMiddleware = options => request => {
  request.options.headers[TEST_OPTIONS_HEADER] = options
}

function handleAuthFailure(loginUrl, fromVenice) {
  if (fromVenice) {
    reAuth()
  } else {
    location.href = loginUrl
  }
  // We want to wait until redirect / venice handling is complete, so we return a promise that will never be resolved
  return new Promise((resolve, reject) => {})
}

// Apparently, fetch-plus-json doesn't handle errors properly so we have to do this.
// See also https://github.com/RickWong/fetch-plus/issues/24
// First parameter is the function to call when there was an authentication failure (HTTP 401)
const jsonErrorMiddleware = authFailure => request => {
  return {
    error: response => {
      if (!response.ok) {
        if (response.status === 401) {
          return authFailure()
        } else if (typeof response.json === 'function') {
          const json = response.json()
          if (json.then) {
            return json.then(body => {
              throw body // reject the promise with the new json
            })
          }
          throw json
        }
      }
      throw response // fatal error
    },
  }
}

function createFetchClient(csrfToken) {
  const store = getJSONFromScript('store')
  const loginUrl = loginUrlSelector({ returnUri: window.location.href })(store)
  const fromVenice = fromVeniceSelector()(store)
  _fetchClient = createClient('')
  _fetchClient.addMiddleware(fetchPlusJson())
  _fetchClient.addMiddleware(
    jsonErrorMiddleware(handleAuthFailure.bind(null, loginUrl, fromVenice))
  )
  _fetchClient.addMiddleware(XHRMiddleware)
  _fetchClient.addMiddleware(cookieMiddleware)
  if (csrfToken) {
    _fetchClient.addMiddleware(fetchPlusCsrf('X-CSRF-Token', csrfToken))
  }

  if (process.env.NODE_ENV !== 'production') {
    const testOptions = getDataAttr('test-options')
    if (testOptions) {
      _fetchClient.addMiddleware(testMiddleware(testOptions))
    }
  }
  return _fetchClient
}

export default function fetchClient() {
  return _fetchClient || createFetchClient(getDataAttr('csrf'))
}
