import cloneDeep from 'lodash.clonedeep'

const SET_CHECKPOINT = 'pools/form_history/SET_CHECKPOINT'
const RESTORE_CHECKPOINT = 'pools/form_history/RESTORE_CHECKPOINT'

export function setCheckpoint(formName) {
  return { type: SET_CHECKPOINT, formName }
}

export function restoreCheckpoint(formName) {
  return { type: RESTORE_CHECKPOINT, formName }
}

export function reduxFormPlugin(formName) {
  return {
    [formName]: (state, action) => {
      if (action.type === SET_CHECKPOINT && action.formName === formName) {
        return { ...state, savedState: cloneDeep(state) }
      } else if (
        action.type === RESTORE_CHECKPOINT &&
        action.formName === formName
      ) {
        return { ...state.savedState }
      }
      return state
    },
  }
}
