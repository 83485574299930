import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import StandardError from 'components/common/standard_error'
import Modal from 'components/common/modal'
import { includes } from 'lodash'
import cssModules from 'react-css-modules'
import styles from './campaign_eligibility.less'
import { getI18n } from 'intl'
import {
  NO_DESCRIPTION_ERRORS,
  DEXTER_SPECIFIC_ERRORS,
  CIP_ELIGIBILITY_CODES,
  CIP_ERROR_CODE,
} from 'shared/shared_consts'
import { trackImpression, trackClick } from 'lib/fpti_analytics'
import { getCIPStatus } from 'lib/create_campaign_eligibility'
import StandardDialog from './../../common/standard_dialog'

const i18n = getI18n('components/common/campaign_eligibility')

class CampaignEligibility extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    CIPStatusCode: PropTypes.string,
    verificationFlow: PropTypes.string,
    onClose: PropTypes.func,
    dialog: PropTypes.bool,
    iconName: PropTypes.string,
  }

  handleAnalyticsEvent(flow) {
    trackClick({
      clickName: 'account_overview',
    })
  }

  render() {
    let isCreationLimitReached = false
    let { iconName: icon } = this.props
    let { CIPStatusCode, verificationFlow, dialog } = this.props
    const flow = includes(window.location.href, 'crowdfunding', false)
      ? 'GENEROSITY_NETWORK'
      : undefined
    let pageName, erpg, eccd
    if (CIPStatusCode === 'USER_REACHED_CAMPAIGN_CREATION_LIMIT') {
      pageName = 'create:duplicate_error'
      erpg = 'active_campaign_exists'
      isCreationLimitReached = true
    } else if (includes(CIP_ERROR_CODE, CIPStatusCode, false)) {
      pageName = 'create:cip_error'
      eccd = 'Pass through CIP error'
    } else if (verificationFlow === 'RUGS') {
      pageName = 'create:rugs_error'
      eccd = 'Pass through RUGS error'
    } else {
      pageName = 'create:generic_error'
      eccd = `generic error: ${CIPStatusCode}`
    }

    trackImpression({
      pageName: pageName,
      erpg: erpg,
      eccd: eccd,
      event_name: 'dw_giving_failed_customer_interaction_occurred',
      event_type: 'ac',
      error_desc: CIPStatusCode,
      error_code: 200,
      screen: 'create_fundraiser',
    })
    CIPStatusCode =
      includes(DEXTER_SPECIFIC_ERRORS, CIPStatusCode) ||
      includes(CIP_ELIGIBILITY_CODES, CIPStatusCode)
        ? CIPStatusCode
        : 'ERROR'

    if (CIP_ELIGIBILITY_CODES.RUGS_CHECK_HIT_FAILED === CIPStatusCode) {
      icon = 'personal'
    } else if (CIP_ELIGIBILITY_CODES.RUGS_CHECK_FAILED === CIPStatusCode) {
      icon = 'undo'
    } else if (DEXTER_SPECIFIC_ERRORS.REJECTED === CIPStatusCode) {
      icon = 'international'
    }

    const title = i18n(`title.${CIPStatusCode}`)
    const description = includes(NO_DESCRIPTION_ERRORS, CIPStatusCode)
      ? ''
      : i18n(`description.${CIPStatusCode}`)
    const ctaInfo = {}
    if (isCreationLimitReached) {
      ctaInfo.label = i18n('manage_your_fundraiser')
      ctaInfo.target = '/pools'
      icon = 'fundraiser'
    } else {
      ctaInfo.label = i18n('go_to_account_button')
      ctaInfo.target = '/myaccount/summary'
      icon = 'undo'
    }
    return !isCreationLimitReached || !dialog ? (
      <Modal showClose={false}>
        <div styleName="eligibilty-errors">
          <StandardError
            iconType="warning"
            title={title}
            subtitle={description}
            iconName={icon}
          />
          <a
            href={ctaInfo.target}
            onClick={() => this.handleAnalyticsEvent(flow)}
            className="vx_btn vx_btn-block vx_btn-secondary"
            styleName="go-to-account-button"
          >
            {ctaInfo.label}
          </a>
        </div>
      </Modal>
    ) : (
      <StandardDialog
        title={title}
        subtitle={description}
        closeButtonTitle={ctaInfo.label}
        size="medium"
        onClose={this.props.onClose}
        onQuit={() => (window.location = ctaInfo.target)}
        iconName={icon}
      />
    )
  }
}
export default connect((state, ownProps) => {
  const campaignType = ownProps.route?.campaignType || ownProps.campaignType
  const cipStatusObj =
    state.current_user.CIPEligibility &&
    state.current_user.CIPEligibility[campaignType]
  return {
    CIPStatus:
      cipStatusObj && getCIPStatus(campaignType, cipStatusObj, ownProps, state),
    CIPStatusCode: cipStatusObj && cipStatusObj.CIPStatusCode,
    verificationFlow: state.current_user.verificationFlow,
    ...ownProps,
  }
})(cssModules(CampaignEligibility, styles))
