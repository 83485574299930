import React from 'react'
import Clipboard from 'clipboard'
import PropTypes from 'prop-types'
import { LinkIcon } from '@paypalcorp/pp-react'

export default class ClipboardSharingButton extends React.Component {
  static defaultProps = { enabled: true }

  state = {
    isCopied: false,
  }

  handleCopySuccess = () => {
    this.props.onShowToast()
  }

  componentDidMount() {
    if (this.props.enabled) {
      const copyText = this.state.copyText
      const copyBoxTextRef = this.copyBoxText
      this.clipboard = new Clipboard(this.copyLink, {
        text() {
          return copyText
        },
        target() {
          return copyBoxTextRef
        },
      })
      this.clipboard.on('success', this.handleCopySuccess)
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  render() {
    return (
      <div
        data-nemo="copy"
        href="javascript:;"
        ref={copyLink => {
          this.copyLink = copyLink
        }}
        onClick={e => this.props.handleClick('copy', this.props, e)}
        onKeyUp={e => this.props.handleKeyUp('copy', this.props, e)}
        tabIndex={0}
        className={this.props.className}
      >
        <LinkIcon size="sm" />
        {this.props.showSubtext && (
          <div styleName="sub-text">{this.props.i18n2('clipboard')}</div>
        )}
        {!this.state.isCopied && (
          <input
            type="text"
            ref={copyBoxText => {
              this.copyBoxText = copyBoxText
            }}
            value={this.props.shareUrl}
            data-nemo="share"
            readOnly={true}
            name="share"
            style={{
              position: 'absolute',
              left: '-999em',
            }}
            aria-hidden={true}
          />
        )}
      </div>
    )
  }
}

ClipboardSharingButton.propTypes = {
  showFacebook: PropTypes.bool,
  showMessenger: PropTypes.bool,
  showTwitter: PropTypes.bool,
  showWhatsApp: PropTypes.bool,
  showEmail: PropTypes.bool,
  showSms: PropTypes.bool,
  showClipboard: PropTypes.bool,
  showSubtext: PropTypes.bool,
  shareText: PropTypes.string,
  className: PropTypes.string,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
  shareMsgFB: PropTypes.string,
  shareMsgTwitter: PropTypes.string,
  shareMsgWhatsapp: PropTypes.string,
  shareUrl: PropTypes.string,
  enabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleKeyUp: PropTypes.func,
  i18n2: PropTypes.func,
  onShowToast: PropTypes.func,
}
