import React from 'react'
import PropTypes from 'prop-types'
import { getI18n } from 'intl'
import StandardError from 'components/common/standard_error'
import { FOU_LIMIT_REACHED_ERROR } from 'shared/shared_consts'
import { Button } from '@paypalcorp/pp-react'
import { get } from 'lodash'
import styles from './post_update_error.less'
import cssModules from 'react-css-modules'
import { withRouter } from 'react-router'
import { trackFPTI } from 'lib/fpti_utils'
import { Message } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/edit_glimpse/post_update')

class PostUpdateError extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    router: PropTypes.routerShape,
    campaign: PropTypes.object,
  }
  handleBack = () => {
    this.props.router.push({ pathname: `/pools` })
  }

  componentDidMount() {
    const { campaign, error } = this.props
    const errorName = get(this.props, 'error.name')
    let errorObject = error
    if (errorName === FOU_LIMIT_REACHED_ERROR) {
      errorObject = {
        error_desc: 'reached_limit',
        error_code: '422',
      }
    }
    trackFPTI(
      campaign,
      'impression',
      'dw_giving_post_update_error_screen_shown',
      '',
      errorObject
    )
  }
  render() {
    const error = get(this.props, 'error.name')
    const isLimitReachedError = error === FOU_LIMIT_REACHED_ERROR

    return (
      <div styleName="topAlign">
        <StandardError
          iconType="error"
          icon={isLimitReachedError ? <></> : null}
          title={
            isLimitReachedError
              ? i18n('error_max_update_title')
              : i18n('error_title')
          }
          subtitle={
            isLimitReachedError ? (
              i18n('error_max_update_subtitle')
            ) : (
              <>
                <Message id="components.edit_glimpse.post_update.error_subtitle" />{' '}
                <Message id="components.edit_glimpse.post_update.error_goback" />
              </>
            )
          }
          backButton={isLimitReachedError ? false : true}
          extraContent={
            <Button
              as="a"
              onClick={this.handleBack}
              role="button"
              primary="true"
              styleName="extraContent"
              data-testid="error-btn"
            >
              {isLimitReachedError
                ? i18n('error_max_update_done_button')
                : i18n('back')}
            </Button>
          }
          iconName={isLimitReachedError ? '' : 'undo'}
        />
      </div>
    )
  }
}

export default withRouter(cssModules(PostUpdateError, styles))
