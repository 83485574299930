import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n, formatCurrency } from 'intl'
import styles from './transfer_complete.less'
import StandardError from 'components/common/standard_error'
import { toCFPBStringId } from 'client/lib/cfpb'
import { trackClick } from 'lib/fpti_analytics'
import { isStage, isDev } from 'client/lib/deploy_env'
import { BodyText, Button, HeadingText } from '@paypalcorp/pp-react'
import Pictograms from 'components/common/pictograms'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/edit_glimpse/transfer')

class TransferComplete extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    amount: PropTypes.number,
    currency: PropTypes.string.isRequired,
    transferError: PropTypes.any,
    onDone: PropTypes.func.isRequired,
    fromVenice: PropTypes.bool.isRequired,
    paypalDomain: PropTypes.string,
    cdnHost: PropTypes.string.isRequired,
    worldReady: PropTypes.Object,
  }

  shouldComponentUpdate(nextProps, nextState) {
    // since the form this relies on might be destroyed while this transitions, we don't want to re-render if amount is non-existent.
    return nextProps.amount != null
  }

  handleFPTI = e => {
    trackClick({
      clickName: 'dw_giving_fundraiser_transfer_success_withdraw_pressed',
      data: {
        event_name: 'dw_giving_fundraiser_transfer_success_withdraw_pressed',
      },
    })
  }

  render() {
    const hostName =
      isDev() || isStage() ? location.hostname : this.props.paypalDomain
    if (this.props.transferError) {
      return <StandardError iconType="warning" title={i18n('transfer_error')} />
    }
    const withdrawlUrl = `https://${hostName}/myaccount/wallet/withdraw`
    return (
      <div styleName="transfer-container">
        <div styleName="success-image">
          <Pictograms
            classname="creation-share-image"
            svg="send-receive"
            data-testid="transfer-complete-image-url"
          />
        </div>
        <HeadingText size="sm" styleName="title">
          {i18n(toCFPBStringId('complete_title'), {
            amount: formatCurrency({
              value: this.props.amount,
              currencyCode: this.props.currency,
              additionalConfig: {},
              worldReady: this.props.worldReady,
            }),
            title: this.props.title,
          })}
        </HeadingText>
        <BodyText styleName="sub-title">
          {i18n(toCFPBStringId('complete_sub_title'))}
        </BodyText>
        <div styleName="actions">
          <Button
            as="a"
            href={withdrawlUrl}
            secondary
            onClick={this.handleFPTI}
            styleName="withdraw-button"
          >
            {i18n('withdraw_to_bank')}
          </Button>
        </div>
      </div>
    )
  }
}

export default injectWorldReady(cssModules(TransferComplete, styles))
