import OrganizerUpdateDetail from './organizer_update_detail'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { get } from 'lodash'
import { organizerUpdate } from 'lib/organizerupdate'
import { organizerUpdateState } from 'lib/fpti_utils'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const organizerUpdateItems = get(campaign, 'organizerUpdate.items') || []
  const organizerUpdateId = get(props, 'routeParams.fouId', '')
  const selectedOrganizerUpdate = organizerUpdate(
    organizerUpdateId,
    organizerUpdateItems
  )
  const getOrganizerUpdate = get(campaign, 'organizerUpdate') || {}
  const updateState = organizerUpdateState(getOrganizerUpdate)
  return {
    organizerUpdateRow: selectedOrganizerUpdate,
    postUpdateUrl: `c/${campaign.id}/postupdate`,
    enableEditButton: get(props, 'params.enableEditButton', false),
    campaign,
    eventName: 'dw_giving_update_detail_screen_shown',
    updateState,
  }
}

export default connect(mapStateToProps)(OrganizerUpdateDetail)
