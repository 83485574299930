import React from 'react'
import StandardError from 'components/common/standard_error'
import { GN_DISCLAIMERS } from 'shared/shared_consts'
import { trackImpression } from 'lib/fpti_analytics'
import { CREATION_BLOCKS } from '../create/create'
import { PAYPAL_THEME, UserProfileIcon, Button } from '@paypalcorp/pp-react'
import PropTypes from 'prop-types'
import styles from './create_fundraiser_error.less'
import cssModules from 'react-css-modules'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl, getI18n } from 'intl'

const i18n = getI18n('components/pages/create/create')
const i18nError = getI18n('errors/errors')

CreateFundraiserError.propTypes = {
  blockType: PropTypes.oneOf(Object.values(CREATION_BLOCKS)),
  countryName: PropTypes.string,
  email: PropTypes.string,
  countryCode: PropTypes.string,
  charity: PropTypes.object,
  logoutUrl: PropTypes.string,
}

function CreateFundraiserError({
  blockType,
  charity,
  countryCode,
  email,
  logoutUrl,
  countryName,
}) {
  const { formatMessage } = useIntl()
  const shouldShowButton =
    blockType === CREATION_BLOCKS.byAccountType ||
    blockType === CREATION_BLOCKS.byUnconfirmed

  const { sysColorPrimaryMain } = PAYPAL_THEME

  if (charity) {
    trackImpression({
      error_desc: blockType || 'CREATE_ERROR',
      error_code: 500,
      event_name: 'dw_giving_failed_customer_interaction_occurred',
      screen: 'start_fundraiser',
      event_type: 'ac',
    })
  }

  if (blockType === CREATION_BLOCKS.byGNAccountRestricted) {
    return (
      <StandardError
        iconType="warning"
        title={i18n('error_account_restricted_title')}
        subtitle={i18n('error_account_restricted_sub_title')}
        extraContent={
          <Button
            as="a"
            href={`${GN_DISCLAIMERS[countryCode].userAgreement}`}
            role="button"
            secondary
          >
            {i18n('error_account_restricted_button')}
          </Button>
        }
        iconName="passkey"
      />
    )
  } else if (blockType === CREATION_BLOCKS.byAccountType) {
    return (
      <StandardError
        icon={
          <UserProfileIcon style={{ color: sysColorPrimaryMain }} size="lg" />
        }
        title={i18n(`${blockType}_title`)}
        backButton={shouldShowButton}
        extraContent={
          <a data-pa-click="signin" href={logoutUrl}>
            {i18n('signin_link')}
          </a>
        }
      />
    )
  } else if (blockType === CREATION_BLOCKS.genericError) {
    return (
      <StandardError
        iconType="error"
        title={i18nError('generic_error_1')}
        backButton={shouldShowButton}
      />
    )
  } else if (blockType === CREATION_BLOCKS.currencyMismatchError) {
    trackImpression({
      error_desc: blockType || 'CREATE_ERROR',
      event_name: 'dw_giving_Create_fundraiser_with_Cross_Currency_mismatch',
      screen: 'start_fundraiser',
      event_type: 'ac',
    })
    return (
      <StandardError
        title={formatMessage(
          'components.common.campaign_eligibility.title.CURRENCY_MISMATCH'
        )}
        subtitle={
          <div styleName="mt-10">
            <Message id="components.common.campaign_eligibility.description.CURRENCY_MISMATCH" />
          </div>
        }
        extraContent={
          <>
            <div styleName="mb-25">
              <Button
                as="a"
                href="/myaccount/money/balances/summary"
                role="button"
              >
                <Message id="components.common.campaign_eligibility.change_primary_currency" />
              </Button>
            </div>
            <div>
              <a href="/pools">
                <Message id="components.common.campaign_eligibility.not_yet" />
              </a>
            </div>
          </>
        }
        iconName="cash"
      />
    )
  }
  return (
    <StandardError
      iconType="warning"
      title={i18n(`${blockType}_title`)}
      subtitle={i18n(`${blockType}_subtitle`, {
        country: countryName,
        email: email,
      })}
      showButton={shouldShowButton}
      backButton={shouldShowButton}
      extraContent={
        blockType === CREATION_BLOCKS.byUnconfirmed ? (
          <a styleName="settings-link" href="/myaccount/settings">
            {i18n('unconfirmed_settings_link')}
          </a>
        ) : (
          undefined
        )
      }
    />
  )
}

export default cssModules(CreateFundraiserError, styles)
