import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../crowdfunding_basics/crowdfunding_basics.less'
import { sendEnsightenEvent } from 'lib/ensighten_analytics'
import { POOL_TYPE } from 'shared/shared_consts'
import { Button } from '@paypalcorp/pp-react'
import { trackEventNameClick } from 'lib/fpti_analytics'
import clsx from 'clsx'
import PersonalBasicsForm from './partials/personalBasicsForm'
import PrivateBasicsForm from './partials/privateBasicsForm'
import CharityBasicsForm from './partials/charityBasicsForm'
import { Message } from '@paypalcorp/worldready-react'

const CrowdfundingBasics = props => {
  const { anyInactiveFieldsTouchedWithError, type } = props

  useEffect(() => {
    props.untouchAll()
    sendEnsightenEvent('MoneyPoolStart')
  }, [])

  const renderForm = () => {
    switch (type) {
      case POOL_TYPE.PERSONAL:
        return <PersonalBasicsForm {...props} />
      case POOL_TYPE.PRIVATE:
        return <PrivateBasicsForm {...props} />
      case POOL_TYPE.CHARITY:
      default:
        return <CharityBasicsForm {...props} />
    }
  }

  return (
    <form
      data-testid="crowdfunding-basics__form"
      onSubmit={props.handleSubmit}
      autoComplete="off"
    >
      {renderForm()}

      <div styleName="form__button">
        <Button
          data-nemo="create-basics-next"
          data-testid="basics-btn-next"
          className={clsx({
            'vx_btn-disabled': anyInactiveFieldsTouchedWithError,
          })}
          disabled={anyInactiveFieldsTouchedWithError}
          onClick={() =>
            trackEventNameClick({
              event_name: 'dw_giving_create_fundraiser_next_pressed',
            })
          }
        >
          <Message id="components.pages.crowdfunding.pool_basics.next" />
        </Button>
      </div>
    </form>
  )
}

CrowdfundingBasics.propTypes = {
  anyInactiveFieldsTouchedWithError: PropTypes.bool.isRequired,
  charity: PropTypes.object,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
  untouchAll: PropTypes.func.isRequired,
}

export default cssModules(CrowdfundingBasics, styles, { allowMultiple: true })
