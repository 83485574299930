import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './postupdate_share.less'
import CrossFadeTransition from 'components/common/transitions/cross_fade'
import ShareUpdateActivity from 'components/common/share_update_activity'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'
import { trackFPTI } from 'lib/fpti_utils'
import Pictograms from 'components/common/pictograms/pictograms'

class PostUpdateShare extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    cdnHost: PropTypes.string,
    campaign: PropTypes.object,
  }

  render() {
    const { campaign } = this.props
    trackFPTI(campaign, 'impression', 'dw_giving_share_update_screen_shown')
    const Component = (
      <div styleName="publish-container">
        <div styleName="img-container-header">
          <Pictograms
            cdnHost={this.props.cdnHost}
            type="multicolored"
            svg="share"
            data-testid="share-img"
          />
        </div>
        <HeadingText size="sm" styleName="title" data-testid="share-title">
          <Message id="components.pages.crowdfunding.postupdate_share.share_title" />
        </HeadingText>
        <BodyText styleName="subtitle" data-testid="share-subtitle">
          <Message id="components.pages.crowdfunding.postupdate_share.share_your_page" />
        </BodyText>
        <div styleName="textarea-wrapper">
          <ShareUpdateActivity
            campaignId={this.props.campaignId}
            featureType="share_update"
          />
        </div>
      </div>
    )

    return <CrossFadeTransition>{Component}</CrossFadeTransition>
  }
}

export default cssModules(PostUpdateShare, styles)
