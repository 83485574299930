import { trackClick, trackImpression } from 'lib/fpti_analytics'
import { decodeCampaignId } from 'shared/campaign_url'
import _get from 'lodash/get'

export function trackFPTI(
  props,
  eventType,
  event,
  method,
  error,
  count,
  updateState,
  order
) {
  const listEvents = [
    'dw_giving_manage_fundraiser_post_update_button_pressed',
    'dw_giving_create_update_screen_shown',
    'dw_giving_create_update_save_pressed',
    'dw_giving_create_update_dismiss_pressed',
    'dw_giving_share_update_screen_shown',
    'dw_giving_share_update_channel_pressed',
    'dw_giving_share_update_dismiss_pressed',
    'dw_giving_organizer_update_list_post_pressed',
    'dw_giving_edit_update_screen_shown',
    'dw_giving_edit_update_save_pressed',
    'dw_giving_edit_update_delete_pressed',
    'dw_giving_edit_update_dismiss_pressed',
    'dw_giving_update_post_success_occurred',
    'dw_giving_post_update_error_screen_shown',
    'dw_giving_post_update_error_dismiss_pressed',
    'dw_giving_post_update_form_error_occurred',
    'dw_giving_post_update_quit_screen_shown',
    'dw_giving_post_update_quit_finish_update_pressed',
    'dw_giving_post_update_quit_cancel_pressed',
    'dw_giving_post_update_quit_dismiss_pressed',
  ]
  const charity = _get(props, 'charity')
  const charityName = _get(charity, 'name')
  const charityId = _get(charity, 'nonprofit_id')
  const fundraiserId = _get(props, 'id')
  const campaignId = decodeCampaignId(fundraiserId)
  const fundraiserExpired = _get(props, 'event_status')
  const fptiPayload = {
    event_name: event,
    flow: charityName ? 'GENEROSITY_NETWORK_CHARITIES' : 'GENEROSITY_NETWORK',
    charityname: charityName ? charityName : '',
    crid: charityId ? charityId : '',
    order: order && Number(order) && Number(order) + 1,
  }
  if (method) {
    fptiPayload.dismiss_method = method
  }
  if (count) {
    fptiPayload.count = count
  }
  if (updateState) {
    fptiPayload.update_state = updateState
  }
  if (listEvents.indexOf(event) > -1) {
    if (fundraiserExpired === 'expired') {
      fptiPayload.fundraiser_expired = 'yes'
    } else if (fundraiserExpired === 'open') {
      fptiPayload.fundraiser_expired = 'no'
    }
  }
  if (error) {
    fptiPayload.error_desc = error.error_desc
    fptiPayload.error_code = error.error_code
  }
  if (order) {
    fptiPayload.order = order
  }
  if (eventType === 'impression') {
    fptiPayload.campaignId = campaignId
    trackImpression(fptiPayload)
  } else if (eventType === 'ac') {
    fptiPayload.campaignId = campaignId
    fptiPayload.event_type = eventType
    trackImpression(fptiPayload)
  } else if (eventType === 'click') {
    fptiPayload.campaign_id = campaignId
    trackClick({
      clickName: event,
      data: fptiPayload,
    })
  }
}

export function organizerUpdateState(organizerUpdate) {
  const organizerUpdateTotal = _get(organizerUpdate, 'total', 0)
  let stateValue = 'multiple'
  if (organizerUpdateTotal === 0) {
    stateValue = 'zero'
  } else if (organizerUpdateTotal === 1) {
    stateValue = 'one'
  }
  return stateValue
}
