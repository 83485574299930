import Cookies from 'js-cookie'
import { USR_TXNS_COOKIE_EXPIRY } from 'shared/shared_consts'
import { txnsCookieKey } from 'shared/cookie_key'

export function saveTxnInCookie(campaignId, userId, txn) {
  const userTxnsCookiesKey = txnsCookieKey(campaignId, userId)
  const rawUserTxns = Cookies.get(userTxnsCookiesKey)
  let userTxns
  try {
    userTxns = rawUserTxns ? JSON.parse(decodeURIComponent(rawUserTxns)) : []
  } catch (err) {
    userTxns = []
  }
  userTxns.push(txn)
  const userTxnExpiryTime = new Date(
    new Date().getTime() + USR_TXNS_COOKIE_EXPIRY
  )
  Cookies.set(userTxnsCookiesKey, JSON.stringify(userTxns), {
    expires: userTxnExpiryTime,
  })
}
