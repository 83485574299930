// This little util helps disable tabbing for a section of the document
// Based on http://stackoverflow.com/questions/5206813/restrict-tabindex-focusing-to-a-section-of-the-page

let tabbedDisabledNodes = []

// Disables tabs for all decendant elements of a given node which can be focusable (i.e. have tabindex > -1)
export function disableTabs(node) {
  ;[...node.getElementsByTagName('*')].forEach(decendant => {
    if (decendant.tabIndex > -1) {
      let tabIndex = decendant.tabIndex
      decendant.tabIndex = -1
      tabbedDisabledNodes.push({ node: decendant, tabIndex })
    }
  })
}

// Disables tabs for all decendant elements of a list of nodes
export function disableTabsForNodes(nodes) {
  tabbedDisabledNodes = []
  nodes.forEach(disableTabs)
}

// Re-enables tabs that were disabled from
export function reEnableTabs() {
  tabbedDisabledNodes.forEach(({ node, tabIndex }) => {
    node.tabIndex = tabIndex
  })
  tabbedDisabledNodes = []
}
