import { CAMPAIGN_CREATED } from './campaign'

export default function reducer(state = {}, action) {
  switch (action.type) {
    case CAMPAIGN_CREATED: {
      return { ...state, has_campaigns: true }
    }
    default:
      return state
  }
}
