import React from 'react'
import { HeadingText, Link } from '@paypalcorp/pp-react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n } from 'intl'
import styles from './activity.less'
import { FullActivityList } from 'components/contributions_list/owner_activity_list'
import ActivityRow from 'components/contributions_list/rows/activity_row'
import { trackClick } from 'lib/fpti_analytics'
import { ACTIVITIES_TRANSACTIONS_PER_PAGE } from 'shared/shared_consts'

const i18n = getI18n('components/edit_glimpse/activity')

class CrowdfundingActivity extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    activitiesCount: PropTypes.string,
  }

  handleFPTI = transactionId => {
    trackClick({
      clickName: 'dw_giving_manage_fundraiser_activity_tile_pressed',
      data: {
        event_name: 'dw_giving_manage_fundraiser_activity_tile_pressed',
        txn_id: transactionId,
      },
    })
  }

  render() {
    return (
      <div>
        <HeadingText styleName="activity-title" size="sm">
          {i18n('crowdfunding_activity.title')}
          {`(${this.props.activitiesCount})`}
        </HeadingText>
        <div styleName="list-container">
          <FullActivityList
            hideEmpty={true}
            showDaysRows={true}
            txnsPerPage={ACTIVITIES_TRANSACTIONS_PER_PAGE}
            totalTxnsCount={this.props.activitiesCount}
            campaignId={this.props.campaignId}
            rowElement={(activityItem, key) => (
              <Link
                styleName="activity-link"
                href={`/myaccount/transactions/details/${
                  activityItem.credit_txn_id
                }`}
                target="_blank"
                onClick={() => {
                  this.handleFPTI(activityItem.credit_txn_id)
                }}
              >
                <ActivityRow {...activityItem} key={key} />
              </Link>
            )}
          />
        </div>
      </div>
    )
  }
}

export default cssModules(CrowdfundingActivity, styles, {
  allowMultiple: true,
})
