import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './paypal_page.less'
import clsx from 'clsx'
import { RenderFooter, RenderHeader } from '../header_footer'
import CrowdfundingBanner from 'components/pages/crowdfunding/crowdfunding_banner'

class PayPalPage extends React.Component {
  static propTypes = {
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    children: PropTypes.any,
    useWhiteBg: PropTypes.bool,
    isLoggedIn: PropTypes.any,
    isPreview: PropTypes.bool,
    paypalDomain: PropTypes.string,
    campaignId: PropTypes.string.isRequired,
    alwaysShowHeaderNavigation: PropTypes.bool.isRequired,
    alwaysShowBanner: PropTypes.bool.isRequired,
    allowEntryBanner: PropTypes.bool,
    banner: PropTypes.any,
  }

  static defaultProps = {
    showHeader: true,
    showFooter: true,
    useWhiteBg: false,
    alwaysShowHeaderNavigation: true,
    alwaysShowBanner: false, //alwaysShowBanner will be true from crowdfunding page
  }

  showHeaderNavigation = () => {
    const headerContent = document.getElementById('data-header')?.innerHTML
    const { showHeader, alwaysShowHeaderNavigation } = this.props
    if (!showHeader) {
      return false
    }

    return alwaysShowHeaderNavigation ? true : !headerContent
  }

  render() {
    const showBanner =
      this.props.allowEntryBanner &&
      !this.props.isPreview &&
      this.props.alwaysShowBanner

    const contentStyles = clsx('content-inside', {
      'no-header': !this.props.showHeader,
      'no-footer': !this.props.showFooter,
      'white-bg': this.props.useWhiteBg,
    })

    return (
      <div>
        {this.props.showHeader && (
          <input
            type="checkbox"
            id="toggleNavigation"
            className="vx_globalNav-toggleCheckbox"
          />
        )}
        {this.props.showHeader && <RenderHeader />}
        <div
          className={'vx_foreground-container foreground-container'}
          styleName="background sticky-footer-container"
        >
          {showBanner && (
            <CrowdfundingBanner
              isLoggedIn={this.props.isLoggedIn}
              {...this.props.banner}
            />
          )}

          <section
            styleName={contentStyles}
            className={
              this.props.allowEntryBanner && this.props.alwaysShowBanner
                ? ''
                : 'vx_mainContent contents'
            }
            id="contents"
            role="main"
          >
            {this.props.children}
            <div styleName="clear" />
          </section>
          {this.props.showFooter && (
            <div styleName="sticky-footer">
              <RenderFooter />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default cssModules(PayPalPage, styles, { allowMultiple: true })
