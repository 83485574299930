import CrowdfundingShare from '../crowdfunding_share'
import { connect } from 'react-redux'
import { createRemotely } from 'redux/modules/campaign'
import { glimpseRoute } from 'lib/route_utils'
import { encodeCampaignId } from 'shared/campaign_url'
import {
  campaignUrlForDisplay,
  campaignUrlWithMeDomain,
} from 'lib/campaign_url'
import {
  getCampaignSelector,
  getLastCreatedCampaignSelector,
} from 'redux/selectors/campaign'
import preventNavigation from 'components/common/prevent_navigation'

const mapStateToProps = (state, props) => {
  const lastCreatedCampaign = getLastCreatedCampaignSelector(state)
  const campaign = lastCreatedCampaign || getCampaignSelector(state, props)
  const campaignId = campaign.id
  const encodedCampaignId = encodeCampaignId(campaignId)

  return {
    campaignId,
    encodedCampaignId,
    operatingRemotely: campaign.operatingRemotely,
    shareUrl: glimpseRoute('create-crowdfunding', 'share/' + encodedCampaignId),
    poolUrl: campaignUrlWithMeDomain(campaign.id, campaign.campaign_type),
    displayUrl: campaignUrlForDisplay(campaign.id, campaign.campaign_type),
    creationError: campaign.err,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onPublish() {
      dispatch(createRemotely(campaignId))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(preventNavigation(CrowdfundingShare, props => props.operatingRemotely))
