import React, { useState } from 'react'
import { Button, DeleteIcon, Link } from '@paypalcorp/pp-react'
import { trackClick, trackImpression } from 'lib/fpti_analytics'
import { useIntl } from 'intl'
import StandardDialog from 'components/common/standard_dialog'
import { Message } from '@paypalcorp/worldready-react'
import PropTypes from 'prop-types'

const DeleteFundraiser = ({
  campaign,
  showDeleteReviewModal,
  onDeleteClick,
  charity,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState()
  const { formatMessage } = useIntl()

  const handledeleteDialogClose = () => {
    trackEvents('dw_giving_delete_fundraiser_cancel_pressed')
    setShowDeleteDialog(false)
  }

  const handleDelete = () => {
    setShowDeleteDialog(true)
    trackEvents('dw_giving_edit_fundraiser_delete_pressed')
    trackImpression({
      event_name: 'dw_giving_delete_fundraiser_screen_shown',
    })
  }

  const trackEvents = eventName => {
    trackClick({
      clickName: eventName,
      data: {
        event_name: eventName,
      },
    })
  }

  const onOkHandler = event => {
    if (campaign.money_box_amount) {
      showDeleteReviewModal()
    } else {
      setShowDeleteDialog(false)
      onDeleteClick()
    }
  }

  return (
    <>
      {showDeleteDialog && (
        <StandardDialog
          size="medium"
          title={formatMessage(
            'components.edit_glimpse.edit_main.delete_title_cf'
          )}
          subtitle={formatMessage(
            `${
              charity
                ? 'components.edit_glimpse.edit_main.delete_subtitle_cf_gnc'
                : 'components.edit_glimpse.edit_main.delete_subtitle_cf'
            }`
          )}
          closeButtonTitle={formatMessage(
            'components.edit_glimpse.edit_main.keep_cf'
          )}
          okButtonTitle={formatMessage(
            'components.edit_glimpse.edit_main.delete_cf'
          )}
          onClose={handledeleteDialogClose}
          onOk={onOkHandler}
          isDelete={true}
          iconName="delete"
          data-testid="delete_fundraiser__delete-dialog"
        />
      )}
      <Button
        data-testid="delete_fundraiser__delete-link"
        as={Link}
        onClick={handleDelete}
        tertiary
        iconComponent={DeleteIcon}
      >
        <Message id="components.edit_glimpse.edit_main.delete_cf_title" />
      </Button>
    </>
  )
}

DeleteFundraiser.propTypes = {
  campaign: PropTypes.object,
  charity: PropTypes.object,
  showDeleteReviewModal: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default DeleteFundraiser
