import React from 'react'
import PropTypes from 'prop-types'
import { CaptionText } from '@paypalcorp/pp-react'

import cssModules from 'react-css-modules'
import styles from '../introduction.less'

import { trackClick } from 'lib/fpti_analytics'
import { GN_DISCLAIMERS, POOL_TYPE } from 'shared/shared_consts'
import { useIntl } from 'intl'

const Disclaimer = ({ event, countryCode, fundraiserType }) => {
  const { formatMessage } = useIntl()
  const { consumerLink } = GN_DISCLAIMERS[countryCode] || ''

  let disclaimerContentKey
  if (fundraiserType === POOL_TYPE.PRIVATE) {
    // This config is just to avoid the lint warning
    // "It is not recommended to have Country specific logic inside the code"
    // and is not in the config.json because there are only 2, with more we could move it
    const introDisclaimerConfig = {
      US: 'flow.private.intro.disclaimer_us_market',
      AU: 'flow.private.intro.disclaimer_us_market',
    }

    const privateDisclaimerContentKey =
      introDisclaimerConfig[countryCode] ||
      'flow.private.intro.disclaimer_eu_market'

    disclaimerContentKey = formatMessage(privateDisclaimerContentKey)
  } else if (fundraiserType === POOL_TYPE.PERSONAL) {
    disclaimerContentKey = formatMessage(
      `components.pages.halfFullSheet.half_full_sheet.disclaimer_gn_common_${countryCode}`,
      { consumerLink }
    )
  } else if (fundraiserType === POOL_TYPE.CHARITY) {
    disclaimerContentKey = formatMessage(
      `components.pages.halfFullSheet.half_full_sheet.disclaimer_gnc_${countryCode}`
    )
  }

  return (
    disclaimerContentKey && (
      <CaptionText
        data-testid="disclaimer-content"
        styleName="sheet__disclaimer"
        onClick={() => {
          if (event.feesDetailsClickEventName) {
            trackClick({
              clickName: event.feesDetailsClickEventName,
              data: {
                event_name: event.feesDetailsClickEventName,
              },
            })
          }
        }}
        dangerouslySetInnerHTML={{ __html: disclaimerContentKey }}
      />
    )
  )
}

Disclaimer.propTypes = {
  event: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  fundraiserType: PropTypes.string.isRequired,
}

export default cssModules(Disclaimer, styles)
