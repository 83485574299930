/* istanbul ignore file */

/**
 * This file is complex to test because it calls many functions
 * on deeper levels, a refactor might be needed, this is why
 * I'm adding the ignore line at the top
 */
import PoolDescription from './crowdfunding_story'
import { reduxForm, initialize, change } from 'redux-form'
import {
  CROWDFUNDING_DESCRIPTION_LENGTH,
  CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
} from 'shared/lengths'
import { required, length, combine, minLength } from 'lib/validators'
import formUtils from 'lib/form_utils'
import { push } from 'react-router-redux'
import { injectWorldReady } from '@paypalcorp/worldready-react'

import { NEW_CAMPAIGN_ID, setData } from 'redux/modules/campaign'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { formatMessage } from 'intl'
import { POOL_TYPE } from 'shared/shared_consts'

const validators = (value, props) =>
  [
    required(
      'description',
      formatMessage(
        props.worldReady,
        'components.pages.crowdfunding.pool_story.description_required'
      )
    ),
    length(
      'description',
      CROWDFUNDING_DESCRIPTION_LENGTH,
      formatMessage(
        props.worldReady,
        `components.pages.crowdfunding.pool_story.description_too_long`,
        {
          maxLength: CROWDFUNDING_DESCRIPTION_LENGTH,
        }
      )
    ),
    props.isTitleInfoAndMinDescriptionEnabled &&
      minLength(
        'description',
        CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
        formatMessage(
          props.worldReady,
          `components.pages.crowdfunding.pool_story.description_too_short`,
          {
            minLength: CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
          }
        )
      ),
  ].filter(Boolean)

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)

  return {
    shouldAutoFocus: props.router.params.action !== 'POP',
    initialValues: {
      description: campaign.description,
    },
    charity: campaign.charity,
    npid: campaign.npid,
    description: campaign.description,
    type: campaign.type,
    isTitleInfoAndMinDescriptionEnabled:
      state.flags.isTitleInfoAndMinDescriptionEnabled &&
      campaign.type === POOL_TYPE.PERSONAL,
  }
}

const mapDispatchToProps = dispatch => {
  // event_name is hardcoded for crowdfunding phase 1
  return {
    onSubmit(formValues) {
      dispatch(
        setData(NEW_CAMPAIGN_ID, {
          description: formValues.description,
          event_name: 'COVID-19',
        })
      )
      dispatch(
        push({
          pathname: `${
            GLOBAL_CONSTS.REQUEST_URI
          }/c/create-crowdfunding/select-cover`,
        })
      )
    },
    initializeForm: data => initialize('crowdfunding_story', data),
    handleChange: (field, value) => change('crowdfunding_story', field, value),
  }
}

export default injectWorldReady(
  reduxForm(
    {
      form: 'crowdfunding_story',
      destroyOnUnmount: false,
      fields: ['description'],
      touchOnBlur: true,
      touchOnChange: false,
      validate: (...data) => combine(...validators(...data))(...data),
    },
    mapStateToProps,
    mapDispatchToProps
  )(formUtils(PoolDescription))
)
