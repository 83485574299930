import React from 'react'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'
import { POOL_TYPE } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import PropTypes from 'prop-types'
import styles from './fundraiser_share_info.less'

export const FundraiserShareInfo = ({
  campaignType,
  hasSocialShare5xEnabled,
  hasSocialShare7xEnabled,
}) => {
  let titleId = 'components.pages.crowdfunding.pool_share.share_title'
  let subTitleId =
    'components.pages.crowdfunding.pool_share.share_your_page_new'
  let shareInfoList = [
    'share_your_page_more_1',
    'share_your_page_more_2',
    'share_your_page_more_3',
  ]

  let shareInfoHeader
  let shareInfoFooter

  if (campaignType === POOL_TYPE.PRIVATE) {
    titleId = 'components.pages.crowdfunding.pool_share.share_title'
    subTitleId = 'flow.private.publish.description'
    shareInfoList = []
  } else if (
    campaignType === POOL_TYPE.PERSONAL &&
    (hasSocialShare5xEnabled || hasSocialShare7xEnabled) //Experiment for social share
  ) {
    shareInfoHeader = 'components.pages.crowdfunding.pool_share.some_share_tips'
    shareInfoFooter =
      'components.pages.crowdfunding.pool_story.personal_tip_box_details_footer'

    //When hasSocialShare5xEnabled is enabled
    titleId =
      'components.pages.crowdfunding.pool_share.share_title_for_personal'
    subTitleId =
      'components.pages.crowdfunding.pool_share.share_page_subheader_raise_5x'

    if (hasSocialShare7xEnabled) {
      titleId =
        'components.pages.crowdfunding.pool_share.share_title_for_personal'
      subTitleId =
        'components.pages.crowdfunding.pool_share.share_page_subheader_raise_7x'
    }
  }

  return (
    <>
      <HeadingText
        date-testid="fundraiser-share-info__heading"
        size="sm"
        className={styles.title}
      >
        <Message id={titleId} />
      </HeadingText>
      <BodyText
        date-testid="fundraiser-share-info__subheading"
        className={styles.subtitle}
      >
        <Message id={subTitleId} />
      </BodyText>
      {shareInfoHeader && (
        <BodyText
          className={styles.mb24}
          date-testid="fundraiser-share-info__info-header"
        >
          <Message id={shareInfoHeader} />
        </BodyText>
      )}
      <ul className={styles.mb24}>
        {shareInfoList.map(key => {
          return (
            <li key={key}>
              <BodyText date-testid="fundraiser-share-info__info-point">
                <Message
                  id={`components.pages.crowdfunding.pool_share.${key}`}
                />
              </BodyText>
            </li>
          )
        })}
      </ul>
      {shareInfoFooter && (
        <BodyText
          className={styles.footerInfo}
          date-testid="fundraiser-share-info__info-footer"
        >
          <Message id={shareInfoFooter} />
        </BodyText>
      )}
    </>
  )
}

FundraiserShareInfo.defaultProps = {
  hasSocialShare7xEnabled: false,
  hasSocialShare5xEnabled: false,
}

FundraiserShareInfo.propTypes = {
  campaignType: PropTypes.string.isRequired,
  hasSocialShare7xEnabled: PropTypes.bool,
  hasSocialShare5xEnabled: PropTypes.bool,
}

export default FundraiserShareInfo
