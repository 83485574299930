import React from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import cssModules from 'react-css-modules'
import styles from './reveal.less'

const DURATION = 300

class RevealTransition extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  }

  render() {
    const transitionNames = {
      enter: styles['reveal-enter'],
      enterActive: styles['reveal-enter-active'],
      leave: styles['reveal-leave'],
      leaveActive: styles['reveal-leave-active'],
    }

    return (
      <ReactCSSTransitionGroup
        transitionName={transitionNames}
        transitionEnterTimeout={DURATION}
        transitionLeaveTimeout={DURATION}
      >
        {this.props.children}
      </ReactCSSTransitionGroup>
    )
  }
}

export default cssModules(RevealTransition, styles)
