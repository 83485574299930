import ShareCampaign from './share_campaign_textarea'
import { connect } from 'react-redux'
import { getShareProps } from 'lib/share'

const mapStateToProps = (state, ownProps) => {
  return getShareProps(state, ownProps)
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareCampaign)
