const RESET_IS_RETURNING_FROM_WALLET =
  'pools/current_user/RESET_IS_RETURNING_FROM_WALLET'

export default function reducer(state = {}, action) {
  switch (action.type) {
    case RESET_IS_RETURNING_FROM_WALLET: {
      return { ...state, isReturningFromWallet: false }
    }
    default:
      return state
  }
}

export function resetIsReturningFromWallet() {
  return { type: RESET_IS_RETURNING_FROM_WALLET }
}
