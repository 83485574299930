import Pledge from './pledge'
import { reduxForm } from 'redux-form'
import { updateRemotely } from 'redux/modules/campaign'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)

  return {
    currency: campaign.currency,
    maxAmount: campaign.max_amount,
    initialValues: {
      pledge: campaign.pledge || 0,
    },
    minAmount: state.flags.min_amount_donation,
    campaignTitle: campaign.title,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onPledge(fields) {
      return dispatch(
        updateRemotely(campaignId, { pledge: fields.pledge || 0 })
      )
    },
  }
}

export default reduxForm(
  {
    form: 'pledge',
    fields: ['pledge'],
    destroyOnUnmount: false,
    touchOnChange: false,
    touchOnBlur: true,
  },
  mapStateToProps,
  mapDispatchToProps
)(Pledge)
