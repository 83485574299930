import React from 'react'
import { CaptionText, Card } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'
import styles from './tip_box_personal.less'

const PersonalTipBox = () => {
  return (
    <Card data-testid="personal-tip-box__container" className={styles.continer}>
      <Card.Content>
        <CaptionText className={styles.header}>
          <Message id="components.pages.crowdfunding.pool_story.personal_tip_box_header" />
        </CaptionText>
        <ul className={styles.header}>
          {[
            'personal_tip_box_details_1',
            'personal_tip_box_details_2',
            'personal_tip_box_details_3',
          ].map(item => (
            <CaptionText key={item} as="li">
              <Message
                id={`components.pages.crowdfunding.pool_story.${item}`}
              />
            </CaptionText>
          ))}
        </ul>
        <CaptionText>
          <Message id="components.pages.crowdfunding.pool_story.personal_tip_box_details_footer" />
        </CaptionText>
      </Card.Content>
    </Card>
  )
}

export default PersonalTipBox
