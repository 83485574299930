import React, { useState } from 'react'
import PropTypes from 'prop-types'
import 'node_modules/@paypalcorp/pp-vx/dist/components/icons/icons.css'
import { Button, Sheet } from '@paypalcorp/pp-react'
import {
  EmailSharing,
  FacebookSharing,
  MessengerSharing,
  SmsSharing,
  TwitterSharing,
  WhatsAppSharing,
  ClipboardSharing,
} from 'components/common/share_buttons'
import ToastContainer from 'components/common/toast_container'
import { TOAST_NOTIFICATIONS } from 'shared/shared_consts'
import * as styles from './share_button.modules.less'
import { trackFPTI } from 'lib/fpti_utils'

const ShareButton = props => {
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const closeSheet = () => {
    if (props.isCopySuccess) {
      props.onHideToast()
    }
    setIsSheetOpen(false)
  }
  const handleOnClick = () => {
    trackFPTI(props.campaign, 'click', 'dw_giving_detail_share_pressed')
    setIsSheetOpen(true)
  }

  const generateOptions = propsToPass => {
    const {
      options = [
        'email',
        'sms',
        'facebook',
        'messenger',
        'twitter',
        'whatsapp',
        'clipboard',
      ],
    } = propsToPass
    return options.map(option => {
      switch (option) {
        case 'email':
          return (
            <li>
              <EmailSharing
                {...propsToPass}
                className={styles.buttonContainer}
                showSubtext
              />
            </li>
          )
        case 'sms':
          return (
            <li>
              <SmsSharing
                {...propsToPass}
                className={styles.buttonContainer}
                showSubtext
              />
            </li>
          )
        case 'facebook':
          return (
            <li>
              <FacebookSharing
                {...propsToPass}
                className={`${styles.buttonContainer} ${styles.facebookColor}`}
                showSubtext
              />
            </li>
          )
        case 'messenger':
          return (
            <li>
              <MessengerSharing
                {...propsToPass}
                className={`${styles.buttonContainer} ${styles.facebookColor}`}
                showSubtext
              />
            </li>
          )
        case 'twitter':
          return (
            <li>
              <TwitterSharing
                {...propsToPass}
                className={`${styles.buttonContainer} ${styles.twitterColor}`}
                showSubtext
              />
            </li>
          )
        case 'whatsapp':
          return (
            <li>
              <WhatsAppSharing
                {...propsToPass}
                className={styles.buttonContainer}
                showSubtext
              />
            </li>
          )
        case 'clipboard':
          return (
            <li>
              <ClipboardSharing
                {...propsToPass}
                className={styles.buttonContainer}
                showSubtext
              />
            </li>
          )
        default:
          return null
      }
    })
  }

  const { secondary = true, text, i18n } = props

  return (
    <>
      {props.isCopySuccess && (
        <ToastContainer
          delayTime={300}
          message={i18n('copy_success')}
          isSuccess={true}
          id={TOAST_NOTIFICATIONS.COPY_SUCCESS}
        />
      )}
      <Button onClick={handleOnClick} secondary={secondary}>
        {text}
      </Button>
      <Sheet
        isOpen={isSheetOpen}
        onClose={closeSheet}
        title={i18n('share_sheet_title')}
        divider
      >
        <ul className={styles.listContainer}>{generateOptions(props)}</ul>
      </Sheet>
    </>
  )
}

ShareButton.propTypes = {
  text: PropTypes.string,
  options: PropTypes.array,
  secondary: PropTypes.bool,
  shareSheetTitle: PropTypes.element,
  shareUrl: PropTypes.string.isRequired,
  displayUrl: PropTypes.string.isRequired,
  shareable: PropTypes.bool.isRequired,
  appId: PropTypes.number.isRequired,
  onClickOverride: PropTypes.func,
  emailBody: PropTypes.string,
  emailSubject: PropTypes.string,
  isMobile: PropTypes.bool,
  charity: PropTypes.object,
  i18n: PropTypes.func,
  isCopySuccess: PropTypes.bool,
  onHideToast: PropTypes.func,
  campaign: PropTypes.object,
}

export default ShareButton
