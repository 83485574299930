import { connect } from 'react-redux'
import DeleteUpdateActivity from './delete_update_activity'
import { deleteOrganizerUpdate } from '../../../../redux/modules/organizer_update'
import { show } from 'redux/modules/toast_notification'

const mapDispatchToProps = dispatch => {
  return {
    deleteOrganizerActivity(campaignId, id) {
      return dispatch(deleteOrganizerUpdate(campaignId, id))
    },
    onToggleToast(toastId, message) {
      dispatch(show(toastId, message))
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(DeleteUpdateActivity)
