import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { Toast } from '@paypalcorp/pp-react'
import { hide } from 'redux/modules/toast_notification'

class ToastContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    message: PropTypes.node,
    duration: PropTypes.number,
    visible: PropTypes.object,
    delayTime: PropTypes.number,
    id: PropTypes.string,
    isSuccess: PropTypes.bool,
  }

  state = {
    show:
      this.props.visible[this.props.id] && this.props.delayTime ? false : true,
  }

  handleClose = () => {
    const { dispatch, id } = this.props
    dispatch(hide(id))
  }

  componentDidMount() {
    const { delayTime } = this.props
    if (delayTime) {
      setTimeout(() => this.setState({ show: true }), delayTime)
    }
  }

  render() {
    const { duration = 7000, id, isSuccess, message } = this.props
    return this.state.show ? (
      <Toast
        id={id}
        message={message}
        isSuccess={isSuccess}
        duration={duration}
        style={{ zIndex: 2010 }}
        onClose={this.handleClose}
      />
    ) : null
  }
}

export default connect(state => ({
  visible: state.toast_notification,
}))(ToastContainer)
