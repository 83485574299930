import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../../crowdfunding_basics/crowdfunding_basics.less'
import DateInputBox from 'components/common/date_input_box'
import CurrencyInputBox from 'components/common/currency_input'
import CharityCard from 'components/common/charity_card'
import { CROWDFUNDING_CAMPAIGN_MAX_DAYS } from 'shared/shared_consts'
import { CaptionText, TextInput, HeadingText } from '@paypalcorp/pp-react'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import { useIntl, getDefaultCurrencyCode } from 'intl'
import { Message } from '@paypalcorp/worldready-react'

const CharityBasicsForm = props => {
  const {
    charity,
    currency,
    disclaimerCharity,
    fields: { poolName, goalAmount, endDate },
    shouldAutoFocus,
  } = props

  const { formatMessage } = useIntl()

  return (
    <>
      <HeadingText size="sm" styleName="title">
        <Message id="components.pages.crowdfunding.pool_basics.title" />
      </HeadingText>

      <div styleName="charity-header">
        <Message id="components.pages.crowdfunding.pool_basics.charity_header" />
      </div>

      <CharityCard charity={charity} />

      <div styleName="textbox-wrapper">
        <TextInput
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.personal_pool_name_title'
          )}
          {...domOnlyProps(poolName)}
          errorText={getFormError(poolName)}
          autoFocus={shouldAutoFocus}
          helperText={formatMessage(
            'components.pages.crowdfunding.pool_basics.tip_description'
          )}
          data-testid="basics-name"
        />
      </div>

      <div styleName="textbox-wrapper">
        <CurrencyInputBox
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.goal_title'
          )}
          dataNemo="goal-amount"
          dataTestId="basics-amount"
          disableCents={true}
          errorText={getFormError(goalAmount)}
          currencyCode={currency || getDefaultCurrencyCode()}
          {...goalAmount}
        />
      </div>

      <div styleName="textbox-wrapper">
        <DateInputBox
          id="end_date"
          label={formatMessage(
            'components.pages.crowdfunding.pool_basics.end_date_title',
            {
              maxDays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
            }
          )}
          dataNemo="date-pool-ends"
          dataTestId="basics-end-date"
          errorText={getFormError(endDate)}
          {...endDate}
          useEndOfDay={false}
        />
      </div>

      {disclaimerCharity && (
        <CaptionText
          styleName="gn-disclaimer"
          dangerouslySetInnerHTML={{ __html: disclaimerCharity }}
        />
      )}
    </>
  )
}

CharityBasicsForm.propTypes = {
  charity: PropTypes.object,
  currency: PropTypes.string,
  disclaimerCharity: PropTypes.string,
  fields: PropTypes.object.isRequired,
  shouldAutoFocus: PropTypes.bool,
}

export default cssModules(CharityBasicsForm, styles)
