import { useEffect, useRef } from 'react'

/**
 * Moves one DOM element to another DOM element through a Ref. If the DOM
 * element ends up orphaned then it is returned to its original parent, but only
 * if the original parent has an id.
 *
 * Useful to move elements rendered by the server. Elements not present in the virtual DOM.
 * @param elementId
 * @returns {{ref: React.MutableRefObject<HTMLElement>}}
 */
export default function useMoveDOMElementToRef(elementId) {
  const ref = useRef()
  useEffect(
    () => {
      const element = document.getElementById(elementId)
      if (element) {
        element.originalParentId =
          element.originalParentId === undefined
            ? element.parentElement?.id
            : element.originalParentId // ??= not supported
        element.remove()
        ref.current.appendChild(element)
      }

      return () => {
        if (element?.originalParentId) {
          element.remove()
          document.getElementById(element.originalParentId).appendChild(element)
        }
      }
    },
    [elementId]
  )

  return { ref }
}
