import CreatePage from './create'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { getBlockType } from 'lib/create_util'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)

  return {
    blockType: getBlockType(state, props, campaign),
    countryName: state.country.country_name,
    creationError: campaign.err,
    email: state.current_user.email,
    hideClose: campaign.operatingRemotely,
    operatingRemotely: campaign.operatingRemotely,
    campaignType: campaign.campaign_type,
    countryCode: state.country.country_code,
    charity: campaign.charity,
    cdnHost: state.geo.cdnHost,
  }
}

export default connect(mapStateToProps)(CreatePage)
