import React from 'react'
import CountryBlockedError from 'components/common/country_blocked'
import { trackEnter } from 'lib/glimpse'
import { isCampaignOwner } from 'lib/is_owner'
import { hasSendChildRoute } from 'lib/contribute'
import { isGNSelector } from 'redux/selectors/campaign'
import { glimpselessRoute, glimpseRoute } from 'lib/route_utils'

export default (store, history) => ({
  path: 'send',
  getComponent(nextState, callback) {
    if (!store.getState().isContributionEligible) {
      return callback(null, {
        glimpse: props => <CountryBlockedError {...props} showClose={true} />,
      })
    }
    return null
  },
  onEnter(nextState, replace) {
    trackEnter('send', nextState, replace)
    const isOwner = isCampaignOwner(store, nextState.params.campaignId)
    const isGN = isGNSelector(store.getState(), {
      campaignId: nextState.params.campaignId,
    })
    const isMobilePledgeRoute = isOwner && isGN

    if (isMobilePledgeRoute) {
      const childRoutes = ['amount', 'nochipin', 'note', 'review']
      if (!hasSendChildRoute(childRoutes, nextState.location.pathname)) {
        replace(glimpseRoute('edit', 'pledge', 'send'))
        return
      }
    }
    replace(glimpselessRoute('send'))
    return
  },
})
