import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ContributionsList from './contributions_list'
import { publicContributionsListSelector } from 'redux/selectors/contributions'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { daysFromToday } from 'shared/helpers/datetime_helpers'
import { injectWorldReady } from '@paypalcorp/worldready-react'
import { formatName } from 'intl'
import PublicContributionRow from './rows/public_contribution_row'
import {
  POOLS_CAMPAIGN_TYPES,
  CONTRIBUTION_TRANSACTIONS_PER_PAGE,
} from 'shared/shared_consts'
import { get } from 'lodash'
import { addPledgeToContributionList } from './helpers/'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

class UnboundPublicContributionsList extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string.isRequired,
    contributions: PropTypes.array,
    txnsPerPage: PropTypes.number.isRequired,
    totalTxnsCount: PropTypes.number.isRequired,
    txnCount: PropTypes.number,
    goalRemaining: PropTypes.number,
    daysRemaining: PropTypes.number,
    currency: PropTypes.string.isRequired,
    ownerId: PropTypes.string.isRequired,
    ownerName: PropTypes.string.isRequired,
    canChipIn: PropTypes.bool.isRequired,
    preview: PropTypes.bool,
    onChipInClick: PropTypes.func,
    hideChipIn: PropTypes.bool,
    pledge: PropTypes.number,
    campaignType: PropTypes.string.isRequired,
    removeExistingCommentFlag: PropTypes.bool,
  }

  static defaultProps = {
    txnCount: 0,
    hideChipIn: false,
  }

  shouldShowCallToChipIn = () => {
    return this.props.hideChipIn
      ? false
      : this.props.canChipIn && this.props.goalRemaining > 0
  }

  shouldSkipRenderingList = () => {
    return !this.shouldShowCallToChipIn() && !this.props.contributions
  }

  render() {
    if (this.shouldSkipRenderingList()) {
      return null
    }
    let contributionList = this.props.contributions
      ? [...this.props.contributions]
      : null
    if (this.props.campaignType === CROWD_FUNDING && this.props.pledge) {
      contributionList = addPledgeToContributionList(this.props)
    }

    return (
      <ContributionsList
        campaignId={this.props.campaignId}
        showTxnsDates={true}
        preview={this.props.preview}
        totalTxnsCount={this.props.totalTxnsCount}
        contributions={contributionList}
        rowElement={(contribution, key) => (
          <PublicContributionRow
            {...contribution}
            key={key}
            preview={this.props.preview}
            removeExistingCommentFlag={this.props.removeExistingCommentFlag}
          />
        )}
      />
    )
  }
}

export default injectWorldReady(
  connect((state, props) => {
    const campaign = getCampaignSelector(state, props)
    let daysRemaining
    if (campaign.end_date != null) {
      daysRemaining = daysFromToday(new Date(campaign.end_date))
    }

    let goalRemaining
    const currentValue = campaign.current_value + campaign.pledge
    if (campaign.goal != null && currentValue != null) {
      goalRemaining = campaign.goal - currentValue
    }

    return {
      contributions: publicContributionsListSelector(state, props),
      txnsPerPage: props.txnsPerPage || CONTRIBUTION_TRANSACTIONS_PER_PAGE,
      txnCount: campaign.txn_count,
      totalTxnsCount: get(
        campaign,
        'transactionSummary.total_number_of_contributions',
        0
      ),
      goalRemaining,
      daysRemaining,
      preview: campaign.preview,
      canChipIn: campaign.can_chip_in,
      pledge: campaign.pledge,
      currency: campaign.currency,
      campaignId: campaign.id,
      ownerId: campaign.owner.id,
      campaignType: campaign.campaign_type,
      removeExistingCommentFlag:
        state.flags && state.flags.removeExistingComment,
      ownerName:
        campaign.owner.business_name ||
        formatName(props.worldReady, {
          givenName: campaign.owner.first_name,
          surname: campaign.owner.last_name,
        }),
    }
  })(UnboundPublicContributionsList)
)
