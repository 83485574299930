import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../share_update_activity/share_update_activity.less'
import Clipboard from 'clipboard'
import {
  Link,
  LinkIcon,
  CheckmarkIcon,
  PAYPAL_THEME,
  CaptionText,
  Row,
  Col,
} from '@paypalcorp/pp-react'
import clsx from 'clsx'
import { Message } from '@paypalcorp/worldready-react'
import {
  EmailSharing,
  TwitterSharing,
  FacebookSharing,
  WhatsAppSharing,
  SmsSharing,
} from 'components/common/share_buttons'
import { trackClick } from 'lib/fpti_analytics'

const TOOLTIP_SHOW_DELAY = 750
const { sysColorSuccessMain } = PAYPAL_THEME

class ShareCampaignTextarea extends React.Component {
  static propTypes = {
    shareUrl: PropTypes.string.isRequired,
    displayUrl: PropTypes.string.isRequired,
    shareable: PropTypes.bool.isRequired,
    appId: PropTypes.number.isRequired,
    onClickOverride: PropTypes.func,
    showFacebook: PropTypes.bool,
    showMessenger: PropTypes.bool,
    showTwitter: PropTypes.bool,
    showWhatsApp: PropTypes.bool,
    showEmail: PropTypes.bool,
    showSms: PropTypes.bool,
    showSubtext: PropTypes.bool,
    description: PropTypes.string,
    styles: PropTypes.object,
    isMobile: PropTypes.bool,
    campaignId: PropTypes.string,
    charity: PropTypes.object,
    eventName: PropTypes.string,
  }

  static defaultProps = {
    shareable: true,
    showFacebook: true,
    showMessenger: true,
    showTwitter: true,
    showWhatsApp: true,
    showEmail: true,
    showSms: true,
    showSubtext: false,
    isMobile: false,
    inline: true,
  }

  state = {
    copyText: this.props.description || this.props.displayUrl,
    showTooltip: false,
    errorMsg: false,
  }

  componentDidMount() {
    if (this.props.shareable) {
      this.clipboard = new Clipboard(this.copyLink, {
        text: () => this.props.shareUrl,
      })
      this.clipboard.on('success', this.handleCopySuccess)
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  copyValidation = () => {
    if (this.state.copyText === '') {
      this.setState({ errorMsg: true })
    } else {
      this.setState({ errorMsg: false })
    }
    trackClick({
      clickName: 'copy',
      data: {
        event_name: this.props.eventName,
        link_name: 'copy',
      },
    })
  }

  handleChange = event => {
    this.setState({ copyText: event.target.value, errorMsg: false })
  }

  handleCopySuccess = e => {
    this.setState({ showTooltip: true })
    setTimeout(() => {
      this.setState({ showTooltip: false })
    }, TOOLTIP_SHOW_DELAY)
  }

  render() {
    const { showTooltip } = this.state

    const commonProps = {
      ...this.props,
      className: styles['share-icon'],
      inline: false,
      showSubtext: true,
      shareText: this.state.copyText,
      eventName: this.props.eventName,
    }

    return (
      <div>
        {this.state.errorMsg && (
          <span data-testid="copy-validation-error" styleName="errorMsg">
            <Message id="components.share_campaign_textarea.error" />
          </span>
        )}
        <Row>
          <Col sm={12} md={4} styleName="share-button-container">
            <EmailSharing {...commonProps} />
          </Col>
          {this.props.isMobile && (
            <Col sm={12} md={4} styleName="share-button-container">
              <SmsSharing {...commonProps} />
            </Col>
          )}
          <Col sm={12} md={4} styleName="share-button-container">
            <FacebookSharing {...commonProps} />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <TwitterSharing {...commonProps} />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <WhatsAppSharing {...commonProps} />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <div
              styleName={clsx('copy-box-wrapper share-icon')}
              style={
                showTooltip ? { backgroundColor: sysColorSuccessMain } : {}
              }
            >
              <Link
                styleName="copy-wrapper"
                data-nemo="copy"
                href="javascript:;"
                ref={copyLink => {
                  this.copyLink = copyLink
                }}
                onClick={this.copyValidation}
                title="copy"
              >
                {showTooltip ? (
                  <CheckmarkIcon size="sm" style={{ color: 'white' }} />
                ) : (
                  <LinkIcon size="sm" />
                )}
              </Link>
              {this.props.showSubtext && (
                <div styleName="sub-text">
                  <Message id="components.share_campaign_textarea.copy" />
                </div>
              )}
            </div>
            <CaptionText
              onClick={() => this.copyLink.click()}
              styleName="share-label share-label-cursor"
            >
              <Message id="components.share_campaign_textarea.copylink" />
            </CaptionText>
          </Col>
        </Row>
      </div>
    )
  }
}

export default cssModules(ShareCampaignTextarea, styles, {
  allowMultiple: true,
})
