import { connect } from 'react-redux'
import CrowdfundingDetailView from 'components/crowdfunding_detail_view'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { getCategoryDefaultCover, getMainCategory } from 'client/lib/category'
import {
  clearDNWTransactionError,
  setIsLoading,
} from 'redux/modules/dnw_transaction'
import { getStagingHostName } from '@paypalcorp/instance-location'
import { publicContributionsListSelector } from 'redux/selectors/contributions'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  const mainCategory = getMainCategory(campaign.category)
  const defaultCategoryCover = getCategoryDefaultCover(state, mainCategory)
  const isLoadingDNWTransaction = state.dnw_transaction.isLoading
  const countryCode = campaign.country_code
  const geo = state.geo
  const isMobile = state.device_info.isMobile
  const paymentsTokenError = state.dnw_transaction.error
  const hasMarketplaceTreatment = state.flags.has_marketplace_treatment
  const hostName = [state.deployEnv.isDev, state.deployEnv.isStage].includes(
    true
  )
    ? getStagingHostName()
    : state.geo.paypalDomain

  return {
    campaign,
    countryCode,
    defaultCategoryCover,
    geo,
    hasMarketplaceTreatment,
    hostName,
    isLoadingDNWTransaction,
    isMobile,
    paymentsTokenError,
    contributions: publicContributionsListSelector(state, ownProps),
  }
}
const mapDispatchToProps = {
  clearDNWTransactionError,
  setIsLoading,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrowdfundingDetailView)
