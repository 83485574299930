import React from 'react'
import cssModules from 'react-css-modules'
import styles from './index.less'
import useMoveDOMElementToRef from './useMoveDOMElementToRef'

export const RenderHeader = cssModules(() => {
  const { ref } = useMoveDOMElementToRef('globalnav-header')
  return (
    <div
      styleName="header__container"
      ref={ref}
      data-testid="header__container"
    />
  )
}, styles)

export const RenderFooter = () => {
  const { ref } = useMoveDOMElementToRef('globalnav-footer')
  return <div ref={ref} data-testid="footer__container" />
}
