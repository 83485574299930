import { isNil } from 'lodash'

export function inverseLerp(start, end, value) {
  return (value - start) / (end - start)
}

/**
 * Returns a safe number from the given value
 * validating that is not null, undefined, an empty string, or not a number.
 *
 * @param {string|number} value - The value to parse.
 * @returns {float} - The given value as float or 0.
 */
export function safeNumber(value) {
  const parsedValue = parseFloat(value)
  if (isNil(value) || value === '' || isNaN(parsedValue)) {
    return 0
  } else {
    return parsedValue
  }
}
