import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './back_button.less'
import { ArrowLeftIcon } from '@paypalcorp/pp-react'
import { KEY_PRESS_EVENTS } from 'shared/shared_consts'

const { ENTER_KEY } = KEY_PRESS_EVENTS

class BackButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  }

  render() {
    return (
      <div
        aria-label="back"
        styleName="back-button"
        data-pa-click="back"
        onClick={this.props.onClick}
        onKeyUp={e => {
          if (e.key === ENTER_KEY) {
            this.props.onClick()
          }
        }}
        role="link"
        tabIndex="0"
        data-testid="back_button"
      >
        <ArrowLeftIcon size="sm" />
      </div>
    )
  }
}

export default cssModules(BackButton, styles)
