import React from 'react'
import cssModules from 'react-css-modules'
import styles from './fundraiser_skeleton.less'

const FundraiserSkeleton = () => {
  return (
    <>
      <div styleName="plaque title" />

      <div styleName="plaque badge" />
      <div styleName="plaque badge" />

      <div styleName="plaque amount" />
      <div styleName="plaque progress-bar" />
      <div styleName="plaque amount-raised" />

      <div styleName="actions">
        <div styleName="actions__action">
          <div styleName="circle" />
          <div styleName="plaque" />
        </div>

        <div styleName="actions__action">
          <div styleName="circle" />
          <div styleName="plaque" />
        </div>

        <div styleName="actions__action">
          <div styleName="circle" />
          <div styleName="plaque" />
        </div>

        <div styleName="actions__action">
          <div styleName="circle" />
          <div styleName="plaque" />
        </div>
      </div>

      <div styleName="plaque activity" />
      <div styleName="plaque activity" />
      <div styleName="plaque activity" />
    </>
  )
}

export default cssModules(FundraiserSkeleton, styles, { allowMultiple: true })
