import clsx from 'clsx'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './collapsible_text.less'
import { Button } from '@paypalcorp/pp-react'
import React, { useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { COLLAPSIBLE_STATE } from 'shared/shared_consts'

/**

A collapsible text component that shows ellipsis after a certain number of lines and can be expanded and collapsed on click.

@component

@param {string} [props.header] - The text to display in the header of the component.
@param {string} [props.footer] - The text to display in the footer of the component.
@param {number} [props.maxLines=5] - The maximum number of lines to show before displaying ellipsis.
@param {boolean} [props.showToggle=true] - Whether or not to show the toggle button to expand and collapse the text.
@param {Object} [props.toggleContent={ open: "", closed: "" }] - The text to display on the toggle button when the text is open or closed.
@param {string} props.toggleContent.open - The text to display on the toggle button when the text is open.
@param {string} props.toggleContent.closed - The text to display on the toggle button when the text is closed.
@param {Object} [props.fpti={ onLoad: ()=>{}, onClick: (isCollapsedState)=>{} }] - FPTI events to be called when the component loads or when the toggle is clicked.
@param {string} props.fpti.onLoad - The FPTI function to be executed when the component loads for the first time.
@param {string} props.fpti.onClick=(isCollapsedState)=>{} - The FPTI function to be executed when the btn toggles to open or close the collapsed text, receives the collapsed state
@param {ReactNode} props.children - The text content to display in the component.
@param {string} props.dataTestId - The ID to identify the wrapper for testing and to set the element's ID for observation.
@return {JSX.Element}
*/

const CollapsibleText = ({
  fpti,
  header,
  footer,
  maxLines,
  children,
  showToggle,
  dataTestId,
  toggleContent,
}) => {
  const [textElement, setTextElement] = useState({})
  const [currentWidth, setCurrentWidth] = useState(0)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [hasEnoughText, setHasEnoughText] = useState(null)
  const toggleButtonText = isCollapsed
    ? toggleContent.closed
    : toggleContent.open
  const overrideFPTI = { ...defaultProps.fpti, ...fpti }
  const toggleCollapsedState = () => {
    const collapsedState = !isCollapsed
    overrideFPTI.onClick(
      collapsedState
        ? COLLAPSIBLE_STATE.COLLAPSED
        : COLLAPSIBLE_STATE.UNCOLLAPSED
    )
    setIsCollapsed(collapsedState)
  }

  const checkTextOverflow = () => {
    setHasEnoughText(textElement.scrollHeight > textElement.clientHeight)
  }

  // If the screen is resized, the description could need a button now
  const checkWrapperWidth = () => {
    setCurrentWidth(textElement.offsetWidth)
  }

  /**
   * For some reason Safari wasn't recognizing the initial dimensions, and read both:
   * scroll and client's height as same, even when they are not when there is a lot
   * of content, this is why we need this observer, this dependency is able to
   * properly recognize the difference between both and is able to set the values
   * that are later used with the useEffect hook
   */
  const ro = new ResizeObserver(entries => {
    for (const entry of entries) {
      const { scrollHeight, clientHeight } = entry.target
      setTextElement({ scrollHeight, clientHeight })
    }
  })

  /**
   * The triggers are required because of the following:
   * currentWidth = A not clamped div could get clamped after resizing the screen like in mWeb
   * isCollapsed  = To make sure a previously clamped div that no longer requires the button, hides it
   *                will no longer need it if it is bigger, like in screen resize
   * textElement  = To check the overflow as soon as the element is painted or if the height changes
   */
  useEffect(
    () => {
      if (isCollapsed) {
        checkTextOverflow()
      }
    },
    [currentWidth, isCollapsed, textElement]
  )

  useEffect(() => {
    overrideFPTI.onLoad()
    ro.observe(document.getElementById(dataTestId))
    window.addEventListener('resize', checkWrapperWidth)
    return () => window.removeEventListener('resize', checkWrapperWidth)
  }, [])

  return (
    <div data-testid={dataTestId}>
      {header && <div styleName="header">{header}</div>}

      <div
        id={dataTestId}
        style={{
          WebkitLineClamp: maxLines,
        }}
        styleName={clsx('collapsible', { collapsed: isCollapsed })}
      >
        {children}
      </div>

      {footer && <div styleName="footer">{footer}</div>}

      {showToggle &&
        hasEnoughText && (
          <div styleName="button">
            <Button tertiary onClick={toggleCollapsedState}>
              {toggleButtonText}
            </Button>
          </div>
        )}
    </div>
  )
}
const defaultProps = {
  header: null,
  footer: null,
  showToggle: true,
  maxLines: 5,
  toggleContent: { open: '', closed: '' },
  fpti: { onLoad: () => {}, onClick: () => {} },
}

CollapsibleText.propTypes = {
  fpti: PropTypes.object,
  header: PropTypes.string,
  footer: PropTypes.string,
  showToggle: PropTypes.bool,
  maxLines: PropTypes.number,
  toggleContent: PropTypes.object,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

CollapsibleText.defaultProps = defaultProps

export default cssModules(CollapsibleText, styles, { allowMultiple: true })
