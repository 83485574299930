import React from 'react'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import routes from './routes'

function createElement(Component, props) {
  return React.createElement(Component, {
    campaignId: props.params.campaignId, // eslint-disable-line react/prop-types
    ...props,
  })
}

export default store => {
  const history = syncHistoryWithStore(browserHistory, store)
  return () => (
    <Router
      history={history}
      routes={routes(store, history)}
      createElement={createElement}
    />
  )
}
