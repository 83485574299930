import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n, formatCurrency, getDefaultCurrencyCode } from 'intl'
import styles from './transfer.less'
import CurrencyInputBox from 'components/common/currency_input'
import TransferReviewBox from '../transfer_review_box'
import { MIN_TRANSFER_AMOUNT } from 'shared/shared_consts'
import { trackClick } from 'lib/fpti_analytics'
import { getFormError } from 'lib/validators'
import { BodyText, Button, HeadingText, Link } from '@paypalcorp/pp-react'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/edit_glimpse/transfer')

class Transfer extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string.isRequired,
    campaignName: PropTypes.string.isRequired,
    moneyboxAmount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    anyInactiveFieldsTouchedWithError: PropTypes.bool,
    onTransferAll: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    shouldAutoFocus: PropTypes.bool,
    onTransfer: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    isCrowdfunding: PropTypes.bool,
    worldReady: PropTypes.Object,
  }

  state = {
    transferAll: false,
  }

  handleTransferAllClick = () => {
    this.props.onTransferAll(this.props.moneyboxAmount)
    this.setState({ transferAll: true })
  }

  handleTransfer = fields => {
    trackClick({
      clickName: 'dw_giving_fundraiser_transfer_confirm_pressed',
      data: {
        event_name: 'dw_giving_fundraiser_transfer_confirm_pressed',
      },
    })
    this.props.onTransfer(fields.transferAmount)
  }

  render() {
    const {
      fields: { transferAmount },
      anyInactiveFieldsTouchedWithError,
      isCrowdfunding,
    } = this.props

    return (
      <form onSubmit={this.props.handleSubmit(this.handleTransfer)}>
        <HeadingText size="sm" styleName="title">
          {i18n('title')}
        </HeadingText>
        <BodyText>
          {i18n(`current_balance${isCrowdfunding ? '_cf' : ''}`)}
          &nbsp;
          {formatCurrency({
            value: this.props.moneyboxAmount,
            currencyCode: this.props.currency,
            additionalConfig: {},
            worldReady: this.props.worldReady,
          })}
        </BodyText>
        <Link
          data-pa-click="transferAll"
          href="javascript:;"
          onClick={this.handleTransferAllClick}
        >
          {i18n('transfer_all')}
        </Link>
        <div styleName="amount-container">
          <CurrencyInputBox
            autoFocus={this.props.shouldAutoFocus}
            label={i18n('amount_title')}
            disableCents={false}
            currencyCode={this.props.currency || getDefaultCurrencyCode()}
            dataNemo="transfer-amount-input"
            {...transferAmount}
            minAmount={MIN_TRANSFER_AMOUNT}
            errorText={getFormError(transferAmount)}
            isControlledComponent={this.state.transferAll}
          />
        </div>

        <TransferReviewBox
          campaignId={this.props.campaignId}
          showAmount={false}
        />

        <div styleName="transfer-btn">
          <Button
            data-nemo="transfer-submit-button"
            className={`${
              anyInactiveFieldsTouchedWithError ? 'vx_btn-disabled' : ''
            }`}
            disabled={anyInactiveFieldsTouchedWithError}
          >
            {i18n('transfer_money')}
          </Button>
        </div>
      </form>
    )
  }
}

export default injectWorldReady(cssModules(Transfer, styles))
