import {
  AMOUNT_TYPES,
  CROWDFUNDING_CAMPAIGN_MAX_DAYS,
  POOL_TYPE,
} from 'shared/shared_consts'
import {
  CROWDFUNDING_DESCRIPTION_LENGTH,
  CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
  TITLE_LENGTH,
} from 'shared/lengths'
import EditMain from './edit_main_cf'
import {
  getTranslatedCategoryList,
  getCategoryLabel,
  filterDepreciatedCategory,
  sortCategory,
} from 'lib/category'
import {
  combine,
  required,
  atMost,
  atLeast,
  length,
  futureDate,
  minLength,
} from 'lib/validators'
import { endDateValidators, maxDaysValidator } from 'lib/end_date_validators'
import { reduxForm } from 'redux-form'
import { deleteRemotely, updateRemotely } from 'redux/modules/campaign'
import formUtils from 'lib/form_utils'
import { getCampaignSelector, isGNSelector } from 'redux/selectors/campaign'
import { show } from 'redux/modules/toast_notification'
import { getMinMaxAmount } from 'shared/helpers/campaign'
import { formatMessage, formatCurrency, getTimeZone } from 'intl'
import { injectWorldReady } from '@paypalcorp/worldready-react'

function stringifyContributionPrefs(campaign, worldReady) {
  const amount = formatCurrency({
    value: campaign.amount,
    currencyCode: campaign.currency,
    additionalConfig: {},
    worldReady,
  })

  switch (campaign.amount_type) {
    case AMOUNT_TYPES.any:
      return formatMessage(worldReady, 'components.edit_glimpse.edit_main.any')
    case AMOUNT_TYPES.at_least:
      return formatMessage(
        worldReady,
        'components.edit_glimpse.edit_main.at_least',
        {
          amount,
        }
      )
    case AMOUNT_TYPES.exactly:
      return formatMessage(
        worldReady,
        'components.edit_glimpse.edit_main.exactly',
        {
          amount,
        }
      )
    default:
      return 'UNKNOWN'
  }
}

function stringifyPublicTrackingPrefs(campaign, worldReady) {
  const octal =
    (campaign.share_progress ? 1 : 0) +
    (campaign.share_contributors ? 2 : 0) +
    (campaign.share_progress && campaign.share_contributions ? 4 : 0)

  return formatMessage(
    worldReady,
    `components.edit_glimpse.edit_main.tracking_prefs_${octal}`
  )
}

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const startDate = campaign.start_date
    ? new Date(campaign.start_date)
    : new Date()
  const isNewCategoryEnabled = state.flags.enable_new_category
  const charity = campaign.charity
  const isTitleInfoAndMinDescriptionEnabled =
    state.flags.isTitleInfoAndMinDescriptionEnabled &&
    campaign.type === POOL_TYPE.PERSONAL

  const amountCommons = {
    value: campaign.amount,
    currencyCode: campaign.currency,
    additionalConfig: {
      removeTrailingZeros: true,
    },
    worldReady: props.worldReady,
  }

  let MIN_AMOUNT
  let MAX_AMOUNT
  let formattedMaxGoalAmount
  let formattedMinGoalAmount = formatCurrency({
    value: state.flags.min_amount_donation,
    ...amountCommons,
  })

  /*
   * Checking elmo for new flow and discoverable type for private fundraiser
   * TODO: DTRAINIER-5332 - Instead of relying on ELMO we should pass the type
	 * and have this populated and passed in the object
   */

  const isPrivateFundraiser = campaign.type === POOL_TYPE.PRIVATE

  let categoryList = getTranslatedCategoryList(state)

  categoryList = filterDepreciatedCategory(categoryList, isNewCategoryEnabled)
  categoryList = sortCategory(categoryList)

  let category = campaign?.category

  let validators = [
    required(
      'description',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_main.description_required'
      )
    ),
    length(
      'description',
      CROWDFUNDING_DESCRIPTION_LENGTH,
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_main.description_too_long',
        {
          maxLength: CROWDFUNDING_DESCRIPTION_LENGTH,
        }
      )
    ),
    length(
      'poolName',
      TITLE_LENGTH,
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_main.pool_name_too_long'
      )
    ),
    required(
      'poolName',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_main.pool_name_is_required_cf',
        { maxCharLength: TITLE_LENGTH }
      )
    ),
    required(
      'endDate',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_end_date.end_date_required_cf'
      )
    ),
    ...endDateValidators(
      'endDate',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_end_date.invalid_date',
        {
          maxdays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
        }
      )
    ),
    maxDaysValidator(
      'endDate',
      startDate,
      CROWDFUNDING_CAMPAIGN_MAX_DAYS,
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.edit_end_date.invalid_date_limit_cf',
        {
          maxdays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
        }
      )
    ),
  ]

  if (campaign && campaign.can_chip_in) {
    validators.push(
      futureDate(
        'endDate',
        formatMessage(
          props.worldReady,
          'components.edit_glimpse.edit_end_date.date_must_be_future'
        )
      )
    )
  }

  if (isTitleInfoAndMinDescriptionEnabled) {
    validators.push(
      minLength(
        'description',
        CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
        formatMessage(
          props.worldReady,
          `components.pages.crowdfunding.pool_story.description_too_short`,
          {
            minLength: CROWDFUNDING_DESCRIPTION_MIN_LENGTH,
          }
        )
      )
    )
  }

  if (isGNSelector(state, props)) {
    const minMaxAmount = getMinMaxAmount(campaign)
    MIN_AMOUNT = minMaxAmount.MIN_AMOUNT
    MAX_AMOUNT = minMaxAmount.MAX_AMOUNT

    formattedMaxGoalAmount = formatCurrency({
      value: MAX_AMOUNT,
      ...amountCommons,
    })

    formattedMinGoalAmount = formatCurrency({
      value: MIN_AMOUNT,
      ...amountCommons,
    })

    validators.push(
      atMost(
        'goalAmount',
        MAX_AMOUNT,
        formatMessage(
          props.worldReady,
          'components.edit_glimpse.edit_main.goal_amount_invalid',
          {
            amount: formattedMaxGoalAmount,
          }
        )
      )
    )
  }

  // For PRIVATE fundraisers goal is expected to be optional
  // and with no minimum
  if (campaign.type !== POOL_TYPE.PRIVATE) {
    validators.push(
      required(
        'goalAmount',
        formatMessage(
          props.worldReady,
          'components.edit_glimpse.edit_main.goal_amount_required_cf',
          { amount: formattedMinGoalAmount }
        )
      ),
      atLeast(
        'goalAmount',
        MIN_AMOUNT || state.flags.min_amount_donation,
        formatMessage(
          props.worldReady,
          'components.edit_glimpse.edit_main.goal_amount_invalid_min',
          {
            amount: formattedMinGoalAmount,
          }
        )
      )
    )
  }

  return {
    validate: combine(...validators),
    initialValues: {
      poolName: campaign.title,
      goalAmount: campaign.goal
        ? campaign.goal && campaign.goal.toString()
        : '',
      endDate: campaign.end_date ? new Date(campaign.end_date) : undefined,
      category: category,
      description: campaign.description,
      backgroundImage: campaign.background_image,
      backgroundPan: campaign.background_pan,
      backgroundImageFilename: campaign.background_image_filename,
      gradientColor: campaign.gradient_color,
    },
    ended: !campaign.can_chip_in,
    contribPrefs: stringifyContributionPrefs(campaign, props.worldReady),
    tracking: stringifyPublicTrackingPrefs(campaign, props.worldReady),
    currency: campaign.currency,
    showDelete: !campaign.preview,
    categoryName: getCategoryLabel(state, campaign.category),
    isAndroid:
      state.device_info.isMobile && state.device_info.deviceOS === 'Android',
    maxDays: CROWDFUNDING_CAMPAIGN_MAX_DAYS,
    maxGoalAmount: MAX_AMOUNT,
    categoryList: categoryList,
    campaign: campaign,
    charity: charity,
    formattedMaxGoalAmount: formattedMaxGoalAmount,
    // use timezone of user session for ppui dateInput & calendar to display
    // correct date after selection
    timeZone: getTimeZone(),
    // Show category field when there is not charity or private fundraiser.
    showCategoryInput: !(charity || isPrivateFundraiser),
    isTitleInfoAndMinDescriptionEnabled,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onSave(formValues) {
      // Patch call when the fundraiser is expired needs to only send "description"
      const data = formValues.isExpired
        ? { description: formValues.description }
        : {
            goal: formValues.goalAmount || 0,
            category: formValues.category,
            description: formValues.description,
            title: formValues.poolName,
            end_date: formValues.endDate,
            gradient_color: formValues.gradientColor,
            background_image: formValues.backgroundImage || '',
            background_image_filename: formValues.backgroundImageFilename,
            background_pan: formValues.backgroundPan,
          }
      return dispatch(updateRemotely(campaignId, data))
    },
    onToggleToast(toastId) {
      dispatch(show(toastId))
    },
    onDelete() {
      return dispatch(deleteRemotely(campaignId))
    },
  }
}

export default injectWorldReady(
  reduxForm(
    {
      form: 'edit',
      fields: [
        'poolName',
        'goalAmount',
        'endDate',
        'category',
        'description',
        'backgroundImage',
        'backgroundImageFilename',
        'backgroundPan',
        'gradientColor',
      ],
    },
    mapStateToProps,
    mapDispatchToProps
  )(formUtils(EditMain))
)
