import { getStore } from './store'
import _get from 'lodash/get'
import {
  DISCOVERABLE,
  NON_DISCOVERABLE,
  POOL_TYPE,
  AUDIENCE,
  PRODUCT_TYPES,
} from '../../shared/shared_consts'
import { decodeCampaignId } from '../../shared/campaign_url'
import { NEW_CAMPAIGN_ID } from '../redux/modules/campaign'
import { sanitizePathnameForFpti } from '../redux/analytics'

function getCurrentCampaign() {
  const store = getStore()
  const currentCampaignId = _get(store, 'campaign.selectedCampaignId')
  return _get(store, `campaign.${currentCampaignId}`)
}

/**
 * Retrieves the charity type from the current campaign in the store.
 * @returns {string|null} The charity type of the current campaign, or null if it is not found.
 */

function getCharityType() {
  return _get(getCurrentCampaign(), 'charity.charity_type', '')
}

function getAudience() {
  const type = _get(getCurrentCampaign(), 'type', '')

  if (type) {
    const discoverable =
      type === POOL_TYPE.PRIVATE ? NON_DISCOVERABLE : DISCOVERABLE

    return AUDIENCE[discoverable]
  }

  return ''
}

function getCampignId() {
  const store = getStore()
  const currentCampaignId = _get(store, 'campaign.selectedCampaignId')
  return currentCampaignId && currentCampaignId !== NEW_CAMPAIGN_ID
    ? decodeCampaignId(currentCampaignId)
    : ''
}

function getFlow() {
  const type = _get(getCurrentCampaign(), 'type', '')
  switch (type) {
    case POOL_TYPE.CHARITY:
      return PRODUCT_TYPES.GENEROSITY_NETWORK_CHARITIES

    case POOL_TYPE.PERSONAL:
    case POOL_TYPE.PRIVATE:
      return PRODUCT_TYPES.GENEROSITY_NETWORK

    default:
      return ''
  }
}

function getCharityName() {
  return _get(getCurrentCampaign(), 'charity.name', '')
}

function getCRID() {
  return _get(getCurrentCampaign(), 'charity.nonprofit_id')
}

function getEventSource() {
  const store = getStore()
  return _get(store, 'device_info.isMobile') ? 'mobile' : 'web'
}

function getUserAccountCountry() {
  const store = getStore()
  return _get(store, 'user_config.country', '')
}

function getUserAccountType() {
  const store = getStore()
  return _get(store, 'current_user.accountType', '')
}

function getFundraiserStatus() {
  return _get(getCurrentCampaign(), 'event_status', '')
}

function getPageName() {
  const store = getStore()
  const pathname = window.location.pathname
  const { fptiPageName } = sanitizePathnameForFpti(store, pathname)
  return fptiPageName
}

/**
 * Adds global properties to the payload object.
 * @param {object} payload - The payload object to add global properties to.
 * @returns {object} The updated payload object with global properties added.
 */
export function addGlobalProperties(payload) {
  const globalProperties = {
    pageName: getPageName(),
    charity_type: getCharityType(),
    audience: getAudience(),
    campaign_id: getCampignId(),
    flow: getFlow(),
    charityname: getCharityName(),
    crid: getCRID(),
    npid: getCRID(),
    event_source: getEventSource(),
    acct_cntry: getUserAccountCountry(),
    account_type: getUserAccountType(),
    comp: 'poolsnodeweb',
    fundraiser_expired: getFundraiserStatus() === 'expired' ? 'yes' : 'no',
  }

  if (payload.data) {
    return {
      ...payload,
      data: { ...globalProperties, ...payload.data },
    }
  } else {
    return { ...globalProperties, ...payload }
  }
}
