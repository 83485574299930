import React from 'react'
import {
  HeadingText,
  Button,
  Link,
  BodyText,
  IconButton,
  AddIcon,
} from '@paypalcorp/pp-react'
import { Link as RouterLink } from 'react-router'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './updates.less'
import UpdateRow from 'components/contributions_list/rows/update_row'
import { glimpseRoute } from 'lib/route_utils'
import _ from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import Pictograms from 'components/common/pictograms'
import { trackFPTI } from 'lib/fpti_utils'

class CrowdfundingActivity extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    activitiesCount: PropTypes.string,
    cdnHost: PropTypes.string,
    organizerUpdate: PropTypes.object,
    showLatest: PropTypes.bool,
    charity: PropTypes.object,
    params: PropTypes.object,
    campaign: PropTypes.object,
  }
  render() {
    const {
      campaignId,
      organizerUpdate,
      showLatest,
      cdnHost,
      charity,
      campaign,
    } = this.props
    const postUpdateUrl = `c/${campaignId}/postupdate`
    const viewpostUpdateUrl = `${postUpdateUrl}/viewupdates`
    const organizerUpdatesCount = _.get(organizerUpdate, 'total') || 0
    let updateState = 'multiple'
    if (organizerUpdatesCount === 0) {
      updateState = 'zero'
    } else if (organizerUpdatesCount === 1) {
      updateState = 'one'
    }
    return (
      <>
        <div styleName="update-heading">
          <HeadingText size="sm" styleName="title" data-testid="title">
            <Message id="components.edit_glimpse.updates_activity.title" />
          </HeadingText>
          {organizerUpdatesCount > 0 && (
            <>
              <Button
                size="lg"
                secondary
                iconComponent={AddIcon}
                as={RouterLink}
                to={glimpseRoute('pools', postUpdateUrl)}
                className="hidden-xs"
                data-testid="postupdate-icon"
                onClick={() => {
                  trackFPTI(
                    campaign,
                    'click',
                    'dw_giving_manage_fundraiser_post_update_button_pressed',
                    '',
                    '',
                    '',
                    updateState
                  )
                }}
              >
                <Message id="components.edit_glimpse.updates_activity.post_update" />
              </Button>
              <div className="visible-xs">
                <IconButton
                  as={RouterLink}
                  styleName="postupdate-icon"
                  to={glimpseRoute('pools', postUpdateUrl)}
                  size="lg"
                  buttonType="secondary"
                  svgIconComponent={AddIcon}
                  data-testid="postupdate-icon"
                  onClick={() => {
                    trackFPTI(
                      campaign,
                      'click',
                      'dw_giving_manage_fundraiser_post_update_button_pressed',
                      '',
                      '',
                      '',
                      updateState
                    )
                  }}
                />
              </div>
            </>
          )}
        </div>
        {organizerUpdatesCount > 0 ? (
          <UpdateRow
            organizerUpdate={organizerUpdate}
            postUpdateUrl={postUpdateUrl}
            showLatest={showLatest}
            truncateDescription={true}
            truncateSubject={true}
            charity={charity}
            updateState={updateState}
            campaignId={campaignId}
            campaign={campaign}
            eventName="dw_giving_manage_fundraiser_update_item_pressed"
          />
        ) : (
          <div styleName="activity-empty" size="xs">
            <Pictograms
              classname="empty-image"
              alt=""
              cdnHost={cdnHost}
              type="multicolored"
              svg="announce"
              data-testid="empty-image-url"
            />
            <BodyText styleName="empty_text" data-testid="empty_text">
              <Message id="components.edit_glimpse.updates_activity.empty_text" />
            </BodyText>
            <Button
              size="sm"
              styleName="empty-post-button"
              as={RouterLink}
              to={glimpseRoute('pools', postUpdateUrl)}
              data-testid="empty-post-button"
              onClick={() => {
                trackFPTI(
                  campaign,
                  'click',
                  'dw_giving_manage_fundraiser_post_update_button_pressed',
                  '',
                  '',
                  '',
                  updateState
                )
              }}
            >
              <Message id="components.edit_glimpse.updates_activity.post_update" />
            </Button>
          </div>
        )}

        {organizerUpdatesCount > 1 && (
          <div styleName="show-more">
            <Link
              as={RouterLink}
              to={glimpseRoute('pools', viewpostUpdateUrl)}
              data-testid="show_more"
              onClick={() => {
                trackFPTI(
                  campaign,
                  'click',
                  'dw_giving_manage_fundraiser_show_previous_update_pressed',
                  '',
                  '',
                  '',
                  updateState
                )
              }}
            >
              <Message id="components.edit_glimpse.updates_activity.show_more_action" />
            </Link>
          </div>
        )}
      </>
    )
  }
}

export default cssModules(CrowdfundingActivity, styles)
