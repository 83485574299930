import { getStore } from 'client/lib/store'

import { SUBSCRIPTION_TYPES } from 'shared/shared_consts'

const store = getStore()

export const CFPB_STRING_ID_BASE_EXTENSION = 'cfpb'

export const CFPB_STRING_ID_EXTENSIONS = Object.assign(
  {},
  ...Object.entries(SUBSCRIPTION_TYPES).map(
    ([productSubscriptionKey, productSubscription]) => {
      return {
        [productSubscription]: productSubscription.toLowerCase(),
      }
    }
  )
)

/**
 * Converts i18n stringId key into the CFPB equivalent (when applicable)
 *
 * Any product subscription type passed from the server identifies
 * that the current user is under CFPB jurisdiction and should see CFPB
 * dynamic content, otherwise the product subscription type will be
 * undefined and the user should NOT see CFPB dynamic content
 *
 * For accounts that should see CFPB dynamic content the account product
 * subscription type determines the specific CFPB translation
 *
 * @param {string} stringId - i18n stringId key
 * @returns {string} - if the current user is under CFPB jurisdiction then
 *                     an i18n stringId key that has been transformed
 *                     into the CFPB equivalent, otherwise the original
 *                     i18n stringId key
 */
export function toCFPBStringId(stringId) {
  const productSubscriptions = Object.entries(SUBSCRIPTION_TYPES).map(
    ([productSubscriptionKey, productSubscription]) => productSubscription
  )

  const productSubscription =
    store.current_user && store.current_user.productSubscription

  if (productSubscriptions.includes(productSubscription)) {
    return `${stringId}_${CFPB_STRING_ID_BASE_EXTENSION}_${
      CFPB_STRING_ID_EXTENSIONS[productSubscription]
    }`
  } else {
    return stringId
  }
}
