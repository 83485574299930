import { createSelector } from 'reselect'

import { isObject } from 'shared/helpers/obj_helpers'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import {
  POOLS_CAMPAIGN_TYPES,
  POOLS_STATUS,
  USER_CONSENT_STATUS,
} from 'shared/shared_consts'
import { getMainCategory } from 'client/lib/category'
import { hoursFromToday } from 'shared/helpers/datetime_helpers'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export const getCurrentUserId = state => {
  return state.current_user && state.current_user.id
    ? state.current_user.id
    : ''
}

export const getActiveUserConsent = state => {
  const userConsent = state.current_user?.user_consent?.user_consent_data
  return userConsent?.status === USER_CONSENT_STATUS.PENDING ? userConsent : {}
}

export const getCampaigns = state => state.campaign

export const getCampaignFromProps = (state, { campaignId }) => {
  return state.campaign[campaignId]
}
const getCampaignFromLastCreationId = state =>
  state.campaign[state.campaign.last_creation_id]

export const getLastCreatedCampaignSelector = createSelector(
  getCampaignFromLastCreationId,
  campaign => campaign
)

export const getCampaignSelector = createSelector(
  getCampaignFromProps,
  campaign => campaign
)

export const getNewCampaignSelector = createSelector(
  getCampaigns,
  campaigns => {
    return campaigns[NEW_CAMPAIGN_ID]
  }
)

function isValidCampaign(campaign) {
  if (!isObject(campaign)) {
    return false
  }

  if (campaign.deleted || campaign.id === NEW_CAMPAIGN_ID) {
    return false
  }

  return true
}

export const allCampaignsSelector = createSelector(getCampaigns, campaigns => {
  let allCampaigns = Object.values(campaigns).filter(isValidCampaign)
  allCampaigns.forEach(campaign => {
    campaign.category = getMainCategory(campaign.category)
  })
  return getAllCampaign(allCampaigns)
})

export const isGNSelector = createSelector(getCampaignSelector, campaign => {
  const hasCrowdfinding = Boolean(
    campaign && campaign.campaign_type === CROWD_FUNDING
  )
  const hasCharity = Boolean(campaign && campaign.charity)

  if (hasCharity) {
    return false
  }
  return hasCrowdfinding
})

export const isGNCSelector = createSelector(getCampaignSelector, campaign => {
  const hasCrowdfinding = Boolean(
    campaign && campaign.campaign_type === CROWD_FUNDING
  )
  const hasCharity = Boolean(campaign && campaign.charity)

  if (!hasCharity) {
    return false
  }
  return hasCrowdfinding
})

export const getCampaignCategorySelector = createSelector(
  getCampaignFromProps,
  campaign => {
    if (!campaign || !campaign.category) {
      return undefined
    }
    return campaign.category
  }
)

export const getPersonalizationStudioBannerSelector = createSelector(
  state => state.personalizationStudio.banner,
  banner => banner || {}
)

export const getAllowEntryBannerSelector = createSelector(
  getPersonalizationStudioBannerSelector,
  banner => {
    const { bannerIcon, bannerButtonText, bannerLink, bannerTitleText } = banner
    return Boolean(
      bannerIcon && bannerButtonText && bannerLink && bannerTitleText
    )
  }
)

/*
Open Fundraisers
  1. Fundraisers ending in next 48 hrs order by end time ascending.
  2. Inactive GN Fundraisers with balance in descending order of balance.
  3. Active Fundraisers ascending order of end date.
  Closed Fundraisers
    1. Fundraisers ended in descending order of expiry_date after the money is transferred
*/
export function getAllCampaign(allCampaigns) {
  let fundraisersExpiringIn48Hours = []
  let fundraisersEndedWithBalance = []
  let activeFundraisersMoreThan48Hours = []
  let closedFundraisers = []

  allCampaigns.forEach(campaign => {
    let hoursToExpire = hoursFromToday(new Date(campaign.end_date))
    let campaignBalance = campaign.moneybox_amount
      ? campaign.moneybox_amount
      : 0

    if (hoursToExpire <= 48 && hoursToExpire >= 0) {
      campaign.event_status = POOLS_STATUS.EXPIRE_SOON
      fundraisersExpiringIn48Hours.push(campaign)
    } else if (campaignBalance > 0 && hoursToExpire < 0) {
      campaign.event_status = POOLS_STATUS.OPEN
      fundraisersEndedWithBalance.push(campaign)
    } else if (hoursToExpire > 48) {
      campaign.event_status = POOLS_STATUS.OPEN
      activeFundraisersMoreThan48Hours.push(campaign)
    } else {
      campaign.event_status = POOLS_STATUS.EXPIRED
      closedFundraisers.push(campaign)
    }
  })
  //Fundraisers ending in next 48 hrs order by end time ascending.
  fundraisersExpiringIn48Hours.sort(
    (campaign1, campaign2) =>
      new Date(campaign1.end_date).valueOf() -
      new Date(campaign2.end_date).valueOf()
  )
  //Inactive GN Fundraisers with balance in descending order of balance.
  fundraisersEndedWithBalance.sort(
    (campaign1, campaign2) =>
      campaign2.moneybox_amount - campaign1.moneybox_amount
  )
  //Active Fundraisers ascending order of end date.
  activeFundraisersMoreThan48Hours.sort(
    (campaign1, campaign2) =>
      new Date(campaign1.end_date).valueOf() -
      new Date(campaign2.end_date).valueOf()
  )
  //Fundraisers ended in descending order of expiry_date after the money is transferred
  closedFundraisers.sort(
    (campaign1, campaign2) =>
      new Date(campaign2.end_date).valueOf() -
      new Date(campaign1.end_date).valueOf()
  )
  return {
    openFundraisers: [
      ...fundraisersExpiringIn48Hours,
      ...fundraisersEndedWithBalance,
      ...activeFundraisersMoreThan48Hours,
    ],
    closedFundraisers,
  }
}

/**
 * Get an integer from how many valid campaigns are in the state
 *
 * @param {object} state
 * @returns {int} Amount of valid campaigns
 */
export function getCampaignCount(state) {
  const campaigns = getCampaigns(state)
  const excludeKeys = ['undefined']
  const filteredCampaigns = Object.keys(campaigns).filter(
    key => isValidCampaign(campaigns[key]) && !excludeKeys.includes(key)
  )

  return filteredCampaigns.length
}
