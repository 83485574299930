import React from 'react'
import edit from './edit'
import postUpdate from './post_update'
import send from './send'
import guest from './guest'
import CampaignNotFound from 'components/pages/campaign_not_found'
import CountryBlocked from 'components/common/country_blocked'
import CrowdFundingDetailsPage from 'components/pages/crowdfunding/crowdfunding_details'
import ManageAllPage from 'components/pages/hub/manage_all_page'
import ViewUpdatesActivity from 'components/glimpses/edit_glimpse/viewupdates'
import { getCampaignSelector } from 'redux/selectors/campaign'
import createRoute from './create'
import createCrowdfundingRoute from './create_crowdfunding'
import hubRoute from './hub'
import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'
import {
  buildTransaction,
  createDNWTransactionSuccess,
  createDNWTransactionFailure,
  setIsLoading,
} from 'redux/modules/dnw_transaction'
import EditGlimpse from 'components/glimpses/edit_glimpse'
import OrganizerUpdateDetail from 'components/glimpses/edit_glimpse/organizer_update_detail'

export default (store, history, path) => ({
  indexRoute: hubRoute(store, history),
  path,
  childRoutes: [
    createRoute(store),
    createCrowdfundingRoute(store),
    {
      path: ':campaignId',
      onEnter(nextState) {
        const queryToken = nextState.location.query.token
        const isCancel = nextState.location.query.type === 'cancel'
        const prevTxnCount = nextState.location.query.txn_count
          ? Number(nextState.location.query.txn_count)
          : 0
        const campaign = getCampaignSelector(store.getState(), {
          campaignId: nextState.params.campaignId,
        })
        const txnCount = campaign ? campaign.txn_count : undefined
        const shouldRequestTnxDetails =
          prevTxnCount === txnCount && Boolean(queryToken) && !isCancel

        if (shouldRequestTnxDetails) {
          store.dispatch(setIsLoading(true))
          return fetchClient()
            .request(
              routes.campaign.api.get_payments_token_transaction({
                id: queryToken,
              }),
              {
                method: 'GET',
              }
            )
            .then(({ anonymous, sender, transaction_details }) => {
              const campaignId = nextState.params.campaignId
              store.dispatch(
                createDNWTransactionSuccess(
                  buildTransaction({
                    anonymous: anonymous,
                    contributor_name: sender.full_name,
                    campaign_id: campaignId,
                    date: new Date().toISOString(),
                    transaction_details: {
                      ...transaction_details,
                    },
                  }),
                  sender,
                  prevTxnCount
                )
              )
            })
            .catch(error => {
              store.dispatch(createDNWTransactionFailure(error))
              return error
            })
            .finally(() => {
              history.replace(nextState.location.pathname)
            })
        } else if (nextState.location.query.txn_count) {
          // when a user cancels DNW flow the return url will have query param txn_count
          // we remove it here
          history.replace(nextState.location.pathname)
        }
      },
      getComponent(nextState, callback) {
        if (!store.getState().user_config) {
          return callback(null, CountryBlocked)
        }
        const campaign = getCampaignSelector(store.getState(), {
          campaignId: nextState.params.campaignId,
        })
        if (!campaign) {
          return callback(null, CampaignNotFound)
        }

        if (nextState.location.pathname.indexOf('postupdate') > 0) {
          return callback(null, ManageAllPage)
        }
        if (nextState.location.pathname.indexOf('edit') === -1) {
          return callback(null, CrowdFundingDetailsPage)
        }
        return callback(null, ManageAllPage)
      },
      childRoutes: [
        edit(store, history),
        postUpdate(store, history),
        send(store, history),
        guest(store, history),
        {
          path: 'viewupdates',
          component: { glimpse: EditGlimpse },
          indexRoute: {
            getComponent(nextState, callback) {
              return callback(null, props => (
                <ViewUpdatesActivity {...props} isDonerView={true} />
              ))
            },
            onEnter(nextState, replace) {
              if (!store.getState()?.flags?.isOrganizerUpdatesEnabled) {
                replace('/pools')
                return
              }
              nextState.params.hideLogo = true
              nextState.params.hideButton = true
              nextState.params.isOwnerView = true
            },
          },
          childRoutes: [
            {
              path: ':fouId/updatedetails',
              getComponent(nextState, callback) {
                return callback(null, props => (
                  <OrganizerUpdateDetail {...props} isDonerView={true} />
                ))
              },
              onEnter(nextState, replace) {
                nextState.params.enableEditButton = false
                nextState.params.hideLogo = true
                nextState.params.hideButton = true
              },
            },
          ],
        },
      ],
    },
  ],
})
