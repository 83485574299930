import TabLayout from './crowdfunding_tab_layout'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { publicContributionsListSelector } from 'redux/selectors/contributions'
import { get } from 'lodash'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  return {
    charity: campaign.charity,
    organizerUpdate: campaign.organizerUpdate,
    cdnHost: state.geo.cdnHost,
    contributions: publicContributionsListSelector(state, ownProps),
    contributionsCount: get(
      campaign,
      'transactionSummary.total_number_of_contributions'
    ),
    pledge: campaign.pledge,
    ...ownProps,
    campaign,
  }
}

export default connect(mapStateToProps)(TabLayout)
