import { ONE_DAY_IN_MILLISECONDS } from 'shared/shared_consts'

export function length(field, maxLength, message) {
  return values => {
    if (values[field] && values[field].length > maxLength) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function minLength(field, minRequiredLength, message) {
  return values => {
    if (values[field]?.length < minRequiredLength) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function required(field, message) {
  return values => {
    const value = values[field]
    if (!value || (typeof value === 'string' && value.match(/^\s*$/))) {
      return { [field]: [message] }
    } else {
      return {}
    }
  }
}

export function regex(field, rx, message) {
  return values => {
    if (!values[field]) {
      return {}
    }
    if (!rx.test(values[field])) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function futureDate(field, message) {
  return values => {
    const now = new Date()
    const dateInQuestion = values[field]

    // if it's not a date, user is probably still typing, so ignore that
    if (!(dateInQuestion instanceof Date)) {
      return {}
    }

    if (dateInQuestion.getTime() < now.getTime()) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function futureCreationEndDate(field, message) {
  return values => {
    const now = new Date()
    const dateInQuestion = values[field]
    const dateAtLeast24HrsFromNow = now.getTime() + ONE_DAY_IN_MILLISECONDS

    // if it's not a date, user is probably still typing, so ignore that
    if (!(dateInQuestion instanceof Date)) {
      return {}
    }

    // this func is used soley for creation end date validation
    // end_date can not be set on current day must be 24hrs in future
    if (dateInQuestion.getTime() < dateAtLeast24HrsFromNow) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function atMost(field, value, message) {
  return values => {
    if (values[field] && values[field] > value) {
      return { [field]: [message] }
    }
    return {}
  }
}

export function atLeast(field, value, message) {
  return values => {
    if (values[field] && values[field] < value) {
      return { [field]: [message] }
    }
    return {}
  }
}

function mergeErrors(err1, err2) {
  let result = { ...err1 }
  for (const field in err2) {
    // TODO: the `no-extra-parens` rule will be fixed in ESLint 4 to work well with spread operators.
    // eslint-disable-next-line no-extra-parens
    result[field] = [...(result[field] || []), ...(err2[field] || [])]
  }

  return result
}

export function combine(...validators) {
  return values => {
    let errors = {}
    for (const validator of validators) {
      errors = mergeErrors(errors, validator(values))
    }
    return errors
  }
}

export function getFormError(field) {
  let error = field.error
  let hasError = false
  if (error) {
    if (!Array.isArray(error)) {
      error = [error]
    }
    error = error.filter(e => !!e)
    hasError = error.length > 0
  }
  return hasError && field.touched ? error[0] : ''
}
