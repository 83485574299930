import PostUpdate from './post_update_cf'
import { reduxForm } from 'redux-form'
import formUtils from 'lib/form_utils'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { show } from 'redux/modules/toast_notification'
import { injectWorldReady } from '@paypalcorp/worldready-react'
import { formatMessage } from 'intl'
import { combine, required, length } from 'lib/validators'
import { TITLE_LENGTH, CROWDFUNDING_DESCRIPTION_LENGTH } from 'shared/lengths'
import {
  getOrganizerUpdate,
  editOrganizerUpdate,
  createOrganizerUpdate,
} from 'redux/modules/organizer_update'
import { organizerUpdate, formUpdatePayload } from 'lib/organizerupdate'
import _ from 'lodash'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const organizerUpdateItems = _.get(campaign, 'organizerUpdate.items') || []
  const organizerUpdateId = _.get(props, 'routeParams.fouId') || ''
  const postupdate = organizerUpdate(organizerUpdateId, organizerUpdateItems)

  const subject = _.get(postupdate, 'subject') || ''
  const text = _.get(postupdate, 'text') || ''
  const validators = [
    required(
      'text',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.post_update.text_required'
      )
    ),
    length(
      'text',
      CROWDFUNDING_DESCRIPTION_LENGTH,
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.post_update.text_too_long',
        { maxLength: CROWDFUNDING_DESCRIPTION_LENGTH }
      )
    ),
    length(
      'subject',
      TITLE_LENGTH,
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.post_update.subject_too_long',
        { maxLength: TITLE_LENGTH }
      )
    ),
    required(
      'subject',
      formatMessage(
        props.worldReady,
        'components.edit_glimpse.post_update.subject_required'
      )
    ),
  ]
  return {
    validate: combine(...validators),
    initialValues: {
      subject: subject,
      text: text,
    },
    showDelete: organizerUpdateId,
    organizerUpdateId: organizerUpdateId,
    campaignId: props.campaignId,
    campaignError: campaign.err,
    campaign: campaign,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onSave(formValues, organizerUpdateId) {
      if (organizerUpdateId) {
        const formattedPayload = formUpdatePayload(formValues)
        return dispatch(
          editOrganizerUpdate(campaignId, organizerUpdateId, formattedPayload)
        )
      } else {
        return dispatch(
          createOrganizerUpdate({
            campaignId,
            subject: formValues.subject,
            text: formValues.text,
          })
        )
      }
    },
    onToggleToast(toastId, message) {
      dispatch(show(toastId, message))
    },
    fetchOrganizerUpdateList(page = 1, size = 1) {
      return dispatch(
        getOrganizerUpdate(campaignId, { page, size, campaignId })
      )
    },
  }
}

export default injectWorldReady(
  reduxForm(
    {
      form: 'postupdate',
      fields: ['subject', 'text'],
    },
    mapStateToProps,
    mapDispatchToProps
  )(formUtils(PostUpdate))
)
