import CrowdfundingShare from './crowdfunding_share'
import { connect } from 'react-redux'
import { createRemotely } from 'redux/modules/campaign'
import { campaignUrlWithMeDomain } from 'lib/campaign_url'
import {
  getCampaignSelector,
  getLastCreatedCampaignSelector,
} from 'redux/selectors/campaign'
import preventNavigation from 'components/common/prevent_navigation'
import { POOL_TYPE } from 'shared/shared_consts'
import { injectWorldReady } from '@paypalcorp/worldready-react'
import { generateShareMessage } from '../../../../lib/generate_share_message'

const mapStateToProps = (state, props) => {
  const lastCreatedCampaign = getLastCreatedCampaignSelector(state)
  const campaign = lastCreatedCampaign || getCampaignSelector(state, props)
  const campaignId = campaign.id
  const urlWithMeDomain = campaignUrlWithMeDomain(
    campaign.id,
    campaign.campaign_type
  )

  const { type } = campaign
  const isSmallGroup = type === POOL_TYPE.PRIVATE

  const shareMessage = generateShareMessage(
    isSmallGroup,
    campaign,
    urlWithMeDomain,
    props.worldReady
  )

  return {
    campaignId,
    charity: campaign.charity,
    operatingRemotely: campaign.operatingRemotely,
    poolUrl: urlWithMeDomain,
    creationError: campaign.err,
    shareDescription: shareMessage,
    paypalDomain: state.geo.paypalDomain,
    enable_ces_link: state.flags.enable_ces_link,
    cdnHost: state.geo.cdnHost,
    campaignType: campaign.type,
    hasSocialShare5xEnabled: state.flags.hasSocialShare5xEnabled,
    hasSocialShare7xEnabled: state.flags.hasSocialShare7xEnabled,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onPublish() {
      dispatch(createRemotely(campaignId))
    },
  }
}

export default injectWorldReady(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(preventNavigation(CrowdfundingShare, props => props.operatingRemotely))
)
