import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './modal.less'
import clsx from 'clsx'

import {
  disableBodyScrollability,
  enableBodyScrollability,
  scrollToTop,
} from 'lib/scroll'
import { KEY_PRESS_EVENTS } from 'shared/shared_consts'
import { ProgressMeter, CloseIcon } from '@paypalcorp/pp-react'

const { ENTER_KEY } = KEY_PRESS_EVENTS

const ESC_KEY_CODE = 27

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    styles: PropTypes.object,
    leftHeader: PropTypes.any,
    onClose: PropTypes.func,
    glimpse: PropTypes.bool,
    showSpinner: PropTypes.bool,
    showClose: PropTypes.bool,
    hideTopWithoutHeader: PropTypes.bool,
    isWideView: PropTypes.bool,
    progressPercent: PropTypes.number,
    showProgressBar: PropTypes.bool,
    isPadding: PropTypes.bool,
  }

  static defaultProps = {
    showClose: true,
    hideTopWithoutHeader: false,
    isWideView: false,
    isPadding: false,
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  scrollToTop = () => {
    scrollToTop(this.container)
  }

  onKeyUp = event => {
    if (event.keyCode === ESC_KEY_CODE || event.which === ESC_KEY_CODE) {
      this.handleClose()
    }
  }

  registerForEscKey = () => {
    window.addEventListener('keyup', this.onKeyUp)
  }

  unregisterForEscKey = () => {
    window.removeEventListener('keyup', this.onKeyUp)
  }

  componentDidMount() {
    this.registerForEscKey()
    if (this.props.glimpse) {
      disableBodyScrollability(this.container)
    }
    document.body.setAttribute('style', 'overflow-y:hidden')
  }

  componentWillUnmount() {
    this.unregisterForEscKey()
    if (this.props.glimpse) {
      enableBodyScrollability(this.container)
    }
    document.body.removeAttribute('style')
  }

  render() {
    const {
      isWideView,
      progressPercent,
      showProgressBar,
      isPadding,
    } = this.props

    const hasTopPart = !!(this.props.leftHeader || this.props.showClose)

    return (
      <div
        styleName="modal-overflow-container"
        ref={container => {
          this.container = container
        }}
        tabIndex={0}
        aria-modal="true"
        role="dialog"
        aria-label="modal"
      >
        <div styleName={clsx('modal-wrapper', { glimpse: this.props.glimpse })}>
          <div
            styleName={clsx('white-part', {
              'outer-wide-view-container': isWideView,
            })}
            className={clsx({ 'vx_has-spinner-large': this.props.showSpinner })}
          >
            <div styleName={'modal-header'} data-autofocus-inside>
              {(hasTopPart || !this.props.hideTopWithoutHeader) && (
                <>
                  <div styleName="left-header">{this.props.leftHeader}</div>
                  {this.props.showClose && (
                    <span
                      data-testid="x-button"
                      aria-label="close"
                      styleName="x-button"
                      onClick={this.handleClose}
                      role="button"
                      tabIndex="0"
                      onKeyPress={e => {
                        if (e.key === ENTER_KEY) {
                          this.handleClose()
                        }
                      }}
                    >
                      <CloseIcon size="sm" />
                    </span>
                  )}
                </>
              )}
            </div>
            {showProgressBar && (
              <div styleName="modal-progress">
                <ProgressMeter
                  max="100"
                  size="sm"
                  value={progressPercent}
                  styleName="progress-bar"
                />
              </div>
            )}
            <div
              id="formContent"
              styleName={clsx('contents', {
                'inner-wide-view-container': isWideView,
                stepName: isPadding,
              })}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default cssModules(Modal, styles, { allowMultiple: true })
