import TransferComplete from './transfer_complete'
import { connect } from 'react-redux'

import { getValues } from 'redux-form'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { inflateUserTxns } from 'redux/modules/user_txns'
import { go } from 'react-router-redux'

const mapStateToProps = (state, props) => {
  const formValues = getValues(state.form.transfer)
  const campaign = getCampaignSelector(state, props)
  return {
    title: campaign.title,
    currency: campaign.currency,
    amount: formValues && formValues.transferAmount,
    transferError: campaign.err,
    paypalDomain: state.geo.paypalDomain,
    cdnHost: state.geo.cdnHost,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onDone() {
      dispatch(inflateUserTxns(campaignId))
      // 1 - back to transfer review
      // 2 - back to transfer form
      // 3 - back to manage page
      dispatch(go(-3))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferComplete)
