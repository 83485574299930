// taken from here: http://stackoverflow.com/questions/40958727/javascript-generate-unique-number-based-on-string
export function getHash(input) {
  let hash = 0,
    len = input.length
  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i) // eslint-disable-line no-bitwise
    hash |= 0 // eslint-disable-line no-bitwise
  }
  return hash
}
