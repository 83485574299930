import { FINISH_IFRAME_SEND_SUCCESSFULLY } from './send_iframe'
import {
  FINISH_CHIP_IN_SUCCESSFULLY,
  FINISH_WITHDRAW_SUCCESSFULLY,
} from './campaign'

const INFLATION_DELAY = 500

export const INFLATE = 'pools/user_txns/INFLATE'

export default function reducer(state = { list: [] }, action) {
  switch (action.type) {
    case FINISH_CHIP_IN_SUCCESSFULLY:
    case FINISH_IFRAME_SEND_SUCCESSFULLY:
    case FINISH_WITHDRAW_SUCCESSFULLY: {
      const newTxn = {
        id: action.payload.txn.paymentId,
        campaign_id: action.payload.txn.campaignId,
        date: action.payload.txn.date,
        amount: action.payload.txn.amount,
        note: action.payload.txn.note,
        anonymous: action.payload.txn.anonymous,
        pending: action.payload.txn.pending,
        owner_chip_in: action.type === FINISH_CHIP_IN_SUCCESSFULLY, // chipper gets feedback that the txn succeeded, even if pending.
        withdraw: action.payload.txn.withdraw,
        contributor_id: action.payload.sender && action.payload.sender.id,
        contributor_name: action.payload.sender && action.payload.sender.name,
        contributor_photo: action.payload.sender && action.payload.sender.photo,
      }
      // TODO: the `no-extra-parens` rule will be fixed in a future ESLint version to work well with spread operators.
      // eslint-disable-next-line no-extra-parens
      return { ...state, list: [newTxn, ...(state.list || [])] }
    }
    case INFLATE: {
      return { ...state, list: [] }
    }
    default:
      return state
  }
}

export function inflateUserTxns(id) {
  return (dispatch, getState) => {
    setTimeout(() => {
      let list = getState().user_txns.list || []
      list = list.filter(txn => txn.campaign_id === id)
      dispatch({ type: INFLATE, payload: { id, list } })
    }, INFLATION_DELAY)
  }
}
