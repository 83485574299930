import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import CharityCard from 'components/common/charity_card'
import CrowdFundingCoverImage from 'components/crowdfunding_cover_image'
import CrowdFundingPreview from 'components/pages/crowdfunding/crowdfunding_preview'
import StandardDialog from 'components/common/standard_dialog'
import {
  DESCRIPTION_ROWS,
  CROWDFUNDING_DESCRIPTION_LENGTH,
} from 'shared/lengths'
import styles from './edit_main_cf.less'
import { appendPath } from 'lib/route_utils'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import {
  HeadingText,
  Dialog,
  Button,
  DropdownMenu,
  TextArea,
  BodyText,
} from '@paypalcorp/pp-react'
import { trackClick, trackImpression } from 'lib/fpti_analytics'
import _get from 'lodash/get'
import {
  INVALID_IMAGE,
  POOL_TYPE,
  TOAST_NOTIFICATIONS,
} from 'shared/shared_consts'
import { hoursFromToday } from 'shared/helpers/datetime_helpers'
import PrivateEditForm from './partials/privateEditForm'
import { hubPageUrl } from '../../../../lib/campaign_url'
import DeleteReview from '../delete_error/delete_review'
import DeleteFundraiser from './partials/delete_fundraiser'
import { useIntl } from 'intl'
import PersonalOrCharityEdit from './partials/personal_or_charity_edit'
import { Message } from '@paypalcorp/worldready-react'

const EditMain = props => {
  const { formatMessage, formatDate } = useIntl()

  const {
    fields: {
      poolName,
      goalAmount,
      endDate,
      category,
      description,
      anyInactiveFieldsTouchedWithError,
      backgroundImage,
      backgroundImageFilename,
      backgroundPan,
      gradientColor,
    },
    charity,
    formattedMaxGoalAmount,
    timeZone,
    currency,
    showCategoryInput,
    campaign: { category_image: categoryImage },
    campaignId,
    handleSubmit,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [editedCampagin, setEditedCampagin] = useState(props.campaign)
  const [showBackDialog, setShowBackDialog] = useState(false)
  const [showDeleteReview, setShowDeleteReview] = useState(false)

  const handleModalClick = e => {
    e.preventDefault()
    trackEvents('dw_giving_edit_fundraiser_preview_pressed')
    setData()
  }

  const handleModalClose = () => {
    setIsOpen(false)
    trackClick({
      clickName: 'dw_giving_fundraiser_preview_dismiss_pressed',
      data: {
        event_name: 'dw_giving_fundraiser_preview_dismiss_pressed',
        dismiss_method: 'close',
      },
    })
    trackEvents('dw_giving_fundraiser_preview_dismiss_pressed')
  }

  const setData = () => {
    const newCampaign = Object.assign(editedCampagin, {
      goal: goalAmount.value || 0,
      category: category.value,
      description: description.value,
      end_date: endDate.value,
      title: poolName.value,
      event_name: poolName.value,
      gradient_color: gradientColor.value,
      background_image: backgroundImage.value,
      background_image_filename: backgroundImageFilename.value,
      background_pan: backgroundPan.value,
    })
    setEditedCampagin(newCampaign)
    setIsOpen(true)
    trackImpression({
      event_name: 'dw_giving_fundraiser_preview_screen_shown',
    })
  }

  const handleEditSubmit = () => {
    const saveEvent = showBackDialog
      ? 'dw_giving_quit_editing_fundraiser_save_pressed'
      : 'dw_giving_edit_fundraiser_save_pressed'
    trackEvents(saveEvent)
    setShowBackDialog(false)

    const { value, initialValue } = backgroundImage || {}
    const request = {
      poolName: poolName.value,
      endDate: endDate.value,
      goalAmount: parseInt(goalAmount.value || 0),
      category: category.value,
      description: description.value,
      gradientColor: gradientColor.value,
      backgroundImage: value,
      backgroundImageFilename:
        value === initialValue ? '' : backgroundImageFilename.value,
      backgroundPan: backgroundPan.value,
      isExpired: hoursFromToday(new Date(props.campaign.end_date)) <= 0,
    }

    return props
      .onSave(request)
      .then(() => {
        editBack()
        props.onToggleToast(TOAST_NOTIFICATIONS.EDIT_SUCCESS)
      })
      .catch(err => {
        if (err.error === INVALID_IMAGE) {
          props.router.push({
            pathname: appendPath(location.pathname, 'manage/error'),
          })
          return
        }
        props.router.push({
          pathname: appendPath(location.pathname, 'error'),
          state: { updateData: err.updateData },
        })
      })
  }

  const editBack = () => {
    const { params: { hideBack = false } = {} } = props.router
    if (hideBack) {
      props.router.push({
        pathname: '/pools',
        state: { reloadTransactions: false, campaignId: props.campaignId },
      })
    } else {
      props.router.goBack()
    }
  }

  const handleDialogClose = () => setShowBackDialog(false)

  const handleDeleteOk = () => {
    trackEvents('dw_giving_delete_fundraiser_confirm_pressed')
    props
      .onDelete()
      .then(() => {
        location.href = hubPageUrl()
      })
      .catch(err => {
        props.router.push({
          pathname: appendPath(location.pathname, 'delete/error'),
          state: { updateData: err },
        })
      })
  }

  const handleQuit = () => {
    trackEvents('dw_giving_quit_editing_fundraiser_without_save_pressed')
    editBack()
    setShowBackDialog(false)
  }

  const trackEvents = eventName => {
    trackClick({
      clickName: eventName,
      data: {
        event_name: eventName,
      },
    })
  }

  if (showDeleteReview) {
    return (
      <DeleteReview
        data-testid="edit_main_cf__delete-review"
        campaignId={campaignId}
        onDelete={handleDeleteOk}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(handleEditSubmit)}>
      {showBackDialog && (
        <StandardDialog
          size="medium"
          title={formatMessage(
            'components.edit_glimpse.edit_main.dirty_dialog_title_cf'
          )}
          subtitle={formatMessage(
            'components.edit_glimpse.edit_main.dirty_dialog_subtitle_cf'
          )}
          closeButtonTitle={formatMessage(
            'components.edit_glimpse.edit_main.quit_editing'
          )}
          okButtonTitle={formatMessage(
            'components.edit_glimpse.edit_main.save_changes'
          )}
          onClose={handleDialogClose}
          onOk={handleEditSubmit}
          onQuit={handleQuit}
          iconName="logout"
        />
      )}

      <HeadingText size="lg" styleName="title">
        <Message id="components.edit_glimpse.edit_main.edit_title_cf" />
      </HeadingText>
      {charity && (
        <>
          <div styleName="charity-header">
            <Message id="components.edit_glimpse.edit_main.charity" />
          </div>
          <CharityCard charity={charity} />
          <div styleName="charity-subtitle">
            <Message id="components.edit_glimpse.edit_main.charity_subtitle" />
          </div>
        </>
      )}
      <BodyText strong>
        <Message id="components.edit_glimpse.edit_main.detail_title_cf" />
      </BodyText>
      {props.ended && (
        <div styleName="ended-title">
          <Message
            id="components.edit_glimpse.edit_main.ended"
            endDate={formatDate(endDate.value, {
              style: 'date-short',
            })}
          />
        </div>
      )}

      {props.campaign.type === POOL_TYPE.PRIVATE ? (
        <PrivateEditForm
          campaign={props.campaign}
          endDate={endDate}
          currency={currency}
          poolName={poolName}
          goalAmount={goalAmount}
          formattedMaxGoalAmount={formattedMaxGoalAmount}
        />
      ) : (
        <PersonalOrCharityEdit
          campaign={props.campaign}
          endDate={endDate}
          currency={currency}
          poolName={poolName}
          goalAmount={goalAmount}
          formattedMaxGoalAmount={formattedMaxGoalAmount}
          timeZone={timeZone}
        />
      )}
      <BodyText strong styleName="detail-title">
        <Message id="components.edit_glimpse.edit_main.about_titile_cf" />
      </BodyText>
      {showCategoryInput && (
        <div styleName="category">
          <DropdownMenu
            label={_get(
              formatMessage('components.edit_glimpse.edit_main.category'),
              'props.children',
              formatMessage('components.edit_glimpse.edit_main.category')
            )}
            dataNemo="pool-category"
            options={props.categoryList}
            value={category.value}
            onChange={event => category.onChange(event.target.value)}
            data-testid="edit-category"
            disabled={!props.campaign.can_chip_in}
          />
        </div>
      )}
      <div styleName="description-container">
        <div styleName="textbox-wrapper">
          <TextArea
            {...domOnlyProps(description)}
            id={description.name}
            dataNemo="about-pool-description"
            minRows={DESCRIPTION_ROWS}
            label={
              props.campaign.type === POOL_TYPE.PERSONAL
                ? formatMessage(
                    'components.pages.crowdfunding.pool_story.title'
                  )
                : formatMessage(
                    'components.edit_glimpse.edit_main.description_cf'
                  )
            }
            showCharacterCounter={true}
            maxCharacters={CROWDFUNDING_DESCRIPTION_LENGTH}
            errorText={getFormError(description)}
            data-testid="edit-description"
          />
        </div>
      </div>
      {!props.isAndroid && (
        <>
          <BodyText strong styleName="detail-title">
            <Message id="components.edit_glimpse.edit_main.photo_titile_cf" />
          </BodyText>
          <div styleName="uploader">
            <CrowdFundingCoverImage
              showPreviewComponent={true}
              fields={props.fields}
              categoryImage={categoryImage}
            />
          </div>
        </>
      )}
      {props.showDelete && (
        <DeleteFundraiser
          onDeleteClick={handleDeleteOk}
          showDeleteReviewModal={() => setShowDeleteReview(true)}
          charity={charity}
          campaign={props.campaign}
          date-testid="edit_main_cf-delete-link"
        />
      )}
      <Button
        data-nemo="save"
        className="vx_btn vx_btn-block"
        styleName="save-button"
        data-testid="edit-btn-save"
      >
        <Message id="components.edit_glimpse.edit_main.save_cf" />
      </Button>
      <Button
        onClick={handleModalClick}
        styleName="btn"
        disabled={anyInactiveFieldsTouchedWithError}
        data-nemo="preview-next"
        secondary
      >
        <Message id="components.edit_glimpse.edit_main.preview_cf" />
      </Button>
      <Dialog
        isOpen={isOpen}
        onClose={handleModalClose}
        styleName="modal"
        action={
          <HeadingText size="md" styleName="modal_title">
            <Message id="components.edit_glimpse.edit_main.preview_cf" />
          </HeadingText>
        }
      >
        <CrowdFundingPreview isEdit={true} editedCampaign={editedCampagin} />
      </Dialog>
    </form>
  )
}

EditMain.propTypes = {
  fields: PropTypes.object.isRequired,
  ended: PropTypes.bool,
  showDelete: PropTypes.bool,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  isAndroid: PropTypes.bool,
  category: PropTypes.string,
  categoryList: PropTypes.array,
  router: PropTypes.object,
  campaign: PropTypes.object,
  dirty: PropTypes.bool,
  formattedMaxGoalAmount: PropTypes.string,
  charity: PropTypes.object,
  onToggleToast: PropTypes.func,
  timeZone: PropTypes.string,
  currency: PropTypes.string,
  campaignId: PropTypes.string,
  showCategoryInput: PropTypes.boolean,
  onDelete: PropTypes.func,
  worldReady: PropTypes.object,
}

export default cssModules(EditMain, styles)
