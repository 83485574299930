import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './alert.less'

const TYPES = ['help', 'warning', 'critical', 'success']

class Alert extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(TYPES),
    children: PropTypes.any,
  }

  render() {
    return (
      <div styleName={`alert-${this.props.type}`}>
        <p styleName="alert-text">{this.props.children}</p>
      </div>
    )
  }
}

export default cssModules(Alert, styles)
