import { decode as base62Decode, encode as base62Encode } from 'base-62.js'
// returns the full base url of the app
export function baseUrl(hostname, requestUri) {
  return `https://${hostname}${requestUri}`
}

// returns a url to campaign
// @meDomain - whether the url should be in the .me domain or the default one (.com domain)
// @shortenedCampaign - whether to generate a url with the short campaign path (`/c/:encoded_id`) or the full one (`/campaign/:base_10_id`)
export function campaignUrl(
  campaignId,
  hostname,
  requestUri,
  meDomain = false,
  shortenedCampaign = true
) {
  const campaignPath = shortenedCampaign ? 'c' : 'campaign'
  campaignId = shortenedCampaign ? encodeCampaignId(campaignId) : campaignId
  if (meDomain) {
    hostname = hostname.replace('.com', '.me').replace('www.', '')
  }
  return `${baseUrl(hostname, requestUri)}/${campaignPath}/${encodeCampaignId(
    campaignId
  )}`
}

export function encodeCampaignId(campaignId) {
  if (/^\d+$/.test(campaignId)) {
    return base62Encode(campaignId)
  }
  return campaignId
}

export function decodeCampaignId(campaignId) {
  return base62Decode(campaignId).toString()
}

export function dexterURL(returnUrl, redirectUrl) {
  //for AU Country there 2 urls CIP and CRS in any order (first_compliance_Url & next_compliance_Url)
  //for a country other than AU there is only one url first_compliance_Url
  const firstComplianceUrl = Array.isArray(redirectUrl) && redirectUrl[0]
  const nextComplianceUrl = Array.isArray(redirectUrl) && redirectUrl[1]

  if (firstComplianceUrl) {
    const url = nextComplianceUrl
      ? `${firstComplianceUrl}?returnUrl=${returnUrl}?next_compliance_Url=${nextComplianceUrl}`
      : `${firstComplianceUrl}?returnUrl=${returnUrl}`
    return url
  }
  return `/policydashboard/process/compliance/cip?returnUrl=${returnUrl}&attempt_intention=moneypool_creation&pp_flow=moneypool_creation_web_app`
}
export function validateImagePath(path) {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.svg']
  return imageExtensions.some(extension =>
    path?.toLowerCase().endsWith(extension)
  )
    ? path
    : false
}
