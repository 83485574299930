import EditGlimpse from 'components/glimpses/edit_glimpse'
import ManageCrowdfunding from 'components/glimpses/edit_glimpse/manage_cf'
import Activity from 'components/glimpses/edit_glimpse/activity'
import EditMainCrowdfunding from 'components/glimpses/edit_glimpse/edit_main_cf'
import EditError from 'components/glimpses/edit_glimpse/edit_error'
import Pledge from 'components/glimpses/chip_in_glimpse/pledge'
import PledgeSuccess from 'components/glimpses/chip_in_glimpse/pledge/pledge_success'
import CrowdfundingShare from 'components/pages/crowdfunding/crowdfunding_share'
import DeleteError from 'components/glimpses/edit_glimpse/delete_error'
import Transfer from 'components/glimpses/edit_glimpse/transfer'
import TransferComplete from 'components/glimpses/edit_glimpse/transfer_complete'

import { sendEnsightenEvent } from 'lib/ensighten_analytics'

import { trackEnter, trackChange, firstInGlimpse } from 'lib/glimpse'
import {
  getStepName,
  glimpselessRoute,
  assignActionToRouterParams,
  assertNoDeepLink,
} from 'lib/route_utils'
import { isCampaignOwner } from 'lib/is_owner'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { destroy } from 'redux-form'
import { fromVeniceSelector } from 'redux/selectors/venice'
import { POOLS_CAMPAIGN_TYPES, INVALID_IMAGE } from 'shared/shared_consts'

const LAST_TRANSFER_STEP = 'complete'
const INITIAL_STEP = 'manage'
const INITIAL_STEP_IN_PREVIEW = undefined
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export default (store, history) => ({
  path: 'edit',
  component: { glimpse: EditGlimpse },
  indexRoute: {
    getComponent(nextState, callback) {
      const campaign = getCampaignSelector(store.getState(), {
        campaignId: nextState.params.campaignId,
      })

      if (campaign.campaign_type === CROWD_FUNDING) {
        return callback(null, EditMainCrowdfunding)
      }
    },
  },
  onEnter(nextState, replace) {
    const campaignId = nextState.params.campaignId
    const inPreview = campaignId === NEW_CAMPAIGN_ID
    if (isCampaignOwner(store, campaignId) || inPreview) {
      trackEnter('edit', nextState, replace)
      nextState.params.hideBack = true
      const stepName = getStepName('edit', nextState.routes)
      if (stepName !== 'transfer') {
        // Only support deep linking into edit flow is the user is coming from Venice
        const isVeniceUser = true || fromVeniceSelector()(store.getState())
        if (!isVeniceUser) {
          assertNoDeepLink(
            'edit',
            inPreview ? INITIAL_STEP_IN_PREVIEW : INITIAL_STEP,
            nextState,
            replace
          )
        }
      }
    } else {
      replace(glimpselessRoute('edit'))
    }
  },
  onChange(prevState, nextState, replace) {
    trackChange('edit', prevState, nextState, replace)
    nextState.params.hideBack = firstInGlimpse('edit')
  },
  childRoutes: [
    {
      path: 'manage',
      getComponent(nextState, callback) {
        const campaign = getCampaignSelector(store.getState(), {
          campaignId: nextState.params.campaignId,
        })

        if (campaign.campaign_type === CROWD_FUNDING) {
          return callback(null, ManageCrowdfunding)
        }
      },
    },
    { path: 'manage/activity', component: Activity },
    { path: 'delete/error', component: DeleteError },
    {
      path: 'error',
      component: EditError,
    },
    {
      path: ':part/error',
      component: EditError,
      onEnter(nextState) {
        let campaign = getCampaignSelector(store.getState(), {
          campaignId: nextState.params.campaignId,
        })
        if (campaign.err && campaign.err.error === INVALID_IMAGE) {
          nextState.params.hideBack = true
        }
      },
    },
    {
      path: 'transfer',
      indexRoute: {
        component: Transfer,
        onEnter(nextState) {
          if (
            nextState.location.action !== 'POP' ||
            !nextState.params.prevStep
          ) {
            sendEnsightenEvent('MoneyPoolWithdrawStart')
          }
        },
      },
      childRoutes: [
        {
          path: 'complete',
          component: TransferComplete,
          onEnter(nextState) {
            sendEnsightenEvent('MoneyPoolWithdrawFin')
          },
        },
      ],
      onChange(prevState, nextState) {
        assignActionToRouterParams(nextState)
        nextState.params.hideBack =
          getStepName('transfer', nextState.routes) === LAST_TRANSFER_STEP
      },
      onLeave() {
        store.dispatch(destroy('transfer'))
      },
    },
    {
      path: 'pledge',
      indexRoute: {
        component: Pledge,
        onEnter(nextState, replace) {
          sendEnsightenEvent('MoneyPoolPledgeStart')
        },
      },
      childRoutes: [
        {
          path: 'complete',
          component: PledgeSuccess,
          onEnter(nextState, replace) {
            nextState.params.hideBack = true
            const { params } = nextState.location.state
            if (!params) {
              location.href = GLOBAL_CONSTS.REQUEST_URI
            }
          },
        },
      ],
    },
    {
      path: 'share',
      component: CrowdfundingShare,
      onEnter(nextState, replace) {
        nextState.params.hideLogo = true
        nextState.params.hideButton = true
      },
    },
  ],
})
