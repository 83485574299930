import { createSelector } from 'reselect'

const loginInfoSelector = state => state.login_info
const countrySelector = state => state.country

export function loginUrlSelector({
  returnUri,
  onboardData,
  showGuest = false,
}) {
  return createSelector(
    [loginInfoSelector, countrySelector],
    (loginInfo, country) => {
      const baseLoginUrl = loginInfo.login_url
      const localeParams = country
        ? `&locale.x=${country.locale}&country.x=${country.country_code}`
        : ''

      const encodedURI = encodeURIComponent(returnUri)
      const guest = showGuest ? '&intent=signin' : ''
      const ctxId = showGuest ? `&ctxId=${loginInfo.mayfly_key}` : ''
      let loginUrl = `${baseLoginUrl}?returnUri=${encodedURI}${guest}${ctxId}${localeParams}`

      // NOTE: The Unified Login page which has the "Contribute as a Guest" CTA
      // doesn't support signup through Tesla at this point, so
      // onboarding data is useless
      if (!showGuest && onboardData !== undefined) {
        loginUrl += '&' + onboardData
      }
      return loginUrl
    }
  )
}

export function logoutUrlSelector({ returnUri = '/' } = {}) {
  return createSelector([loginInfoSelector], loginInfo => {
    const baseLogoutUrl = loginInfo.logout_url
    return `${baseLogoutUrl}?returnUri=${encodeURIComponent(returnUri)}`
  })
}

export const isLoggedInSelector = createSelector(
  [loginInfoSelector],
  loginInfo => loginInfo.logged_in
)
