import React from 'react'
import PropTypes from 'prop-types'
import {
  SegmentedController,
  SegmentedControllerPanel,
  Link,
  BodyText,
} from '@paypalcorp/pp-react'
import cssModules from 'react-css-modules'
import { Link as RouterLink } from 'react-router'
import { getI18n } from 'intl'
import _get from 'lodash/get'
import CampaignDetails from 'components/pages/campaign/campaign_details'
import styles from './crowdfunding_tab_layout.less'
import { CROWDFUNDING_TAB_PRESS } from 'shared/shared_consts'
import { glimpseRoute } from 'lib/route_utils'
import { Message } from '@paypalcorp/worldready-react'
import OrganizerUpdateDetail from 'components/glimpses/edit_glimpse/organizer_update_detail/organizer_update_detail'
import { trackFPTI, organizerUpdateState } from 'lib/fpti_utils'
import { trackImpression } from 'lib/fpti_analytics'

const i18n = getI18n('components/crowdfunding_tab_layout')

class CrowdFundingTabLayout extends React.Component {
  static propTypes = {
    charity: PropTypes.object,
    campaign: PropTypes.object,
    campaignId: PropTypes.string,
    organizerUpdate: PropTypes.object,
    contributions: PropTypes.array,
    contributionsCount: PropTypes.number,
    pledge: PropTypes.number,
  }

  state = {
    selectedTabIdx: 0,
  }

  tabHandler(e) {
    this.setState({ selectedTabIdx: e.currentTarget.dataset.tabIndex })
    const CLICK_NAME =
      e.currentTarget.id === CROWDFUNDING_TAB_PRESS.ABOUT_TAB
        ? 'dw_giving_detail_about_tab_pressed'
        : 'dw_giving_detail_updates_tab_pressed'
    const getorganizerUpdate = _get(this.props, 'organizerUpdate') || {}
    const updateState = organizerUpdateState(getorganizerUpdate)
    trackFPTI(this.props.campaign, 'click', CLICK_NAME, '', '', '', updateState)
  }

  updatesComponent = (
    organizerUpdateItem,
    campaignId,
    campaign,
    updateState,
    postUpdateUrl
  ) => {
    if (organizerUpdateItem.length > 0) {
      return (
        <div styleName="organizer-update-doner">
          <OrganizerUpdateDetail
            organizerUpdateRow={organizerUpdateItem[0]}
            postUpdateUrl={postUpdateUrl}
            truncateDescription={false}
            truncateSubject={false}
          />
          {organizerUpdateItem.length > 1 && (
            <div styleName="show-more">
              <Link
                as={RouterLink}
                to={glimpseRoute('pools', `c/${campaignId}/viewupdates`)}
                data-testid="show_more"
                onClick={() => {
                  trackFPTI(
                    campaign,
                    'click',
                    'dw_giving_detail_updates_tab_show_previous_pressed',
                    '',
                    '',
                    '',
                    updateState
                  )
                }}
              >
                <Message id="components.edit_glimpse.updates_activity.show_more_action" />
              </Link>
            </div>
          )}
        </div>
      )
    } else {
      return (
        <div styleName="organizer-update-doner">
          <BodyText>
            <Message id="components.crowdfunding_tab_layout.no_updates" />
          </BodyText>
        </div>
      )
    }
  }

  render() {
    const { campaignId, organizerUpdate, campaign } = this.props
    const postUpdateUrl = `c/${campaignId}/postupdate`
    const organizerUpdateItem = _get(organizerUpdate, 'items', [])
    const updateState = organizerUpdateState(organizerUpdate)
    trackImpression({
      event_name: 'dw_giving_detail_about_tab_shown',
    })
    trackImpression({
      event_name: 'dw_giving_detail_updates_tab_shown',
      updateState,
    })
    return (
      <SegmentedController
        name="TabLayout"
        selectedTabIdx={this.state.selectedTabIdx}
        tabs={[
          { label: i18n('about_title'), id: 'about-tab', panelId: 'about-tab' },
          {
            label: (
              <Message id="components.edit_glimpse.updates_activity.title" />
            ),
            id: 'contributors-tab',
            panelId: 'contributors-tab',
          },
        ]}
        onClick={this.tabHandler.bind(this)}
      >
        <SegmentedControllerPanel id="about-tab-panel" tabId="group1-tab1">
          <CampaignDetails
            campaignId={this.props.campaignId}
            tab_layout={true}
          />
        </SegmentedControllerPanel>
        <SegmentedControllerPanel
          id="contributors-tab-panel"
          tabId="group1-tab2"
        >
          {this.updatesComponent(
            organizerUpdateItem,
            campaignId,
            campaign,
            updateState,
            postUpdateUrl
          )}
        </SegmentedControllerPanel>
      </SegmentedController>
    )
  }
}
export default cssModules(CrowdFundingTabLayout, styles, {
  allowMultiple: true,
})
