import { getJSONFromScript } from 'lib/server_params'

import { SCRIPT_IDS } from 'shared/shared_consts'

const DEPLOY_ENV_DEFAULT = {
  isDev: true,
  isTest: false,
  isStage: false,
  isSandbox: false,
  isLive: false,
}
const VALID_DEPLOY_ENV_FLAGS = Object.keys(DEPLOY_ENV_DEFAULT)

export function isDev() {
  return getDeployEnv().isDev
}

export function isTest() {
  return getDeployEnv().isTest
}

export function isStage() {
  return getDeployEnv().isStage
}

export function isSandbox() {
  return getDeployEnv().isSandbox
}

export function isLive() {
  return getDeployEnv().isLive
}

function getDeployEnv() {
  const store = getJSONFromScript(SCRIPT_IDS.STORE)

  if (store.deployEnv && typeof store.deployEnv === 'object') {
    const deployEnvStore = store.deployEnv

    if (
      Object.entries(deployEnvStore).some(
        ([flag, value]) =>
          VALID_DEPLOY_ENV_FLAGS.includes(flag) && value === true
      )
    ) {
      return deployEnvStore
    } else {
      return DEPLOY_ENV_DEFAULT
    }
  } else {
    return DEPLOY_ENV_DEFAULT
  }
}
