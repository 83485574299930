import React from 'react'
import PropTypes from 'prop-types'
import { getI18n } from 'intl'
import {
  PhoneIcon,
  ShareWhatsappIcon,
  EmailIcon,
  ShareFacebookIcon,
  ShareMessengerIcon,
  ShareTwitterIcon,
  CaptionText,
} from '@paypalcorp/pp-react'
import { getPopupOptionsString } from 'lib/share'
import {
  shareURLByMessenger,
  shareByTwitter,
  shareByEmail,
  shareBySMS,
} from 'consumerweb-sharing'
import { KEY_PRESS_EVENTS } from 'shared/shared_consts'
import { trackClick } from 'lib/fpti_analytics'
import ClipboardSharingButton from './clipboard_sharing_container'
import _get from 'lodash/get'
import styles from './share_buttons.less'

const { ENTER_KEY } = KEY_PRESS_EVENTS

const i18n = getI18n('components/share_buttons')
const i18nText = getI18n('components/share_campaign_textarea')

const propTypes = {
  showFacebook: PropTypes.bool,
  showMessenger: PropTypes.bool,
  showTwitter: PropTypes.bool,
  showWhatsApp: PropTypes.bool,
  showEmail: PropTypes.bool,
  showSms: PropTypes.bool,
  showClipboard: PropTypes.bool,
  showSubtext: PropTypes.bool,
  shareText: PropTypes.string,
  className: PropTypes.string,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
  shareMsgFB: PropTypes.string,
  shareMsgTwitter: PropTypes.string,
  shareMsgWhatsapp: PropTypes.string,
  shareUrl: PropTypes.string,
  inline: PropTypes.bool,
  eventName: PropTypes.string,
}

const handleIconClick = (socialNetwork, props, event) => {
  event.preventDefault()
  const shareText = props.shareText
  const fbUrl = `https://www.facebook.com/dialog/share?app_id=${
    props.appId
  }&display=popup&quote=${props.shareMsgFB}&href=${encodeURI(props.shareUrl)}`
  const whatsappText = shareText || props.shareMsgWhatsapp
  const whatsappUrl =
    `https://${props.isMobile ? 'api' : 'web'}.whatsapp.com/send?text=` +
    encodeURIComponent(whatsappText)
  let clickName = 'social_media'
  const featureType = _get(props, 'featureType')
  const fundraiser_expired = _get(props, 'event_status')
  switch (socialNetwork) {
    case 'facebook':
      window.open(fbUrl, 'facebook', getPopupOptionsString(640, 480))
      break

    case 'messenger':
      shareURLByMessenger(shareText, props.appId, window.location.href)
      break

    case 'twitter':
      shareByTwitter(shareText || props.shareMsgTwitter, 640, 480)
      break

    case 'whats-app':
      window.open(whatsappUrl)
      break

    case 'email':
      shareByEmail(props.emailSubject, shareText || props.emailBody)
      break

    case 'sms':
      shareBySMS(shareText)
      break

    case 'copy':
      clickName = 'copy'
      break

    default:
      break
  }
  trackClick({
    clickName,
    data: {
      event_name:
        featureType === 'share_update'
          ? 'dw_giving_share_update_channel_pressed'
          : props.eventName,
      link_name: socialNetwork,
      opt_sel: socialNetwork,
      fundraiser_expired: fundraiser_expired === 'expired' ? 'yes' : 'no',
    },
  })
}

const handleKeyUp = (socialNetwork, props, event) => {
  if (event.key === ENTER_KEY) {
    handleIconClick(socialNetwork, props, event)
  }
}

const displayComponent = display => {
  if (!display) {
    return
  }
}

const SubText = prop => {
  const Component = prop.inline ? (
    <div>{i18n(prop.contentkey)}</div>
  ) : (
    <CaptionText className={styles['sub-text']}>
      {i18nText(prop.contentkey)}
    </CaptionText>
  )
  return Component
}

export const EmailSharing = props => {
  displayComponent(props.showEmail)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('email', props, e)}
      onKeyUp={e => handleKeyUp('email', props, e)}
      tabIndex={0}
      data-testid="email-sharing"
    >
      <EmailIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'email'} />
      )}
    </div>
  )
}

export const TwitterSharing = props => {
  displayComponent(props.showTwitter)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('twitter', props, e)}
      onKeyUp={e => handleKeyUp('twitter', props, e)}
      tabIndex={0}
      data-testid="twitter-sharing"
    >
      <ShareTwitterIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'twitter'} />
      )}
    </div>
  )
}

export const FacebookSharing = props => {
  displayComponent(props.showFacebook)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('facebook', props, e)}
      onKeyUp={e => handleKeyUp('facebook', props, e)}
      tabIndex={0}
      data-testid="facebook-sharing"
    >
      <ShareFacebookIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'facebook'} />
      )}
    </div>
  )
}

export const MessengerSharing = props => {
  displayComponent(props.showMessenger)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('messenger', props, e)}
      onKeyUp={e => handleKeyUp('messenger', props, e)}
      tabIndex={0}
    >
      <ShareMessengerIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'messenger'} />
      )}
    </div>
  )
}

export const WhatsAppSharing = props => {
  displayComponent(props.showWhatsApp)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('whats-app', props, e)}
      onKeyUp={e => handleKeyUp('whats-app', props, e)}
      tabIndex={0}
      data-testid="whatsapp-sharing"
    >
      <ShareWhatsappIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'whatsapp'} />
      )}
    </div>
  )
}

export const SmsSharing = props => {
  displayComponent(props.showSms)

  return (
    <div
      className={props.className}
      onClick={e => handleIconClick('sms', props, e)}
      onKeyUp={e => handleKeyUp('sms', props, e)}
      tabIndex={0}
      data-testid="sms-sharing"
    >
      <PhoneIcon size="sm" color="sysColorAccent1Main" />
      {props.showSubtext && (
        <SubText inline={props.inline} contentkey={'sms'} />
      )}
    </div>
  )
}

export const ClipboardSharing = props => {
  return (
    <ClipboardSharingButton
      handleClick={handleIconClick}
      handleKeyUp={handleKeyUp}
      i18n2={i18n}
      {...props}
    />
  )
}

SmsSharing.propTypes = propTypes
EmailSharing.propTypes = propTypes
TwitterSharing.propTypes = propTypes
WhatsAppSharing.propTypes = propTypes
FacebookSharing.propTypes = propTypes
MessengerSharing.propTypes = propTypes
