import {
  COUNTRIES_SMALL_LEGAL_TEXT_GNC,
  TNC_COUNTRIES,
} from '../../shared/shared_consts'

export function hasDisclaimer(countryCode) {
  return TNC_COUNTRIES.includes(countryCode)
}

export function hasSmallLegalTexGnc(countryCode) {
  return COUNTRIES_SMALL_LEGAL_TEXT_GNC.includes(countryCode)
}
