import DeleteError from './delete_error'
import { connect } from 'react-redux'
import { deleteRemotely } from 'redux/modules/campaign'
import { hubPageUrl } from 'lib/campaign_url'

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    onDelete() {
      return dispatch(deleteRemotely(campaignId)).then(() => {
        location.href = hubPageUrl()
      })
    },
  }
}

export default connect(
  null,
  mapDispatchToProps
)(DeleteError)
