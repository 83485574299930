import { getI18n } from 'intl'
import {
  campaignUrlForDisplay,
  campaignUrlWithMeDomain,
} from 'lib/campaign_url'
import { canShareSelector } from 'redux/selectors/share'
import { getCampaignSelector } from 'redux/selectors/campaign'

const i18nBase = getI18n('components/pages/crowdfunding/pool_share')

export function getPopupOptionsString(popupWidth, popupHeight) {
  const left = (document.body.clientWidth - popupWidth) * 0.5
  const top = (document.body.clientHeight - popupHeight) * 0.5
  return `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
}

export function getShareProps(state, ownProps) {
  const isGNCEnabled = state.flags.is_gnc_enabled
  const countryCode = state.country.country_code
  const canShare = canShareSelector(state)
  const campaign = getCampaignSelector(state, ownProps)
  const shareUrl = campaignUrlWithMeDomain(campaign.id, campaign.campaign_type)
  const campaignTitle = campaign.title
  const charityName = campaign.charity && campaign.charity.name
  const isGncCampaign = campaign.charity && campaign.charity.name
  const textPlaceholder = i18nBase('text_placeholder')

  const emailSubjectTextGN = i18nBase('email_subject_gn', {
    campaignTitle: campaign.title,
  })

  const emailSubjectTextGNC = i18nBase(`email_subject_gnc_${countryCode}`, {
    campaignTitle,
    charityName,
  })

  const emailBodyTextGN = `${i18nBase('email_body_section_1_gn', {
    shareUrl,
    campaignTitle,
  })}
	`

  const emailBodyTextGNC = `${i18nBase('email_body_heading')}\n\n${i18nBase(
    `email_body_section_1_gnc_${countryCode}`,
    {
      charityName,
    }
  )}\n\n${i18nBase(`email_body_section_2_gnc_${countryCode}`, {
    shareUrl: shareUrl,
  })}\n\n${i18nBase('email_body_section_3_gn')}
  `
  const shareMsgWhatsappGNC = `${i18nBase(
    `share_whatsapp_gnc_1_${countryCode}`,
    {
      charityName: charityName,
      shareUrl: shareUrl,
    }
  )}\n\n${i18nBase(`share_whatsapp_gnc_2_${countryCode}`, {
    shareUrl: shareUrl,
  })}\n\n${i18nBase('email_body_section_3_gn')}`

  const shareMsgFB =
    isGNCEnabled && isGncCampaign
      ? i18nBase(`share_fb_gnc_${countryCode}`, {
          charityName: charityName,
          shareUrl: shareUrl,
        })
      : i18nBase('share_message_gn', {
          fundraiser_name: campaignTitle,
          shareUrl: '',
        })

  const shareMsgTwitter =
    isGNCEnabled && isGncCampaign
      ? i18nBase(`share_twitter_gnc_${countryCode}`, {
          charityName: charityName,
          shareUrl: shareUrl,
        })
      : i18nBase('share_message_gn', {
          fundraiser_name: campaignTitle,
          shareUrl: shareUrl,
        })

  const shareMsgWhatsapp =
    isGNCEnabled && isGncCampaign ? shareMsgWhatsappGNC : emailBodyTextGN
  const hashtags =
    isGNCEnabled && isGncCampaign
      ? `#GenerosityNetwork\n#${charityName.replace(/\s/g, '')}`
      : `#GenerosityNetwork\n#PeopleHelpingPeople`

  const emailSubject =
    isGNCEnabled && isGncCampaign ? emailSubjectTextGNC : emailSubjectTextGN
  const emailBody =
    isGNCEnabled && isGncCampaign ? emailBodyTextGNC : emailBodyTextGN

  let props = {
    shareable: true,
    shareUrl: shareUrl,
    displayUrl: campaignUrlForDisplay(campaign.id, campaign.campaign_type),
    appId: state.social.app_id,
    emailBody,
    emailSubject,
    textPlaceholder,
    isMobile: state.device_info.isMobile,
    shareMsgFB,
    shareMsgTwitter: `${shareMsgTwitter}\n${hashtags}`,
    shareMsgWhatsapp,
    charity: campaign.charity,
  }

  if (!canShare) {
    props.showFacebook = false
    props.showMessenger = false
    props.showTwitter = false
    props.showWhatsApp = false
    props.showEmail = false
    props.showSms = false
  }

  return props
}
