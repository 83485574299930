import React from 'react'
import { Sheet } from '@paypalcorp/pp-react'
import PropTypes from 'prop-types'

const HalfSheet = props => {
  const {
    mobileFixedHeight,
    children,
    show,
    onClose,
    title,
    containerClassName,
  } = props

  return (
    <Sheet
      isOpen={show}
      title={title}
      onCloseComplete={onClose}
      mobileFixedHeight={mobileFixedHeight}
      skipFormFocus={true}
      containerClassName={containerClassName}
      data-testid="half-sheet-component"
    >
      {children}
    </Sheet>
  )
}

HalfSheet.propTypes = {
  children: PropTypes.any.isRequired,
  show: PropTypes.boolean,
  onClose: PropTypes.func.isRequired,
  mobileFixedHeight: PropTypes.string,
  title: PropTypes.string,
  containerClassName: PropTypes.string,
}

HalfSheet.defaultProps = {
  show: false,
  mobileFixedHeight: '90',
  title: '',
  containerClassName: '',
}

export default HalfSheet
