import CampaignDetails from './campaign_details'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { connect } from 'react-redux'
import { getInitials } from 'shared/helpers/name_helpers'
import { formatName } from 'intl'
import { NEW_CAMPAIGN_ID } from 'redux/modules/campaign'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const ownerName =
    campaign.owner.business_name ||
    formatName(props.worldReady, {
      givenName: campaign.owner.first_name,
      surname: campaign.owner.last_name,
    })
  return {
    details: campaign.description,
    campaign: campaign,
    profileImage: campaign.owner.profile_photo,
    initials: getInitials(ownerName),
    ownerName: ownerName,
    isCharity: !!campaign.charity && campaign.charity.name,
    charity: campaign.charity,
    isPreview: campaign.id === NEW_CAMPAIGN_ID,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default injectWorldReady(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CampaignDetails)
)
