import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { getI18n } from 'intl'
import styles from './manage_cf.less'
import { Link as RouterLink, withRouter } from 'react-router'
import { glimpseRoute } from 'lib/route_utils'
import FlatProgress from 'components/common/flat_progress'
import NoCurrencyAlert from 'components/pages/campaign/no_currency_alert'
import {
  Row,
  Col,
  CaptionText,
  Link,
  IconButton,
  CharitiesIcon,
  EditIcon,
  PreviewIcon,
  ShareIcon,
  TransferIcon,
  PledgeIcon,
  LoadingSpinner,
} from '@paypalcorp/pp-react'
import ToastContainer from 'components/common/toast_container'
import CampaignBalance from 'components/pages/campaign/campaign_balance'
import CrowdfundingActivity from 'components/glimpses/edit_glimpse/activity/crowdfunding_activity'
import CrowdfundingUpdate from 'components/glimpses/edit_glimpse/updates/updates_activity'
import CrowdfundingHeader from 'components/crowdfunding_campaign_header'
import { routerShape } from 'react-router/lib/PropTypes'
import { trackClick } from '../../../../lib/fpti_analytics'

import {
  INSTRUMENT_EVENTS,
  INSTRUMENT_TAGS,
  KEY_PRESS_EVENTS,
  TOAST_NOTIFICATIONS,
} from 'shared/shared_consts'

const { ENTER_KEY } = KEY_PRESS_EVENTS
const i18n = getI18n('components/edit_glimpse/manage_cf')

class Manage extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    canDonateGNC: PropTypes.bool,
    canPledge: PropTypes.bool,
    charity: PropTypes.object,
    currencyCode: PropTypes.string,
    currentValue: PropTypes.number,
    dispatch: PropTypes.func,
    goal: PropTypes.number,
    hasChippedIn: PropTypes.bool,
    hasCurrencyBalance: PropTypes.bool,
    hasMoneybox: PropTypes.bool,
    onDonate: PropTypes.func,
    onTransfer: PropTypes.func,
    router: routerShape.isRequired,
    showUpdateSuccess: PropTypes.object,
    showDeleteSuccess: PropTypes.object,
    paypalDomain: PropTypes.string,
    cdnHost: PropTypes.string,
    activitiesCount: PropTypes.number,
    leftAlignFlag: PropTypes.bool,
    loadingActivities: PropTypes.bool,
    organizerUpdate: PropTypes.object,
    campaign: PropTypes.object,
    isOrganizerUpdatesEnabled: PropTypes.bool,
  }

  handleTransferClick = event => {
    this.handleFPTI(event)
    if (!this.props.hasMoneybox) {
      event.preventDefault()
    } else {
      this.props.onTransfer()
    }
  }

  handleDonateClick = event => {
    event.preventDefault()

    trackClick({
      clickName: INSTRUMENT_TAGS.DONATE,
      data: {
        event_name:
          INSTRUMENT_EVENTS.DW_GIVING_MANAGE_FUNDRASIER_DONATE_PRESSED,
      },
    })

    this.props.onDonate(this.props.campaignId)
  }

  handleFPTI = event => {
    if (event && event.currentTarget) {
      trackClick({
        clickName: event.currentTarget.getAttribute('fptitag'),
        data: {
          event_name: event.currentTarget.getAttribute('fptitag'),
        },
      })
    }
  }

  render() {
    const {
      campaignId,
      canDonateGNC,
      canPledge,
      charity,
      currencyCode,
      currentValue,
      goal,
      hasChippedIn,
      hasCurrencyBalance,
      hasMoneybox,
      paypalDomain,
      activitiesCount,
      leftAlignFlag,
      loadingActivities,
      organizerUpdate,
      cdnHost,
      campaign,
      isOrganizerUpdatesEnabled,
      showUpdateSuccess,
      showDeleteSuccess,
    } = this.props
    const isCharity = !!charity && charity.name
    const manageCampaignUrl = `c/${campaignId}`
    const editCampaignUrl = `${manageCampaignUrl}/edit`
    const shareCampaignUrl = `${manageCampaignUrl}/edit/share`
    const pledgeCampaignUrl = `${manageCampaignUrl}/edit/pledge`

    const message =
      showUpdateSuccess?.message ||
      showDeleteSuccess?.message ||
      i18n('update_success_message')

    return (
      <div styleName="container">
        {!hasCurrencyBalance && (
          <NoCurrencyAlert
            paypalDomain={paypalDomain}
            currency={currencyCode}
          />
        )}
        {(showUpdateSuccess || showDeleteSuccess) && (
          <ToastContainer
            delayTime={1000}
            message={message}
            isSuccess={showUpdateSuccess}
            id={
              showUpdateSuccess
                ? TOAST_NOTIFICATIONS.EDIT_SUCCESS
                : TOAST_NOTIFICATIONS.DELETE_SUCCESS
            }
          />
        )}
        <div styleName={leftAlignFlag ? 'text-left' : 'text-center'}>
          <div styleName={leftAlignFlag ? 'pool-header-left' : 'pool-header'}>
            <CrowdfundingHeader
              campaignId={campaignId}
              showDaysRemaining={true}
            />
          </div>
          <div styleName="progress-bar">
            <FlatProgress
              goalAmount={goal}
              totalAmount={currentValue}
              currencyCode={currencyCode}
              campaignType={campaign.type}
            />
          </div>
          <Row styleName="manage-icon-row">
            <Col xs={4} md={2} styleName="manage-icon-col">
              <Link
                aria-label="edit"
                as={RouterLink}
                styleName="manage-link"
                fptitag="dw_giving_manage_fundraiser_edit_pressed"
                data-nemo="manage_edit"
                onClick={this.handleFPTI}
                data-testid="manage-btn-edit"
                to={glimpseRoute('pools', editCampaignUrl)}
              >
                <IconButton
                  size="lg"
                  buttonType="secondary"
                  svgIconComponent={EditIcon}
                />
                <CaptionText styleName="manage-icon-text-align">
                  {i18n('edit_fundraiser')}
                </CaptionText>
              </Link>
            </Col>
            <Col xs={4} md={2} styleName="manage-icon-col">
              <Link
                as={RouterLink}
                styleName="manage-link"
                fptitag="dw_giving_manage_fundraiser_show_pressed"
                to={glimpseRoute('pools', manageCampaignUrl)}
                onClick={this.handleFPTI}
                data-nemo="manage_show_fundraiser"
                data-testid="manage-btn-next"
                onKeyPress={e => {
                  if (e.key === ENTER_KEY) {
                    this.handleFPTI()
                  }
                }}
                tabIndex={0}
              >
                <IconButton
                  size="lg"
                  buttonType="secondary"
                  svgIconComponent={PreviewIcon}
                />
                <CaptionText styleName="manage-icon-text-align">
                  {i18n('show_fundraiser')}
                </CaptionText>
              </Link>
            </Col>
            <Col xs={4} md={2} styleName="manage-icon-col">
              <Link
                aria-label="share"
                as={RouterLink}
                styleName="manage-link"
                to={glimpseRoute('pools', shareCampaignUrl)}
                ftpiTag="dw_giving_manage_fundraiser_share_bubble_pressed"
                onClick={this.handleFPTI}
                data-nemo="manage_share"
              >
                <IconButton
                  size="lg"
                  buttonType="secondary"
                  svgIconComponent={ShareIcon}
                />
                <CaptionText styleName="manage-icon-text-align">
                  {i18n('share')}
                </CaptionText>
              </Link>
            </Col>
            {hasChippedIn &&
              !isCharity && (
                <Col xs={4} md={2} styleName="manage-icon-col">
                  <Link
                    as={RouterLink}
                    onClick={this.handleTransferClick}
                    ftpiTag="dw_giving_manage_fundraiser_transfer_pressed"
                    disabled={!hasMoneybox}
                    data-nemo="manage_transfer"
                    onKeyPress={e => {
                      if (e.key === ENTER_KEY) {
                        this.handleTransferClick()
                      }
                    }}
                    styleName="manage-link"
                    tabIndex={0}
                  >
                    <IconButton
                      size="lg"
                      buttonType="secondary"
                      svgIconComponent={TransferIcon}
                    />
                    <CaptionText styleName="manage-icon-text-align">
                      {i18n('transfer_link')}
                    </CaptionText>
                  </Link>
                </Col>
              )}
            {canPledge &&
              !isCharity && (
                <Col xs={4} md={2} styleName="manage-icon-col">
                  <Link
                    aria-label="pledge"
                    as={RouterLink}
                    styleName="manage-link"
                    to={glimpseRoute('pools', pledgeCampaignUrl)}
                    ftpiTag="dw_giving_manage_fundraiser_pledge_bubble_pressed"
                    onClick={this.handleFPTI}
                    data-nemo="manage_pledge"
                  >
                    <IconButton
                      size="lg"
                      buttonType="secondary"
                      svgIconComponent={PledgeIcon}
                    />
                    <CaptionText styleName="manage-icon-text-align">
                      {i18n('pledge')}
                    </CaptionText>
                  </Link>
                </Col>
              )}
            {canDonateGNC && (
              <Col xs={4} md={2} styleName="manage-icon-col">
                <Link
                  aria-label="donate"
                  as={RouterLink}
                  onClick={this.handleDonateClick}
                  data-nemo="manage_donate"
                  styleName="manage-link"
                  to=""
                >
                  <IconButton
                    size="lg"
                    buttonType="secondary"
                    svgIconComponent={CharitiesIcon}
                  />
                  <CaptionText styleName="manage-icon-text-align">
                    {i18n('donate')}
                  </CaptionText>
                </Link>
              </Col>
            )}
          </Row>
        </div>
        {!isCharity && (
          <CampaignBalance
            campaignId={campaignId}
            leftAlignFlag={leftAlignFlag}
          />
        )}
        {loadingActivities && (
          <div styleName="activityLoader">
            <LoadingSpinner screenReaderText="loading" size="lg" />
          </div>
        )}
        {isOrganizerUpdatesEnabled && (
          <CrowdfundingUpdate
            campaignId={campaignId}
            organizerUpdate={organizerUpdate}
            cdnHost={cdnHost}
            showLatest={true}
            charity={charity}
            campaign={campaign}
          />
        )}
        {!isCharity && (
          <CrowdfundingActivity
            campaignId={campaignId}
            activitiesCount={activitiesCount}
          />
        )}
      </div>
    )
  }
}

export default withRouter(cssModules(Manage, styles))
