import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './strip.less'

import {
  DEFAULT_GRADIENT_COLOR,
  SOLID_BACKGROUND_IMAGE,
} from 'shared/shared_consts'

class Strip extends React.Component {
  static propTypes = {
    stripRef: PropTypes.func,
    isResponsive: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    staticHeight: PropTypes.number,
    backgroundImage: PropTypes.string,
    categoryImage: PropTypes.string,
    backgroundPan: PropTypes.number,
    gradientColor: PropTypes.string,
    customStyle: PropTypes.object,
    customStyleInput: PropTypes.bool,
  }

  static defaultProps = {
    gradientColor: DEFAULT_GRADIENT_COLOR,
  }

  state = {
    bg_image: this.props.backgroundImage,
  }

  onImageError = () => {
    const { categoryImage } = this.props
    this.setState({
      bg_image: categoryImage ? categoryImage : SOLID_BACKGROUND_IMAGE,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.backgroundImage !== this.props.backgroundImage) {
      this.onUpdate(this.props.backgroundImage)
    }
  }

  onUpdate = backgroundImage => {
    this.setState({ bg_image: backgroundImage })
  }

  render() {
    const { bg_image } = this.state
    const { backgroundImage, customStyleInput } = this.props
    let styleNames = []
    const responsiveStyle =
      this.props.isFullscreen && this.props.isResponsive
        ? 'responsive-fullscreen'
        : 'responsive'
    styleNames.push(this.props.isResponsive ? responsiveStyle : 'static')
    let customStyle = this.props.customStyle || {}
    let initialStyle = {}
    let mergedStyle = {}
    if (this.props.backgroundImage) {
      initialStyle.backgroundImage = `url(${bg_image})`
      initialStyle.backgroundPosition = `50% ${this.props.backgroundPan}%`
      mergedStyle = { ...customStyle, ...initialStyle }
      styleNames.push('strip-with-image')
      if (customStyleInput) {
        styleNames.push('strip-image')
      }
    } else {
      customStyle = {
        height: this.props.staticHeight || '100%',
      }
      styleNames.push(
        `strip-${this.props.gradientColor || DEFAULT_GRADIENT_COLOR}`
      )
      styleNames.push('strip-image')
    }

    const stripComponent = backgroundImage ? (
      <div
        styleName={styleNames.join(' ')}
        className="strip"
        style={mergedStyle}
        ref={this.props.stripRef}
      >
        <img
          styleName={'alternative-image'}
          src={bg_image}
          onError={this.onImageError}
        />
      </div>
    ) : (
      <div
        styleName={styleNames.join(' ')}
        className="strip"
        style={customStyle}
        ref={this.props.stripRef}
      />
    )
    return stripComponent
  }
}

export default cssModules(Strip, styles, { allowMultiple: true })
