import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import TransferReviewBox from '../transfer_review_box'
import styles from './delete_review.less'
import { Message } from '@paypalcorp/worldready-react'

const DeleteReview = ({ onDelete, campaignId }) => {
  return (
    <div data-testid="delete-review__modal">
      <div styleName="title">
        <Message id="components.edit_glimpse.edit_delete.review_title" />
      </div>
      <TransferReviewBox campaignId={campaignId} />
      <button
        data-testid="delete-review__delete-btn"
        onClick={onDelete}
        styleName="delete-button"
      >
        <Message id="components.edit_glimpse.edit_delete.review_delete_action" />
      </button>
    </div>
  )
}

DeleteReview.propTypes = {
  campaignId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default cssModules(DeleteReview, styles)
