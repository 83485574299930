import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_image_adjust.less'
import Cropper from 'components/common/cropper'

class CrowdFundingImageAdjust extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    onImageChange: PropTypes.func,
    onImageRemove: PropTypes.func,
  }

  render() {
    return (
      <div>
        <div styleName="preview-container">
          <div styleName="viewer" id="tim">
            <Cropper
              imgSrc={this.props.imageUrl}
              onImageRemove={this.props.onImageRemove}
              updateImageData={url => this.props.onImageChange({ url })}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default cssModules(CrowdFundingImageAdjust, styles)
