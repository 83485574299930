import React from 'react'
import PropTypes from 'prop-types'
import { normalizeImage } from '../../../lib/image_utils'
import { ALLOWED_IMAGE_TYPES } from 'shared/shared_consts'

class ImageFileField extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    onChange: PropTypes.func,
    restrictedTotalSize: PropTypes.number,
    onImageLoad: PropTypes.func,
  }

  handleOpenFileDialog = () => {
    this.input.click()
  }

  handleFileSelect = fileSelectEvent => {
    const fileReader = new FileReader()
    let file
    this.props.onImageLoad(true)
    fileReader.onload = onLoadEvent => {
      const url = onLoadEvent.target.result
      const options = { mimeType: file.type }
      if (this.props.onChange) {
        if (this.props.restrictedTotalSize) {
          const restrictedSize = this.props.restrictedTotalSize
          options.normalizeImageSize = true
          normalizeImage(
            url,
            restrictedSize,
            restrictedSize,
            options,
            normalizedUrl => {
              this.props.onChange({
                url: normalizedUrl,
                name: file && file.name,
              })
              this.props.onImageLoad(false)
            }
          )
        } else {
          normalizeImage(url, null, null, options, normalizedUrl => {
            this.props.onChange({ url: normalizedUrl, name: file && file.name })
            this.props.onImageLoad(false)
          })
        }
      }
      this.props.onImageLoad(false)
    }
    file = fileSelectEvent.target.files[0]
    if (file) {
      fileReader.readAsDataURL(file)
    }
  }

  render() {
    return (
      <div onClick={this.handleOpenFileDialog}>
        {this.props.children}
        <input
          style={{ display: 'none' }}
          type="file"
          accept={ALLOWED_IMAGE_TYPES}
          ref={input => {
            this.input = input
          }}
          onChange={this.handleFileSelect}
        />
      </div>
    )
  }
}

export default ImageFileField
