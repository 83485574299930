import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'
import {
  ADD_ORGANIZER_UPDATE,
  DELETE_ORGANIZER_UPDATE,
  EDIT_ORGANIZER_UPDATE,
  SET_ORGANIZER_UPDATE_LIST,
  startRemoteOperation,
  finishRemoteOperation,
  failedCampaignRemoteOperation,
  APPEND_ORGANIZER_UPDATE,
} from './campaign'

/**
 * @param {searchParams: {page: int, size: int, campaignId: string}}
 * Returns a list of organizerUpdate list with pagesize and pagenumber
 */

export function getOrganizerUpdate({ page, ...searchParams }) {
  return dispatch => {
    dispatch(startRemoteOperation(searchParams.campaignId))
    return fetchClient()
      .request(routes.organizer_update.api.index(), {
        method: 'GET',
        query: { ...searchParams, updatePage: page },
      })
      .then(response => {
        dispatch(
          setOrganizerUpdateList(
            searchParams.page > 1
              ? APPEND_ORGANIZER_UPDATE
              : SET_ORGANIZER_UPDATE_LIST,
            searchParams.campaignId,
            response
          )
        )
        dispatch(finishRemoteOperation(searchParams.campaignId))
        return Promise.resolve(response)
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(searchParams.campaignId, err))
        return Promise.reject(err)
      })
  }
}

/**
 * @param {campaignId: string, id: string}
 * Returns success after deleting the data
 */
export function deleteOrganizerUpdate(campaignId, id) {
  return dispatch => {
    dispatch(startRemoteOperation(campaignId))
    return fetchClient()
      .request(routes.organizer_update.api.index(), {
        method: 'DELETE',
        query: { id, campaignId },
      })
      .then(response => {
        dispatch(removeOrganizerUpdate(campaignId, id))
        dispatch(finishRemoteOperation(campaignId))
        return Promise.resolve()
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(campaignId, err))
        return Promise.reject(err)
      })
  }
}

/**
 * @param {body: {campaignId: string, subject: string, text: string}}
 * Returns a newly created instance of organizer update
 */
export function createOrganizerUpdate(body) {
  return dispatch => {
    dispatch(startRemoteOperation(body.campaignId))
    return fetchClient()
      .request(routes.organizer_update.api.index(), {
        method: 'POST',
        body,
      })
      .then(response => {
        dispatch(addOrganizerUpdate(body.campaignId, response))
        dispatch(finishRemoteOperation(body.campaignId))
        return Promise.resolve(response)
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(body.campaignId, err))
        return Promise.reject(err)
      })
  }
}

/**
 * @param {
 *   campaignId: string,
 *   id: string,
 *   updatableInstance: [{op: string, path: string, value: string}]
 * }
 * Returns the updated instance of organizer update
 */

export function editOrganizerUpdate(campaignId, id, updatableInstance) {
  return dispatch => {
    dispatch(startRemoteOperation(campaignId))
    return fetchClient()
      .request(routes.organizer_update.api.index(), {
        method: 'PATCH',
        body: {
          id,
          campaignId,
          updatableInstance,
        },
      })
      .then(response => {
        dispatch(modifyOrganizerUpdate(campaignId, response))
        dispatch(finishRemoteOperation(campaignId))
        return Promise.resolve(response)
      })
      .catch(err => {
        err.updateData = err.message
        dispatch(failedCampaignRemoteOperation(campaignId, err))
        return Promise.reject(err)
      })
  }
}

export function setOrganizerUpdateList(type, id, response) {
  return { type, payload: { id, response } }
}

export function removeOrganizerUpdate(campaignId, id) {
  return {
    type: DELETE_ORGANIZER_UPDATE,
    payload: { campaignId, id },
  }
}

export function modifyOrganizerUpdate(campaignId, response) {
  return {
    type: EDIT_ORGANIZER_UPDATE,
    payload: { campaignId, response },
  }
}

export function addOrganizerUpdate(campaignId, response) {
  return {
    type: ADD_ORGANIZER_UPDATE,
    payload: { campaignId, response },
  }
}
