// This is an HoC which prevents navigating away from the current route,
// when a certain condition is true. This condition is given by the result of
// a function which is the first parameter. The parameter to this function
// the the component's props.

// Example:
// preventedComponent = preventNavigation(MyComponent, props => props.doingWork)
import React from 'react'
import PropTypes from 'prop-types'
import { routerShape } from 'react-router/lib/PropTypes'

const preventNavigation = (ComponentWithPreventedNavigation, shouldPrevent) => {
  return class extends React.Component {
    static propTypes = {
      router: routerShape.isRequired,
      route: PropTypes.any,
    }

    componentDidMount = () => {
      this.props.router.setRouteLeaveHook(
        this.props.route,
        this.routerWillLeave
      )
    }

    routerWillLeave = nextLocation => {
      if (shouldPrevent(this.props, nextLocation)) {
        return false
      }
      return null
    }

    render() {
      return <ComponentWithPreventedNavigation {...this.props} />
    }
  }
}

export default preventNavigation
