import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './icon.less'
import {
  AddCameraIcon,
  SendMoneyIcon,
  PaypalAppIcon,
  BankIcon,
  UserProfileIcon,
  PartySuppliesIcon,
  GiftCardIcon,
  ShopIcon,
} from '@paypalcorp/pp-react'

import Rocket from '!!babel-loader!react-svg-loader!images/rocket.svg' // eslint-disable-line import/no-unresolved
import People from '!!babel-loader!react-svg-loader!images/pools_logo.svg' // eslint-disable-line import/no-unresolved

export const ICON_SIZES = {
  normal: '35%',
  big: '45%',
  huge: '75%',
}

export const STROKE_COLORS = ['black', 'blue', 'white']

class Icon extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    iconSize: PropTypes.oneOf(Object.keys(ICON_SIZES)),
    strokeColor: PropTypes.oneOf(STROKE_COLORS),
  }

  static defaultProps = {
    iconSize: 'normal',
    strokeColor: 'black',
  }

  render() {
    let IconSVG
    let disableFill // some icons are buggy when using the `fill` css option
    switch (this.props.type) {
      case 'add-photo':
        IconSVG = AddCameraIcon
        break
      case 'party':
        IconSVG = PartySuppliesIcon
        break
      case 'gift':
        IconSVG = GiftCardIcon
        break
      case 'rocket':
        IconSVG = Rocket
        break
      case 'shop':
        IconSVG = ShopIcon
        break
      case 'money-out':
        IconSVG = SendMoneyIcon
        break
      case 'profile':
        IconSVG = UserProfileIcon
        disableFill = true
        break
      case 'bank':
        IconSVG = BankIcon
        disableFill = true
        break
      case 'people':
        IconSVG = People
        disableFill = true
        break
      case 'paypal-icon':
        IconSVG = PaypalAppIcon
        disableFill = true
        break
      default:
        IconSVG = ''
    }

    if (!IconSVG) {
      return null
    }

    return (
      <IconSVG
        styleName={clsx('icon', `stroke-color-${this.props.strokeColor}`, {
          'disable-fill': disableFill,
        })}
        width={ICON_SIZES[this.props.iconSize]}
        height={ICON_SIZES[this.props.iconSize]}
      />
    )
  }
}

export default cssModules(Icon, styles, { allowMultiple: true })
