import { getJSONFromScript } from 'lib/server_params'

import { SCRIPT_IDS } from 'shared/shared_consts'

export function getStore() {
  return getJSONFromScript(SCRIPT_IDS.STORE)
}

/**
 * Synchronizes the script inserted in the header with the
 * latest value from the redux store in the client side.
 *
 * @param {object} newStore - The new Redux store object.
 */
export function syncScriptAndStore(newStore) {
  const jsonObject = getStore()
  const newObject = Object.assign(jsonObject, newStore)
  const updatedJsonString = JSON.stringify(newObject)

  // Update current store in script with new values
  document.getElementById(SCRIPT_IDS.STORE).innerHTML = updatedJsonString
}
