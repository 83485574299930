import React from 'react'
import styles from './tip_box.less'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import {
  CaptionText,
  LightBulbIcon,
  InformationIcon,
} from '@paypalcorp/pp-react'

class TipBox extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
  }
  renderIcon = type => {
    let icon
    if (type === 'cover') {
      icon = <InformationIcon size="sm" />
    } else if (type === 'story') {
      icon = <LightBulbIcon size="sm" />
    }
    return icon
  }
  render() {
    const { type } = this.props
    return (
      <div data-testid="tip-box__container" styleName="tip-wrapper">
        <div> {this.renderIcon(type)} </div>
        <CaptionText styleName="tip-text">{this.props.text}</CaptionText>
      </div>
    )
  }
}

export default cssModules(TipBox, styles)
