import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import FirstChild from 'components/common/first_child'
import styles from './modal_animations.less'

const ANIMATION_DURATION = 250

const TRANSITION_DURATION = {
  enter: 'modal-enter',
  enterActive: 'modal-enter-active',
  appear: 'modal-enter',
  appearActive: 'modal-enter-active',
  leave: 'modal-leave',
  leaveActive: 'modal-leave-active',
}

class ModalAnimations extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    styles: PropTypes.object,
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionName={TRANSITION_DURATION}
        component={FirstChild}
        transitionAppearTimeout={ANIMATION_DURATION}
        transitionEnterTimeout={ANIMATION_DURATION}
        transitionLeaveTimeout={ANIMATION_DURATION}
      >
        {this.props.children && React.Children.only(this.props.children)}
      </ReactCSSTransitionGroup>
    )
  }
}

export default cssModules(ModalAnimations, styles)
