import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './horizontal_scroller.less'
import { Card, Avatar, BodyText, HorizontalScroll } from '@paypalcorp/pp-react'

const HorizontalScroller = props => {
  const { isMobile, dataTestId, items, onClick } = props
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = index => {
    setCurrentPage(index)
  }

  const renderCard = item => {
    const { name, logo_url } = item
    /**
     * Shimmer depends on actually having something to load
     * so, when there is a logo, it's assumed that something should load
     * otherwise there is no need to even display the shimmer, this is why
     * isLoading state depends on logo_url having a value or not
     */
    const [isLoading, setIsLoading] = useState(!!logo_url)
    const [isImageVisible, setIsImageVisible] = useState(false)

    const handleImageLoad = () => {
      setIsLoading(false)
      setIsImageVisible(true)
    }

    const handleImageError = () => {
      setIsLoading(false)
    }

    return (
      <Card onClick={() => onClick(item)}>
        <Card.Content styleName="card__content">
          <div styleName="content__top">
            {logo_url && (
              <img
                src={logo_url}
                role="presentation"
                alt=""
                styleName={clsx('top__image', {
                  'top__image--visible': isImageVisible,
                })}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            )}

            {!isImageVisible && (
              <Avatar isLoading={isLoading} generic="causes" size="lg" />
            )}
          </div>
          <div styleName="content__bottom">
            <BodyText size="sm">{name}</BodyText>
          </div>
        </Card.Content>
      </Card>
    )
  }

  const renderCards = cssModules(renderCard, styles, { allowMultiple: true })

  return (
    <HorizontalScroll
      items={items}
      renderItem={renderCards}
      numOfVisibleItems={isMobile ? 1 : 2}
      currentPage={currentPage}
      onPageChange={handlePageChange}
      data-testid={`${dataTestId}-horizontal-scroller`}
    />
  )
}

HorizontalScroller.propTypes = {
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
  items: PropTypes.object.isRequired,
}

HorizontalScroller.defaultProps = {
  isMobile: false,
  dataTestId: 'main',
}

export default HorizontalScroller
