import EditError from './edit_error'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { updateRemotely } from 'redux/modules/campaign'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    error: campaign.err,
    campaignType: campaign.campaign_type,
    countryCode: state.country.country_code,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    onTryAgain() {
      dispatch(
        updateRemotely(props.params.campaignId, props.location.state.updateData)
      )
        .then(() => {
          props.router.go(-2) // 1st back - to edit page, second back - to edit_main
        })
        .catch(err => {
          // Do nothing, because we're already in the error page
          console.error(err) // eslint-disable-line no-console
        })
    },
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditError)
)
