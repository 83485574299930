import { formatMessage, formatDate, formatCurrency } from 'intl'

export const generateShareMessage = (
  isSmallGroup,
  campaign,
  urlWithMeDomain,
  worldReady
) => {
  const baseMessage = {
    expiryDate: formatDate(worldReady, campaign.end_date, {
      style: 'date-short',
    }),
    shareUrl: urlWithMeDomain,
    campaignTitle: campaign.title,
  }

  let message = 'components.pages.crowdfunding.pool_share.share_message'

  if (isSmallGroup) {
    message = campaign.goal
      ? 'components.pages.crowdfunding.pool_share.share_message_small_with_amount'
      : 'components.pages.crowdfunding.pool_share.share_message_small_no_amount'
  }

  return formatMessage(worldReady, message, {
    goalAmount: formatCurrency({
      value: campaign.goal,
      currencyCode: campaign.currency,
      additionalConfig: {},
      worldReady,
    }),
    ...baseMessage,
  })
}
