import React from 'react'
import { useIntl } from 'intl'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { Message } from '@paypalcorp/worldready-react'
import styles from '../../crowdfunding_campaign_header.less'
import { Col, Badge, ClockIcon } from '@paypalcorp/pp-react'
import { daysFromToday } from 'shared/helpers/datetime_helpers'
import clsx from 'clsx'

const DaysComponent = ({ endDate, canChipIn, topAlignment }) => {
  const { formatDate } = useIntl()
  const days = daysFromToday(new Date(endDate))

  const badgeType = canChipIn ? 'warning' : 'neutral'
  const badgeContentId = canChipIn
    ? 'components.crowdfunding_campaign_header.end_in'
    : 'components.crowdfunding_campaign_header.ended_on'

  return (
    <Col
      lg="auto"
      md="auto"
      xs="auto"
      styleName={clsx('iconCaptionContainer', {
        'top-alignment': topAlignment,
      })}
    >
      <Badge leftIcon={ClockIcon} type={badgeType} data-testid="days-badge">
        <Message
          id={badgeContentId}
          days={days < 0 ? 0 : days}
          date={formatDate(endDate, {
            style: 'date-short',
          })}
          data-testid="days-message"
        />
      </Badge>
    </Col>
  )
}

DaysComponent.propTypes = {
  endDate: PropTypes.string,
  canChipIn: PropTypes.bool,
  topAlignment: PropTypes.bool,
}

export default cssModules(DaysComponent, styles, {
  allowMultiple: true,
})
