import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  ZoomInIcon,
  ZoomOutIcon,
  RefreshIcon,
  DeleteIcon,
} from '@paypalcorp/pp-react'
import ReactCropper from 'react-cropper'
import cssModules from 'react-css-modules'
import styles from './cropper.less'
import 'cropperjs/dist/cropper.css'
import { isImageDefaultCover } from 'shared/helpers/image_helper'

const CLOCKWISE = 'clockwise'
const ROTATE_DEGREE = 90
const ADJUST_ZOOM_VALUE = 0.1

function Cropper(props) {
  const { imgSrc } = props
  const [cropper, setCropper] = React.useState(null)
  const [image, setImage] = React.useState(imgSrc)
  const [zoom, setZoom] = React.useState(props.initialZoom)
  const [rotate, setRotate] = React.useState(props.initialRotate)

  React.useEffect(
    () => {
      setImage(image)
    },
    [image]
  )

  const updateData = updatedCropper => {
    const value = updatedCropper?.getCroppedCanvas?.()?.toDataURL?.()
    if (value && !isImageDefaultCover(image)) {
      props.updateImageData(value)
    }
  }

  const handleRotate = direction => {
    const value =
      direction === CLOCKWISE ? rotate + ROTATE_DEGREE : rotate - ROTATE_DEGREE
    setRotate(value)
    cropper.rotateTo(value)
    updateData(cropper)
  }

  const handleZoomIn = () => {
    const value = zoom <= 0.3 ? 0.4 : zoom + ADJUST_ZOOM_VALUE
    cropper.zoomTo(value)
    updateData(cropper)
  }

  const handleZoomOut = () => {
    const value = zoom <= 0.3 ? zoom : zoom - ADJUST_ZOOM_VALUE
    cropper.zoomTo(value)
    updateData(cropper)
  }

  return (
    <div styleName="image-cropper">
      <Row styleName="action-button-row">
        <Col xs={4} styleName="icon-panel">
          <ZoomInIcon
            size="sm"
            onClick={() => handleZoomIn()}
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleZoomIn()
              }
            }}
          />
          <ZoomOutIcon
            size="sm"
            onClick={() => handleZoomOut()}
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleZoomOut()
              }
            }}
          />
          <RefreshIcon
            size="sm"
            onClick={() => handleRotate('clockwise')}
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleRotate('clockwise')
              }
            }}
          />
        </Col>
        <Col xs={{ span: 2, offset: 6 }}>
          <DeleteIcon
            size="sm"
            onClick={props.onImageRemove}
            tabIndex={0}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                props.onImageRemove(e)
              }
            }}
          />
        </Col>
      </Row>
      <Row styleName="cropper-wrapper">
        {image && (
          <ReactCropper
            zoomTo={0}
            initialAspectRatio={2 / 1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            cropBoxResizable={false}
            cropBoxMovable={true}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={instance => {
              setCropper(instance)
            }}
            ready={instance => {
              updateData(instance.currentTarget.cropper)
            }}
            cropend={event => updateData(event.currentTarget.cropper)}
            zoom={event => {
              updateData(event.currentTarget.cropper)
              setZoom(event.detail.ratio)
            }}
            guides={true}
            dragMode="move"
            {...props.cropperProps}
          />
        )}
      </Row>
    </div>
  )
}

Cropper.propTypes = {
  initialZoom: PropTypes.number,
  initialRotate: PropTypes.number,
  mime: PropTypes.string,
  quality: PropTypes.number,
  file: PropTypes.object,
  cropperProps: PropTypes.object,
  croppedCanvasProps: PropTypes.object,
  onDiscard: PropTypes.func,
  onCompleted: PropTypes.func,
  imgSrc: PropTypes.string,
  updateImageData: PropTypes.func,
  onImageRemove: PropTypes.func,
}

Cropper.defaultProps = {
  initialZoom: 0,
  initialRotate: 0,
  mime: null,
  quality: 70,
  cropperProps: {},
  croppedCanvasProps: {},
  onDiscard: () => {},
  onCompleted: () => {},
}

export default cssModules(Cropper, styles)
