import ViewUpdatesActivity from './view_updates_activity'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { getOrganizerUpdate } from 'redux/modules/organizer_update'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    organizerUpdate: campaign.organizerUpdate,
    campaign,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {
    fetchOrganizerUpdateList(page = 1, size = 1) {
      return dispatch(getOrganizerUpdate({ page, size, campaignId }))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUpdatesActivity)
