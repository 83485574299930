import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './fundraiser_skeleton.less'
import ZeroFundraiser from '../zero_fundraiser'
import FundraiserSkeleton from '../fundraiser_skeleton'

const ZeroFundraiserOrSkeleton = ({ selectedTabIdx }) => {
  return selectedTabIdx === '0' ? (
    <div styleName="zero-fundraiser">
      <ZeroFundraiser
        isWide={true}
        showHeader={false}
        showFooter={false}
        showImageHeader={false}
      />
    </div>
  ) : (
    <FundraiserSkeleton />
  )
}

ZeroFundraiserOrSkeleton.propTypes = {
  selectedTabIdx: PropTypes.string,
}

ZeroFundraiserOrSkeleton.defaultProps = {
  selectedTabIdx: '0',
}

export default cssModules(ZeroFundraiserOrSkeleton, styles)
