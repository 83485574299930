import { CIP_FLOW_URL, FLOW_DETAILS } from '../../shared/shared_consts'

export function appendPath(basepath, appendage) {
  if (basepath[basepath.length - 1] === '/') {
    return basepath + appendage
  }
  return basepath + '/' + appendage
}

export function glimpselessRoute(glimpseName) {
  const rx = new RegExp(`/${glimpseName}(/.*)?`)
  return window.location.pathname.replace(rx, '')
}

export function glimpseRoute(
  glimpseName,
  name,
  currentGlimpseName = glimpseName
) {
  const pathname = window.location.pathname
  const subPathString = name ? `${glimpseName}/${name}` : `${glimpseName}`
  // no relative links, so we're doing it like this
  const rx = new RegExp(`/${currentGlimpseName}(/.*)?`)
  if (rx.test(pathname)) {
    return pathname.replace(rx, '/' + subPathString)
  }

  return appendPath(pathname, subPathString)
}

export function getStepName(glimpseName, routes) {
  let glimpseRouteIndex = routes.findIndex(route => route.path === glimpseName)
  return (routes[glimpseRouteIndex + 1] || {}).path
}

export function assertInitialStep(flowName, initialStep, nextState, replace) {
  const stepName = getStepName(flowName, nextState.routes)
  if (Array.isArray(initialStep) && initialStep.indexOf(stepName) < 0) {
    replace(glimpseRoute(flowName, initialStep[0]))
  }
  if (typeof initialStep === 'string' && stepName !== initialStep) {
    replace(glimpseRoute(flowName, initialStep))
  }
}

export function assertNoDeepLink(flowName, initialStep, nextState, replace) {
  if (getStepName(flowName, nextState.routes) !== initialStep) {
    replace(glimpselessRoute(flowName))
  }
}

// we need this since the action on the nextstate is for some reason different than the one
// we'll get on the `location` param (which is always `POP`...)
export function assignActionToRouterParams(nextState) {
  nextState.params.action = nextState.location.action
}

export function completeCipVerification(campaignId) {
  const successUrl = `/pools/c/${campaignId}/send/cip`,
    returnUrl = `/pools/c/${campaignId}`,
    homeInfoParams = `?feature=${FLOW_DETAILS.FEATURE}&entryPoint=${
      CIP_FLOW_URL.ENTRY_POINT
    }&subFlow=${FLOW_DETAILS.SUB_FLOW}&successUrl=${encodeURIComponent(
      successUrl
    )}&returnUrl=${encodeURIComponent(returnUrl)}`
  return `${CIP_FLOW_URL.HOMEINFO_URL}${homeInfoParams}`
}

export const pathNameToFTPI = {
  types: 'dw_giving_intro_fundraiser_dismiss_pressed',
  nonprofit: 'dw_giving_find_fundraiser_charity_dismiss_pressed',
  basics: 'dw_giving_create_fundraiser_dismiss_pressed',
  story: 'dw_giving_fundraiser_tell_story_dismiss_pressed',
  cover: 'dw_giving_fundraiser_add_photo_dismiss_pressed',
  'select-cover': 'dw_giving_fundraiser_select_photo_dismiss_pressed',
}
