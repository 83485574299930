import { baseUrl, campaignUrl as generalCampaignUrl } from 'shared/campaign_url'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts.js'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export function hubPageUrl() {
  return baseUrl(location.hostname, GLOBAL_CONSTS.REQUEST_URI)
}

// returns a url to campaign page with all the arguments filled based on the client's hostname
export function campaignUrl(campaignId, meDomain = false) {
  // don't show .me domain in staging (no support for this)
  const shouldHaveMeDomain =
    meDomain &&
    location.hostname.indexOf('.qa.paypal.com') === -1 &&
    location.hostname.indexOf('.qa.paypal.cn') === -1 &&
    location.hostname.indexOf('.stage.paypal.cn') === -1 &&
    location.hostname.indexOf('.stage.paypal.com') === -1
  return generalCampaignUrl(
    campaignId,
    location.hostname,
    GLOBAL_CONSTS.REQUEST_URI,
    shouldHaveMeDomain
  )
}

export function campaignUrlWithMeDomain(campaignId, campaignType) {
  return campaignUrl(campaignId, isMeDomainAllowed(campaignType))
}

// a beautified url helper that drops the https protocol (for display purposes)
export function campaignUrlForDisplay(campaignId, campaignType) {
  const url = campaignUrl(campaignId, isMeDomainAllowed(campaignType))
  return url.replace('https://', '')
}

function isMeDomainAllowed(campaignType) {
  return campaignType === CROWD_FUNDING ? false : true
}
