import { DEAFULT_COVER_IMAGES } from '../shared_consts'
import { getDefaultCover } from './poolCategory'

export function isImageDefaultCover(image, category) {
  //do not scan or upload the image to mediaserve if image is from default image covers
  //those image urls are trusted images from paypal CDN
  return (
    DEAFULT_COVER_IMAGES.indexOf(image) > -1 ||
    image === getDefaultCover(category)
  )
}
