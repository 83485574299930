import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'
import { getDefaultCurrencyCode } from 'intl'
import { colocate } from 'shared/helpers/obj_helpers'
import { encodeCampaignId } from 'shared/campaign_url'
import { serializeCampaignToServer as serialize } from '../../lib/serializer'
import { AMOUNT_TYPES, STORAGE_EXPIRATION } from 'shared/shared_consts'
import { endOfDay } from 'shared/helpers/datetime_helpers'
import { INFLATE as INFLATE_USER_TXNS } from './user_txns'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { saveTxnInCookie } from 'lib/cookies_utils'
import { trackImpression } from 'lib/fpti_analytics'
import { LOCATION_CHANGE } from 'react-router-redux'
import * as venice from 'lib/venice_bridge'
import { CREATE_DNW_TRANSACTION_SUCCESS } from './dnw_transaction'
import _get from 'lodash/get'

export const NEW_CAMPAIGN_ID = '__new_campaign__'
export const SET_CURRENT_CAMPAIGN_ID = 'pools/campaign/SET_CURRENT_CAMPAIGN_ID'

const DEFAULT_PAN = 50

const CREATE = 'pools/campaign/CREATE'
const DELETE = 'pools/campaign/DELETE'

const SET_DATA = 'pools/campaign/SET_DATA'

const RESET_REMOTE_OPERATION = 'pools/campaign/RESET_REMOTE_OPERATION'
const START_CAMPAIGN_REMOTE_OPERATION =
  'pools/campaign/START_CAMPAIGN_REMOTE_OPERATION'
const FINISH_CAMPAIGN_REMOTE_OPERATION =
  'pools/campaign/FINISH_CAMPAIGN_REMOTE_OPERATION'
const FAILED_CAMPAIGN_REMOTE_OPERATION =
  'pools/campaign/FAILED_CAMPAIGN_REMOTE_OPERATION'

export const CAMPAIGN_CREATED = 'pools/campaign/CAMPAIGN_CREATED'
export const FINISH_CHIP_IN_SUCCESSFULLY =
  'pools/campaign/FINISH_CHIP_IN_SUCCESSFULLY'
export const FINISH_WITHDRAW_SUCCESSFULLY =
  'pools/campaign/FINISH_WITHDRAW_SUCCESSFULLY'
export const SET_TXN_LIST = 'pools/campaign/SET_TXN_LIST'
export const SET_ACTIVITIES_LIST = 'pools/campaign/SET_ACTIVITIES_LIST'
export const SET_ORGANIZER_UPDATE_LIST =
  'pools/campaign/SET_ORGANIZER_UPDATE_LIST'
export const DELETE_ORGANIZER_UPDATE = 'pools/campaign/DELETE_ORGANIZER_UPDATE'
export const ADD_ORGANIZER_UPDATE = 'pools/campaign/ADD_ORGANIZER_UPDATE'
export const EDIT_ORGANIZER_UPDATE = 'pools/campaign/EDIT_ORGANIZER_UPDATE'
export const APPEND_ORGANIZER_UPDATE = 'pools/campaign/APPEND_ORGANIZER_UPDATE'

function resetExpiration() {
  return { persistExpiresAt: new Date().valueOf() + STORAGE_EXPIRATION }
}

function setInCampaign(state, id, obj) {
  return { ...state, [id]: { ...state[id], ...obj, ...resetExpiration() } }
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case START_CAMPAIGN_REMOTE_OPERATION:
      return setInCampaign(state, action.payload.id, {
        operatingRemotely: true,
      })
    case FINISH_CAMPAIGN_REMOTE_OPERATION:
      return setInCampaign(state, action.payload.id, {
        operatingRemotely: false,
        err: null,
      })
    case SET_TXN_LIST: {
      const newTxns = action.payload.response && action.payload.response.txns
      const list =
        action.page === 1
          ? [...newTxns.list]
          : [...state[action.payload.id].transactions, ...newTxns.list]
      const transactionSummary =
        action.page === 1
          ? { ...newTxns.transactionSummary }
          : { ...state[action.payload.id].transactionSummary }
      return setInCampaign(state, action.payload.id, {
        transactionSummary,
        transactions: list,
        current_value: parseFloat(transactionSummary.total_amount),
        net_amount: parseFloat(transactionSummary.net_amount),
        moneybox_amount: parseFloat(transactionSummary.total_available_balance),
      })
    }
    case SET_ORGANIZER_UPDATE_LIST: {
      const organizerUpdate = action?.payload?.response
      return setInCampaign(state, action.payload.id, {
        organizerUpdate,
      })
    }
    case DELETE_ORGANIZER_UPDATE: {
      const organizerUpdateList =
        state[action.payload.campaignId]?.organizerUpdate?.items || []
      const totalOrganiserUpdates =
        state[action.payload.campaignId]?.organizerUpdate?.total || 0

      const updatedData = organizerUpdateList.filter(
        ins => action.payload.id !== ins.fou_id
      )
      return setInCampaign(state, action.payload.campaignId, {
        organizerUpdate: {
          ...(state[action.payload.campaignId]?.organizerUpdate || {}),
          items: updatedData,
          total: totalOrganiserUpdates - 1,
        },
      })
    }

    case ADD_ORGANIZER_UPDATE: {
      const list =
        state[action.payload.campaignId]?.organizerUpdate?.items || []
      const totalOrganiserUpdates =
        state[action.payload.campaignId]?.organizerUpdate?.total || 0

      const newList = [action.payload.response, ...list]
      return setInCampaign(state, action.payload.campaignId, {
        organizerUpdate: {
          ...(state[action.payload.campaignId]?.organizerUpdate || {}),
          items: newList,
          total: totalOrganiserUpdates + 1,
        },
      })
    }
    case APPEND_ORGANIZER_UPDATE: {
      const previousList = _get(
        state[action.payload.id],
        'organizerUpdate.items',
        []
      )

      return setInCampaign(state, action.payload.id, {
        organizerUpdate: {
          ...action.payload.response,
          items: [
            ...previousList,
            ..._get(action.payload, 'response.items', []),
          ],
        },
      })
    }
    case EDIT_ORGANIZER_UPDATE: {
      const organizerUpdateData =
        state[action.payload.campaignId]?.organizerUpdate?.items || []

      const udpatedList = organizerUpdateData.reduce((updatableList, ins) => {
        if (action.payload.response.fou_id === ins.fou_id) {
          updatableList.push(action.payload.response)
        } else {
          updatableList.push(ins)
        }
        return updatableList
      }, [])
      return setInCampaign(state, action.payload.campaignId, {
        organizerUpdate: {
          ...(state[action.payload.campaignId]?.organizerUpdate || {}),
          items: udpatedList,
        },
      })
    }

    case SET_ACTIVITIES_LIST: {
      const newActvities =
        action.payload.response && action.payload.response.txns
      const activitiyList =
        action.page === 1
          ? [...newActvities.list]
          : [...state[action.payload.id].activities, ...newActvities.list]
      const transactionSummary =
        action.page === 1
          ? { ...newActvities.transactionSummary }
          : { ...state[action.payload.id].transactionSummary }
      return setInCampaign(state, action.payload.id, {
        transactionSummary,
        activities: activitiyList,
        current_value: parseFloat(transactionSummary.total_amount),
        net_amount: parseFloat(transactionSummary.net_amount),
        moneybox_amount: parseFloat(transactionSummary.total_available_balance),
      })
    }

    case RESET_REMOTE_OPERATION:
      return {
        ...state,
        ...setInCampaign(state, action.payload.id, {
          operatingRemotely: false,
          err: null,
        }),
        last_creation_id: null,
      }
    case FAILED_CAMPAIGN_REMOTE_OPERATION:
      return setInCampaign(state, action.payload.id, {
        operatingRemotely: false,
        err: action.payload.err,
      })
    case CREATE: {
      const {
        owner,
        currencyCode,
        campaignType,
        charity,
        type,
      } = action.payload
      return {
        ...state,
        [NEW_CAMPAIGN_ID]: {
          id: NEW_CAMPAIGN_ID,
          owner,
          currency: currencyCode,
          charity,
          campaign_type: campaignType,
          start_date: new Date(),
          current_value: 0,
          can_chip_in: true,
          can_donate: true,
          preview: true, // new campaigns that haven't been saved to server are in preview

          // Default values for new campaign
          share_progress: true,
          share_contributors: true,
          share_contributions: true,
          background_pan: DEFAULT_PAN,
          amount_type: AMOUNT_TYPES.any,
          pledge: 0,
          npid: charity?.nonprofit_id,
          type,

          ...resetExpiration(),
        },
      }
    }
    case DELETE: {
      return setInCampaign(state, action.payload.id, { deleted: true })
    }
    case CAMPAIGN_CREATED:
      return {
        ...state,
        [action.payload.newId]: {
          ...state[NEW_CAMPAIGN_ID],
          id: action.payload.newId,

          // the assumption here is that when we have an id, we're saved in the server, therefore we're
          // no longer in preview mode.
          preview: false,

          ...resetExpiration(),
        },
        last_creation_id: action.payload.newId,
      }
    case SET_DATA:
      return setInCampaign(state, action.payload.id, action.payload.data)
    case INFLATE_USER_TXNS: {
      const id = action.payload.id
      const totalInflatedAmount = action.payload.list.reduce((sum, txn) => {
        return txn.withdraw ? sum : sum + txn.amount
      }, 0)
      const totalMoneyBoxInflatedAmount = action.payload.list.reduce(
        (sum, txn) => {
          return txn.withdraw ? sum - txn.amount : sum
        },
        0
      )
      return {
        ...state,
        [id]: {
          ...state[id],
          current_value:
            (state[id].current_value ? state[id].current_value : 0) +
            totalInflatedAmount,
          moneybox_amount:
            (state[id].moneybox_amount ? state[id].moneybox_amount : 0) +
            totalMoneyBoxInflatedAmount,
          activities: [...action.payload.list, ...state[id].activities],
          ...resetExpiration(),
        },
      }
    }
    case LOCATION_CHANGE: {
      return {
        ...state,
        operatingRemotely: false,
      }
    }
    case CREATE_DNW_TRANSACTION_SUCCESS: {
      const { amount, campaign_id } = action.payload.txn
      const prevTxnCount = action.payload.prevTxnCount
      const transactionSummary = _get(
        state[campaign_id],
        'transactionSummary',
        {}
      )
      let contributionsCount = _get(
        state[campaign_id],
        'transactionSummary.total_number_of_contributions',
        0
      )
      let totalTransactions = _get(
        state[campaign_id],
        'transactionSummary.total_number_of_transactions',
        0
      )
      if (isNaN(prevTxnCount) || prevTxnCount === contributionsCount) {
        contributionsCount = contributionsCount + 1
        totalTransactions = totalTransactions + 1
      }
      //if transaction summary is not there transaction summary will be initialised with 0 value of these props
      transactionSummary.total_number_of_contributions = contributionsCount
      transactionSummary.total_number_of_transactions = totalTransactions

      return {
        ...state,
        [campaign_id]: {
          ...state[campaign_id],
          current_value:
            (state[campaign_id].current_value
              ? state[campaign_id].current_value
              : 0) + amount,
          txn_count: state[campaign_id].txn_count + 1,
          transactionSummary,
          transactions: [
            action.payload.txn,
            ...state[campaign_id].transactions,
          ],
        },
      }
    }
    case SET_CURRENT_CAMPAIGN_ID: {
      return {
        ...state,
        selectedCampaignId: action.payload,
      }
    }
    default:
      return state
  }
}

export function startRemoteOperation(id) {
  return { type: START_CAMPAIGN_REMOTE_OPERATION, payload: { id } }
}

export function finishRemoteOperation(id) {
  return { type: FINISH_CAMPAIGN_REMOTE_OPERATION, payload: { id } }
}

export function failedCampaignRemoteOperation(id, err) {
  return { type: FAILED_CAMPAIGN_REMOTE_OPERATION, payload: { id, err } }
}

export function resetRemoteOperation(id) {
  return { type: RESET_REMOTE_OPERATION, payload: { id } }
}

export function create(campaignType, type) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE,
      payload: {
        owner: getState().current_user,
        currencyCode:
          getState().current_user?.primary_currency_code ||
          getDefaultCurrencyCode(),
        campaignType,
        type,
        charity: getState().charity?.npoCharity,
      },
    })
  }
}

export function deleteCampaign(id) {
  return { type: DELETE, payload: { id } }
}

export function campaignCreated(newId) {
  return { type: CAMPAIGN_CREATED, payload: { newId } }
}

export function setData(id, data) {
  if (data.goal != null) {
    data.goal = parseFloat(data.goal)
  }

  if (data.end_date != null) {
    data.end_date = data.end_date
      ? endOfDay(new Date(data.end_date)).toISOString()
      : undefined
  }

  if (data.background_pan != null) {
    data.background_pan = parseInt(data.background_pan, 10)
  }

  if (data.share_progress === false) {
    // YES, this must be like that to differentiate the case where it's not there
    data.share_amount = false
  }

  return { type: SET_DATA, payload: { id, data } }
}

export function createRemotely() {
  return (dispatch, getState) => {
    const campaignData = getCampaignSelector(getState(), {
      campaignId: NEW_CAMPAIGN_ID,
    })
    dispatch(startRemoteOperation(NEW_CAMPAIGN_ID))
    return fetchClient()
      .request(routes.campaign.api.index(), {
        method: 'POST',
        body: serialize(campaignData),
      })
      .then(response => {
        const campaignId = response.campaign.id
        trackImpression({
          pageName: 'campaign-create-success',
          campaignId: campaignId,
        })
        // since the success page doesn't have a route
        venice.campaignCreated()
        dispatch(finishRemoteOperation(NEW_CAMPAIGN_ID))
        dispatch(campaignCreated(encodeCampaignId(campaignId)))
      })
      .catch(err => {
        console.error(err) // eslint-disable-line
        dispatch(failedCampaignRemoteOperation(NEW_CAMPAIGN_ID, err))
      })
  }
}

export function updateRemotely(id, data) {
  return (dispatch, getState) => {
    const campaignData = getCampaignSelector(getState(), { campaignId: id })

    data = colocate(data, 'background_image', 'background_image_filename')

    // skip the server if we're in preview since the campaign doesn't exist there yet
    if (campaignData.preview || campaignData.id === NEW_CAMPAIGN_ID) {
      dispatch(setData(id, data))
      return Promise.resolve()
    }

    const body = serialize(data, { alwaysIncludePan: true })

    // these fields are not editable for GNC
    if (campaignData.charity) {
      delete body.category
    }
    //discoverable is not editable field
    if (campaignData.discoverable) {
      delete body.non_discoverable
    }
    dispatch(startRemoteOperation(id))
    return fetchClient()
      .request(routes.campaign.api.id({ id }), {
        method: 'PATCH',
        body,
      })
      .then(response => {
        data.can_chip_in = response.can_chip_in
        if (data.title) {
          document.title = data.title
        }
        data.background_image = _get(response, 'background_image', '')
        data.background_thumbnail_image = _get(
          response,
          'background_thumbnail_image',
          ''
        )
        data.event_name = _get(response, 'title', '')
        dispatch(setData(id, data))
        dispatch(finishRemoteOperation(id))
      })
      .catch(err => {
        err.updateData = data
        dispatch(failedCampaignRemoteOperation(id, err))
        return Promise.reject(err)
      })
  }
}

export function deleteRemotely(id) {
  return (dispatch, getState) => {
    dispatch(startRemoteOperation(id))
    return fetchClient()
      .request(routes.campaign.api.id({ id }), {
        method: 'DELETE',
      })
      .then(response => {
        dispatch(deleteCampaign(id))
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(id, err))
        return Promise.reject(err)
      })
  }
}

export function endNowRemotely(id) {
  return (dispatch, getState) => {
    const campaignData = getCampaignSelector(getState(), { campaignId: id })

    // fail it if we're in preview since it's irrelevant
    if (campaignData.preview || campaignData.id === NEW_CAMPAIGN_ID) {
      return Promise.reject()
    }

    dispatch(startRemoteOperation(id))
    return fetchClient()
      .request(routes.campaign.api.end({ id }), {
        method: 'POST',
      })
      .then(response => {
        dispatch(
          setData(id, {
            end_date: response.end_date,
            can_chip_in: response.can_chip_in,
          })
        )
        dispatch(finishRemoteOperation(id))
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(id, err))
        return Promise.reject(err)
      })
  }
}

export function withdrawRemotely(id, amount) {
  return (dispatch, getState) => {
    const campaignData = getCampaignSelector(getState(), { campaignId: id })

    // fail it if we're in preview since it's irrelevant
    if (campaignData.preview || campaignData.id === NEW_CAMPAIGN_ID) {
      return Promise.reject()
    }

    dispatch(startRemoteOperation(id))
    return fetchClient()
      .request(routes.campaign.api.withdraw({ id }), {
        method: 'POST',
        body: { amount },
      })
      .then(response => {
        const { payment_id: paymentId } = response
        const date = new Date().toISOString()
        const txn = {
          paymentId,
          amount,
          campaignId: id,
          pending: true,
          withdraw: true,
          date,
        }
        saveTxnInCookie(id, getState().current_user.id, txn)
        dispatch({
          type: FINISH_WITHDRAW_SUCCESSFULLY,
          payload: { txn, withdrawAmount: amount },
        })
        dispatch(finishRemoteOperation(id))
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(id, err))
        return Promise.reject(err)
      })
  }
}

export function chipInRemotely(id, fields) {
  return (dispatch, getState) => {
    dispatch(startRemoteOperation(id))
    return fetchClient()
      .request(routes.campaign.api.chip_in({ id }), {
        method: 'POST',
        body: { ...fields },
      })
      .then(response => {
        const { payment_id: paymentId } = response
        const { amount, anonymous } = fields
        const date = new Date().toISOString()
        const txn = {
          paymentId,
          pending: true,
          amount: parseFloat(amount),
          campaignId: id,
          anonymous,
          date,
        }
        const sender = {
          id: getState().current_user.id,
          name:
            getState().current_user.business_name ||
            getState().current_user.full_name,
          photo: getState().current_user.profile_photo,
        }
        saveTxnInCookie(id, getState().current_user.id, txn)
        dispatch({
          type: FINISH_CHIP_IN_SUCCESSFULLY,
          payload: { txn, sender },
        })
        dispatch(finishRemoteOperation(id))
      })
      .catch(err => {
        dispatch(failedCampaignRemoteOperation(id, err))
        return Promise.reject(err)
      })
  }
}
