import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'
import { SET_TXN_LIST, SET_ACTIVITIES_LIST } from './campaign'

export function showMoreTxns(campaignId, page, pageSize, transactionType) {
  // show more actually fetches just the photos of the additional txns, since the txns are already in the client.
  return (dispatch, getState) => {
    return fetchClient()
      .request(routes.contributors.api.transactions(), {
        method: 'GET',
        query: {
          page,
          pageSize,
          campaignId: campaignId,
          contribution_type: transactionType || 'CONTRIBUTION',
        },
      })
      .then(response => {
        if (transactionType === 'ALL') {
          dispatch(
            finishShowMoreActivities(page, campaignId, {
              ...response,
            })
          )
        } else {
          dispatch(
            finishShowMoreTxns(page, campaignId, {
              ...response,
            })
          )
        }
        return response
      })
      .catch(e => {
        dispatch(finishShowMoreTxns(page, {}))
      })
  }
}

export function finishShowMoreTxns(page, id, response) {
  return { page, type: SET_TXN_LIST, payload: { id, response } }
}

export function finishShowMoreActivities(page, id, response) {
  return { page, type: SET_ACTIVITIES_LIST, payload: { id, response } }
}
