import React from 'react'
import PropTypes from 'prop-types'

import ModalWizard from 'components/modal_wizard'
import { routerShape } from 'react-router/lib/PropTypes'
import { withRouter } from 'react-router'
import { trackFPTI } from 'lib/fpti_utils'
import { get } from 'lodash'
import { FOU_LIMIT_REACHED_ERROR } from 'shared/shared_consts'

import { exitGlimpse, isForward } from 'lib/glimpse'

class EditGlimpse extends React.Component {
  static propTypes = {
    operatingRemotely: PropTypes.bool,
    children: PropTypes.any,
    routes: PropTypes.array.isRequired,
    params: PropTypes.object,
    location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    router: routerShape.isRequired,
    route: PropTypes.any,
    progressValue: PropTypes.number,
    goalReached: PropTypes.bool,
    charity: PropTypes.object,
    campaign: PropTypes.object,
    campaignId: PropTypes.string,
  }

  handleClose = () => {
    //reload transaction when user is coming from transfer route
    const reloadTransactions = this.props?.location?.pathname.includes(
      'transfer'
    )
      ? true
      : false
    const route = {
      pathname: '/pools',
      state: { reloadTransactions, campaignId: this.props.campaignId },
    }

    if (this.props.route.path === 'viewupdates') {
      //We need to work on glimpse routing and fix this later. This is temporary solve
      //When doner clicks on close ICON on organizer update sheet, take the user to fundraiser page
      this.props.router.push({ pathname: `/pools/c/${this.props.campaignId}` })
    } else {
      exitGlimpse('edit', this.props.router, route, true)
    }
    const getRoutes = get(this.props, 'routes')
    const routeName = getRoutes[getRoutes.length - 1].path
    const campaign = this.props.campaign
    const count = get(this.props, 'campaign.organizerUpdate.total', 0)

    if (routeName === 'viewupdates') {
      trackFPTI(
        campaign,
        'click',
        'dw_giving_organizer_update_list_dismiss_pressed',
        'close',
        '',
        count
      )
    } else if (routeName === ':fouId/share') {
      trackFPTI(
        campaign,
        'click',
        'dw_giving_share_update_dismiss_pressed',
        'close'
      )
    } else if (routeName === ':fouId/updatedetails') {
      trackFPTI(
        campaign,
        'click',
        'dw_giving_update_detail_dismiss_pressed',
        'close',
        count
      )
    } else if (routeName === 'error') {
      let errorObject = campaign.err
      const errName = get(campaign, 'err.name')

      if (errName === FOU_LIMIT_REACHED_ERROR) {
        errorObject = {
          error_desc: 'reached_limit',
          error_code: '422',
        }
      }
      trackFPTI(
        campaign,
        'click',
        'dw_giving_post_update_error_dismiss_pressed',
        'close',
        errorObject,
        count
      )
    } else {
      trackFPTI(
        campaign,
        'click',
        'dw_giving_donor_update_list_dismiss_pressed',
        'close',
        count
      )
      trackFPTI(
        campaign,
        'click',
        'dw_giving_manage_fundraiser_dismiss_pressed',
        'close'
      )
    }
  }

  render() {
    const child = React.Children.only(this.props.children)
    const stepName =
      this.props.routes && this.props.routes[this.props.routes.length - 1].path
    const isDelete = stepName === 'delete'
    const isPadding = stepName === 'viewupdates'
    return (
      <ModalWizard
        movingForward={this.props.location && isForward(this.props.location)}
        hideBack={this.props.params?.hideBack}
        glimpse={true}
        showSpinner={!isDelete && this.props.operatingRemotely}
        onClose={this.handleClose}
        route={this.props.route}
        isPadding={isPadding}
      >
        {React.cloneElement(child, { key: stepName })}
      </ModalWizard>
    )
  }
}

export default withRouter(EditGlimpse)
