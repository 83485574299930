import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './introduction.less'
import { Sheet } from '@paypalcorp/pp-react'
import { Button } from '@paypalcorp/pp-react-buttons'
import Pictograms from 'components/common/pictograms'
import { POOL_TYPE } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import { trackClick, trackImpression } from 'lib/fpti_analytics'
import clsx from 'clsx'
import PrivateIntroductionContent from './partials/private'
import PersonalIntroductionContent from './partials/personal'
import CharityIntroductionContent from './partials/charity'
import Disclaimer from './partials/disclaimer'

const eventDetails = Object.freeze({
  [POOL_TYPE.PERSONAL]: {
    startEventName: 'dw_giving_create_listed_fundraiser_info_start_pressed',
    closeEventName: 'dw_giving_create_listed_fundraiser_info_dismiss_pressed',
    feesDetailsClickEventName:
      'dw_giving_create_listed_fundraiser_info_disclaimer_pressed',
    pageImpression: 'dw_giving_create_listed_fundraiser_info_screen_shown',
  },
  [POOL_TYPE.PRIVATE]: {
    startEventName:
      'dw_giving_create_small_group_fundraiser_info_start_pressed',
    closeEventName:
      'dw_giving_create_small_group_fundraiser_info_dismiss_pressed',
    feesDetailsClickEventName: '',
    pageImpression: 'dw_giving_create_small_group_fundraiser_info_screen_shown',
  },
  [POOL_TYPE.CHARITY]: {
    startEventName: 'dw_giving_create_charity_fundraiser_info_start_pressed',
    closeEventName: 'dw_giving_create_charity_fundraiser_info_dismiss_pressed',
    feesDetailsClickEventName: '',
    pageImpression: 'dw_giving_create_charity_fundraiser_info_screen_shown',
  },
})

class Introduction extends React.Component {
  static propTypes = {
    disclaimer: PropTypes.string,
    type: PropTypes.string,
    hideFullHalfSheet: PropTypes.func,
    onPageLoad: PropTypes.func,
    gnHref: PropTypes.string,
    gncHref: PropTypes.string,
    formattedMaxGoalAmount: PropTypes.string,
    countryCode: PropTypes.string,
  }
  state = {
    isSheetOpen: true,
    isBtnProcessing: false,
  }

  event = eventDetails[this.props.type]

  closeSheet = e => {
    trackClick({
      clickName: this.event.closeEventName,
      data: {
        event_name: this.event.closeEventName,
        dismiss_method: e?.type === 'touchstart' ? 'background' : 'close',
      },
    })
    this.setState({ isSheetOpen: false })
    this.props.hideFullHalfSheet()
  }

  render() {
    const { type, countryCode } = this.props
    // default values for POOL_TYPE.PERSONAL
    let image = 'announce'
    let dataTestId = 'intro-personal-pictogram'
    // TODO: DTRAINIER-5298 Extract pictograms logic to component
    let pathname = `/pools/c/create-crowdfunding/basics?type=${
      POOL_TYPE.PERSONAL
    }`
    let fundraiserButton =
      'components.pages.halfFullSheet.half_full_sheet.start_fundraiser'
    if (type === POOL_TYPE.CHARITY) {
      image = 'collect-donations'
      fundraiserButton =
        'components.pages.halfFullSheet.half_full_sheet.start_fundraiser_charity'
      pathname = '/pools/c/create-crowdfunding/nonprofit'
      dataTestId = 'intro-gnc-half-sheet-image'
    } else if (type === POOL_TYPE.PRIVATE) {
      image = 'create-profile'
      fundraiserButton = 'flow.private.intro.cta_collect_money'
      dataTestId = 'intro-unlisted-fundraiser-half-sheet-image'
      pathname = `/pools/c/create-crowdfunding/basics?type=${POOL_TYPE.PRIVATE}`
    }

    // Save the type of pool(flow) selected on page load
    this.props.onPageLoad(type)

    if (this.props.type) {
      trackImpression({
        event_name: this.event.pageImpression,
      })
    }

    return (
      <Sheet
        isOpen={this.state.isSheetOpen}
        onCloseComplete={this.closeSheet}
        mobileFixedHeight="90"
        noCloseButton={this.state.isBtnProcessing}
        skipFormFocus={true}
        className={clsx({
          'vx_has-spinner-large': this.state.isBtnProcessing,
        })}
      >
        <div styleName="sheet__pictogram">
          <Pictograms
            classname="category-selection-image"
            svg={image}
            dataTestId={dataTestId}
          />
        </div>

        <div styleName="sheet__body">
          {type === POOL_TYPE.PRIVATE && (
            <PrivateIntroductionContent
              formattedMaxGoalAmount={this.props.formattedMaxGoalAmount}
            />
          )}
          {type === POOL_TYPE.PERSONAL && (
            <PersonalIntroductionContent
              formattedMaxGoalAmount={this.props.formattedMaxGoalAmount}
            />
          )}
          {type === POOL_TYPE.CHARITY && (
            <CharityIntroductionContent
              formattedMaxGoalAmount={this.props.formattedMaxGoalAmount}
            />
          )}
        </div>

        <Disclaimer
          event={this.event}
          fundraiserType={type}
          countryCode={countryCode}
        />

        <div styleName="sheet__cta">
          <Button
            data-testid="intro-start-fundraiser"
            as="a"
            href={pathname}
            onClick={() => {
              this.setState({ isBtnProcessing: true })
              trackClick({
                clickName: this.event.startEventName,
                data: {
                  event_name: this.event.startEventName,
                },
              })
            }}
          >
            <Message id={fundraiserButton} />
          </Button>
        </div>
      </Sheet>
    )
  }
}

export default cssModules(Introduction, styles)
