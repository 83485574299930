import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import Dropzone from 'react-dropzone'
import _ from 'lodash'

import styles from './image_upload_field.less'

import {
  normalizeImage,
  isValidImageFileType,
  base64ToHex,
} from '../../../lib/image_utils'
import {
  ALLOWED_IMAGE_TYPES,
  COVER_IMAGE_UPLOAD_ERRORS,
  UNALLOWED_ENCODED_IMAGE_TYPES,
} from 'shared/shared_consts'

class ImageUploadField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dynamicClass: '',
    }
    this.dropZone = React.createRef()
  }

  static propTypes = {
    children: PropTypes.any,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    restrictedTotalSize: PropTypes.number,
    onImageLoad: PropTypes.func,
  }

  preventDefault = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleOpenFileDialog = () => {
    this.input.click()
  }

  openFileDialog = () => {
    this.dropZone.current.open()
  }

  handleFileSelect = fileSelectEvent => {
    this.readFiles(fileSelectEvent.target.files)
  }

  readFiles = files => {
    let fileReader = new FileReader()
    let file
    this.props.onImageLoad(true)
    fileReader.onload = onLoadEvent => {
      let url = onLoadEvent.target.result
      // Get file signature from magic bytes
      const magicNumber = url.split('base64,')[1].slice(0, 6)
      const fileSignature = base64ToHex(magicNumber)
      // Look for specific unallowed file types using file signature
      if (UNALLOWED_ENCODED_IMAGE_TYPES.includes(fileSignature)) {
        const error = { code: COVER_IMAGE_UPLOAD_ERRORS.FILE_INVALID_TYPE }
        this.props.onError(error)
        this.props.onImageLoad(false)
        this.setState(prevStage => {
          return { dynamicClass: 'not-supported' }
        })
        return null
      }
      let options = { mimeType: file.type }
      if (this.props.onChange) {
        if (this.props.restrictedTotalSize) {
          const restrictedSize = this.props.restrictedTotalSize
          options.normalizeImageSize = true
          normalizeImage(
            url,
            restrictedSize,
            restrictedSize,
            options,
            normalizedUrl => {
              this.props.onChange({
                url: normalizedUrl,
                name: file && file.name,
              })
              this.props.onImageLoad(false)
            }
          )
        } else {
          normalizeImage(url, null, null, options, normalizedUrl => {
            this.props.onChange({ url: normalizedUrl, name: file && file.name })
            this.props.onImageLoad(false)
          })
        }
      }
      this.props.onImageLoad(false)
    }
    file = files[0]

    if (file) {
      fileReader.readAsDataURL(file)
    }
  }

  handleDragEnter = e => {
    this.preventDefault(e)
  }

  handleDragLeave = e => {
    this.preventDefault(e)
    this.setState(prevStage => ({
      dynamicClass: '',
    }))
  }

  handleDragOver = e => {
    this.preventDefault(e)
    const data = e.dataTransfer
    const fileType = data.items[0].type
    this.setState(prevStage => {
      return isValidImageFileType(fileType)
        ? { dynamicClass: 'dragover' }
        : { dynamicClass: 'not-supported' }
    })
  }

  handleDragDrop = (files, evt) => {
    const error = _.get(evt, '0.errors.0')
    if (error) {
      return this.props.onError(error)
    }
    const fileType = files[0].type
    if (isValidImageFileType(fileType)) this.readFiles(files)
  }

  render() {
    return (
      <div
        styleName={`dragzone ${this.state.dynamicClass}`}
        data-testid="image-file-upload"
      >
        <Dropzone
          multiple={false}
          noDragEventsBubbling={true}
          accept={ALLOWED_IMAGE_TYPES}
          onDrop={this.handleDragDrop}
          onDragEnter={this.handleDragEnter}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          className={`dragzone ${this.state.dynamicClass}`}
          ref={this.dropZone}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                styleName={`dragzone ${this.state.dynamicClass}`}
              >
                {this.props.children}
                <input {...getInputProps()} data-testid="select-image" />
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    )
  }
}

export default cssModules(ImageUploadField, styles, { allowMultiple: true })
