import * as React from 'react'
import PropTypes from 'prop-types'

import cssModules from 'react-css-modules'
import { Button, Sheet } from '@paypalcorp/pp-react'
import StandardError from 'components/common/standard_error'
import styles from './payments_token_sheet.less'
import { getI18n } from 'intl'

const i18nErrors = getI18n('errors/errors')
const i18n = getI18n('components/common/campaign_eligibility')
const i18nBack = getI18n('components/back_button')
const DONATION_TOKEN_MERCHANT_UNABLE_RECEIVE_MONEY =
  'DONATION_TOKEN_MERCHANT_UNABLE_RECEIVE_MONEY'

class PaymentsTokenSheet extends React.Component {
  static propTypes = {
    hostName: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.function,
    paymentsTokenError: PropTypes.string,
    subTitle: PropTypes.string,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    this.props.onClose()
    location.reload(true)
  }

  render() {
    const { isOpen, paymentsTokenError, hostName } = this.props

    const isDonationTokenMerchantUnableReceiveMoney =
      paymentsTokenError === DONATION_TOKEN_MERCHANT_UNABLE_RECEIVE_MONEY

    return (
      <>
        {isOpen && (
          <Sheet
            hideTitle
            isOpen={this.props.isOpen}
            onClose={this.handleClose}
            title="Donation Error"
            containerClassName={styles['container-class']}
            mobileFixedHeight="90"
          >
            <div styleName="error-wrapper">
              {isDonationTokenMerchantUnableReceiveMoney ? (
                <StandardError
                  center
                  backButton={false}
                  iconType="error"
                  subtitle={i18nErrors('donation_error_1')}
                  title={i18nErrors('generic_error_1')}
                  extraContent={
                    <a href={`https://${hostName}/generosity-network`}>
                      {i18nErrors('cta_error_1')}
                    </a>
                  }
                />
              ) : (
                <StandardError
                  center
                  backButton={false}
                  iconType="error"
                  title={i18n('title.ERROR')}
                  extraContent={
                    <Button
                      styleName="back-button"
                      secondary
                      onClick={this.handleClose}
                    >
                      {i18nBack('back')}
                    </Button>
                  }
                />
              )}
            </div>
          </Sheet>
        )}
      </>
    )
  }
}
export default cssModules(PaymentsTokenSheet, styles)
