import EXIF from 'exif-js'
import { includes } from 'lodash'
import { ALLOWED_IMAGE_TYPES } from 'shared/shared_consts'

//options are:
//normalizeImageSize - decrease image size to fit in toWidth and toHeight
export function normalizeImage(
  dataUrl,
  toWidth,
  toHeight,
  { normalizeImageSize = false, mimeType = 'image/jpeg' } = {},
  callback
) {
  const img = document.createElement('img')

  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let changed
    let resizeWidth = img.width
    let resizeHeight = img.height

    // PNGs weigh too much
    if (mimeType === 'image/png') {
      mimeType = 'image/jpeg'
    }

    if (normalizeImageSize) {
      if (resizeWidth > toWidth) {
        resizeHeight *= toWidth / resizeWidth
        resizeWidth = toWidth
        changed = true
      }

      if (resizeHeight > toHeight) {
        resizeWidth *= toHeight / resizeHeight
        resizeHeight = toHeight
        changed = true
      }

      if (changed) {
        resizeWidth = Math.floor(resizeWidth)
        resizeHeight = Math.floor(resizeHeight)
        canvas.width = resizeWidth
        canvas.height = resizeHeight
      }
    }

    EXIF.getData(img, () => {
      if (img.exifdata && img.exifdata.Orientation) {
        if (!changed) {
          canvas.width = resizeWidth = img.width
          canvas.height = resizeHeight = img.height
        }

        // in exif orientations 5,6,7,8 the image width and height flips, so we need to flip
        // the canvas dimensions as well
        if (img.exifdata.Orientation >= 5 && img.exifdata.Orientation <= 8) {
          ;[canvas.width, canvas.height] = [canvas.height, canvas.width]
        }

        switch (img.exifdata.Orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, resizeWidth, 0)
            break
          case 3:
            ctx.transform(-1, 0, 0, -1, resizeWidth, resizeHeight)
            break
          case 4:
            ctx.transform(1, 0, 0, -1, 0, resizeHeight)
            break
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0)
            break
          case 6:
            ctx.transform(0, 1, -1, 0, resizeHeight, 0)
            break
          case 7:
            ctx.transform(0, -1, -1, 0, resizeHeight, resizeWidth)
            break
          case 8:
            ctx.transform(0, -1, 1, 0, 0, resizeWidth)
            break
          default:
            ctx.transform(1, 0, 0, 1, 0, 0)
        }
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)
        return callback(canvas.toDataURL(mimeType))
      } else if (changed) {
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)
        return callback(canvas.toDataURL(mimeType))
      }
      return callback(dataUrl)
    })
  }
  img.src = dataUrl
}

export function isValidImageFileType(fileType = '') {
  return includes(ALLOWED_IMAGE_TYPES, fileType)
}

export function base64ToHex(str) {
  const raw = atob(str)
  let result = ''
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16)
    result += hex.length === 2 ? hex : '0' + hex
  }
  return result.toUpperCase()
}
