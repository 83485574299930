import React from 'react'
import PropTypes from 'prop-types'
import { PhysicalCard, Row, Col } from '@paypalcorp/pp-react'
import { Checkbox } from '@paypalcorp/pp-react-checkbox'
import { DEAFULT_COVER_IMAGES } from 'shared/shared_consts'
import styles from '../crowdfunding_select_cover.less'
import cssModules from 'react-css-modules'

const DefaultImages = ({
  defaultImage1,
  defaultImage2,
  setSelectedPhoto,
  selectedPhoto,
}) => {
  return (
    <Row>
      <Col>
        <PhysicalCard
          style={{
            background: `no-repeat url(${DEAFULT_COVER_IMAGES[defaultImage1]})`,
            backgroundSize: 'cover',
            cursor: 'pointer',
            borderRadius: '1rem',
          }}
          onClick={() => setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage1])}
          tabIndex={0}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage1])
            }
          }}
          data-testid="default_images__pysical_card_1"
        />
        <div styleName="image-checkbox-container">
          <Checkbox
            checked={selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage1]}
            onClick={() =>
              setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage1])
            }
            tabIndex={-1}
            data-testid="default_images__checkbox_1"
          />
        </div>
      </Col>
      <Col>
        <PhysicalCard
          style={{
            background: `no-repeat url(${DEAFULT_COVER_IMAGES[defaultImage2]})`,
            backgroundSize: 'cover',
            cursor: 'pointer',
            borderRadius: '1rem',
          }}
          onClick={() => setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage2])}
          tabIndex={0}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage2])
            }
          }}
          data-testid="default_images__pysical_card_2"
        />
        <div styleName="image-checkbox-container">
          <Checkbox
            checked={selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage2]}
            onClick={() =>
              setSelectedPhoto(DEAFULT_COVER_IMAGES[defaultImage2])
            }
            tabIndex={-1}
            data-testid="default_images__checkbox_2"
          />
        </div>
      </Col>
    </Row>
  )
}

DefaultImages.propTypes = {
  defaultImage1: PropTypes.string,
  defaultImage2: PropTypes.string,
  selectedPhoto: PropTypes.string,
  setSelectedPhoto: PropTypes.func,
}

export default cssModules(DefaultImages, styles)
