import { appendPath } from 'lib/route_utils'
import campaignRoute from './campaign'
import hubRoute from './hub'

export default (store, history) => [
  {
    path: GLOBAL_CONSTS.REQUEST_URI,
    onEnter(nextState, replace) {
      if (!nextState.location.state || !nextState.location.state.initial) {
        replace({
          ...nextState.location,
          state: { initial: true },
        })
      }
    },
    indexRoute: hubRoute(store, history),
    childRoutes: [campaignRoute(store, history, 'c')],
  },
  {
    path: '*',
    onEnter() {
      location.href = appendPath(GLOBAL_CONSTS.REQUEST_URI, 'not_found')
    },
  },
]
