import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'
import styles from '../crowdfunding_cover.less'
import CrowdFundingCoverImage from 'components/crowdfunding_cover_image'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { Message } from '@paypalcorp/worldready-react'
import { POOL_TYPE } from 'shared/shared_consts'

const AddPhoto = props => {
  const { categoryImage, coverImage, fields, campaignType } = props
  const { PERSONAL, PRIVATE } = POOL_TYPE
  const contentKeys = [PERSONAL, PRIVATE].includes(campaignType)
    ? {
        title: 'flow.private.cover.header',
        subtitle: 'flow.private.cover.description',
        bullets: [
          'flow.private.cover.bullet_good_image',
          'flow.private.cover.bullet_exact_image',
          'flow.private.cover.bullet_good_size_image',
        ],
      }
    : {
        title: 'components.pages.crowdfunding.crowdfunding_cover.title',
        subtitle:
          'components.pages.crowdfunding.crowdfunding_cover.sub_title_noimage',
        bullets: [
          'components.pages.crowdfunding.crowdfunding_cover.body_text_noimage_1',
          'components.pages.crowdfunding.crowdfunding_cover.body_text_noimage_2',
          'components.pages.crowdfunding.crowdfunding_cover.body_text_noimage_3',
        ],
      }

  if (campaignType === PERSONAL) {
    contentKeys.bullets[1] =
      'components.pages.crowdfunding.crowdfunding_cover.body_text_noimage_personal_2'
  }

  return (
    <>
      <HeadingText size="sm" styleName="title" data-testid="add-photo__title">
        <Message id={contentKeys.title} />
      </HeadingText>
      <BodyText styleName="sub-title" data-testid="add-photo__subtitle">
        <Message id={contentKeys.subtitle} />
      </BodyText>

      <BodyText styleName="sub-title">
        <ul>
          {contentKeys.bullets.map((item, index) => (
            <li key={index}>
              <Message id={item} />
            </li>
          ))}
        </ul>
      </BodyText>

      <div
        data-testid="add-photo__uploader"
        styleName="uploader"
        onClick={() =>
          trackEventNameClick({
            event_name: 'dw_giving_fundraiser_add_photo_upload_pressed',
          })
        }
      >
        <CrowdFundingCoverImage
          fields={fields}
          ref={coverImage}
          categoryImage={categoryImage}
        />
      </div>
    </>
  )
}

AddPhoto.propTypes = {
  categoryImage: PropTypes.string,
  coverImage: PropTypes.any.isRequired,
  fields: PropTypes.object.isRequired,
  campaignType: PropTypes.string.isRequired,
}

export default cssModules(AddPhoto, styles)
