import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './mock_text.less'

class MockText extends React.Component {
  static propTypes = {
    // string or number, so that we could do things like '100%'
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  render() {
    let width = this.props.width
    if (typeof width === 'number') {
      width = width + 'px'
    }
    return (
      <span styleName="mock-text-container">
        <span styleName="mock-text-aligner" />
        <span styleName="mock-text" style={{ width: width }} />
      </span>
    )
  }
}

export default cssModules(MockText, styles)
