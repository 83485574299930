import CampaignBalance from './campaign_balance'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)

  return {
    pledge: campaign.pledge,
    currencyCode: campaign.currency,
    withdraw: campaign.current_value - campaign.moneybox_amount,
    moneyboxAmount: campaign.moneybox_amount,
    netAmount: campaign.net_amount,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignBalance)
