import { trackEnter } from 'lib/glimpse'
import { glimpselessRoute } from 'lib/route_utils'

export default (store, history) => ({
  path: 'guest',
  getComponent(nextState, callback) {
    return null
  },
  onEnter(nextState, replace) {
    trackEnter('guest', nextState, replace)
    replace(glimpselessRoute('guest'))
    return
  },
})
