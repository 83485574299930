import React from 'react'

import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './report_link.less'
import { getI18n } from 'intl'
import { trackClick } from 'lib/fpti_analytics'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import { CaptionText, Link } from '@paypalcorp/pp-react'

const i18n = getI18n('components/report_link')
const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

class ReportLink extends React.Component {
  static propTypes = {
    currentUserCountryCode: PropTypes.string,
    campaignId: PropTypes.string,
    campaignType: PropTypes.string,
    paypalDomain: PropTypes.string,
    charity: PropTypes.object,
  }

  static defaultProps = {
    currentUserCountryCode: 'US',
  }

  getReportPoolLink = (country = 'US') => {
    return `https://${
      this.props.paypalDomain
    }/${country}/smarthelp/contact-us?email=paypalme`
  }

  handleReportPoolClick = () => {
    trackClick({
      clickName: 'report_pool_pressed',
      data: {
        event_name: 'dw_giving_view_fundraiser_report_pressed',
      },
    })
  }

  render() {
    const { currentUserCountryCode } = this.props

    return (
      <CaptionText strong styleName="report-link-container">
        <Link
          href={this.getReportPoolLink(currentUserCountryCode)}
          target="_blank"
          onClick={this.handleReportPoolClick}
        >
          {this.props.campaignType === CROWD_FUNDING
            ? i18n('report_this_campaign')
            : i18n('report_this_pool')}
        </Link>
      </CaptionText>
    )
  }
}

export default cssModules(ReportLink, styles)
