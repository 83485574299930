import React from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import cssModules from 'react-css-modules'
import { getStepName } from 'lib/route_utils'
import { hubPageUrl } from 'lib/campaign_url'
import StandardDialog from 'components/common/standard_dialog'
import ModalWizard from 'components/modal_wizard'
import Modal from 'components/common/modal'
import ModalAnimations from 'components/common/transitions/modal_animations'
import { POOLS_CAMPAIGN_TYPES, SCREEN_NAME_MAPPING } from 'shared/shared_consts'
import {
  trackImpression,
  trackClick,
  trackEventNameClick,
} from 'lib/fpti_analytics'
import styles from './create.less'
import CreateFundraiserError from '../create_fundraiser_error/create_fundraiser_error_container'
import { Message } from '@paypalcorp/worldready-react'

export const TRANSITION_DURATION = 750
export const CREATION_BLOCKS = {
  byKillSwitch: 'kill_switched',
  byCountry: 'blocked',
  byAccountType: 'fundraiser_blocked',
  byUnconfirmed: 'unconfirmed',
  byAccountRestricted: 'account_resrticted',
  byGNAccountRestricted: 'error_account_restricted_title',
  genericError: 'genericError',
  currencyMismatchError: 'currencyMismatchError',
}

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

class CreatePage extends React.Component {
  static propTypes = {
    blockType: PropTypes.oneOf(Object.values(CREATION_BLOCKS)),
    campaignType: PropTypes.string,
    children: PropTypes.any,
    countryName: PropTypes.string,
    creationError: PropTypes.object,
    email: PropTypes.string,
    hideClose: PropTypes.bool,
    location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    operatingRemotely: PropTypes.bool,
    countryCode: PropTypes.string,
    charity: PropTypes.object,
    cdnHost: PropTypes.string,
    params: PropTypes.object,
    routes: PropTypes.array.isRequired,
  }

  state = {
    showCloseDialog: false,
  }

  trackFPTI = (type, eventName, link) => {
    const currentStep = getStepName('create-crowdfunding', this.props.routes)
    let eccd
    if (
      this.props.blockType === CREATION_BLOCKS.byAccountRestricted ||
      this.props.blockType === CREATION_BLOCKS.byGNAccountRestricted
    ) {
      eccd = 'MATCH'
    } else {
      return false
    }

    if (type === 'impression') {
      trackImpression({
        event_name: eventName,
        screen: SCREEN_NAME_MAPPING[currentStep],
        eccd: eccd,
        error_desc: this.props.blockType,
      })
    } else {
      trackClick({
        event_name: eventName,
        eccd: eccd,
        link: link,
      })
    }
  }

  handleDismissEvents = currentStep => {
    const stepToEventNameMapping = {
      types: 'dw_giving_intro_fundraiser_dismiss_pressed',
      basics: 'dw_giving_create_fundraiser_dismiss_pressed',
      story: 'dw_giving_fundraiser_tell_story_dismiss_pressed',
      cover: 'dw_giving_fundraiser_add_photo_dismiss_pressed',
      'select-cover': 'dw_giving_fundraiser_select_photo_dismiss_pressed',
      nonprofit: 'dw_giving_find_fundraiser_charity_dismiss_pressed',
    }
    if (stepToEventNameMapping[currentStep]) {
      trackEventNameClick({
        event_name: stepToEventNameMapping[currentStep],
        dismiss_method: 'close',
      })
    } else {
      this.trackFPTI('click', 'pools_create_error_close_pressed', 'close')
    }
  }

  handleClose = () => {
    const currentStep = getStepName('create-crowdfunding', this.props.routes)
    const currentStepInCreateFlow = getStepName('create', this.props.routes)

    this.handleDismissEvents(currentStep)
    if (
      currentStepInCreateFlow === 'types' ||
      currentStep === 'types' ||
      currentStep === 'publish' ||
      currentStep === 'share/:campaignId' ||
      currentStep === 'basics' ||
      currentStep === 'nonprofit'
    ) {
      this.handleCloseDialogOk()
    } else {
      this.setState({ showCloseDialog: true })
      trackImpression({
        event_name: 'dw_giving_quit_creating_fundraiser_screen_shown',
      })
    }
  }

  closeDialog = () => {
    this.setState({ showCloseDialog: false })
    trackEventNameClick({
      event_name: 'dw_giving_quit_creating_fundraiser_cancel_pressed',
    })
  }

  handleCloseDialogOk = () => {
    trackEventNameClick({
      event_name: 'dw_giving_quit_creating_fundraiser_quit_pressed',
    })
    if (document.referrer.indexOf('/create-crowdfunding') > -1) {
      // sometimes the referrer is the same page
      location.href = hubPageUrl()
    } else if (
      document.referrer.indexOf('/signin') > -1 &&
      window.history.length > 2
    ) {
      window.history.go(-2)
    } else {
      location.href = document.referrer || hubPageUrl()
    }
  }

  getErrorComponent = () => {
    const { charity, blockType } = this.props

    return (
      <Modal onClose={this.handleClose}>
        <CreateFundraiserError blockType={blockType} charity={charity} />
      </Modal>
    )
  }

  render() {
    const child = React.Children.only(this.props.children)
    const stepName = this.props.params.nextStep
    const prevStepName = this.props.params.prevStep
    const isInitialState =
      this.props.location.state && this.props.location.state.initial

    const movingForward = this.props.location.action !== 'POP'
    let transitionNames

    if (prevStepName === 'preview') {
      transitionNames = {
        enter: styles['create-modal-enter'],
        enterActive: styles['create-modal-enter-active'],
        leave: styles['preview-leave'],
        leaveActive: styles['preview-leave-active'],
      }
    } else if (stepName === 'preview') {
      transitionNames = {
        enter: styles['preview-enter'],
        enterActive: styles['preview-enter-active'],
        leave: styles['create-modal-leave'],
        leaveActive: styles['create-modal-leave-active'],
      }
    } else {
      transitionNames = {}
    }

    if (this.props.blockType) {
      return this.getErrorComponent(this.props.blockType)
    }

    const crowdfundingStep = getStepName(
      'create-crowdfunding',
      this.props.routes
    )

    let progressPercent = 0
    let showProgressBar = false
    let previousURL = null
    switch (crowdfundingStep) {
      case 'basics':
        progressPercent = 25
        showProgressBar = true
        if (this.props.charity) {
          previousURL = 'nonprofit'
        }
        break
      case 'nonprofit':
        break
      case 'story':
        progressPercent = 75
        showProgressBar = true
        previousURL = 'basics'
        break
      case 'select-cover':
        previousURL = 'story'
        break
      case 'cover':
        progressPercent = 100
        showProgressBar = true
        previousURL = 'select-cover'
        break
      default:
        break
    }
    return (
      <div>
        {this.state.showCloseDialog && (
          <StandardDialog
            size="medium"
            title={
              <Message id="components.pages.create.create.close_dialog_title" />
            }
            subtitle={
              <Message id="components.pages.create.create.close_dialog_subtitle" />
            }
            closeButtonTitle={
              this.props.campaignType === CROWD_FUNDING ? (
                <Message id="components.pages.create.create.keep_creating_cf_button" />
              ) : (
                <Message id="components.pages.create.create.keep_creating_button" />
              )
            }
            okButtonTitle={
              this.props.campaignType === CROWD_FUNDING ? (
                <Message id="components.pages.create.create.quit_cf_button" />
              ) : (
                <Message id="components.pages.create.create.quit_button" />
              )
            }
            onClose={this.closeDialog}
            onOk={this.handleCloseDialogOk}
            iconName={CROWD_FUNDING ? 'logout' : ''}
          />
        )}
        <ReactCSSTransitionGroup
          transitionName={transitionNames}
          transitionEnterTimeout={TRANSITION_DURATION}
          transitionLeaveTimeout={TRANSITION_DURATION}
          component="div"
          styleName="preview-animation-container"
        >
          <ModalAnimations>
            <ModalWizard
              data-testid="modal_wizard"
              key={'wizard'}
              movingForward={movingForward}
              hideBack={
                (stepName === 'publish' && !this.props.creationError) ||
                isInitialState ||
                !previousURL
              }
              hideClose={this.props.hideClose}
              onClose={this.handleClose}
              isWideView={this.props.campaignType === CROWD_FUNDING}
              progressPercent={progressPercent}
              showProgressBar={showProgressBar}
              previousURL={previousURL}
            >
              {React.cloneElement(child, { key: stepName })}
            </ModalWizard>
          </ModalAnimations>
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

export default cssModules(CreatePage, styles)
