import { DateInput, Calendar } from '@paypalcorp/pp-react'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { endOfDay } from 'shared/helpers/datetime_helpers'
import { useIntl } from 'intl'

DateInputBox.propTypes = {
  onBlur: PropTypes.func,
  disabled: PropTypes.boolean,
  initialValue: PropTypes.string,
  timeZone: PropTypes.string,
  errorText: PropTypes.any,
  label: PropTypes.string,
  dataNemo: PropTypes.string,
  dataTestId: PropTypes.string,
  isCalenderEnable: PropTypes.boolean,
  id: PropTypes.string,
  name: PropTypes.string,
  useEndOfDay: PropTypes.boolean,
}

function DateInputBox(props) {
  const initialValue = props.initialValue
  const [selectedDate, setSelectedDate] = useState(
    new Date(
      initialValue.getFullYear(),
      initialValue.getMonth(),
      initialValue.getDate()
    )
  )
  const [isCalendarVisible, setCalenderVisible] = useState()
  const { formatMessage } = useIntl()

  const handleBlur = evt => {
    setCalenderVisible(false)
    props.onBlur(selectedDate)
  }

  const handleSelection = date => {
    setCalenderVisible(false)

    if (props.useEndOfDay) {
      setSelectedDate(endOfDay(date))
    } else {
      setSelectedDate(date)
    }
  }
  const handleFocus = e => {
    //To Do - Need to find better solution
    document.getElementById('formContent').style.overflow = 'visible'
  }

  useEffect(
    () => {
      props.onBlur(selectedDate)
    },
    [selectedDate]
  )

  const calendarElm = (
    <Calendar
      selectedDate={selectedDate}
      onSelection={handleSelection}
      timeZone={props.timeZone}
      legendSelectedDateLabel={formatMessage(
        `components.common.date_input_box.legend_selected_date_label`
      )}
      legendTodayLabel={formatMessage(
        `components.common.date_input_box.legend_today_label`
      )}
    />
  )

  return (
    <DateInput
      id={props.id}
      label={props.label}
      name={props.name}
      data-nemo={props.dataNemo}
      data-testid={props.dataTestId}
      timeZone={props.timeZone}
      errorText={props.errorText}
      onBlur={handleBlur}
      onFocus={handleFocus}
      calendar={calendarElm}
      selectedDate={selectedDate}
      onSelection={handleSelection}
      isCalendarVisible={isCalendarVisible}
      disabled={props.disabled}
      onShowCalendar={!props.disabled && setCalenderVisible}
    />
  )
}

export default DateInputBox
