import ManageAllPage from './manage_all_page'
import { connect } from 'react-redux'
import { allCampaignsSelector } from 'redux/selectors/campaign'
import { showMoreTxns } from 'redux/modules/txns'
import {
  CONTRIBUTION_TRANSACTIONS_PER_PAGE,
  ACTIVITIES_TRANSACTIONS_PER_PAGE,
} from 'shared/shared_consts'
import { getOrganizerUpdate } from 'redux/modules/organizer_update'
import { SET_CURRENT_CAMPAIGN_ID } from 'redux/modules/campaign'

const mapStateToProps = state => {
  return {
    campaigns: allCampaignsSelector(state),
    isMobile: state.device_info.isMobile,
    isLoadingDNWTransaction: state.dnw_transaction.isLoading,
    isOrganizerUpdatesEnabled: state.flags.isOrganizerUpdatesEnabled,
    isFundRaiserEnabled: state.flags.isFundRaiserEnabled,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchTransaction(campaignId) {
      return dispatch(
        showMoreTxns(
          campaignId,
          1,
          CONTRIBUTION_TRANSACTIONS_PER_PAGE,
          'CONTRIBUTION'
        )
      )
    },
    fetchActivities(campaignId) {
      return dispatch(
        showMoreTxns(campaignId, 1, ACTIVITIES_TRANSACTIONS_PER_PAGE, 'ALL')
      )
    },
    fetchOrganizerUpdateList(campaignId, page = 1, size = 1) {
      return dispatch(getOrganizerUpdate({ page, size, campaignId }))
    },
    setCurrentCampaignId(campaignId = '') {
      return dispatch({
        type: SET_CURRENT_CAMPAIGN_ID,
        payload: campaignId,
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAllPage)
