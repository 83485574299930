export function daysUntilToday(startDate) {
  return daysFromTo(startDate, new Date())
}

export function daysFromToday(endDate) {
  return daysFromTo(new Date(), endDate)
}

export function hoursFromToday(endDate) {
  return (endDate - new Date()) / 1000 / 60 / 60
}

export function daysFromTo(startDate, endDate) {
  return Math.floor((endDate - startDate) / 1000 / 60 / 60 / 24)
}

export function endOfDay(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  )
}

export const formatTime = {
  hours: 'H',
  minutes: 'M',
}

export function detailDaysFromToday(timestamp) {
  const endDate = new Date(timestamp)
  const today = new Date()
  let days = 0
  let hours = 0
  let minutes = 0
  let seconds = 0

  if (endDate < today) {
    return {
      days,
      hours,
      minutes,
      seconds,
    }
  }

  let delta = Math.abs(endDate - today) / 1000
  // calculate (and subtract) whole days
  days = daysFromToday(endDate)

  delta -= days * 86400

  // calculate (and subtract) whole hours
  hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  seconds = Math.floor(delta % 60)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}
