import Manage from './manage_cf'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { push } from 'react-router-redux'
import { glimpseRoute } from 'lib/route_utils'
import { createDNWTransaction } from 'client/redux/modules/dnw_transaction'
import { TOAST_NOTIFICATIONS } from 'shared/shared_consts'
import { get } from 'lodash'
import { getCampaignTotalValue } from 'lib/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  const canChipInAndCanDonate = campaign.can_chip_in && campaign.can_donate
  const canDonateGNC =
    Boolean(campaign.charity) &&
    canChipInAndCanDonate &&
    Boolean(campaign.is_owner)
  const canPledge =
    (campaign.is_owner && campaign.can_chip_in) || canChipInAndCanDonate

  return {
    canDonateGNC: canDonateGNC,
    canPledge: canPledge,
    charity: campaign.charity,
    currencyCode: campaign.currency,
    currentValue: getCampaignTotalValue({
      currentValue: campaign.current_value,
      pledge: campaign.pledge,
    }),
    goal: campaign.goal,
    hasChippedIn: campaign.current_value > 0,
    hasCurrencyBalance: campaign.has_currency_balance,
    hasMoneybox: campaign.moneybox_amount > 0,
    isOrganizerUpdatesEnabled: state.flags.isOrganizerUpdatesEnabled,
    activitiesCount: get(
      campaign,
      'transactionSummary.total_number_of_transactions',
      0
    ),
    showUpdateSuccess:
      state.toast_notification[TOAST_NOTIFICATIONS.EDIT_SUCCESS],
    showDeleteSuccess:
      state.toast_notification[TOAST_NOTIFICATIONS.DELETE_SUCCESS],
    paypalDomain: state.geo.paypalDomain,
    organizerUpdate: campaign.organizerUpdate,
    cdnHost: state.geo.cdnHost,
    campaign: campaign,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  const transferCampaignUrl = `c/${campaignId}/edit/transfer`
  return {
    onTransfer() {
      dispatch(push({ pathname: glimpseRoute('pools', transferCampaignUrl) }))
    },
    onDonate(id) {
      dispatch(
        createDNWTransaction({
          campaignId: id,
          hasFullPageDonationRedirect: true,
        })
      )
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manage)
