import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './campaign_balance.less'
import { getI18n, formatCurrency } from 'intl'
import { BodyText, HeadingText, Tooltip } from '@paypalcorp/pp-react'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const i18n = getI18n('components/campaign_balance')

class campaign_balance extends React.Component {
  static propTypes = {
    pledge: PropTypes.number,
    withdraw: PropTypes.number,
    campaignId: PropTypes.string,
    currencyCode: PropTypes.string,
    moneyboxAmount: PropTypes.number,
    netAmount: PropTypes.number,
    leftAlignFlag: PropTypes.bool,
    worldReady: PropTypes.Object,
  }

  render() {
    const {
      currencyCode,
      moneyboxAmount,
      netAmount,
      pledge,
      leftAlignFlag,
    } = this.props
    return (
      <div styleName="balance-wrapper">
        {!leftAlignFlag ? (
          <HeadingText styleName="donation-title" size="sm" as="h1">
            {i18n('donations')}
          </HeadingText>
        ) : null}
        <div styleName="balance">
          {!leftAlignFlag ? (
            <div styleName="amount-row">
              <div styleName="label">
                <BodyText>{i18n('amount_received')}</BodyText>
                <Tooltip
                  name="amount_received_info"
                  description={i18n('amount_tooltip_1')}
                />
              </div>
              <div styleName="value">
                <BodyText strong>
                  {formatCurrency({
                    value: netAmount,
                    currencyCode,
                    additionalConfig: {},
                    worldReady: this.props.worldReady,
                  })}
                </BodyText>
              </div>
            </div>
          ) : null}
          <div styleName="amount-row">
            {leftAlignFlag ? (
              <>
                <div styleName="label">
                  <BodyText>{i18n('available')}</BodyText>
                  <Tooltip
                    name="available_balance_info"
                    description={i18n('available_tooltip')}
                  />
                </div>
                <div styleName="bold-value">
                  <BodyText strong>
                    {formatCurrency({
                      value: moneyboxAmount,
                      currencyCode,
                      additionalConfig: {},
                      worldReady: this.props.worldReady,
                    })}
                  </BodyText>
                </div>
              </>
            ) : (
              <>
                <div styleName="label">
                  <BodyText>{i18n('available')}</BodyText>
                  <Tooltip
                    name="available_balance_info"
                    description={i18n('available_tooltip')}
                  />
                </div>
                <div styleName="value">
                  <BodyText strong>
                    {formatCurrency({
                      value: moneyboxAmount,
                      currencyCode,
                      additionalConfig: {},
                      worldReady: this.props.worldReady,
                    })}
                  </BodyText>
                </div>
              </>
            )}
          </div>
          <div styleName="pledge-row">
            {leftAlignFlag ? (
              <>
                <div styleName="label">
                  <BodyText>{i18n('pledge_by')}</BodyText>
                </div>
                <div styleName="bold-value">
                  <BodyText strong>
                    {formatCurrency({
                      value: pledge,
                      currencyCode,
                      additionalConfig: {},
                      worldReady: this.props.worldReady,
                    })}
                  </BodyText>
                </div>
              </>
            ) : (
              <>
                <div styleName="label">
                  <BodyText strong>{i18n('pledge_by')}</BodyText>
                </div>
                <div styleName="value">
                  <BodyText strong>
                    {formatCurrency({
                      value: pledge,
                      currencyCode,
                      additionalConfig: {},
                      worldReady: this.props.worldReady,
                    })}
                  </BodyText>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default injectWorldReady(cssModules(campaign_balance, styles))
