import React from 'react'
import {
  HeadingText,
  Button,
  Link,
  IconButton,
  AddIcon,
} from '@paypalcorp/pp-react'
import { Link as RouterLink } from 'react-router'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './view_updates.less'
import UpdateRow from 'components/contributions_list/rows/update_row'
import { glimpseRoute } from 'lib/route_utils'
import _ from 'lodash'
import { Message } from '@paypalcorp/worldready-react'
import { trackFPTI } from 'lib/fpti_utils'
import { trackClick, trackImpression } from 'lib/fpti_analytics'

class ViewUpdatesActivity extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string,
    activitiesCount: PropTypes.string,
    organizerUpdate: PropTypes.object,
    fetchOrganizerUpdateList: PropTypes.func,
    router: PropTypes.object,
    isDonerView: PropTypes.bool,
    campaign: PropTypes.object,
  }
  state = {
    page: 1,
  }
  onShowMore = (eventType, eventName) => {
    this.props
      .fetchOrganizerUpdateList(this.state.page, 5)
      .then(data => {
        this.setState({ page: this.state.page + 1 })
        const count = _.get(data, 'total') || 0

        if (eventType === 'impression') {
          trackImpression({ event_name: eventName, count })
        } else {
          trackClick({
            clickName: eventName,
            data: {
              event_name: eventName,
              count,
            },
          })
        }
      })
      .catch(err => {
        const { pathname } = this.props.router.location
        const nextPathname = `${pathname}/error`
        this.props.router.push({
          pathname: `${nextPathname}`,
          state: { updateData: err.updateData },
        })
      })
  }
  componentDidMount() {
    const eventName = this.props.isDonerView
      ? 'dw_giving_donor_update_list_screen_shown'
      : 'dw_giving_organizer_update_list_screen_shown'
    this.onShowMore('impression', eventName)
  }
  confirmNavigation = callback => {
    this.trackFPTI(
      this.props.campaign,
      'click',
      'dw_giving_create_update_dismiss_pressed',
      'close'
    )
  }

  trackPostUpdateEvent = total => {
    trackFPTI(
      this.props.campaign,
      'click',
      'dw_giving_organizer_update_list_post_pressed',
      '',
      '',
      total
    )
  }
  render() {
    const { campaignId, organizerUpdate, isDonerView, campaign } = this.props
    const postUpdateUrl = `c/${campaignId}/postupdate`
    const donerViewUrl = `c/${campaignId}/viewupdates`
    const total = _.get(organizerUpdate, 'total', 0)
    let updateState = 'multiple'
    if (total === 0) {
      updateState = 'zero'
    } else if (total === 1) {
      updateState = 'one'
    }
    return (
      <>
        <div styleName="update-heading">
          <HeadingText size="sm" styleName="title" data-testid="title">
            <Message id="components.edit_glimpse.updates_activity.title" />
          </HeadingText>
          {total > 0 &&
            !isDonerView && (
              <>
                <Button
                  size="lg"
                  secondary
                  iconComponent={AddIcon}
                  as={RouterLink}
                  to={glimpseRoute('pools', postUpdateUrl)}
                  className="hidden-xs"
                  data-testid="postupdate-icon"
                  onClick={() => this.trackPostUpdateEvent(total)}
                >
                  <Message id="components.edit_glimpse.updates_activity.post_update" />
                </Button>
                <div className="visible-xs">
                  <IconButton
                    as={RouterLink}
                    styleName="postupdate-icon"
                    to={glimpseRoute('pools', postUpdateUrl)}
                    size="lg"
                    buttonType="secondary"
                    svgIconComponent={AddIcon}
                    data-testid="postupdate-icon"
                    onClick={() => this.trackPostUpdateEvent(total)}
                  />
                </div>
              </>
            )}
        </div>
        <UpdateRow
          organizerUpdate={organizerUpdate}
          postUpdateUrl={isDonerView ? donerViewUrl : postUpdateUrl}
          truncateDescription={true}
          truncateSubject={false}
          isDonerView={isDonerView}
          campaign={campaign}
          eventName={
            isDonerView
              ? 'dw_giving_donor_update_list_item_pressed'
              : 'dw_giving_organizer_update_list_item_pressed'
          }
          updateState={updateState}
        />
        {Math.ceil(total / 5) >= this.state.page && (
          <div styleName="show-more">
            <Link
              onClick={() => {
                const isDonerEventName = isDonerView
                  ? 'dw_giving_donor_update_list_show_previous_pressed'
                  : 'dw_giving_organizer_update_list_show_previous_pressed'
                this.onShowMore('click', isDonerEventName)
              }}
              data-testid="show-more-button"
            >
              <Message id="components.edit_glimpse.updates_activity.show_more_action" />
            </Link>
          </div>
        )}
      </>
    )
  }
}

export default cssModules(ViewUpdatesActivity, styles)
