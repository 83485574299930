import ShareUpdateActivity from './share_update_activity'
import { connect } from 'react-redux'
import { getUpdateActivityShareDetails } from 'lib/share_update'

const mapStateToProps = (state, ownProps) => {
  return getUpdateActivityShareDetails(state, ownProps)
}

export default connect(
  mapStateToProps,
  {}
)(ShareUpdateActivity)
