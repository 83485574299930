import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../crowdfunding_story.less'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import {
  DESCRIPTION_ROWS,
  CROWDFUNDING_DESCRIPTION_LENGTH,
} from 'shared/lengths'
import { BodyText, HeadingText, TextArea } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl } from 'intl'

const Private = ({ description }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <HeadingText size="sm" styleName="form__title">
        <Message id="flow.private.story.header" />
      </HeadingText>

      <BodyText styleName="form__subtitle">
        <Message id="flow.private.story.description" />
      </BodyText>

      <TextArea
        {...domOnlyProps(description)}
        minRows={DESCRIPTION_ROWS}
        label={formatMessage('flow.private.story.textarea_story_label')}
        showCharacterCounter={true}
        maxCharacters={CROWDFUNDING_DESCRIPTION_LENGTH}
        errorText={getFormError(description)}
        data-testid="story-description"
      />
    </>
  )
}

Private.propTypes = {
  description: PropTypes.string.isRequired,
}

export default cssModules(Private, styles)
