import fetchClient from 'lib/fetch_client'

const MAX_ERRORS = 2
let errors = 0

function log(url, message, file, line, column, error) {
  fetchClient().request(url, {
    method: 'POST',
    body: {
      message,
      href: window.location.href,
      file,
      line,
      column,
      stack: error ? error.stack : '',
      userAgent: navigator.userAgent,
    },
  })
}

export function startErrorTracking(url) {
  window.onerror = function(message, file, line, column, error) {
    if (errors > MAX_ERRORS) {
      return
    }

    errors++

    log(url, message, file, line, column, error)
  }
}
