import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import {
  PhysicalCard,
  Row,
  Col,
  HeadingText,
  BodyText,
} from '@paypalcorp/pp-react'
import { Checkbox } from '@paypalcorp/pp-react-checkbox'
import styles from '../crowdfunding_select_cover.less'
import CustomImageSelection from './custom_image'
import { Message } from '@paypalcorp/worldready-react'
import { POOL_TYPE } from 'shared/shared_consts'

const PersonalAndCharityCover = props => {
  const {
    campaignType,
    defaultCategoryCover,
    setSelectedPhoto,
    selectedPhoto,
  } = props
  const title =
    'components.pages.crowdfunding.crowdfunding_cover.select_cover_title'

  const subtitle =
    campaignType === POOL_TYPE.PERSONAL
      ? 'components.pages.crowdfunding.crowdfunding_cover.personal_select_cover_sub_title'
      : 'components.pages.crowdfunding.crowdfunding_cover.select_cover_sub_title'

  return (
    <div>
      <HeadingText size="sm" styleName="title">
        <Message data-testid="personal_charity_cover_header" id={title} />
      </HeadingText>
      <BodyText styleName="sub-title">
        <Message data-testid="personal_charity_cover_subheader" id={subtitle} />
      </BodyText>
      <Row styleName="row-margin">
        <CustomImageSelection {...props} />
        <Col>
          {defaultCategoryCover && (
            <>
              <PhysicalCard
                style={{
                  background: `no-repeat url(${defaultCategoryCover})`,
                  backgroundSize: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedPhoto(defaultCategoryCover)}
                tabIndex={0}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    setSelectedPhoto(defaultCategoryCover)
                  }
                }}
                data-testid="category-cover-image-card"
              />
              <div styleName="image-checkbox-container">
                <Checkbox
                  checked={selectedPhoto === defaultCategoryCover}
                  onClick={() => setSelectedPhoto(defaultCategoryCover)}
                  tabIndex={-1}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

//Few props are using by its children component
PersonalAndCharityCover.propTypes = {
  defaultCategoryCover: PropTypes.string,
  setSelectedPhoto: PropTypes.func,
  selectedPhoto: PropTypes.string,
  defaultImage1: PropTypes.string,
  defaultImage2: PropTypes.string,
  handleSelectCover: PropTypes.func,
  fields: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
  }),
  charity: PropTypes.object,
  defaultImage3: PropTypes.string,
  campaignType: PropTypes.string.isRequired,
}

export default cssModules(PersonalAndCharityCover, styles)
