import ShareCampaign from './share_campaign_compact'
import { connect } from 'react-redux'
import { getShareProps } from 'lib/share'
import { campaignUrlWithMeDomain } from 'lib/campaign_url'
import { getCampaignSelector } from 'redux/selectors/campaign'
import { POOL_TYPE } from 'shared/shared_consts'
import { injectWorldReady } from '@paypalcorp/worldready-react'
import { generateShareMessage } from '../../../lib/generate_share_message'

const mapStateToProps = (state, ownProps) => {
  const campaign = getCampaignSelector(state, ownProps)
  const shareProps = getShareProps(state, ownProps)
  const urlWithMeDomain = campaignUrlWithMeDomain(
    campaign.id,
    campaign.campaign_type
  )

  const { type, is_owner } = campaign
  const isSmallGroup = type === POOL_TYPE.PRIVATE

  const shareMessage = generateShareMessage(
    isSmallGroup,
    campaign,
    urlWithMeDomain,
    ownProps.worldReady
  )

  return {
    shareText: is_owner ? shareMessage : '',
    ...shareProps,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default injectWorldReady(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShareCampaign)
)
