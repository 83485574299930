import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './../pledge.less'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl } from 'intl'
import { Button, HeadingText } from '@paypalcorp/pp-react'
import { Link as RouterLink } from 'react-router'
import { trackClick } from 'lib/fpti_analytics'

const propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
  geo: PropTypes.object,
  campaignId: PropTypes.string,
}
const PledgeSuccess = props => {
  const nextPathname = '/pools'
  const { params } = props.location.state
  const { currency, campaignTitle, pledge } = params || {}
  const { formatCurrency } = useIntl()
  const formattedPledgeAmount = formatCurrency(pledge, currency)
  const { cdnHost } = props.geo

  if (!params) {
    return null
  }
  return (
    <div styleName="pledge__success">
      <img
        styleName="success__icon"
        src={`https://${cdnHost}/paypal-ui/illustrations/focused/svg/empty-success.svg`}
      />
      <HeadingText styleName="success__title" size="sm">
        <Message
          id="components.edit_glimpse.pledge_success.success_title"
          pledge={formattedPledgeAmount}
          campaignTitle={campaignTitle}
        />
      </HeadingText>

      <div styleName="cta">
        <Button
          as={RouterLink}
          to={{
            pathname: nextPathname,
            state: { campaignId: props.campaignId, reloadTransactions: false },
          }}
          onClick={() => {
            trackClick({
              clickName: 'dw_giving_pledge_confirmation_dismiss_pressed',
              data: {
                event_name: 'dw_giving_pledge_confirmation_dismiss_pressed',
                dismiss_method: 'quit',
              },
            })
          }}
          data-testid="pledge-success-done-button"
        >
          <Message id="components.edit_glimpse.pledge_success.done_button" />
        </Button>
      </div>
    </div>
  )
}

PledgeSuccess.propTypes = propTypes

export default cssModules(PledgeSuccess, styles)
