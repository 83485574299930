import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/common/modal'
import StandardError from 'components/common/standard_error'
import { exitGlimpse } from 'lib/glimpse'
import { getMoneyPoolTNCLink } from 'lib/global'
import { routerShape } from 'react-router/lib/PropTypes'
import { Button } from '@paypalcorp/pp-react'
import { glimpseRoute } from 'lib/route_utils'
import { Link } from 'react-router'
import JsxParser from 'react-jsx-parser'
import { baseUrl } from 'shared/campaign_url'
import { Message } from '@paypalcorp/worldready-react'
import { useIntl } from 'intl'

const CountryBlocked = props => {
  const { formatMessage } = useIntl()
  const handleClose = () => {
    exitGlimpse('send', props.router)
  }

  const {
    money_pool_creation_blocked,
    crowdfunding_enabled,
    paypalDomain,
    countryCode,
    countryName,
    showClose,
    isFundRaiserEnabled,
  } = props

  const titleJSX = formatMessage(
    'components.pages.create.create.moneypool_blocked_title',
    { tc_link: getMoneyPoolTNCLink(paypalDomain, countryCode) }
  )
  let title

  if (!isFundRaiserEnabled || !money_pool_creation_blocked) {
    title = formatMessage('components.pages.create.create.blocked_title')
  } else {
    title = <JsxParser jsx={typeof titleJSX === 'string' ? titleJSX : ''} />
  }

  const crowdfundingEnabledSubtitle =
    !crowdfunding_enabled || !isFundRaiserEnabled
      ? formatMessage('components.pages.create.create.error_eligible_sub_title')
      : ''

  const subtitle = money_pool_creation_blocked
    ? crowdfundingEnabledSubtitle
    : formatMessage(`components.pages.create.create.blocked_subtitle`, {
        country: countryName,
      })

  let button = null
  const iconType =
    money_pool_creation_blocked &&
    (!crowdfunding_enabled && !isFundRaiserEnabled)
      ? 'empty-critical'
      : 'warning'

  if (
    money_pool_creation_blocked &&
    (!crowdfunding_enabled || !isFundRaiserEnabled)
  ) {
    button = (
      <Button
        as="a"
        href={baseUrl(location.hostname, '/myaccount/summary')}
        role="button"
        secondary
      >
        <Message id="components.pages.create.create.error_not_enabled_button" />
      </Button>
    )
  } else if (money_pool_creation_blocked && crowdfunding_enabled) {
    button = (
      <Button as={Link} to={glimpseRoute('create', 'types')}>
        <Message id="components.pages.create.create.start_gn_cta" />
      </Button>
    )
  }

  return (
    <Modal glimpse={true} onClose={handleClose} showClose={showClose}>
      <StandardError
        iconType={iconType}
        title={title}
        subtitle={subtitle}
        showButton={false}
        extraContent={button}
      />
    </Modal>
  )
}

CountryBlocked.propTypes = {
  countryName: PropTypes.string,
  countryCode: PropTypes.string,
  showClose: PropTypes.bool,
  router: routerShape.isRequired,
  money_pool_creation_blocked: PropTypes.bool,
  crowdfunding_enabled: PropTypes.bool,
  paypalDomain: PropTypes.string,
  isFundRaiserEnabled: PropTypes.bool,
}

CountryBlocked.defaultProps = {
  showClose: false,
}

export default CountryBlocked
