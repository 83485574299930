const _cache = {}

export function getJSONFromScript(scriptId) {
  if (_cache[scriptId]) {
    return _cache[scriptId]
  }

  const element = document.getElementById(scriptId)

  try {
    const parsedElement = JSON.parse(element.innerHTML)

    if (
      parsedElement &&
      typeof parsedElement === 'object' &&
      !Array.isArray(parsedElement)
    ) {
      _cache[scriptId] = parsedElement

      return parsedElement
    } else {
      throw new Error(
        `Parsed element with scriptId=${scriptId} should be an object.`
      )
    }
  } catch (error) {
    throw new Error(
      `Parsed element with scriptId=${scriptId} could not be parsed.`
    )
  }
}

export function getDataAttr(dataKey) {
  return document.body.getAttribute(`data-${dataKey}`)
}

export function getJSONDataAttr(dataKey) {
  const attrib = getDataAttr(dataKey)
  if (!attrib) {
    return {}
  }
  return JSON.parse(attrib)
}
