import fetchClient from 'lib/fetch_client'
import routes from 'server_routes'

export function getCharities(searchParams) {
  return (dispatch, getState) => {
    return fetchClient()
      .request(routes.charity.api.getCharities(), {
        method: 'GET',
        query: searchParams,
      })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      })
  }
}
