// a merger between contributors and the txns

import { createSelector } from 'reselect'
import { getCampaignSelector } from 'redux/selectors/campaign'

export const getTxnsSelector = createSelector(
  [getCampaignSelector],
  campaign => campaign.transactions || []
)

export const getActivityTxnsSelector = createSelector(
  [getCampaignSelector],
  campaign => campaign.activities || []
)

export const contributorsMapSelector = state => state.contributors.map

export const activityContributorsMapSelector = state =>
  state.activities && state.activities.contributors.map

const sharingOptionsSelector = createSelector(
  [getCampaignSelector],
  campaign => {
    return {
      sharingProgress: campaign.share_progress,
      sharingContributors: campaign.share_contributors,
      sharingContributions: campaign.share_contributions,
    }
  }
)
const currencySelector = createSelector(
  getCampaignSelector,
  campaign => campaign.currency
)

// used for owner perspective.
// includes all txns along poplated with their contributions,
// regardless of sharing options or contributor anonymous choice.
// it doesn't matter to have it "selecting" when an anonymous user views client,
// because we hide that data in the server-side already
export const fullContributionsListSelector = createSelector(
  [getTxnsSelector, currencySelector],
  (txns, currency) => {
    return txns.map(txn => {
      return {
        ...txn,
        currency,
        contributor_name: txn.contributor_name,
        contributor_photo: txn.contributor_photo,
      }
    })
  }
)

export const fullActivitiesListSelector = createSelector(
  [getActivityTxnsSelector, currencySelector],
  (txns, currency) => {
    return txns.map(txn => {
      return {
        ...txn,
        currency,
        contributor_name: txn.contributor_name,
        contributor_photo: txn.contributor_photo,
      }
    })
  }
)

export const pendingContributionsListSelector = createSelector(
  [fullActivitiesListSelector],
  fullContributionsList =>
    fullContributionsList.filter(contribution => contribution.pending)
)

export const completedContributionsListSelector = createSelector(
  [fullActivitiesListSelector],
  fullContributionsList =>
    fullContributionsList.filter(contribution => !contribution.pending)
)

// includes all txns along with their contributions
// but hides data based on sharing options or contributor anonymous choice
export const publicContributionsListSelector = createSelector(
  [fullContributionsListSelector, sharingOptionsSelector],
  (fullContributorsList, sharingOptions) => {
    const showContributionsAtAll =
      sharingOptions.sharingProgress || sharingOptions.sharingContributors
    if (!showContributionsAtAll) {
      return null
    }
    return fullContributorsList
      .filter(
        contribution =>
          (!contribution.withdraw && !contribution.pending) ||
          contribution.owner_chip_in
      )
      .map(contribution => {
        const shareContributorDetails =
          sharingOptions.sharingContributors && !contribution.anonymous
        return {
          ...contribution,
          contributor_id: shareContributorDetails
            ? contribution.contributor_id
            : undefined,
          contributor_name: shareContributorDetails
            ? contribution.contributor_name
            : undefined,
          contributor_photo: shareContributorDetails
            ? contribution.contributor_photo
            : undefined,
          amount: sharingOptions.sharingContributions
            ? contribution.amount
            : undefined,
        }
      })
  }
)
