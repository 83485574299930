import ModalWizard from './modal_wizard'
import { connect } from 'react-redux'

import { fromVeniceSelector } from 'redux/selectors/venice'

export const mapStateToProps = (state, { hideBack, hideClose }) => {
  const fromVenice = fromVeniceSelector()(state)
  let hideTopWithoutHeader = false
  if (fromVenice) {
    hideBack = true
    hideClose = true
    hideTopWithoutHeader = true
  }

  return { hideBack, hideClose, hideTopWithoutHeader }
}

export default connect(mapStateToProps)(ModalWizard)
