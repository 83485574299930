import { trackImpression } from 'lib/fpti_analytics'
import { LOCATION_CHANGE } from 'react-router-redux'
import { decodeCampaignId } from 'shared/campaign_url'
import { POOLS_CAMPAIGN_TYPES } from 'shared/shared_consts'
import { getCampaignSelector } from 'redux/selectors/campaign'

import { get, includes } from 'lodash'
import { SET_CURRENT_CAMPAIGN_ID } from './modules/campaign'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES
const DONT_TRACK = 'NONE'

const PAGE_NAMES = {
  '': 'hub::main',
  'c-create': DONT_TRACK,
  'c-create-types': 'create::type',
  'c-create-category': 'create::category',
  'c-create-basics': 'create::basics',
  'c-create-preferences': 'create::preferences',
  'c-create-description': 'create::description',
  'c-create-cover': 'create::cover',
  'c-create-preview': 'create::preview',
  'c-create-prepublish': 'create::prepublish',
  'c-create-publish': 'create::publish',
  'c-create-success': 'create::success',
  'c-create-share': 'create::share',

  'c-create-crowdfunding-basics': 'create::basics',
  'c-create-crowdfunding-story': 'create::description',
  'c-create-crowdfunding-cover': 'create::photo',
  'c-create-crowdfunding-nonprofit': 'create::nonprofit',

  'c-xxx': 'campaign::page',
  'c-xxx-nochipin': 'campaign::nochipin',
  'c-xxx-send': DONT_TRACK,
  'c-xxx-send-amount': 'send::amount',
  'c-xxx-send-note': 'send::note',

  'c-xxx-guest': DONT_TRACK,
  'c-xxx-guest-amount': 'guest::amount',
  'c-xxx-guest-note': 'guest::note',

  'c-xxx-send-confirm': 'send::confirm',
  'c-xxx-send-review': 'send::review',
  'c-xxx-send-success': 'send::success',
  'c-xxx-chip_in': DONT_TRACK,
  'c-xxx-chip_in-amount': 'chipin::amount',
  'c-xxx-chip_in-review': 'chipin::review',
  'c-xxx-chip_in-complete': 'chipin::complete',
  'c-xxx-edit-manage': 'manage::main',
  'c-xxx-edit-transfer': 'withdraw::amount',
  'c-xxx-edit-transfer-review': 'withdraw::review',
  'c-xxx-edit-transfer-complete': 'withdraw::complete',
  'c-xxx-edit-manage-activity': 'manage::activity',
  'c-xxx-edit': 'edit::main',
  'c-xxx-edit-name': 'edit::name',
  'c-xxx-edit-cover': 'edit::cover',
  'c-xxx-edit-amount': 'edit::amount',
  'c-xxx-edit-end_date': 'edit::enddate',
  'c-xxx-edit-description': 'edit::description',
  'c-xxx-edit-contribution': 'edit::preferences',
  'c-xxx-edit-tracking': 'edit::tracking',
  'c-xxx-edit-delete': 'delete::confirm',
  'c-xxx-edit-delete-review': 'delete::review',
}

const EVENT_NAMES = {
  '': 'dw_giving_my_fundraiser_screen_shown',
  'c-create-types': 'dw_giving_intro_fundraiser_screen_shown',
  'c-create-crowdfunding-nonprofit':
    'dw_giving_find_fundraiser_charity_screen_shown',
  'c-create-crowdfunding-basics': 'dw_giving_create_fundraiser_screen_shown',
  'c-create-crowdfunding-story': 'dw_giving_fundraiser_tell_story_screen_shown',
  'c-create-crowdfunding-cover': 'dw_giving_fundraiser_add_photo_screen_shown',
  'c-xxx': 'dw_giving_view_fundraiser_screen_shown',
  'c-xxx-edit-manage': 'dw_giving_manage_fundraiser_screen_shown',
  'c-xxx-edit': 'dw_giving_edit_fundraiser_screen_shown',
  'c-xxx-edit-transfer': 'dw_giving_fundraiser_transfer_screen_shown',
  'c-xxx-edit-share': 'dw_giving_share_screen_shown',
  'c-xxx-edit-transfer-complete':
    'dw_giving_fundraiser_transfer_success_screen_shown',
  'c-xxx-edit-pledge': 'dw_giving_fundraiser_pledge_screen_shown',
  'c-xxx-edit-pledge-complete': 'dw_giving_pledge_confirmation_screen_shown',
}

function getCurrentCampaignId(state, pathname) {
  // An owner could have multiple campaigns (our store groups them all together).
  // If we're on a campaign page, let's get the campaignId for that page from store.
  return Object.keys(state.campaign || []).find(key => pathname.includes(key))
}

function isCrowdfundingCampaign(state, campaignId) {
  if (campaignId !== '__new_campaign__') {
    return (
      state.campaign &&
      state.campaign[campaignId].campaign_type === CROWD_FUNDING
    )
  }
  return false
}
export const sanitizePathnameForFpti = (state, pathname) => {
  const campaignId = getCurrentCampaignId(state, pathname)
  if (campaignId) {
    pathname = pathname.replace(campaignId, decodeCampaignId(campaignId)) // replace encoded campaign id
  }
  pathname = pathname.replace(GLOBAL_CONSTS.REQUEST_URI + '/', '') // remove initial path
  pathname = pathname.replace(/\/$/, '') // remove trailing slash
  pathname = pathname.replace(/\//g, '-') // replace / with - since this is what they want in FPTI for non-mapped pages
  pathname = pathname.replace(/[0-9]+/g, 'xxx') // remove numeric ids to make the path generic

  const matchingPageName = PAGE_NAMES[pathname]
  const event_name = EVENT_NAMES[pathname]
  // if there's no matching page name, just track the sanitized path name as it is
  return {
    fptiPageName: matchingPageName || pathname,
    event_name,
  }
}

export const middleware = store => next => {
  let lastPathName
  return action => {
    if (action.type === LOCATION_CHANGE) {
      const pathname = action.payload.pathname
      // checking if new pathname is different than the last, no need to track the same impression twice
      if (pathname && pathname !== lastPathName) {
        let { fptiPageName, event_name } = sanitizePathnameForFpti(
          store.getState(),
          pathname
        )

        if (fptiPageName !== DONT_TRACK) {
          let decodedCampaignId = ''
          const campaignId =
            getCurrentCampaignId(store.getState(), pathname) ||
            '__new_campaign__'

          // And update store during load with default value for selected campaign
          store.dispatch({
            type: SET_CURRENT_CAMPAIGN_ID,
            payload: campaignId,
          })

          const campaign = getCampaignSelector(store.getState(), { campaignId })
          const charityname = get(campaign, 'charity.name', '')
          const crid = get(campaign, 'charity.nonprofit_id', '')
          const charity_type = get(campaign, 'charity.charity_type', '')
          const discoverable = get(campaign, 'discoverable', '')

          if (campaignId !== '__new_campaign__') {
            decodedCampaignId = decodeCampaignId(campaignId)
          }

          const isCrowfunding = isCrowdfundingCampaign(
            store.getState(),
            campaignId
          )
          let flow =
            isCrowfunding || includes(pathname, 'crowdfunding', false)
              ? 'GENEROSITY_NETWORK'
              : undefined
          if (charityname || includes(pathname, 'nonprofit', false))
            flow = 'GENEROSITY_NETWORK_CHARITIES'

          const CIPStatusCode = get(
            store.getState(),
            `current_user.CIPEligibility.CROWD_FUNDING.CIPStatus`,
            ''
          )
          const contingency = CIPStatusCode === 'ELIGIBLE' ? 'cip' : undefined

          /**
           * 0. Client-Side: Location change
           * 1. react-router-redux: sends LOCATION_CHANGE message
           *     - This middleware gets the message
           *        + Schedules an action after call-stack is empty, a campaign is needed
           * 2. react-router: executes `onEnter` actions
           *     - Campaign created
           * 3. ... other function calls
           * 4. Scheduled action is called
           */
          setTimeout(() => {
            trackImpression({
              pageName: fptiPageName,
              campaignId: decodedCampaignId,
              flow: flow,
              contingency: contingency,
              crid: crid,
              event_name,
              charityname,
              trackCPL: true,
              charity_type,
              discoverable,
            })
          }, 0)

          lastPathName = pathname
        }
      }
    }
    next(action)
  }
}
