import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_story.less'
import TipBox from 'components/common/tip_box'
import { domOnlyProps } from 'lib/form_utils'
import { getFormError } from 'lib/validators'
import {
  DESCRIPTION_ROWS,
  CROWDFUNDING_DESCRIPTION_LENGTH,
} from 'shared/lengths'
import { BodyText, Button, HeadingText, TextArea } from '@paypalcorp/pp-react'
import { trackEventNameClick } from 'lib/fpti_analytics'
import clsx from 'clsx'
import { Message } from '@paypalcorp/worldready-react'
import { POOL_TYPE } from 'shared/shared_consts'
import Private from './partials/private'
import { useIntl } from 'intl'
import PersonalTipBox from '../tip_box_personal/tip_box_personal'

function PoolDescription(props) {
  useEffect(() => {
    const { initializeForm, description } = props
    initializeForm({
      description,
    })
  }, [])
  const { formatMessage } = useIntl()
  const handleNextClick = () => {
    const eventName = 'dw_giving_fundraiser_select_photo_screen_shown'
    trackEventNameClick({
      event_name: eventName,
    })
    trackEventNameClick({
      event_name: 'dw_giving_fundraiser_tell_story_next_pressed',
    })
  }

  const {
    fields: { description },
    handleSubmit,
    anyInactiveFieldsTouchedWithError,
    type,
  } = props

  const isPersonalFundraiser = type === POOL_TYPE.PERSONAL
  return (
    <form onSubmit={handleSubmit}>
      {type === POOL_TYPE.PRIVATE ? (
        <Private description={description} />
      ) : (
        <>
          <HeadingText
            size="sm"
            styleName={clsx('form__title', {
              form__buttom_margin: isPersonalFundraiser,
            })}
          >
            {isPersonalFundraiser ? (
              <Message id="components.pages.crowdfunding.pool_story.personal_title" />
            ) : (
              <Message id="components.pages.crowdfunding.pool_story.title" />
            )}
          </HeadingText>
          {!isPersonalFundraiser && (
            <BodyText styleName="form__subtitle">
              <Message id="components.pages.crowdfunding.pool_story.subtitle" />
            </BodyText>
          )}

          <TextArea
            {...domOnlyProps(description)}
            data-nemo="about-pool-description"
            minRows={DESCRIPTION_ROWS}
            label={formatMessage(
              `components.pages.crowdfunding.pool_story.${
                isPersonalFundraiser ? 'title' : 'description_title'
              }`
            )}
            showCharacterCounter={true}
            maxCharacters={CROWDFUNDING_DESCRIPTION_LENGTH}
            errorText={getFormError(description)}
            data-testid="story-description"
          />
          {isPersonalFundraiser ? (
            <PersonalTipBox />
          ) : (
            <TipBox
              text={formatMessage(
                `components.pages.crowdfunding.pool_story.tip_description`
              )}
              type="story"
            />
          )}
        </>
      )}

      <div styleName="form__button">
        <Button
          className={clsx({
            'vx_btn-disabled': anyInactiveFieldsTouchedWithError,
          })}
          disabled={anyInactiveFieldsTouchedWithError}
          data-nemo="description_next"
          onClick={handleNextClick}
          data-testid="story-btn-next"
        >
          <Message id="components.pages.crowdfunding.pool_story.next" />
        </Button>
      </div>
    </form>
  )
}

PoolDescription.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  shouldAutoFocus: PropTypes.bool,
  onChangeCover: PropTypes.func,
  anyInactiveFieldsTouchedWithError: PropTypes.bool.isRequired,
  npid: PropTypes.string,
  charity: PropTypes.object,
  initializeForm: PropTypes.func,
  description: PropTypes.string,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  type: PropTypes.string,
}

export default cssModules(PoolDescription, styles, {
  allowMultiple: true,
})
