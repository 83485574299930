import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './share_update_activity.less'
import Clipboard from 'clipboard'
import FirstChild from 'components/common/first_child'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { CaptionText, Link, LinkIcon, Row, Col } from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'

import {
  EmailSharing,
  TwitterSharing,
  FacebookSharing,
  WhatsAppSharing,
  SmsSharing,
} from 'components/common/share_buttons'

const TOOLTIP_SHOW_DELAY = 750
const TOOLTIP_ANIMATION_DURATION = 250

class ShareUpdateActivity extends React.Component {
  static propTypes = {
    shareUrl: PropTypes.string.isRequired,
    displayUrl: PropTypes.string.isRequired,
    shareable: PropTypes.bool.isRequired,
    appId: PropTypes.number.isRequired,

    onClickOverride: PropTypes.func,

    showFacebook: PropTypes.bool,
    showMessenger: PropTypes.bool,
    showTwitter: PropTypes.bool,
    showWhatsApp: PropTypes.bool,
    showEmail: PropTypes.bool,
    showSms: PropTypes.bool,
    showSubtext: PropTypes.bool,
    description: PropTypes.string,
    styles: PropTypes.object,
    isMobile: PropTypes.bool,
    campaignId: PropTypes.string,
  }

  static defaultProps = {
    shareable: true,
    showFacebook: true,
    showMessenger: true,
    showTwitter: true,
    showWhatsApp: true,
    showEmail: true,
    showSms: true,
    showSubtext: false,
    isMobile: false,
    inline: true,
  }

  state = {
    copyText: this.props.displayUrl,
    showTooltip: false,
    errorMsg: false,
  }

  componentDidMount() {
    if (this.props.shareable) {
      this.clipboard = new Clipboard(this.copyLink, {
        text: () => this.state.copyText,
      })
      this.clipboard.on('success', this.handleCopySuccess)
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  copyValidation = () => {
    if (this.state.copyText === '') {
      this.setState({ errorMsg: true })
    } else {
      this.setState({ errorMsg: false })
    }
  }

  handleChange = event => {
    this.setState({ copyText: event.target.value, errorMsg: false })
  }

  handleCopySuccess = e => {
    this.setState({ showTooltip: true })
    setTimeout(() => {
      this.setState({ showTooltip: false })
    }, TOOLTIP_SHOW_DELAY)
  }

  render() {
    const transitionNames = {
      enter: this.props.styles['tooltip-enter'],
      enterActive: this.props.styles['tooltip-enter-active'],
      leave: this.props.styles['tooltip-leave'],
      leaveActive: this.props.styles['tooltip-leave-active'],
    }

    return (
      <div>
        {this.state.errorMsg && (
          <span data-testid="copy-validation-error" styleName="errorMsg">
            <Message id="components.share_campaign_textarea.error" />
          </span>
        )}
        <Row>
          <Col sm={12} md={4} styleName="share-button-container">
            <EmailSharing
              {...this.props}
              shareText={this.state.copyText}
              className={styles['share-icon']}
              styleName="share-icon"
              inline={false}
              showSubtext={true}
            />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <SmsSharing
              {...this.props}
              shareText={this.state.copyText}
              className={styles['share-icon']}
              styleName="share-icon"
              inline={false}
              showSubtext={true}
            />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <FacebookSharing
              {...this.props}
              shareUrl={this.state.copyText}
              className={styles['share-icon']}
              styleName="share-icon"
              inline={false}
              showSubtext={true}
            />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <TwitterSharing
              {...this.props}
              shareText={this.state.copyText}
              className={styles['share-icon']}
              styleName="share-icon"
              inline={false}
              showSubtext={true}
            />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <WhatsAppSharing
              {...this.props}
              shareText={this.state.copyText}
              className={styles['share-icon']}
              styleName="share-icon"
              inline={false}
              showSubtext={true}
            />
          </Col>
          <Col sm={12} md={4} styleName="share-button-container">
            <div styleName="copy-box-wrapper share-icon">
              <Link
                styleName="copy-wrapper"
                href="javascript:;"
                ref={copyLink => {
                  this.copyLink = copyLink
                }}
                onClick={this.copyValidation}
                title="copy"
                data-testid="copy-text"
              >
                <LinkIcon size="sm" />
              </Link>
              {this.props.showSubtext && (
                <div styleName="sub-text">
                  <Message id="components.share_campaign_textarea.copy" />
                </div>
              )}
              <ReactCSSTransitionGroup
                component={FirstChild}
                transitionName={transitionNames}
                transitionEnterTimeout={TOOLTIP_ANIMATION_DURATION}
                transitionLeaveTimeout={TOOLTIP_ANIMATION_DURATION}
              >
                {this.state.showTooltip && (
                  <div data-testid="copied-tooltip" styleName="copied-tooltip">
                    <Message id="components.share_campaign_textarea.copied" />
                  </div>
                )}
              </ReactCSSTransitionGroup>
            </div>
            <CaptionText
              onClick={() => this.copyLink.click()}
              styleName="share-label share-label-cursor"
            >
              <Message id="components.share_campaign_textarea.copylink" />
            </CaptionText>
          </Col>
        </Row>
      </div>
    )
  }
}

export default cssModules(ShareUpdateActivity, styles, {
  allowMultiple: true,
})
