import SessionTimeout from './session_timeout'
import { connect } from 'react-redux'
import { isLoggedInSelector, loginUrlSelector } from 'redux/selectors/login'

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: isLoggedInSelector(state),
    loginUrl: loginUrlSelector({ returnUri: window.location.href })(state),
    isEuCountry: state.country.isSCA,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionTimeout)
