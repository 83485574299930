import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './avatar_collection.less'
import { useIntl } from 'intl'
import { AvatarStack } from '@paypalcorp/pp-react'
import { UnboundAvatar } from '../common/avatar'
import { getInitials } from '../../../shared/helpers/name_helpers'

const AvatarCollection = ({ contributions, contributionCount }) => {
  const { formatMessage } = useIntl()

  return (
    <AvatarStack
      label={formatMessage(
        'components.crowdfunding_detail_view.people_donated',
        { donationCount: contributionCount }
      )}
      size="xs"
      styleName="avatar-container"
    >
      {contributions
        .slice(0, 3)
        .map(({ contributor_name, contributor_photo, contributor_id }) => {
          return (
            <UnboundAvatar
              key={contributor_id}
              initials={contributor_name && getInitials(contributor_name)}
              imageUrl={contributor_photo}
              data-testid="avatar_collection__avatar"
            />
          )
        })}
    </AvatarStack>
  )
}

AvatarCollection.propTypes = {
  contributions: PropTypes.array.isRequired,
  contributionCount: PropTypes.number.isRequired,
}

export default cssModules(AvatarCollection, styles)
