import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './crowdfunding_campaign_header.less'
import {
  Badge,
  CaptionText,
  Col,
  HeadingText,
  Row,
  Link as PPLink,
  CausesIcon,
} from '@paypalcorp/pp-react'
import EndDateBadge from './helper_components/end_date_badge'
import clsx from 'clsx'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { CHARITY_INSTRUMENTATION } from 'shared/shared_consts'

class CrowdFundingCampaignHeader extends React.Component {
  static propTypes = {
    campaignId: PropTypes.string.isRequired,
    canChipIn: PropTypes.bool.isRequired,
    categoryName: PropTypes.string.isRequired,
    charity: PropTypes.object,
    creatorName: PropTypes.string.isRequired,
    end_date: PropTypes.string,
    initials: PropTypes.string.isRequired,
    paypalDomain: PropTypes.string.isRequired,
    poolName: PropTypes.string.isRequired,
    profileImage: PropTypes.string.isRequired,
    showDaysRemaining: PropTypes.bool,
    isCharityLink: PropTypes.bool,
    discoverable: PropTypes.string,
  }
  static defaultProps = { isCharityLink: false }

  getCharityNameComponent(charity) {
    const { paypalDomain, isCharityLink } = this.props
    return (
      <Row>
        <Col
          lg="auto"
          md="auto"
          xs={6}
          styleName="iconCaptionContainer charity-header"
        >
          <CausesIcon size="sm" color="sysColorLinkMain" />
          <CaptionText
            styleName={clsx('headerCaptionText', {
              mwebheaderCaptionText: charity.nonprofit_id && isCharityLink,
            })}
            size="sm"
          >
            {charity.nonprofit_id && isCharityLink ? (
              <PPLink
                href={`https://${paypalDomain}/fundraiser/charity/${
                  charity.nonprofit_id
                }`}
                target="_blank"
                styleName="charity-name-link"
                onClick={this.handleFPTI}
              >
                {charity.name}
              </PPLink>
            ) : (
              charity.name
            )}
          </CaptionText>
        </Col>
      </Row>
    )
  }

  handleFPTI = () => {
    const { charity } = this.props
    if (charity) {
      trackEventNameClick({
        event_name: CHARITY_INSTRUMENTATION.NPO_LINK_PRESSED,
      })
    }
  }

  getCategoryComponent() {
    return (
      <Col
        lg="auto"
        md="auto"
        xs="auto"
        styleName="iconCaptionContainer iconCaptionWrapper"
      >
        {this.props.categoryName.split(',').map(name => {
          return (
            name && (
              <Badge styleName="categoryBadge" key={name} type="feature">
                {name}
              </Badge>
            )
          )
        })}
      </Col>
    )
  }

  render() {
    const {
      charity,
      end_date,
      showDaysRemaining,
      canChipIn,
      categoryName,
    } = this.props
    return (
      <React.Fragment>
        <Row>
          <Col>
            <HeadingText
              styleName="heading"
              as="h1"
              size="lg"
              data-testid="campaign_header-title"
            >
              {this.props.poolName}
            </HeadingText>
          </Col>
        </Row>
        {charity && this.getCharityNameComponent(charity)}
        <Row styleName="campaign_padding">
          {showDaysRemaining && (
            <EndDateBadge
              endDate={end_date}
              canChipIn={canChipIn}
              topAlignment={categoryName.length === 0 ? true : false}
            />
          )}
          {this.props.categoryName && this.getCategoryComponent()}
        </Row>
      </React.Fragment>
    )
  }
}

export default cssModules(CrowdFundingCampaignHeader, styles, {
  allowMultiple: true,
})
