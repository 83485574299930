import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, CaptionText } from '@paypalcorp/pp-react'
import cssModules from 'react-css-modules'
import styles from './charity_card.less'
import { validateImagePath } from 'shared/campaign_url'

class CharityCard extends React.Component {
  static propTypes = {
    charity: PropTypes.object,
    dataTestId: PropTypes.string,
  }
  static defaultProps = {
    dataTestId: 'charity-tile',
  }
  render() {
    const { charity, dataTestId } = this.props
    const logoURL = validateImagePath(charity.logo_url)
    return (
      <div styleName="charity-wrap">
        <div styleName="charity-title" data-testid={dataTestId}>
          {logoURL ? (
            <Avatar
              data-testid="avatar-image"
              src={charity.logo_url}
              size="md"
              alt=""
              role="presentation"
              styleName="charity-tile-logo"
            />
          ) : (
            <Avatar
              data-testid="avatar-image"
              generic="causes"
              size="md"
              alt=""
              role="presentation"
              styleName="charity-tile-logo"
            />
          )}
          <CaptionText>{charity.name}</CaptionText>
        </div>
      </div>
    )
  }
}

export default cssModules(CharityCard, styles)
