import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './donate_button.less'
import { Button } from '@paypalcorp/pp-react'
import { trackClick } from '../../../../lib/fpti_analytics'
import { INSTRUMENT_EVENTS, INSTRUMENT_TAGS } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'

class DonateButton extends React.Component {
  static propTypes = {
    campaign: PropTypes.object,
    canChipIn: PropTypes.bool,
    canDonate: PropTypes.bool,
    createDNWTransaction: PropTypes.func,
    onClick: PropTypes.func,
    preview: PropTypes.bool,
  }

  handleClick = e => {
    const { campaign } = this.props
    trackClick({
      clickName: INSTRUMENT_TAGS.DONATE,
      data: {
        event_name: INSTRUMENT_EVENTS.DW_GIVING_DETAIL_DONATE_PRESSED,
      },
    })
    this.props.createDNWTransaction({ campaignId: campaign.id })
  }

  render() {
    if (this.props.preview) {
      return (
        <Button
          data-pa-click="donate"
          data-nemo="donate"
          styleName="donate-button preview"
        >
          <Message id="components.donate_button.donate" />
        </Button>
      )
    } else if (!this.props.canDonate || !this.props.canChipIn) {
      return null
    } else {
      return (
        <>
          <Button
            data-nemo="donate"
            styleName="donate-button"
            onClick={this.handleClick}
          >
            <Message id="components.donate_button.donate" />
          </Button>
          <div id="donate-button-cdn" />
        </>
      )
    }
  }
}

export default cssModules(DonateButton, styles, { allowMultiple: true })
