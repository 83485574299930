import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './update_row.less'
import { glimpseRoute } from 'lib/route_utils'
import { Card, Link } from '@paypalcorp/pp-react'
import { Link as RouterLink } from 'react-router'
import OrganizerUpdateDetail from 'components/glimpses/edit_glimpse/organizer_update_detail/organizer_update_detail'
import { trackFPTI } from 'lib/fpti_utils'
import { get } from 'lodash'

class UpdateRow extends React.Component {
  static propTypes = {
    organizerUpdate: PropTypes.Array.isRequired,
    postUpdateUrl: PropTypes.string,
    charity: PropTypes.object,
    campaignId: PropTypes.string,
    eventName: PropTypes.string,
    updateState: PropTypes.string,
    showLatest: PropTypes.boolean,
    truncateDescription: PropTypes.boolean,
    truncateSubject: PropTypes.boolean,
    isDonerView: PropTypes.boolean,
    campaign: PropTypes.object,
    count: PropTypes.number,
  }

  render() {
    const {
      organizerUpdate,
      postUpdateUrl,
      showLatest,
      truncateDescription,
      truncateSubject,
      isDonerView,
      updateState,
      eventName,
      campaign,
      count,
    } = this.props

    let organizerUpdateList = get(organizerUpdate, 'items', [])
    if (showLatest && organizerUpdateList.length) {
      organizerUpdateList = [organizerUpdateList[0]]
    }
    return (
      <>
        {organizerUpdateList.map((row, index) => (
          <Link
            styleName="update-tile"
            as={RouterLink}
            key={row.fou_id}
            to={glimpseRoute(
              'pools',
              `${postUpdateUrl}/${row.fou_id}/updatedetails`
            )}
            data-testid={`update-tile-${index}`}
            onClick={() => {
              trackFPTI(
                campaign,
                'click',
                eventName,
                '',
                '',
                count,
                updateState,
                index
              )
            }}
          >
            <Card styleName="activity-row" size="xs" key={row.fou_id}>
              <OrganizerUpdateDetail
                organizerUpdateRow={row}
                postUpdateUrl={postUpdateUrl}
                truncateDescription={truncateDescription}
                truncateSubject={truncateSubject}
                isDonerView={isDonerView}
                campaign={campaign}
                eventName={eventName}
                updateState={updateState}
              />
            </Card>
          </Link>
        ))}
      </>
    )
  }
}

export default cssModules(UpdateRow, styles)
