import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './standard_dialog.less'
import * as dialogStyles from './../dialog/dialog.less'
import { Link } from 'react-router'
import {
  Dialog,
  Button,
  HeadingText,
  BodyText,
  DeleteIcon,
  FundraiserIcon,
  LogoutIcon,
} from '@paypalcorp/pp-react'

class StandardDialog extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    okButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
    onQuit: PropTypes.func,
    isDelete: PropTypes.bool,
    size: PropTypes.string,
    iconName: PropTypes.string,
  }
  icon = name => {
    if (name === 'delete') {
      return <DeleteIcon color="sysColorAccent1Main" size="xl" />
    } else if (name === 'fundraiser') {
      return <FundraiserIcon color="sysColorAccent1Main" size="xl" />
    } else if (name === 'logout') {
      return <LogoutIcon color="sysColorAccent1Main" size="xl" />
    } else {
      return ''
    }
  }
  render() {
    const { iconName } = this.props
    const propsToTransfer = { ...this.props }
    delete propsToTransfer.styles
    return (
      <Dialog
        containerClassName={dialogStyles[`${this.props.size}-dialog`]}
        isOpen={true}
        onClose={this.props.onClose}
        hideTitle={true}
      >
        <div styleName="dialog-content">
          <div styleName="icon">{this.icon(iconName)}</div>
          <HeadingText size="sm" styleName="title">
            {this.props.title}
          </HeadingText>
          <BodyText styleName="subtitle">{this.props.subtitle}</BodyText>
          {this.props.okButtonTitle &&
            !this.props.isDelete && (
              <Button
                className="vx_btn vx_btn-block"
                styleName="ok-button"
                data-nemo="ok-button"
                onClick={this.props.onOk}
                tabIndex={0}
              >
                {this.props.okButtonTitle}
              </Button>
            )}
          {this.props.okButtonTitle &&
            this.props.isDelete && (
              <Button
                as={Link}
                data-nemo="deleteButton"
                styleName="delete-button"
                onClick={this.props.onOk}
                tabIndex={0}
              >
                {this.props.okButtonTitle}
              </Button>
            )}
          <Button
            className="vx_btn vx_btn-block vx_btn-secondary"
            styleName="close-button"
            data-nemo="close-button"
            onClick={this.props.onQuit ? this.props.onQuit : this.props.onClose}
            secondary
            tabIndex={0}
          >
            {this.props.closeButtonTitle}
          </Button>
        </div>
      </Dialog>
    )
  }
}

export default cssModules(StandardDialog, styles)
