import React from 'react'
import PropTypes from 'prop-types'
import {
  CaptionText,
  PhysicalCard,
  Col,
  PAYPAL_THEME,
  DeleteIcon,
  PictureIcon,
} from '@paypalcorp/pp-react'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { DEAFULT_COVER_IMAGES } from 'shared/shared_consts'
import { Message } from '@paypalcorp/worldready-react'
import { isImageDefaultCover } from 'shared/helpers/image_helper'
import styles from '../crowdfunding_select_cover.less'
import cssModules from 'react-css-modules'

const CustomImageSelection = ({
  selectedPhoto,
  defaultImage1,
  defaultImage2,
  defaultImage3,
  fields,
  handleSelectCover,
  charity,
  setSelectedPhoto,
  defaultCategoryCover,
}) => {
  const { sysColorPrimaryMainActive } = PAYPAL_THEME
  const onAddNewPhotoClick = () => {
    trackEventNameClick({
      event_name: 'dw_giving_fundraiser_add_photo_screen_shown',
    })
    trackEventNameClick({
      event_name: 'dw_giving_fundraiser_select_photo_customize_pressed',
    })
    /* defaultImage3 is index of the DEAFULT_COVER_IMAGES array, it should be zero */
    if (
      selectedPhoto === defaultCategoryCover ||
      selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage1] ||
      selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage2] ||
      (defaultImage3 >= 0 &&
        selectedPhoto === DEAFULT_COVER_IMAGES[defaultImage3])
    ) {
      fields.backgroundImage.onChange('')
      setSelectedPhoto('')
    }
    handleSelectCover()
  }

  const isDefaultSelected = [
    defaultCategoryCover,
    DEAFULT_COVER_IMAGES[defaultImage1],
    DEAFULT_COVER_IMAGES[defaultImage2],
    DEAFULT_COVER_IMAGES[defaultImage3],
  ]
    .filter(Boolean)
    .includes(fields.backgroundImage.value)

  const selectPhoto = () => {
    fields.backgroundImage.onChange('')
    setSelectedPhoto(defaultCategoryCover)
  }

  const isNotDefaultImage = !isImageDefaultCover(selectedPhoto)

  return (
    <Col>
      {!isDefaultSelected &&
      isNotDefaultImage &&
      fields.backgroundImage.value ? (
        <div>
          <PhysicalCard
            style={{
              background: `no-repeat url(${fields.backgroundImage.value})`,
              backgroundSize: 'contain',
              cursor: 'pointer',
            }}
            tabIndex={0}
            data-testid="custom_images__add_photo_card"
          >
            <div styleName="image-delete-container">
              <div
                data-testid="custom_images_delete-icon"
                onClick={() => selectPhoto()}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    selectPhoto()
                  }
                }}
                tabIndex={0}
              >
                <DeleteIcon size="sm" />
              </div>
              <div>
                <CaptionText strong style={{ color: '#fff' }}>
                  <Message id="components.pages.crowdfunding.crowdfunding_cover.delete_photo" />
                </CaptionText>
              </div>
            </div>
          </PhysicalCard>
        </div>
      ) : (
        <PhysicalCard
          onClick={onAddNewPhotoClick}
          data-testid="s-cover-upload-new-photo"
          tabIndex={0}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              onAddNewPhotoClick()
            }
          }}
        >
          <div styleName="upload-card">
            <PictureIcon size="md" />
            <CaptionText strong style={{ color: sysColorPrimaryMainActive }}>
              <Message id="components.pages.crowdfunding.crowdfunding_cover.upload_photo" />
            </CaptionText>
          </div>
        </PhysicalCard>
      )}
    </Col>
  )
}

CustomImageSelection.propTypes = {
  handleSelectCover: PropTypes.func,
  defaultCategoryCover: PropTypes.string,
  fields: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
    }),
  }),
  charity: PropTypes.object,
  defaultImage1: PropTypes.string,
  defaultImage2: PropTypes.string,
  defaultImage3: PropTypes.string,
  selectedPhoto: PropTypes.string,
  setSelectedPhoto: PropTypes.func,
}

export default cssModules(CustomImageSelection, styles)
