export function organizerUpdate(organizerUpdateId, organizerUpdateData) {
  const filterorganizerUpdate = organizerUpdateData.filter(
    element => element.fou_id === organizerUpdateId
  )
  return filterorganizerUpdate.length > 0 ? filterorganizerUpdate[0] : {}
}
export function formUpdatePayload(object) {
  return Object.keys(object).map(key => ({
    op: 'replace',
    path: `/${key}`,
    value: object[key],
  }))
}
