import _ from 'lodash'
import { getI18n } from 'intl'

const i18nBase = getI18n('components/crowdfunding_category')

export const getTranslatedCategoryList = state => {
  const catList = _.get(state, 'categoryData', [])
  const options = catList.map(listItem => {
    return {
      key: listItem.key,
      value: listItem.key,
      label: i18nBase(`category.${listItem.key}.name`),
      sortKey: i18nBase(`category.${listItem.key}.sortkey`),
      primaryText: i18nBase(`category.${listItem.key}.name`),
    }
  })
  options.sort((catA, catB) => catA.sortKey - catB.sortKey)
  return options
}

export const isValidCategory = (state, value = '') => {
  return _.find(
    getTranslatedCategoryList(state),
    listItem => listItem.value === value
  )
}

export const getCategoryLabel = (state, value = '') => {
  const campaignCategoryValue = value?.toString()
  const category =
    _.find(
      getTranslatedCategoryList(state),
      listItem => listItem.value === campaignCategoryValue
    ) || {}
  const categoryLabel = _.get(category, 'label', '')
  return categoryLabel
}

export const getCategoryDefaultCover = (state, value = '') => {
  const categoryList = _.get(state, 'categoryData', [])
  const selectedCategory = _.find(
    categoryList,
    category => category.key === value
  )

  return selectedCategory && selectedCategory.cover
}

export const getMainCategory = category => {
  if (Array.isArray(category)) {
    return category[0]
  }
  return category
}

const crowdfundingCategoryKey = [
  '1001',
  '1002',
  '1003',
  '1004',
  '1005',
  '1006',
  '1007',
  '1008',
  '1009',
  '1014',
  '1015',
  '1017',
  '1018',
  '1019',
  '1020',
  '1021',
  '1022',
]

export const filterDepreciatedCategory = (
  categoryList,
  isNewCategoryEnabled = true
) => {
  if (isNewCategoryEnabled) {
    return categoryList.filter(category =>
      crowdfundingCategoryKey.includes(category.key)
    )
  } else {
    return categoryList.filter(category => isNaN(category.key))
  }
}

export const sortCategory = categoryList => {
  return categoryList.sort((a, b) => a.label.localeCompare(b.label))
}

export const generateCategoryName = (state, category) => {
  if (!category) {
    return ''
  }
  if (Array.isArray(category)) {
    const categories = category.map(element => getCategoryLabel(state, element))
    return categories.join(', ')
  }
  return getCategoryLabel(state, category)
}

export default {
  getTranslatedCategoryList,
  filterDepreciatedCategory,
  isValidCategory,
  getCategoryLabel,
  getCategoryDefaultCover,
}
