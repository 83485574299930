import { USER_AGREEMENT_LINK_COUNTRIES } from 'shared/shared_consts'

export function getMoneyPoolTNCLink(paypalDomain, countryCode) {
  const baseUrl = `https://${paypalDomain}/${countryCode}/webapps/mpp/ua/`
  if (USER_AGREEMENT_LINK_COUNTRIES.indexOf(countryCode) > -1) {
    return `${baseUrl}useragreement-full`
  } else {
    return `${baseUrl}moneypool-tnc`
  }
}
