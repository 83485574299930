import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from '../crowdfunding_cover.less'
import { BodyText, Button, HeadingText } from '@paypalcorp/pp-react'
import CrowdFundingCoverImage from 'components/crowdfunding_cover_image'
import { trackEventNameClick } from 'lib/fpti_analytics'
import { Message } from '@paypalcorp/worldready-react'

const PositionPhoto = props => {
  const { categoryImage, coverImage, fields, handleCoverSelect } = props

  return (
    <>
      <HeadingText size="sm" styleName="title">
        <Message id="components.pages.crowdfunding.crowdfunding_cover.drag_title" />
      </HeadingText>

      <BodyText styleName="sub-title">
        <Message id="components.pages.crowdfunding.crowdfunding_cover.drap_subtitle" />
      </BodyText>

      <div
        data-testid="position-photo__uploader"
        styleName="uploader"
        onClick={() =>
          trackEventNameClick({
            event_name: 'dw_giving_fundraiser_add_photo_upload_pressed',
          })
        }
      >
        <CrowdFundingCoverImage
          fields={fields}
          ref={coverImage}
          categoryImage={categoryImage}
        />
      </div>

      <div styleName="buttons">
        <Button
          onClick={() =>
            handleCoverSelect(
              `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/select-cover`
            )
          }
          data-nemo="add-photo-"
          data-testid="position-photo__add-photo-btn"
          type="button"
        >
          <Message id="components.pages.crowdfunding.crowdfunding_cover.add_photo_btn" />
        </Button>
      </div>
    </>
  )
}

PositionPhoto.propTypes = {
  categoryImage: PropTypes.string.isRequired,
  coverImage: PropTypes.any.isRequired,
  fields: PropTypes.object.isRequired,
  handleCoverSelect: PropTypes.func.isRequired,
}

export default cssModules(PositionPhoto, styles)
