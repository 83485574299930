import TransferReviewBox from './transfer_review_box'
import { connect } from 'react-redux'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    title: campaign.title,
    currency: campaign.currency,
    amount: props.amount || campaign.moneybox_amount,
  }
}

export default connect(mapStateToProps)(TransferReviewBox)
