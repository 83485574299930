/* istanbul ignore file */
export const TITLE_LENGTH = 70
export const DESCRIPTION_LENGTH = 2000
export const CROWDFUNDING_DESCRIPTION_LENGTH = 2000
export const CROWDFUNDING_DESCRIPTION_MIN_LENGTH = 30
export const NOTE_LENGTH = 400

export const DESCRIPTION_ROWS = 10
export const SEND_NOTE_ROWS = 3

export const SHARE_MESSAGE_LENGTH = 250
