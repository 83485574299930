import { createSelector } from 'reselect'

const getCharity = state => state.charity

export const getFeaturedCharities = createSelector(getCharity, charity => {
  if (!(charity && charity.featuredCharities)) {
    return []
  }
  return charity.featuredCharities
})
