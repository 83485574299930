import { getValues } from 'redux-form'
import { saveTxnInCookie } from '../../lib/cookies_utils'
import { trackImpression } from 'lib/fpti_analytics'
import { decodeCampaignId } from 'shared/campaign_url'
import { LOCATION_CHANGE } from 'react-router-redux'

const RESET_IFRAME_STATE = 'pools/campaign/RESET_IFRAME_STATE'
const START_IFRAME_LOAD = 'pools/campaign/START_IFRAME_LOAD'
const FINISH_IFRAME_LOAD = 'pools/campaign/FINISH_IFRAME_LOAD'
const REPORT_ERROR_FROM_IFRAME = 'pools/campaign/REPORT_ERROR_FROM_IFRAME'
const FAILED_IFRAME_LOAD = 'pools/campaign/FAILED_IFRAME_LOAD'
const START_IFRAME_SEND = 'pools/campaign/START_IFRAME_SEND'
export const FINISH_IFRAME_SEND_SUCCESSFULLY =
  'pools/campaign/FINISH_IFRAME_SEND_SUCCESSFULLY'

const DEFAULT_STATE = {
  loading_done: false,
  iframe_loading: false,
  sending_iframe: false,
  send_successful: false,
  send_done: false,
  gs_bypass: window.location.search.indexOf('gsbps=true') >= 0,
}

export default function reducer(state = DEFAULT_STATE, action) {
  if (action.type === RESET_IFRAME_STATE) {
    return DEFAULT_STATE
  } else if (action.type === START_IFRAME_LOAD) {
    return { ...state, iframe_loading: true, loading_done: false }
  } else if (action.type === FINISH_IFRAME_LOAD) {
    return { ...state, iframe_loading: false, loading_done: true }
  } else if (action.type === REPORT_ERROR_FROM_IFRAME) {
    return { ...state, iframe_error: action.message }
  } else if (action.type === START_IFRAME_SEND) {
    return { ...state, send_done: false, amount: undefined }
  } else if (action.type === FINISH_IFRAME_SEND_SUCCESSFULLY) {
    return {
      ...state,
      campaign_id: action.payload.txn.campaignId,
      send_done: true,
      send_successful: true,
      amount: action.payload.txn.amount,
      payment_id: action.payload.txn.paymentId,
    }
  } else if (action.type === LOCATION_CHANGE) {
    return {
      ...state,
      iframe_loading: false,
    }
  }
  return state
}

export function resetIFrameState() {
  return { type: RESET_IFRAME_STATE }
}

export function startIFrameLoad() {
  return { type: START_IFRAME_LOAD }
}

export function finishIFrameLoad() {
  return { type: FINISH_IFRAME_LOAD }
}

export function reportErrorFromIFrame(message) {
  return { type: REPORT_ERROR_FROM_IFRAME, message }
}

export function failedIFrameLoad() {
  return { type: FAILED_IFRAME_LOAD }
}

export function startIFrameSend() {
  return { type: START_IFRAME_SEND }
}

export function finishIFrameSendSuccessfully(
  campaignId,
  amount,
  currency,
  paymentId
) {
  return (dispatch, getState) => {
    const state = getState()

    if (!getState().send_iframe.send_done) {
      let sendForm
      if (state.server.isReturningFromWallet) {
        sendForm = { ...state.preloadSendValues }
      } else {
        sendForm = getValues(getState().form.send)
      }
      const anonymous = sendForm.anonymous
      const note = sendForm.note
      const date = new Date().toISOString()
      let sender
      const txn = {
        amount: parseFloat(amount),
        campaignId,
        currency,
        note,
        paymentId,
        anonymous,
        date,
      }
      if (anonymous) {
        sender = {}
      } else {
        sender = {
          id: getState().current_user.id,
          name:
            getState().current_user.business_name ||
            getState().current_user.full_name,
          photo: getState().current_user.profile_photo,
        }
      }
      saveTxnInCookie(campaignId, getState().current_user.id, txn)
      trackImpression({
        pageName: 'campaign-xxx-send-success',
        campaignId: decodeCampaignId(campaignId),
        amount: amount,
        currency: currency,
      })
      // since there's no actual router change - need to track this manually
      dispatch({
        type: FINISH_IFRAME_SEND_SUCCESSFULLY,
        payload: { txn, sender },
      })
    }
  }
}
