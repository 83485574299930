import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './pictograms.less'
import { getCdnHostName } from '@paypalcorp/instance-location'

class Pictograms extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    svg: PropTypes.string,
    alt: PropTypes.string,
    classname: PropTypes.string,
    cdnHost: PropTypes.string,
    dataTestId: PropTypes.string,
  }

  static defaultProps = {
    type: 'multicolored',
    cdnHost: getCdnHostName(),
    alt: '',
  }

  render() {
    const { type, svg, alt, cdnHost, classname, dataTestId } = this.props
    return (
      <img
        src={`https://${cdnHost}/paypal-ui/pictograms/${type}/svg/${svg}.svg`}
        alt={alt}
        styleName={classname ? classname : ''}
        role={alt ? 'image' : 'presentation'}
        data-testid={dataTestId}
      />
    )
  }
}

export default cssModules(Pictograms, styles)
