export function getInitials(fullName) {
  // TODO: l10n?
  if (fullName == null || fullName.length < 1) {
    return ''
  }

  const splitName = fullName.split(/ +/g).filter(e => e)
  const firstName = splitName.length > 0 ? splitName[0] : null
  const lastName = splitName.length > 1 ? splitName[splitName.length - 1] : null
  return (
    (firstName ? firstName[0].toUpperCase() : '') +
    (lastName ? lastName[0].toUpperCase() : '')
  )
}
