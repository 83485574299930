import React from 'react'
import PropTypes from 'prop-types'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import cssModules from 'react-css-modules'
import { withRouter } from 'react-router'
import { routerShape } from 'react-router/lib/PropTypes'

import Modal from 'components/common/modal'
import BackButton from 'components/common/back_button'

import styles from './modal_wizard.less'

const TRANSITION_DURATION = 250 // in ms

class ModalWizard extends React.Component {
  static propTypes = {
    movingForward: PropTypes.bool,
    styles: PropTypes.object,
    glimpse: PropTypes.bool,
    showSpinner: PropTypes.bool,
    hideBack: PropTypes.bool,
    hideClose: PropTypes.bool,
    children: PropTypes.any,
    router: routerShape.isRequired,
    onClose: PropTypes.func,
    hideTopWithoutHeader: PropTypes.bool,
    isWideView: PropTypes.bool,
    progressPercent: PropTypes.number,
    showProgressBar: PropTypes.bool,
    isPadding: PropTypes.bool,
    previousURL: PropTypes.string,
    step: PropTypes.string,
  }

  handleScrollTop = () => {
    this.modal.scrollToTop()
  }

  removeStaleClass = (attempts = 4) => {
    if (attempts === 0) {
      return
    }
    setTimeout(() => {
      this.removeStaleClass(attempts - 1)
    }, TRANSITION_DURATION)
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.removeStaleClass()
      }.bind(this),
      TRANSITION_DURATION
    )
  }

  confirmNavigation = successCallback => {
    if (this._step && this._step.confirmNavigation) {
      return
    } else {
      successCallback()
    }
  }

  handleBack = () => {
    if (this.props.previousURL) {
      this.props.router.push({
        pathname: `${GLOBAL_CONSTS.REQUEST_URI}/c/create-crowdfunding/${
          this.props.previousURL
        }`,
      })
    }
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.confirmNavigation(this.props.onClose)
    }
  }

  UNSAFE_componentWillUpdate = newProps => {
    const child = React.Children.only(this.props.children)
    const newChild = React.Children.only(newProps.children)

    if (child.key !== newChild.key) {
      // will be set when the new step is mounted
      this._step = this.props.step
    }
  }

  render() {
    const {
      movingForward,
      showSpinner,
      glimpse,
      hideClose,
      hideBack,
      isWideView,
      progressPercent,
      showProgressBar,
      isPadding,
    } = this.props

    const leftHeader = !hideBack && <BackButton onClick={this.handleBack} />

    const transitionNameSuffix = movingForward ? 'forward' : 'backward'
    const transitionNames = {
      enter: this.props.styles[`step-enter-${transitionNameSuffix}`],
      enterActive: this.props.styles[
        `step-enter-${transitionNameSuffix}-active`
      ],
      leave: this.props.styles[`step-leave-${transitionNameSuffix}`],
      leaveActive: this.props.styles[
        `step-leave-${transitionNameSuffix}-active`
      ],
    }

    const child = React.Children.only(this.props.children)

    return (
      <Modal
        leftHeader={leftHeader}
        onClose={this.handleClose}
        glimpse={glimpse}
        showSpinner={showSpinner}
        showClose={!hideClose}
        hideTopWithoutHeader={this.props.hideTopWithoutHeader}
        isWideView={isWideView}
        isPadding={isPadding}
        ref={modal => {
          this.modal = modal
        }}
        progressPercent={progressPercent}
        showProgressBar={showProgressBar}
      >
        <ReactCSSTransitionGroup
          transitionName={transitionNames}
          transitionEnterTimeout={TRANSITION_DURATION}
          transitionLeaveTimeout={TRANSITION_DURATION}
          component="div"
          styleName="step-animation-container"
        >
          {/* TODO: the above style should be applied only while transitioning. However, ReactCSSTransitionGroup
						won't tell us whether we're transitioning. Relevant question in Stack Overflow:
						http://stackoverflow.com/questions/38419560/lifecycle-events-on-reactcsstransitiongroups-component */}
          {React.cloneElement(child, {
            onScrollTop: this.handleScrollTop,
          })}
        </ReactCSSTransitionGroup>
      </Modal>
    )
  }
}

export default withRouter(cssModules(ModalWizard, styles))
