import PostUpdateShare from './postupdate_share'
import { connect } from 'react-redux'
import preventNavigation from 'components/common/prevent_navigation'
import { getCampaignSelector } from 'redux/selectors/campaign'

const mapStateToProps = (state, props) => {
  const campaign = getCampaignSelector(state, props)
  return {
    cdnHost: state.geo.cdnHost,
    campaign,
  }
}

const mapDispatchToProps = (dispatch, { campaignId }) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(preventNavigation(PostUpdateShare, props => props.operatingRemotely))
