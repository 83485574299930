import React from 'react'
import PropTypes from 'prop-types'
import Clipboard from 'clipboard'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import cssModules from 'react-css-modules'
import styles from './copy_box.less'
import FirstChild from 'components/common/first_child'
import { trackClick } from 'lib/fpti_analytics'
import { Message } from '@paypalcorp/worldready-react'

const TOOLTIP_ANIMATION_DURATION = 250
const TOOLTIP_SHOW_DELAY = 750

class CopyBox extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired, // Text to show the user
    copyText: PropTypes.string, // Text to copy to clipboard when user clicks 'copy'. defaults to props.text.
    styles: PropTypes.object,
    enabled: PropTypes.bool,
    charity: PropTypes.object,
    eventName: PropTypes.string,
  }

  static defaultProps = { enabled: true }

  state = {
    copyText: this.props.copyText || this.props.text,
    showTooltip: false,
  }

  componentDidMount() {
    if (this.props.enabled) {
      const copyText = this.state.copyText
      const copyBoxTextRef = this.copyBoxText
      this.clipboard = new Clipboard(this.copyLink, {
        text() {
          return copyText
        },
        target() {
          return copyBoxTextRef
        },
      })
      this.clipboard.on('success', this.handleCopySuccess)
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  handleCopySuccess = e => {
    this.setState({ showTooltip: true })
    setTimeout(() => {
      this.setState({ showTooltip: false })
    }, TOOLTIP_SHOW_DELAY)
  }

  handleFPTI = () => {
    trackClick({
      clickName: 'copy',
      data: {
        event_name: this.props.eventName,
        link_name: 'copy',
      },
    })
  }

  render() {
    const transitionNames = {
      enter: this.props.styles['tooltip-enter'],
      enterActive: this.props.styles['tooltip-enter-active'],
      leave: this.props.styles['tooltip-leave'],
      leaveActive: this.props.styles['tooltip-leave-active'],
    }

    return (
      <div styleName="copy-box-wrapper">
        <div styleName={this.props.enabled ? 'copy-box' : 'copy-box-disabled'}>
          <label htmlFor="share">
            <input
              type="text"
              ref={copyBoxText => {
                this.copyBoxText = copyBoxText
              }}
              value={this.props.text}
              data-nemo="share"
              readOnly={true}
              name="share"
            />
          </label>
          {this.props.enabled && (
            <span styleName="copy-link">
              <a
                data-nemo="copy"
                href="javascript:;"
                onClick={this.handleFPTI}
                ref={copyLink => {
                  this.copyLink = copyLink
                }}
              >
                <Message id="components.common.copy_box.copy" />
              </a>
              <ReactCSSTransitionGroup
                component={FirstChild}
                transitionName={transitionNames}
                transitionEnterTimeout={TOOLTIP_ANIMATION_DURATION}
                transitionLeaveTimeout={TOOLTIP_ANIMATION_DURATION}
              >
                {this.state.showTooltip && (
                  <div styleName="copied-tooltip">
                    <Message id="components.common.copy_box.copied" />
                  </div>
                )}
              </ReactCSSTransitionGroup>
            </span>
          )}
        </div>
      </div>
    )
  }
}

export default cssModules(CopyBox, styles)
