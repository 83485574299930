import { glimpseRoute, glimpselessRoute } from 'lib/route_utils'
import { get } from 'lodash'

let glimpseHistories = {}

export function firstInGlimpse(glimpseName) {
  return glimpseHistories[glimpseName].length === 1
}

export function trackEnter(glimpseName, nextState, replace) {
  glimpseHistories[glimpseName] = [nextState.location]

  if (!getLocationGlimpse(nextState.location)) {
    replace({
      ...nextState.location,
      state: { ...nextState.location.state, glimpse: glimpseName },
    })
  }
}

export function trackChange(glimpseName, prevState, nextState, replace) {
  if (nextState.location.action === 'PUSH') {
    glimpseHistories[glimpseName].push(nextState.location)
  } else if (nextState.location.action === 'POP') {
    const historyStack = glimpseHistories[glimpseName] || []

    // Since 'POP' can mean either browser back or browser forward (!), we need to check for that
    // by inspecting the stack. If the history stack contains the location, it's a back, and if not,
    // it's forward.
    const stackItemIndex = historyStack.findIndex(
      location => location.pathname === nextState.location.pathname
    )

    if (stackItemIndex >= 0 || !historyStack.length) {
      // it's really a back action
      glimpseHistories[glimpseName] = historyStack.slice(0, stackItemIndex + 1)
    } else {
      // it's a forward action
      nextState.location.state = {
        ...(nextState.location.state || {}),
        forward: true,
      }
      historyStack.push(nextState.location)
    }
  }

  if (!getLocationGlimpse(nextState.location)) {
    replace({
      ...nextState.location,
      state: { ...nextState.location.state, glimpse: glimpseName },
    })
  }
}

export function exitGlimpse(
  glimpseName,
  router,
  routePath,
  manageBackForNewTab
) {
  const currentPathName = get(router, 'location.pathname', '')
  if (
    glimpseName === 'send' &&
    routePath &&
    currentPathName.endsWith('review')
  ) {
    router.push(routePath)
  } else if (
    Object.keys(glimpseHistories).length === 1 &&
    manageBackForNewTab
  ) {
    router.replace(routePath)
  } else if (Object.keys(glimpseHistories).length > 0) {
    router.go(-glimpseHistories[glimpseName].length)
  } else {
    router.goBack()
  }
  delete glimpseHistories[glimpseName]
}

export function addGlimpselessRouteToHistory(
  glimpseName,
  nextState,
  replace,
  history
) {
  if (nextState.location.state && nextState.location.state.initial) {
    replace({
      pathname: glimpselessRoute(glimpseName),
    })

    // we need to do this on the next tick to give a chance to the replace() do to its thing first.
    setTimeout(() => {
      history.push(glimpseRoute(glimpseName))
    }, 0)
  }
}

export function getLocationGlimpse(location) {
  if (location.state) {
    return location.state.glimpse
  }
  return null
}

export function isForward(location) {
  return location.action !== 'POP' || (location.state && location.state.forward)
}
