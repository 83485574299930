import { POOLS_CAMPAIGN_TYPES, INVALID_IMAGE } from 'shared/shared_consts'
import { CREATION_BLOCKS } from 'components/pages/create/create'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES

export function getBlockType(state, props, campaign) {
  let blockType
  if (state.current_user.enable_crowdfunding_business_account === false) {
    if (state?.country?.country_code === 'US') {
      blockType = CREATION_BLOCKS.byAccountType
    } else {
      blockType = CREATION_BLOCKS.genericError
    }
  } else if (state.current_user.moneypools_country_blocked) {
    blockType = CREATION_BLOCKS.byCountry
  } else if (!state.flags.allow_creation) {
    blockType = CREATION_BLOCKS.byKillSwitch
  } else if (campaign.err && campaign.err.error === INVALID_IMAGE) {
    blockType =
      campaign.campaign_type === CROWD_FUNDING
        ? CREATION_BLOCKS.byGNAccountRestricted
        : CREATION_BLOCKS.byAccountRestricted
  } else if (state.current_user.blockCreationByCurrencyMismatch) {
    blockType = CREATION_BLOCKS.currencyMismatchError
  }
  return blockType
}
