import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './create_type_small_group.less'
import CampaignEligibility from 'components/common/campaign_eligibility'
import { getI18n } from 'intl'
import { sendEnsightenEvent } from 'lib/ensighten_analytics'
import { HeadingText } from '@paypalcorp/pp-react'
import CreateCard from 'components/common/create_card'
import { trackClick } from 'lib/fpti_analytics'
import { POOLS_CAMPAIGN_TYPES, POOL_TYPE } from 'shared/shared_consts'
import { hasDirectIntegrationIdentifier } from 'client/lib/campaign'
import { Message } from '@paypalcorp/worldready-react'
import Pictograms from 'components/common/pictograms'
import Introduction from 'components/pages/introduction'

export const I18N_CONTEXT = 'components/pages/create/small_group'

const { CROWD_FUNDING } = POOLS_CAMPAIGN_TYPES
const i18n = getI18n(I18N_CONTEXT)
const COUNTRY_CODE_DEFAULT = 'US'

const TYPE_MAP = {
  'reach-lot-of-people': POOL_TYPE.PERSONAL,
  'charity-fundraiser': POOL_TYPE.CHARITY,
  'collect-money-with-friends': POOL_TYPE.PRIVATE,
}

class PoolTypeSmallGroup extends React.Component {
  static propTypes = {
    countryCode: PropTypes.string,
    formattedMaxGoalAmount: PropTypes.string,
    isGNCEnabled: PropTypes.bool,
    isCampaignLimitReached: PropTypes.bool,
    gnHref: PropTypes.string,
    gncHref: PropTypes.string,
    userHasCampaigns: PropTypes.bool,
    location: PropTypes.shape({
      query: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }

  static defaultProps = {
    userHasCampaigns: true,
    countryCode: COUNTRY_CODE_DEFAULT,
  }

  state = {
    showErrorDialog: false,
  }

  showDialogIfTypePresentInQueryString() {
    const poolType = TYPE_MAP[(this.props.location?.query?.type)]
    if (poolType) {
      this.limitDialogToggle(poolType)
    }
  }

  componentDidMount() {
    this.showDialogIfTypePresentInQueryString()
    sendEnsightenEvent('MoneyPoolStart')
  }

  limitDialogToggle = type => {
    if (this.props.isCampaignLimitReached && type !== POOL_TYPE.CHARITY) {
      this.setState(prevState => ({
        showErrorDialog: !prevState.showErrorDialog,
      }))
    } else if (type) {
      this.setState({ type })
    }
  }
  closeFullHalfSheet = () => {
    this.setState({ type: '' })
  }
  //This Method will be used until GN elmo is not enabled
  trackGNEvent = () => {
    this.limitDialogToggle()
    trackClick({
      clickName: 'dw_giving_intro_create_fundraiser_individual_pressed',
      data: {
        event_name: 'dw_giving_intro_create_fundraiser_individual_pressed',
      },
    })
  }

  render() {
    const { countryCode, isGNCEnabled, userHasCampaigns } = this.props
    const charityCampaignSubtitle = hasDirectIntegrationIdentifier(countryCode)
      ? 'charity_campaign_subtitle_direct1'
      : 'charity_campaign_subtitle1'
    const { type } = this.state
    return (
      <div styleName="top-alignment">
        <div styleName="header-section">
          <Pictograms
            svg="donate"
            data-testid="donate-img"
            classname="category-selection-image"
          />
        </div>
        {this.state.showErrorDialog && (
          <CampaignEligibility
            campaignType={CROWD_FUNDING}
            onClose={this.limitDialogToggle}
            dialog={true}
            iconName="fundraiser"
          />
        )}
        <HeadingText
          size="sm"
          styleName="title"
          data-testid="create_type-title"
        >
          {userHasCampaigns ? (
            <Message id="components.pages.create.small_group.small_group_start_fundraiser_title" />
          ) : (
            <Message id="components.pages.create.pool_type.fundraiser_title" />
          )}
        </HeadingText>
        <CreateCard
          isSmallGroup
          title={i18n('campaign_title1')}
          data_nemo="crowdfunding-pool-link"
          dataTestId="create_type-personal-flow"
          subtitle={i18n('campaign_subtitle1')}
          event_name={'dw_giving_intro_create_fundraiser_listed_pressed'}
          onClick={() => this.limitDialogToggle(POOL_TYPE.PERSONAL)}
        />
        <CreateCard
          isSmallGroup
          title={<Message id="flow.common.type.card_private_title" />}
          subtitle={<Message id="flow.common.type.card_private_subtitle" />}
          event_name={'dw_giving_intro_create_fundraiser_small_group_pressed'}
          data_nemo="gn_small_group_creation_link"
          dataTestId="create_type-private-flow"
          onClick={() => this.limitDialogToggle(POOL_TYPE.PRIVATE)}
        />

        {isGNCEnabled && (
          //If gnc is enabled then we need to show the chatiry card.
          <CreateCard
            isSmallGroup
            title={i18n('charity_campaign_title1')}
            subtitle={i18n(`${charityCampaignSubtitle}`)}
            event_name={'dw_giving_intro_create_fundraiser_charity_pressed'}
            data_nemo="gnc_creation_link"
            dataTestId="create_type-charity-flow"
            onClick={() => this.limitDialogToggle(POOL_TYPE.CHARITY)}
          />
        )}
        {type && (
          <Introduction
            type={type}
            hideFullHalfSheet={this.closeFullHalfSheet}
            gnHref={this.props.gnHref}
            gncHref={this.props.gncHref}
          />
        )}
      </div>
    )
  }
}

export default cssModules(PoolTypeSmallGroup, styles)
