import Introduction from './introduction'
import { connect } from 'react-redux'
import { NEW_CAMPAIGN_ID, setData } from 'redux/modules/campaign'
import { destroy } from 'redux-form'
import { formatCurrency } from 'intl'
import { getMinMaxAmount } from 'shared/helpers/campaign'
import { injectWorldReady } from '@paypalcorp/worldready-react'

const mapStateToProps = (state, { type, worldReady }) => {
  const campaign = state.campaign[NEW_CAMPAIGN_ID]
  const minMaxAmount = getMinMaxAmount(campaign)
  const countryCode = state.country.country_code
  const isFundRaiserEnabled = state.flags.isFundRaiserEnabled

  return {
    countryCode,
    host: state.geo.cdnHost,
    type,
    formattedMaxGoalAmount: formatCurrency({
      value: minMaxAmount.MAX_AMOUNT,
      currencyCode: campaign.currency,
      additionalConfig: {},
      worldReady,
    }),
    isFundRaiserEnabled,
  }
}

const mapDispatchToProps = dispatch => ({
  onPageLoad(selectionType) {
    dispatch(
      setData(NEW_CAMPAIGN_ID, {
        type: selectionType,
      })
    )
    dispatch(destroy('crowdfunding_basics'))
  },
})

export default injectWorldReady(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Introduction)
)
