import React from 'react'
import PropTypes from 'prop-types'
import cssModules from 'react-css-modules'
import styles from './flat_progress.less'
import { useIntl } from 'intl'
import {
  ProgressMeter,
  HeadingText,
  CaptionText,
  BodyText,
} from '@paypalcorp/pp-react'
import { Message } from '@paypalcorp/worldready-react'
import { POOL_TYPE } from 'shared/shared_consts'
import clsx from 'clsx'

const FlatProgress = props => {
  const { dataTestId, size, campaignType, detailsPageSpace } = props
  const { formatCurrency, formatPercent, formatMessage } = useIntl()

  const percent = formatPercent(props.totalAmount / props.goalAmount)

  const formattedGoalAmount = formatCurrency(
    props.goalAmount,
    props.currencyCode
  )

  const formattedTotalAmount = formatCurrency(
    props.totalAmount,
    props.currencyCode
  )

  const amountOrPercent = props.displayTotalAmount
    ? formattedTotalAmount
    : percent

  let amountOrPercentContentKey = props.displayTotalAmount
    ? 'amount_of_goal'
    : 'raised_amount_goal'

  if (campaignType === POOL_TYPE.PRIVATE) {
    amountOrPercentContentKey = 'collected_amount_goal'
  }

  return (
    <div styleName="flat-progress">
      <div>
        {props.title ? (
          <CaptionText clampAtLine={1} styleName="title" data-testid="title">
            {props.titleIcon} {props.title}
          </CaptionText>
        ) : (
          <HeadingText
            size="lg"
            styleName={clsx('amount', {
              'amount--mb': detailsPageSpace,
            })}
            data-testid={dataTestId}
          >
            {formatCurrency(props.totalAmount, props.currencyCode)}
          </HeadingText>
        )}
      </div>
      {props.showProgress && props.goalAmount > 0 ? (
        <ProgressMeter
          max={Number(props.goalAmount)}
          value={Number(props.totalAmount)}
          steps={amountOrPercent}
          title={formatMessage(
            `components.common.flat_progress.${amountOrPercentContentKey}`,
            { amount: formattedGoalAmount }
          )}
          styleName="meter-component"
          size={size}
          data-testid={`${dataTestId}-bar`}
        />
      ) : (
        <BodyText data-testid="flat-progress__collected-msg">
          <Message id="components.common.flat_progress.collected" />
        </BodyText>
      )}
    </div>
  )
}

FlatProgress.propTypes = {
  currencyCode: PropTypes.string,
  totalAmount: PropTypes.number,
  goalAmount: PropTypes.value,
  showProgress: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.element,
  displayTotalAmount: PropTypes.bool,
  dataTestId: PropTypes.string,
  size: PropTypes.string,
  campaignType: PropTypes.string,
  detailsPageSpace: PropTypes.bool,
}

FlatProgress.defaultProps = {
  titleIcon: '',
  showProgress: true,
  displayTotalAmount: false,
  dataTestId: 'flat-progress',
}

export default cssModules(FlatProgress, styles, { allowMultiple: true })
