import {
  CROWDFUNDING_GOAL_AMOUNT,
  INTEGRATION_IDENTIFIER_BY_COUNTRY,
  INTEGRATION_IDENTIFIER,
  NON_DISCOVERABLE,
  ONLY_PEOPLE_WITH_LINK,
  EVERYONE,
} from '../shared_consts'

export function getMinMaxAmount(campaign) {
  return CROWDFUNDING_GOAL_AMOUNT[campaign.currency] || {}
}

export function hasPPGFIntegrationIdentifier(countryCode) {
  return Boolean(
    INTEGRATION_IDENTIFIER_BY_COUNTRY[countryCode]?.INTEGRATION_IDENTIFIER ===
      INTEGRATION_IDENTIFIER.PPGF
  )
}

export function getAudience(type) {
  const audience = type === NON_DISCOVERABLE ? ONLY_PEOPLE_WITH_LINK : EVERYONE
  return audience
}
